import { createDataModule } from '@perpay-web/data-module/createDataModule';
import * as actions from '@perpay-web/fintech/actions/ui/addressDetails';

const FetchAddressDetailsDataModule = createDataModule({
    dataRequest: actions.fetchAddressDetailsForDataModule,
    dataSuccess: actions.replaceAddressDetails,
    dataError: actions.addressDetailsError,
    dataReset: actions.resetAddressDetails,
});

const getRoot = (state) => state.dataModules.fetchAddressDetails;
const initialValue = {
    addressDetails: {},
};

export const fetchAddressDetailsDataModule = FetchAddressDetailsDataModule({
    getRoot,
    initialValue,
});

export const getAddressDetails = (state) =>
    fetchAddressDetailsDataModule.getData(state).addressDetails;
