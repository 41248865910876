import { useEffect } from 'react';

/**
 * The effect hook is used to trigger behavior on component mount and unmount.
 * Its API is somewhat implicit, so this utility custom hook useMount clarifies
 * when functionality is specifically only for mount.
 *
 * onMountCb is called when the component that uses the useMountAndUnmount hook is mounted.
 *
 * The raw useEffect hook is still needed if the behavior needs to be triggered more than once.
 *
 * Example Usage:
 * ```
 * const MyComponent({
 *   onMount,
 * }) => {
 *   useMount(() => {
 *     onMount();
 *   });
 *   return <p>Hello, world!</p>
 * };
 * ```
 *
 * https://reactjs.org/docs/hooks-effect.html
 */
export const useMount = (onMountCb) =>
    useEffect(() => {
        onMountCb();

        // explicitly return undefined because no cleanup is needed.
        return undefined;

        // We disable the eslint rule for this line because we want `onMountCb` to be called
        // once and only once, even if it changes from props passed down from above.
        // If we added `onMountCb` to this deps array, it would be called 1. on mount and 2. each time
        // the value of this variable changes from props, which is unexpected and different than the
        // behavior of componentDidMount. Basically, "I'm 99% sure I know what I'm doing here"TM
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
