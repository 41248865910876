import {
    STORE_REPLACE_PINWHEEL_SURVEY_QUESTIONS,
    STORE_RESET_PINWHEEL_SURVEY,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const surveyQuestionsReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_PINWHEEL_SURVEY_QUESTIONS:
            return { ...state, ...payload };
        case STORE_RESET_PINWHEEL_SURVEY:
            return initialState;
        default:
            return state;
    }
};

export default surveyQuestionsReducer;
