import { createCombinedProvider } from '@perpay-web/utils/componentUtils';
import { CenterHeaderComponentStackProvider } from '@perpay-web/fintech/hooks/useCenterHeaderContext';
import { FullBottomNavComponentStackProvider } from '@perpay-web/hooks/useFullBottomNavContext';
import { FullHeaderComponentStackProvider } from '@perpay-web/fintech/hooks/useFullHeaderContext';
import { HeaderRightNavComponentStackProvider } from '@perpay-web/fintech/hooks/useHeaderRightNavContext';
import { HeaderLeftNavComponentStackProvider } from '@perpay-web/fintech/hooks/useHeaderLeftNavContext';
import { TitleComponentStackProvider } from '@perpay-web/hooks/useTitleContext';

const ComponentStackProviders = createCombinedProvider(
    CenterHeaderComponentStackProvider,
    FullBottomNavComponentStackProvider,
    FullHeaderComponentStackProvider,
    HeaderRightNavComponentStackProvider,
    HeaderLeftNavComponentStackProvider,
    TitleComponentStackProvider,
);

export default ComponentStackProviders;
