import {
    BACKEND_FETCH_CREDIT_SCORES_SUMMARY,
    STORE_REPLACE_CREDIT_SCORES_SUMMARY,
    STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR,
    STORE_RESET_CREDIT_SCORES_SUMMARY,
} from '@perpay-web/fintech/constants/actionTypes';

export const fetchCreditScoresSummary = () => ({
    type: BACKEND_FETCH_CREDIT_SCORES_SUMMARY,
});

export const replaceCreditScoresSummary = (creditScoresSummary) => ({
    type: STORE_REPLACE_CREDIT_SCORES_SUMMARY,
    payload: creditScoresSummary,
});

export const fetchCreditScoresSummaryError = (error) => ({
    type: STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR,
    payload: error,
});

export const resetCreditScoresSummary = () => ({
    type: STORE_RESET_CREDIT_SCORES_SUMMARY,
});
