import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    creditUnfreezeCardAccountApplication,
    creditUnfreezeCardAccountApplicationSuccess,
    creditUnfreezeCardAccountApplicationError,
    creditUnfreezeCardAccountApplicationReset,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';

const CreditUnfreezeCardAccountApplicationDataModule = createDataModule({
    dataRequest: creditUnfreezeCardAccountApplication,
    dataSuccess: creditUnfreezeCardAccountApplicationSuccess,
    dataError: creditUnfreezeCardAccountApplicationError,
    dataReset: creditUnfreezeCardAccountApplicationReset,
});

const getRoot = (state) =>
    state.dataModules.unfreezeCreditForCardAccountApplication;
const initialValue = {};

export const creditUnfreezeForCardAccountApplicationDataModule =
    CreditUnfreezeCardAccountApplicationDataModule({
        getRoot,
        initialValue,
    });
