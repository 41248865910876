import { createAction } from '@reduxjs/toolkit';

/**
 * @param {string} prefix
 * @param {object} payloadCreatorOverrides
 */
export const createDataModuleActions = (
    prefix,
    payloadCreatorOverrides = {},
) => {
    const REQUEST_KEY = 'dataRequest';
    const SUCCESS_KEY = 'dataSuccess';
    const ERROR_KEY = 'dataError';
    const RESET_KEY = 'dataReset';

    const actionTypes = {
        [REQUEST_KEY]: `${prefix}::REQUEST`,
        [SUCCESS_KEY]: `${prefix}::SUCCESS`,
        [ERROR_KEY]: `${prefix}::ERROR`,
        [RESET_KEY]: `${prefix}::RESET`,
    };

    // By default, most of our action types will take a single argument
    // which is the payload value of the created action
    // The exception to this is dataReset, which doesn't have a payload
    const defaultPayloadCreators = {
        [REQUEST_KEY]: (payload) => payload,
        [SUCCESS_KEY]: (payload) => payload,
        [ERROR_KEY]: (error) => error,
        [RESET_KEY]: null,
    };
    const payloadCreators = {
        ...defaultPayloadCreators,
        ...payloadCreatorOverrides,
    };

    const createDataModuleActionCreator = (key) => {
        const actionType = actionTypes[key];
        const payloadCreatorFunction = payloadCreators[key];
        let prepareFunction;
        if (typeof payloadCreatorFunction === 'function') {
            prepareFunction = (...payloadArgs) => ({
                payload: payloadCreatorFunction(...payloadArgs),
            });
        }

        return createAction(actionType, prepareFunction);
    };

    // Here we are returning an object of action creators.
    // Depending on overrides provided to this function, these
    // action creators can take a variable number of arguments to
    // their payload, or can be configured to not have a payload at all.
    return {
        [REQUEST_KEY]: createDataModuleActionCreator(REQUEST_KEY),
        [SUCCESS_KEY]: createDataModuleActionCreator(SUCCESS_KEY),
        [ERROR_KEY]: createDataModuleActionCreator(ERROR_KEY),
        [RESET_KEY]: createDataModuleActionCreator(RESET_KEY),
    };
};
