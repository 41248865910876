import { noop } from '@perpay-web/utils/noop';

/**
 * The `deferred` pattern allows Promises to be resolved
 * outside the context that created the Promise. The resolve
 * and reject functions are not meant to be exposed and should
 * remain part of a private interface.
 */
export const deferred = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return { resolve, reject, promise };
};

/**
 * allSettled doesn't care if the requests are successful,
 * only that resolved as value or rejected with an error.
 * This is a sorta-equivalent to Promise.allSettled,
 * Just without the structured results return value.
 */
export const allSettled = (promises) =>
    Promise.all(promises.map((promise) => promise.catch(noop)));

/**
 * Ensure that if a Promise instance is already in-flight from promiseCb,
 * then that live instance is returned to callers.
 */
export const wrapInflight = (promiseCb) => {
    let inflightPromise = null;

    return (...args) => {
        if (inflightPromise) {
            return inflightPromise;
        }

        inflightPromise = promiseCb(...args).finally(() => {
            inflightPromise = null;
        });
        return inflightPromise;
    };
};
