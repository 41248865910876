/**
 * A function that semantically indicates that no operation is required.
 *
 * Use when you need to pass a function instead of passing an empty arrow function
 *
 * ```js
 * // DON'T
 * someService({ onRequiredUnnecessaryCallback: () => {} });
 *
 * // DO
 * someService({ onRequiredUnnecessaryCallback: noop });
 * ```
 * */
export function noop() {}
