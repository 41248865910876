import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchFeatureEnrollments,
    fetchFeatureEnrollmentsSuccess,
    fetchFeatureEnrollmentsError,
    fetchFeatureEnrollmentsReset,
} from '@perpay-web/fintech/actions/entities/featureEnrollments';
import {
    LEGACY_PERPAY_PLUS,
    PERPAY_PLUS,
} from '@perpay-web/fintech/constants/featureTypes';

const FetchFeatureEnrollmentsDataModule = createDataModule({
    dataRequest: fetchFeatureEnrollments,
    dataSuccess: fetchFeatureEnrollmentsSuccess,
    dataError: fetchFeatureEnrollmentsError,
    dataReset: fetchFeatureEnrollmentsReset,
});

const getRoot = (state) => state.dataModules.fetchFeatureEnrollments;
const initialValue = {
    featureEnrollments: [],
};

export const fetchFeatureEnrollmentsDataModule =
    FetchFeatureEnrollmentsDataModule({
        getRoot,
        initialValue,
    });

const getPerpayPlusEnrollments = (state) => {
    const { featureEnrollments } =
        fetchFeatureEnrollmentsDataModule.getData(state);
    const perpayPlusEnrollments = featureEnrollments.filter(
        (enrollment) =>
            enrollment.feature === PERPAY_PLUS ||
            enrollment.feature === LEGACY_PERPAY_PLUS,
    );
    return perpayPlusEnrollments;
};

export const getHasEverOptedInToPerpayPlus = (state) => {
    const perpayPlusEnrollments = getPerpayPlusEnrollments(state);
    return Boolean(perpayPlusEnrollments.length);
};
