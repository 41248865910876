import { UNREQUESTED_STATE } from '@perpay-web/data-module/constants';

const getDefaultValue = (optInitialValue) => {
    if (Array.isArray(optInitialValue)) {
        return optInitialValue;
    }
    return optInitialValue ? { ...optInitialValue } : {};
};

export const getDataModuleInitialState = (optInitialValue) => ({
    requestState: UNREQUESTED_STATE,
    value: getDefaultValue(optInitialValue),
    errors: {},
});
