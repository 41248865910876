import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';
import { PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS } from '@perpay-web/fintech/components/composite/PerpaySplitSetUpPayments/PerpaySplitSetUpPaymentsSteps';
import {
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA,
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_SUCCESS,
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: true,
};

const perpaySplitSetUpPaymentsReducer = (state = initialState, action = {}) => {
    const { type } = action;

    switch (type) {
        case FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA:
            return { ...state, loading: true };
        case FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_SUCCESS:
        case FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS,
    perpaySplitSetUpPaymentsReducer,
);
