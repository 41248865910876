import { exhaustMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import {
    reduxStepsSetStep,
    reduxStepsStepSelector,
    reduxStepsGetKey,
} from '@perpay-web/hooks/useReduxSteps';
import { getRoot as getRootMVP2 } from '@perpay-web/fintech/selectors/ui/cardOnboardingMVP2';
import { getRoot as getRootMetal } from '@perpay-web/fintech/selectors/ui/cardOnboardingMetal';
import { getABTestEnabled } from '@perpay-web/fintech/dataModules/fetchABTests';
import { METAL_CARD_STORE_NAME } from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import {
    updateUserInfoForCardOnboardingMVP2 as updateUserInfoForCardOnboardingMVP2Action,
    updateUserInfoForCardOnboardingMVP2Success,
    updateUserInfoForCardOnboardingMVP2Error,
    updateUserInfoForDataModule,
    updateUserInfoSuccess,
    updateUserInfoErrorForDataModule,
} from '@perpay-web/fintech/actions/entities/userInfo';
import { getSsnIsVerified } from '@perpay-web/fintech/dataModules/crud/userInfoForCardOnboardingMVP2';
import {
    ADDRESS_REJECTED,
    VERIFY_IDENTITY_STEP,
    CARD_ONBOARDING_MVP_2_STEPS,
    REVIEW_TERMS_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import statesIneligibleForCard from '@perpay-web/fintech/constants/statesIneligibleForCardCardSignupMVP';

const getNextStep = (currentStep, ssnIsVerified) => {
    if (currentStep === VERIFY_IDENTITY_STEP || ssnIsVerified) {
        return REVIEW_TERMS_STEP;
    }
    return VERIFY_IDENTITY_STEP;
};

const getNextMetalStep = (currentStep, ssnIsVerified) => {
    if (
        currentStep === metalSteps.IDENTITY_VERIFICATION_STEP ||
        ssnIsVerified
    ) {
        return metalSteps.SELECT_MATERIAL_STEP;
    }
    return metalSteps.IDENTITY_VERIFICATION_STEP;
};

export const updateUserInfoForCardOnboardingMVP2 = (action$, state$) =>
    action$.pipe(
        ofType(updateUserInfoForCardOnboardingMVP2Action().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    updateUserInfoForDataModule(action.payload),
                ],
                waitFor: [updateUserInfoSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const isMetalCardFlow =
                        METAL_CARD_IS_LAUNCHED &&
                        getABTestEnabled(state, METAL_CARD_STORE_NAME);
                    const reduxKey = isMetalCardFlow
                        ? Object.values(metalSteps)
                        : CARD_ONBOARDING_MVP_2_STEPS;

                    if (statesIneligibleForCard.includes(payload.state)) {
                        const reduxStep = isMetalCardFlow
                            ? metalSteps.ADDRESS_REJECTED_STEP
                            : ADDRESS_REJECTED;
                        return [
                            // Send the success to update requestState and not run into infinite loader when we move back screens
                            updateUserInfoForCardOnboardingMVP2Success(payload),
                            reduxStepsSetStep(reduxKey, reduxStep),
                        ];
                    }

                    const reduxStepsState = isMetalCardFlow
                        ? getRootMetal(state)
                        : getRootMVP2(state);
                    const currentStep = reduxStepsStepSelector(
                        reduxStepsState,
                        reduxStepsGetKey(reduxKey),
                    );
                    const ssnIsVerified = getSsnIsVerified(state);
                    const nextStep = isMetalCardFlow
                        ? getNextMetalStep(currentStep, ssnIsVerified)
                        : getNextStep(currentStep, ssnIsVerified);

                    return [
                        updateUserInfoForCardOnboardingMVP2Success(payload),
                        reduxStepsSetStep(reduxKey, nextStep),
                    ];
                },
                errors: [updateUserInfoErrorForDataModule().type],
                errorDispatch: (result) => [
                    updateUserInfoForCardOnboardingMVP2Error(result.payload),
                ],
            }),
        ),
    );
