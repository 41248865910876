import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchDataModuleActions = createDataModuleActions('FETCH_CARD_STATEMENTS');
export const fetchCardStatements = fetchDataModuleActions.dataRequest;
export const replaceCardStatements = fetchDataModuleActions.dataSuccess;
export const setCardStatementsError = fetchDataModuleActions.dataError;
export const resetCardStatements = fetchDataModuleActions.dataReset;

const downloadDataModuleActions = createDataModuleActions(
    'DOWNLOAD_CARD_STATEMENTS',
);
export const downloadCardStatement = downloadDataModuleActions.dataRequest;
export const cardStatementsDownloadSuccess =
    downloadDataModuleActions.dataSuccess;
export const cardStatementsDownloadError = downloadDataModuleActions.dataError;
export const resetCardStatementsDownload = downloadDataModuleActions.dataReset;
