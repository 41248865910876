import { MAGENTO_TOKEN_KEY } from '@perpay-web/storefront/constants/magentoAccessToken';

const EXPIRATION_GRACE_PERIOD = 30 * 1000;

export const retrieveMagentoToken = () => {
    if (window.localStorage && window.localStorage.getItem(MAGENTO_TOKEN_KEY)) {
        const storageToken = JSON.parse(
            window.localStorage.getItem(MAGENTO_TOKEN_KEY),
        );
        return storageToken;
    }

    return undefined;
};

export const storeMagentoToken = (tokens) => {
    if (window.localStorage) {
        window.localStorage.setItem(MAGENTO_TOKEN_KEY, JSON.stringify(tokens));
    }
};

export const removeMagentoToken = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(MAGENTO_TOKEN_KEY);
    }
};

export const isMagentoTokenExpired = (tokenObj) => {
    if (tokenObj.validUntil) {
        // must add "Z" to the end to ensure the comparison works properly
        const validUntil = `${tokenObj.validUntil}Z`;
        return Date.parse(validUntil) - Date.now() < EXPIRATION_GRACE_PERIOD;
    }
    return true;
};

export const createAuthHeader = (token) => ({
    headers: {
        AUTHORIZATION: `Bearer ${token}`,
    },
});
