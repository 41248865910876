import { reportError } from '@perpay-web/utils/errorHandlers';
import { paths } from '@perpay-web/fintech/props/appPaths';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import { useRouteError } from 'react-router-dom';
import { useEffect } from 'react';

import styles from './ErrorBoundary.scss';

const useReportError = () => {
    const error = useRouteError();

    useEffect(() => {
        const shouldReportError = window.STAGING || window.PRODUCTION;

        if (!shouldReportError) {
            return;
        }

        reportError(error);
    }, [error]);
};

export const RouteLevelErrorComponent = () => {
    const error = useRouteError();

    useReportError();

    return (
        <div className={styles['default-error-boundary']}>
            <span className={styles['oops-emoji']} role='img' aria-label='Oops'>
                😭
            </span>
            <p>Oops! We encountered an error. Try again later?</p>
            <CustomLink href={paths.dashboard.path}>
                Return to the dashboard
            </CustomLink>
            {!window.PRODUCTION ? (
                <>
                    <p>{error.message}</p>
                    <pre className={styles['error-stack']}>{error.stack}</pre>
                </>
            ) : null}
        </div>
    );
};

export const AppLevelErrorComponent = () => {
    const error = useRouteError();

    useReportError();

    return (
        <div className={styles['default-error-boundary']}>
            <span className={styles['oops-emoji']} role='img' aria-label='Oops'>
                😭
            </span>
            <p>Oops! We encountered an error.</p>
            <a href='/'>Return to the home page</a>
            {!window.PRODUCTION ? (
                <>
                    <p>{error.message}</p>
                    <pre className={styles['error-stack']}>{error.stack}</pre>
                </>
            ) : null}
        </div>
    );
};
