import {
    BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS,
    STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS,
    STORE_ACCOUNT_DEACTIVATION_REASONS_ERROR,
    BACKEND_DEACTIVATE_ACCOUNT,
    STORE_DEACTIVATE_ACCOUNT_ERROR,
    STORE_RESET_DEACTIVATE_ACCOUNT_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: true,
    submitting: false,
    errors: {},
};

const accountDeactivationModalReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS:
            return { ...state, loading: true };
        case STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS:
            return { ...state, loading: false };
        case STORE_ACCOUNT_DEACTIVATION_REASONS_ERROR:
            return { ...state, errors: payload, submitting: false };
        case BACKEND_DEACTIVATE_ACCOUNT:
            return { ...state, submitting: true };
        case STORE_DEACTIVATE_ACCOUNT_ERROR:
            return { ...state, errors: payload, submitting: false };
        case STORE_RESET_DEACTIVATE_ACCOUNT_ERROR:
            return { ...state, errors: {} };
        default:
            return state;
    }
};

export default accountDeactivationModalReducer;
