import {
    ALGOLIA_CACHE_KEY,
    ALGOLIA_CACHE_REQUESTS_KEY,
    ALGOLIA_CACHE_RESPONSES_KEY,
    ALGOLIA_CACHE_SEARCH_KEY,
    ALGOLIA_TOKEN_KEY,
} from '@perpay-web/storefront/constants/algoliaSearchConfig';

export const retrieveAlgoliaToken = () => {
    if (window.localStorage && window.localStorage.getItem(ALGOLIA_TOKEN_KEY)) {
        const storageToken = JSON.parse(
            window.localStorage.getItem(ALGOLIA_TOKEN_KEY),
        );
        return storageToken;
    }

    return undefined;
};

export const storeAlgoliaToken = (tokens) => {
    if (window.localStorage) {
        window.localStorage.setItem(ALGOLIA_TOKEN_KEY, JSON.stringify(tokens));
    }
};

export const removeAlgoliaToken = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(ALGOLIA_TOKEN_KEY);
    }
};

export const removeAlgoliaCache = () => {
    if (window.localStorage) {
        window.localStorage.removeItem(ALGOLIA_CACHE_KEY);
        window.localStorage.removeItem(ALGOLIA_CACHE_REQUESTS_KEY);
        window.localStorage.removeItem(ALGOLIA_CACHE_RESPONSES_KEY);
        window.localStorage.removeItem(ALGOLIA_CACHE_SEARCH_KEY);
    }
};

export const isAlgoliaTokenExpired = (tokenObj) => {
    if (tokenObj.validUntil) {
        return Date.parse(tokenObj.validUntil) - Date.now() < 0;
    }
    return true;
};
