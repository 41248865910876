import Modal from '@perpay-web/components/base/Modal/Modal';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './BottomSheet.scss';

const BottomSheet = ({
    children,
    onClose = null,
    className = '',
    // Legacy multiple classname prop. Do not duplicate this pattern.
    // https://perpay.atlassian.net/wiki/spaces/PERPAY/pages/3282567207/Components+should+own+their+own+styles
    // eslint-disable-next-line @perpay-web/no-multiple-classname-props
    contentClassName = '',
    keepHeaderAboveOverlay = false,
    showClose = true,
    allowDismissal = true,
}) => (
    <Modal
        onClose={onClose}
        contentClassName={contentClassName}
        containerClassName={getClassName(styles.content, className)}
        keepHeaderAboveOverlay={keepHeaderAboveOverlay}
        showClose={showClose}
        allowDismissal={allowDismissal}
    >
        {children}
    </Modal>
);

export default BottomSheet;
