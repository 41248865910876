import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPayrollInstructionsForDataModule,
    fetchPayrollInstructionsForDataModuleSuccess,
    fetchPayrollInstructionsForDataModuleError,
    fetchPayrollInstructionsForDataModuleReset,
} from '@perpay-web/fintech/actions/entities/instructions';

const FetchPayrollInstructionsDataModule = createDataModule({
    dataRequest: fetchPayrollInstructionsForDataModule,
    dataSuccess: fetchPayrollInstructionsForDataModuleSuccess,
    dataError: fetchPayrollInstructionsForDataModuleError,
    dataReset: fetchPayrollInstructionsForDataModuleReset,
});

const getRoot = (state) => state.dataModules.fetchPayrollInstructions;
const initialValue = {};

export const fetchPayrollInstructionsDataModule =
    FetchPayrollInstructionsDataModule({
        getRoot,
        initialValue,
    });

export const getDirectDepositFormUrl = (state) =>
    fetchPayrollInstructionsDataModule.getData(state).directDepositFormUrl ||
    null;

export const getCompanyFormEnabled = (state) =>
    fetchPayrollInstructionsDataModule.getData(state).companyFormEnabled ||
    null;

export const getIsCompanyOther = (state) =>
    fetchPayrollInstructionsDataModule.getData(state).companyOther || null;

export const getOtherCompanyInstructionsAccordionHeading = (state) => {
    if (
        !fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions
    ) {
        return null;
    }
    return (
        fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions.accordionHeading || null
    );
};

export const getOtherCompanyInstructionsAccordionHTML = (state) => {
    if (
        !fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions
    ) {
        return null;
    }
    return (
        fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions.accordionBody || null
    );
};

export const getOtherCompanyPayrollInstructions = (state) => {
    if (
        !fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions
    ) {
        return null;
    }
    return (
        fetchPayrollInstructionsDataModule.getData(state)
            .otherPayrollInstructions.instructions || null
    );
};

export const getPayrollProviderInstructions = (state) =>
    fetchPayrollInstructionsDataModule.getData(state).payrollInstructions ||
    null;

export const getPayrollWorksWithPerpay = (state) =>
    fetchPayrollInstructionsDataModule.getData(state).payrollWorksWithPerpay ||
    null;
