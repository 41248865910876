import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';
import { handleError } from '@perpay-web/observable/operators/handleError';
import {
    fetchPerpayPlusDashboardSummary as fetchPerpayPlusDashboardSummaryAction,
    perpayPlusDashboardSummarySuccess,
    perpayPlusDashboardSummaryError,
} from '@perpay-web/fintech/actions/entities/perpayPlusDashboardSummary';
import { PERPAY_PLUS_DASHBOARD_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchPerpayPlusDashboardSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchPerpayPlusDashboardSummaryAction().type),
        switchMap(() => get(PERPAY_PLUS_DASHBOARD_SUMMARY_ENDPOINT)),
        mergeMap((results) => [
            perpayPlusDashboardSummarySuccess(results.response),
        ]),
        handleError((error) => [perpayPlusDashboardSummaryError(error)]),
    );
