import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './NonFieldErrors.scss';

const NonFieldErrors = ({ children = null, errors = {}, className = '' }) => {
    const nonFieldErrors =
        (errors && errors.message) || (errors && errors.code) || [];

    return (
        <p className={getClassName(styles.error, className)}>
            {children}
            {nonFieldErrors[0]}
        </p>
    );
};

export default NonFieldErrors;
