export function getClassName(...args) {
    return args.filter(Boolean).join(' ');
}

export const isInsideScrollableContainer = (element, root = document.body) => {
    if (element.scrollHeight !== element.clientHeight) {
        return true;
    }

    if (element === root) {
        return false;
    }

    if (!element.parentElement) {
        return false;
    }

    return isInsideScrollableContainer(element.parentElement, root);
};
