import { useCardFirstSignupVerifyInviteCode } from '@perpay-web/fintech/hooks/useCardFirstSignupVerifyInviteCode';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';

export function withCardFirstSignupVerifyInviteCode(Component) {
    const SignupRedirectCheckWrapper = (props) => {
        const { isCardFirstSignupVerifyInviteCodeLoading } =
            useCardFirstSignupVerifyInviteCode();
        if (isCardFirstSignupVerifyInviteCodeLoading) {
            return <WarningLoaderCircle />;
        }
        return <Component {...props} />;
    };

    SignupRedirectCheckWrapper.displayName = 'SignupRedirectCheckWrapper';

    return SignupRedirectCheckWrapper;
}
