import { connect } from 'react-redux';
import { GOOGLE_RECAPTCHA_SITEKEY } from '@perpay-web/fintech/constants/serviceCredentials';
import { getIsRecaptchaActive } from '@perpay-web/fintech/selectors/authentication';
import { fetchRecaptchaActive } from '@perpay-web/fintech/actions/authentication';
import Recaptcha from '@perpay-web/fintech/components/base/Recaptcha/Recaptcha';

const mapStateToProps = (state, ownProps) => ({
    sitekey: GOOGLE_RECAPTCHA_SITEKEY,
    isActive: getIsRecaptchaActive(state),
    className: ownProps.className,
});

const mapDispatchToProps = (dispatch) => ({
    onMount: () => dispatch(fetchRecaptchaActive()),
});

// Legacy usage of Redux connect API. New code should not use this pattern

const RecaptchaContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Recaptcha);

export default RecaptchaContainer;
