import { LAST_REACT_STOREFRONT_PAGE } from '@perpay-web/constants/storage';
import { SHOP_PREFIX } from '@perpay-web/constants/paths';
import { isAppBrowserAgent } from '@perpay-web/utils/userAgentUtils';

const isMessageLink = (url) =>
    url.startsWith('mailto:') || url.startsWith('sms:');

const isBlobLink = (url) => url.startsWith('blob:');

// Returns whether the provided path is handled by the React app
export const pathIsInternalToApp = (path) => {
    if (!path) {
        return false;
    }

    if (typeof path !== 'string') {
        return false;
    }

    if (isMessageLink(path)) {
        return false;
    }

    if (path.startsWith('http')) {
        return false;
    }

    if (isBlobLink(path)) {
        return false;
    }

    return true;
};

export const pathIsInReactStorefront = (path) =>
    pathIsInternalToApp(path) && path.startsWith(SHOP_PREFIX);

export const getLastReactStorefrontPage = () =>
    window.localStorage.getItem(LAST_REACT_STOREFRONT_PAGE);

export const setLastReactStorefrontPage = (path, query) => {
    let value = path;
    if (query && query.length > 0) value += `${query}`;
    window.localStorage.setItem(LAST_REACT_STOREFRONT_PAGE, value);
};

export const clearLastReactStorefrontPage = () =>
    window.localStorage.removeItem(LAST_REACT_STOREFRONT_PAGE);

export const getAnchorTargetFromUserAgent = () =>
    isAppBrowserAgent() ? '_system' : '_blank';
