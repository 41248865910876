import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './InputIcon.scss';

export const InputIcon = ({ icon, loading, type = null }) => {
    if (icon) {
        return icon;
    }

    if (type === 'select') {
        return (
            <span
                className={getClassName(
                    styles['select-arrow'],
                    styles['text-field-icon'],
                )}
            />
        );
    }

    if (loading) {
        return (
            <span
                className={getClassName(
                    styles.loader,
                    styles['text-field-icon'],
                )}
            />
        );
    }

    return null;
};
