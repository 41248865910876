import { ofType } from 'redux-observable';
import { mergeMap, withLatestFrom, filter } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    BACKEND_FETCH_AB_TEST,
    BACKEND_VERIFY_AB_TEST,
    STORE_AB_TEST_REPLACE,
} from '@perpay-web/fintech/constants/actionTypes';
import { AB_TESTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    replaceABTest,
    fetchABTestError,
    fetchABTests as fetchABTestsAction,
    replaceABTests,
    fetchABTestsError,
    reportABTest,
} from '@perpay-web/fintech/actions/shared/abTests';
import { getABTestEnabled } from '@perpay-web/fintech/selectors/shared/abTests';
import { getABTests } from '@perpay-web/fintech/dataModules/fetchABTests';
import {
    DISABLED_VARIANT,
    ENABLED_VARIANT,
    backendABTestMap,
} from '@perpay-web/constants/experiments';
import { analyticsMarkAuthenticatedAbTestVariant } from '@perpay-web/fintech/actions/analytics/abTesting';

export const fetchABTests = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchABTestsAction().type),
        mergeMap(({ payload }) => get(`${AB_TESTS_ENDPOINT}${payload}/`)),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const abTests = getABTests(state);
            const { response } = results;

            const testName = Object.keys(response).filter(
                (key) => key !== 'userExistedInGroup',
            )[0];
            const { userExistedInGroup } = response;
            const enabled = response[testName];
            const testData = { enabled, userExistedInGroup };

            const updatedABTests = { ...abTests, [testName]: testData };

            const returnActions = [replaceABTests(updatedABTests)];

            if (!userExistedInGroup) {
                returnActions.push(reportABTest({ enabled, testName }));
            }

            return returnActions;
        }),
        handleErrorMessageWithFallback((error) => [fetchABTestsError(error)]),
    );

export function getABTest(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_AB_TEST, BACKEND_VERIFY_AB_TEST),
        mergeMap((action) => {
            const { type, payload } = action;
            let endpoint = `${AB_TESTS_ENDPOINT}${payload}/`;

            if (type === BACKEND_VERIFY_AB_TEST) {
                endpoint = `${endpoint}?verify_company=true`;
            }

            return get(endpoint);
        }),
        mergeMap((results) => [replaceABTest(results.response)]),
        handleErrorMessageWithFallback((error) => [fetchABTestError(error)]),
    );
}

export function reportABTestVariant(action$, state$) {
    return action$.pipe(
        ofType(STORE_AB_TEST_REPLACE),
        // Only fire analytics event when user is being
        // assigned to an A/B test group
        filter((action) => !action.payload.userExistedInGroup),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { payload } = action;
            const testName = Object.keys(payload).filter(
                (key) => key !== 'userExistedInGroup',
            )[0];
            const abTestEnabled = getABTestEnabled(state, testName);
            // Pinwheel AB test is an exception to typical AB test behavior
            const isPinwheelABTest = testName === 'pinwheel';
            return !isPinwheelABTest
                ? [
                      analyticsMarkAuthenticatedAbTestVariant(
                          backendABTestMap[testName],
                          abTestEnabled ? ENABLED_VARIANT : DISABLED_VARIANT,
                      ),
                  ]
                : [];
        }),
    );
}
