import { useSelector, useDispatch } from 'react-redux';
import { useMount } from '@perpay-web/hooks/useMount';
import { verifyCardInviteCode } from '@perpay-web/fintech/actions/ui/signup';
import {
    getIsInviteCodeVerifyStepComplete,
    getIsCardInviteLoading,
} from '@perpay-web/fintech/selectors/ui/signup';

export const useCardFirstSignupVerifyInviteCode = () => {
    const isCardInviteCodeVerifyStepComplete = useSelector(
        getIsInviteCodeVerifyStepComplete,
    );

    const isCardFirstSignupVerifyInviteCodeLoading = useSelector(
        getIsCardInviteLoading,
    );
    // Get the invite code params from the URL
    const params = new URLSearchParams(window.location.search);

    const dispatch = useDispatch();
    useMount(() => {
        const hasPartnerName = params.has('partner_name');
        const hasPartnerCode = params.has('partner_code');
        if (hasPartnerName && hasPartnerCode) {
            if (!isCardInviteCodeVerifyStepComplete && !params.get('next')) {
                const paramsObject = {
                    partnerName: params.get('partner_name'),
                    partnerCode: params.get('partner_code'),
                };
                dispatch(verifyCardInviteCode(paramsObject));
            }
        }
    });

    return { isCardFirstSignupVerifyInviteCodeLoading };
};
