import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchBorrowerCredits,
    replaceBorrowerCredits,
    resetBorrowerCredits,
    setBorrowerCreditsError,
} from '@perpay-web/fintech/actions/entities/borrowerCredits';

const FetchBorrowerCreditsDataModule = createDataModule({
    dataRequest: fetchBorrowerCredits,
    dataSuccess: replaceBorrowerCredits,
    dataError: setBorrowerCreditsError,
    dataReset: resetBorrowerCredits,
});

const getRoot = (state) => state.dataModules.fetchBorrowerCredits;

const initialValue = {
    currentPageNumber: 1,
    pageCount: 0,
    results: [
        {
            amount: 0,
            created: null,
            creditType: '',
            expiration: null,
            minimum: 0,
            stackable: false,
            status: '',
        },
    ],
};

export const fetchBorrowerCreditsDataModule = FetchBorrowerCreditsDataModule({
    getRoot,
    initialValue,
});

export const getBorrowerCreditResults = (state) =>
    fetchBorrowerCreditsDataModule.getData(state).results;

export const getBorrowerCreditsPageNumber = (state) =>
    fetchBorrowerCreditsDataModule.getData(state).currentPageNumber;

export const getBorrowerCreditsPageCount = (state) =>
    fetchBorrowerCreditsDataModule.getData(state).pageCount;
