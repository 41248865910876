import {
    STORE_REPLACE_ORDERS,
    STORE_RESET_ORDERS,
    STORE_REPLACE_ORDER,
} from '@perpay-web/fintech/constants/actionTypes';
import { createReducer } from '@reduxjs/toolkit';

const orderReducer = createReducer({}, (builder) => {
    builder.addCase(STORE_REPLACE_ORDERS, (_state, action) => ({
        ...action.payload,
    }));
    builder.addCase(STORE_RESET_ORDERS, () => ({}));
    builder.addCase(STORE_REPLACE_ORDER, (state, action) => ({
        ...state,
        ...action.payload,
    }));
});

export default orderReducer;
