import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [FullHeaderComponentStackProvider, useFullHeaderContext] =
    createContextProvider(componentStackProviderFactory);

export { FullHeaderComponentStackProvider };

export const useFullHeader = (renderCb) =>
    useComponentStack(useFullHeaderContext(), renderCb);

export const useFullHeaderRender = (props) =>
    useComponentStackRender(useFullHeaderContext(), props);
