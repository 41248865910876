import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchFeatureEnrollmentsForPerpayPlus,
    fetchFeatureEnrollmentsForPerpayPlusSuccess,
    fetchFeatureEnrollmentsForPerpayPlusError,
    fetchFeatureEnrollmentsForPerpayPlusReset,
} from '@perpay-web/fintech/actions/ui/featureEnrollments';

const FetchFeatureEnrollmentsForPerpayPlusDataModule = createDataModule({
    dataRequest: fetchFeatureEnrollmentsForPerpayPlus,
    dataSuccess: fetchFeatureEnrollmentsForPerpayPlusSuccess,
    dataError: fetchFeatureEnrollmentsForPerpayPlusError,
    dataReset: fetchFeatureEnrollmentsForPerpayPlusReset,
});

const getRoot = (state) =>
    state.dataModules.fetchFeatureEnrollmentsForPerpayPlus;
const initialValue = {
    featureEnrollments: [],
};

export const fetchFeatureEnrollmentsForPerpayPlusDataModule =
    FetchFeatureEnrollmentsForPerpayPlusDataModule({
        getRoot,
        initialValue,
    });
