export const PAY_CYCLE_MONTHLY = 'monthly';
export const PAY_CYCLE_SEMI_MONTHLY = 'semi_monthly';
export const PAY_CYCLE_BIWEEKLY = 'bi_weekly';
export const PAY_CYCLE_WEEKLY = 'weekly';

const PAY_CYCLE_TO_TEXT_MAP = {
    [PAY_CYCLE_WEEKLY]: 'weekly',
    [PAY_CYCLE_BIWEEKLY]: 'bi-weekly',
    [PAY_CYCLE_SEMI_MONTHLY]: 'semi-monthly',
    [PAY_CYCLE_MONTHLY]: 'monthly',
};

export const payCycleTypeToText = (payCycle) => PAY_CYCLE_TO_TEXT_MAP[payCycle];
