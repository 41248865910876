import {
    BACKEND_FETCH_AB_TEST,
    BACKEND_VERIFY_AB_TEST,
    STORE_AB_TEST_REPLACE,
    STORE_AB_TEST_ERROR,
    STORE_AB_TEST_RESET,
    PINWHEEL_CREATE_PRIMARY_JOB,
    PINWHEEL_UPDATE_SALARY_INFO,
    PINWHEEL_SPLIT_CREATE_PRIMARY_JOB,
    PINWHEEL_SPLIT_UPDATE_SALARY_INFO,
    STORE_RESET_ATTEMPTED_EMPLOYER_VERIFICATION,
    BACKEND_FETCH_PINWHEEL_ELIGIBILITY,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    errors: {},
    tests: {},
    fetchingABTest: false,
    attemptedEmployerVerification: false,
};

const abTestReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_PINWHEEL_ELIGIBILITY:
            return { ...state, fetchingABTest: true };
        case BACKEND_FETCH_AB_TEST:
        case BACKEND_VERIFY_AB_TEST:
            return { ...state, fetchingABTest: true };
        case PINWHEEL_CREATE_PRIMARY_JOB:
        case PINWHEEL_UPDATE_SALARY_INFO:
        case PINWHEEL_SPLIT_CREATE_PRIMARY_JOB:
        case PINWHEEL_SPLIT_UPDATE_SALARY_INFO:
            return {
                ...state,
                fetchingABTest: true,
                attemptedEmployerVerification: true,
            };
        case STORE_AB_TEST_REPLACE: {
            const testName = Object.keys(payload).filter(
                (key) => key !== 'userExistedInGroup',
            )[0];
            const testValue = payload[testName];
            const test = {
                [testName]: {
                    userExistedInGroup: payload.userExistedInGroup,
                    enabled: testValue,
                },
            };
            const updatedTests = { ...state.tests, ...test };
            return { ...state, tests: updatedTests, fetchingABTest: false };
        }
        case STORE_AB_TEST_ERROR:
            return { ...state, errors: payload, fetchingABTest: false };
        case STORE_AB_TEST_RESET:
            return initialState;
        case STORE_RESET_ATTEMPTED_EMPLOYER_VERIFICATION:
            return { ...state, attemptedEmployerVerification: false };
        default:
            return state;
    }
};

export default abTestReducer;
