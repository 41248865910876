import { timer } from 'rxjs';
import {
    exhaustMap,
    mergeMap,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { handleError } from '@perpay-web/observable/operators/handleError';
import {
    APP_UPDATE_POLL_START,
    APP_UPDATE_POLL_STOP,
} from '@perpay-web/fintech/constants/actionTypes';
import { APP_VERSION_FILE_URL } from '@perpay-web/fintech/constants/urls';
import {
    appUpdatePollStop,
    appUpdateQueueRefresh,
} from '@perpay-web/fintech/actions/appUpdate';

export const APP_UPDATE_REQUEST_INTERVAL_MS = 1000 * 60 * 5; // 5 minutes

export const appUpdate = (action$, state$, { get }) =>
    action$.pipe(
        ofType(APP_UPDATE_POLL_START),
        switchMap(() => timer(0, APP_UPDATE_REQUEST_INTERVAL_MS)),
        takeUntil(action$.pipe(ofType(APP_UPDATE_POLL_STOP), take(1))),
        exhaustMap(() => get(APP_VERSION_FILE_URL)),
        mergeMap((results) => {
            const { version } = results.response;
            if (version !== window.VERSION) {
                return [appUpdatePollStop(), appUpdateQueueRefresh(version)];
            }
            return [];
        }),
        handleError(() => []), // we don't care if this fails
    );
