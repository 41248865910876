import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';
import { fetchFeatureEnrollmentsForPerpayPlusDataModule } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollmentsForPerpayPlus';

const CheckoutDataModule = composeDataModules([
    fetchUserInfoDataModule,
    fetchFeatureEnrollmentsForPerpayPlusDataModule,
]);

const getRoot = (state) => state.dataModules.checkout;

export const checkoutDataModule = CheckoutDataModule({
    getRoot,
    key: 'CHECKOUT',
});
