import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchPinwheelABTestDataModule,
    getPinwheelABTestEnabled,
} from '@perpay-web/fintech/dataModules/fetchPinwheelABTest';
import {
    fetchJobsDataModule,
    getIsCompanyUnverified,
} from '@perpay-web/fintech/dataModules/fetchJobs';
import { generalAccountSummaryDataModule } from '@perpay-web/fintech/dataModules/generalAccountSummary';

const CardPaymentSummaryDataModule = composeDataModules([
    generalAccountSummaryDataModule,
    fetchPinwheelABTestDataModule,
    fetchJobsDataModule,
]);

const getRoot = (state) => state.dataModules.cardPaymentSummary;

export const cardPaymentSummaryDataModule = CardPaymentSummaryDataModule({
    getRoot,
    key: 'CARD_PAYMENT_SUMMARY',
});

export const getIsPinwheelEligible = (state) => {
    const isCompanyUnverified = getIsCompanyUnverified(state);
    if (isCompanyUnverified) return true;
    return getPinwheelABTestEnabled(state);
};

export {
    getMinimumPayment,
    getCoreMinimumPayment,
    getCardMinimumPayment,
    getPayCycleType,
} from '@perpay-web/fintech/dataModules/generalAccountSummary';
