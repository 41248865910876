import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './ResponsiveHeader.scss';

const ResponsiveHeader = ({ className = '', children }) => (
    <p className={getClassName(styles['responsive-header'], className)}>
        {children}
    </p>
);

export default ResponsiveHeader;
