import {
    STORE_REPLACE_ACCOUNT_DEACTIVATION_ELIGIBILITY,
    BACKEND_FETCH_ACCOUNT_DEACTIVATION_ELIGIBILITY,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchAccountDeactivationEligibility() {
    return {
        type: BACKEND_FETCH_ACCOUNT_DEACTIVATION_ELIGIBILITY,
    };
}

export function replaceAccountDeactivationEligibility(eligibility) {
    return {
        type: STORE_REPLACE_ACCOUNT_DEACTIVATION_ELIGIBILITY,
        payload: eligibility,
    };
}
