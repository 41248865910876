import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { useLoginRedirectCheck } from '@perpay-web/fintech/hooks/useLoginRedirectCheck';

export function withLoginRedirectCheck(Component) {
    const LoginRedirectCheckWrapper = (props) => {
        const { isLoadingLoginRedirectCheck } = useLoginRedirectCheck();

        if (isLoadingLoginRedirectCheck) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };

    LoginRedirectCheckWrapper.displayName = 'LoginRedirectCheckWrapper';

    return LoginRedirectCheckWrapper;
}
