import { useCallback, useState } from 'react';

import { Stack } from '@perpay-web/components/design-system/Stack/Stack';
import TextInputServerError from '@perpay-web/components/higher-order/WithFieldServerErrors/TextInputServerError';
import { PasswordIndicatorV2 } from '@perpay-web/fintech/components/base/PasswordIndicatorV2/PasswordIndicatorV2';
import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';

import styles from './PasswordInput.scss';

const PasswordInput = ({
    value = '',
    name = 'password',
    label = 'Password',
    className = '',
    showPasswordIndicator = false,
    ...rest
}) => {
    const [showingPlaintext, setShowingPlaintext] = useState(false);

    const renderIcon = useCallback(
        () => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <span
                className={getClassName(
                    styles['password-icon'],
                    showingPlaintext ? styles['password-icon--unmasked'] : '',
                    styles['text-field-icon'],
                )}
                onKeyDown={noop}
                onClick={() => setShowingPlaintext(!showingPlaintext)}
            />
        ),
        [showingPlaintext],
    );

    return (
        <Stack className={styles['password-group']}>
            <TextInputServerError
                className={getClassName(styles.input, className)}
                name={name}
                id={name}
                label={label}
                value={value}
                type={showingPlaintext ? 'text' : 'password'}
                icon={renderIcon()}
                {...rest}
            />
            {showPasswordIndicator && (
                <PasswordIndicatorV2
                    password={value}
                    className={styles.indicator}
                />
            )}
        </Stack>
    );
};

export default PasswordInput;
