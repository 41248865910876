import { switchMap, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchBorrowerCreditSummary as fetchBorrowerCreditSummaryAction,
    replaceBorrowerCreditSummary,
    setBorrowerCreditSummaryError,
} from '@perpay-web/fintech/actions/shared/borrowerCreditSummary';
import { BORROWER_CREDIT_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchBorrowerCreditSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchBorrowerCreditSummaryAction().type),
        switchMap(() => get(BORROWER_CREDIT_SUMMARY_ENDPOINT)),
        mergeMap((results) => [replaceBorrowerCreditSummary(results.response)]),
        handleErrorMessageWithFallback((error) => [
            setBorrowerCreditSummaryError(error),
        ]),
    );
