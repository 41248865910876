import {
    ANALYTICS_CARD_REWARDS_NOTIFICATION_CLICK_CTA,
    ANALYTICS_CARD_REWARDS_NOTIFICATION_CLOSE,
    ANALYTICS_CARD_REWARDS_NOTIFICATION_VIEW,
} from '@perpay-web/fintech/constants/actionTypes';

export const analyticsPerpayCreditCardRewardsNotificationViewed = () => ({
    type: ANALYTICS_CARD_REWARDS_NOTIFICATION_VIEW,
});

export const analyticsPerpayCreditCardRewardsNotificationClickCta = () => ({
    type: ANALYTICS_CARD_REWARDS_NOTIFICATION_CLICK_CTA,
});

export const analyticsPerpayCreditCardRewardsNotificationClose = () => ({
    type: ANALYTICS_CARD_REWARDS_NOTIFICATION_CLOSE,
});
