export const TAKEOVER_STEP = 'takeoverStep';
export const REVIEW_STEP = 'review';
export const ADDRESS_STEP = 'address';
export const ADDRESS_REJECTED_STEP = 'address-rejected';
export const APPROVED_STEP = 'approved';
export const SUBMITTING_ACCEPTANCE_STEP = 'submittingAcceptance';
export const DENIED_STEP = 'denied';
export const PRE_PINWHEEL_STEP = 'pre-pinwheel';
export const PINWHEEL_STEP = 'pinwheel';
export const SUCCESS_STEP = 'success';
export const PINWHEEL_ERROR_STEP = 'pinwheelError';
export const MANUAL_SETUP_STEP = 'manualSetup';
export const APPLICATION_ERROR_STEP = 'application-error-step';
export const UNFREEZE_STEP = 'unfreeze-step';
export const UPLOAD_DOCUMENTS_STEP = 'upload-documents-step';
export const IN_REVIEW_STEP = 'in-review-step';

export const CARD_ONBOARDING_STEPS = [
    TAKEOVER_STEP,
    REVIEW_STEP,
    ADDRESS_STEP,
    ADDRESS_REJECTED_STEP,
    APPROVED_STEP,
    SUBMITTING_ACCEPTANCE_STEP,
    PRE_PINWHEEL_STEP,
    PINWHEEL_STEP,
    SUCCESS_STEP,
    DENIED_STEP,
    PINWHEEL_ERROR_STEP,
    MANUAL_SETUP_STEP,
    APPLICATION_ERROR_STEP,
    UNFREEZE_STEP,
    UPLOAD_DOCUMENTS_STEP,
    IN_REVIEW_STEP,
];
