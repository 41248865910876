import { standardizeError } from '@perpay-web/utils/errorHandlers';
import { handleError } from '@perpay-web/observable/operators/handleError';

/**
 * A wraper around handleError that handles 4xx and 5xx errors in a uniform manner.
 * @param cb - Callback function that accepts the resulting error dictionary
 */
export function handleErrorMessageWithFallback(cb) {
    return handleError((error) => {
        const standardizedError = standardizeError(error);
        return cb(standardizedError);
    });
}

export function handleErrorMessageWithFallbackWithStatus(cb) {
    return handleError((error) => {
        const standardizedError = standardizeError(error);

        if (error.status) {
            return cb({
                ...standardizedError,
                status: error.status,
            });
        }

        return cb(standardizedError);
    });
}
