import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './CategoryButton.scss';

export const CategoryButton = ({
    className,
    children,
    onClick,
    onMouseDown,
}) => (
    <button
        className={getClassName(styles['category-button'], className)}
        onClick={onClick}
        onMouseDown={onMouseDown}
        type='button'
    >
        {children}
    </button>
);
