import { withReduxStepsReducer } from '@perpay-web/hooks/useReduxSteps';
import { CARD_ONBOARDING_MVP_2_STEPS } from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import {
    BACKEND_UPDATE_SALARY_INFO,
    CARD_UPDATE_SALARY_INFO,
    STORE_UPDATE_SALARY_INFO_ERROR,
    STORE_REPLACE_SALARY_INFO,
    BACKEND_CREATE_DEDUCTION,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
    BACKEND_CREATE_PRIMARY_JOB,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_REPLACE_CARD_ONBOARDING_MVP_2_AMOUNT_TO_CHARGE,
} from '@perpay-web/fintech/constants/actionTypes';
import { updateJobSuccess } from '@perpay-web/fintech/actions/entities/jobs';

const initialState = {
    isSubmittingJob: false,
    errors: {},
    isSubmittingSelfOnboardingForm: false,
    selfOnboardingErrors: {},
    fetchingPinwheelToken: false,
    pinwheelErrors: {},
    amountToCharge: 0,
};

const cardOnboardingMVP2Reducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_UPDATE_SALARY_INFO:
        case updateJobSuccess().type:
            return { ...state, isSubmittingJob: false, errors: {} };
        case CARD_UPDATE_SALARY_INFO:
            return { ...state, isSubmittingJob: true, errors: {} };
        case STORE_REPLACE_SALARY_INFO:
            return { ...state, isSubmittingJob: false };
        case STORE_UPDATE_SALARY_INFO_ERROR:
            return { ...state, isSubmittingJob: false, errors: payload };
        case BACKEND_CREATE_PRIMARY_JOB:
            return {
                ...state,
                isSubmittingSelfOnboardingForm: true,
                selfOnboardingErrors: {},
            };
        case STORE_REPLACE_PRIMARY_JOB:
            return { ...state, isSubmittingSelfOnboardingForm: false };
        case STORE_CREATE_PRIMARY_JOB_ERROR:
            return {
                ...state,
                isSubmittingSelfOnboardingForm: false,
                selfOnboardingErrors: payload,
            };
        case BACKEND_CREATE_DEDUCTION:
            return {
                ...state,
                fetchingPinwheelToken: true,
                pinwheelErrors: {},
            };
        case STORE_REPLACE_DEDUCTION:
            return { ...state, fetchingPinwheelToken: false };
        case STORE_REPLACE_DEDUCTION_ERROR:
            return {
                ...state,
                fetchingPinwheelToken: false,
                pinwheelErrors: payload,
            };
        case STORE_REPLACE_CARD_ONBOARDING_MVP_2_AMOUNT_TO_CHARGE:
            return {
                ...state,
                amountToCharge: payload,
            };
        default:
            return state;
    }
};

export default withReduxStepsReducer(
    CARD_ONBOARDING_MVP_2_STEPS,
    cardOnboardingMVP2Reducer,
);
