import {
    STORE_OPEN_CANCEL_ORDER_MODAL,
    STORE_CLOSE_CANCEL_ORDER_MODAL,
    STORE_ORDER_CANCELLATION_REASONS_ERROR,
    STORE_CANCEL_ORDER_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function openCancelOrderModal(orderUuid) {
    return {
        type: STORE_OPEN_CANCEL_ORDER_MODAL,
        payload: orderUuid,
    };
}

export function closeCancelOrderModal(orderUuid) {
    return {
        type: STORE_CLOSE_CANCEL_ORDER_MODAL,
        payload: orderUuid,
    };
}

export function cancelOrderError(error) {
    return {
        type: STORE_CANCEL_ORDER_ERROR,
        payload: error,
    };
}

export function orderCancellationReasonsError(error) {
    return {
        type: STORE_ORDER_CANCELLATION_REASONS_ERROR,
        payload: error,
    };
}
