import {
    BACKEND_CREATE_PAYSTUB,
    BACKEND_PAYSTUB_POLLING_COMPLETE,
    STORE_RESET_CHECKOUT,
    STORE_PAYSTUB_POLLING_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    submitting: false,
    uploadingPaystub: true,
    errors: {},
};

const paystubReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_CREATE_PAYSTUB:
            return { ...state, uploadingPaystub: true, errors: {} };
        case BACKEND_PAYSTUB_POLLING_COMPLETE:
            return { ...state, uploadingPaystub: false };
        case STORE_PAYSTUB_POLLING_ERROR:
            return {
                ...state,
                uploadingPaystub: false,
                errors: { paystub: payload },
            };
        case STORE_RESET_CHECKOUT:
            return initialState;
        default:
            return state;
    }
};

export default paystubReducer;
