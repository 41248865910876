import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importBankAccountVerificationContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "BankAccountVerificationContainer" */
                '@perpay-web/fintech/components/containers/BankAccountVerificationContainer'
            ),
    );
