import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importBorrowerSignup = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "BorrowerSignup" */
                '@perpay-web/fintech/components/containers/BorrowerSignup'
            ),
    );
