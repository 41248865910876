import { createDataModuleActions as createDataModuleActionsUtil } from '@perpay-web/data-module/createDataModuleActions';

const createDataModuleActions = createDataModuleActionsUtil(
    'CREATE_AUTO_PAY_SCHEDULE_FOR_CARD_AUTO_PAY',
);
export const createAutoPayScheduleForCardAutoPay =
    createDataModuleActions.dataRequest;
export const createAutoPayScheduleForCardAutoPaySuccess =
    createDataModuleActions.dataSuccess;
export const createAutoPayScheduleForCardAutoPayError =
    createDataModuleActions.dataError;
export const createAutoPayScheduleForCardAutoPayReset =
    createDataModuleActions.dataReset;

const cancelDataModuleActions = createDataModuleActionsUtil(
    'CANCEL_AUTO_PAY_SCHEDULE',
);
export const cancelAutoPaySchedule = cancelDataModuleActions.dataRequest;
export const cancelAutoPayScheduleSuccess = cancelDataModuleActions.dataSuccess;
export const cancelAutoPayScheduleError = cancelDataModuleActions.dataError;
export const cancelAutoPayScheduleReset = cancelDataModuleActions.dataReset;
