export const ONE_TIME = 'one_time';
export const MONTHLY = 'monthly';
export const BI_WEEKLY = 'bi_weekly';
export const WEEKLY = 'weekly';

export const FREQUENCY_MAP = {
    [ONE_TIME]: 'one time',
    [MONTHLY]: 'monthly',
    [BI_WEEKLY]: 'biweekly',
    [WEEKLY]: 'weekly',
};
