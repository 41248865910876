import {
    BACKEND_FETCH_PINWHEEL_SURVEY,
    STORE_REPLACE_PINWHEEL_SURVEY,
    STORE_FETCH_PINWHEEL_SURVEY_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    fetchingSurvey: false,
    submitting: false,
    errors: {},
};

const surveyReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_PINWHEEL_SURVEY:
            return { ...state, fetchingSurvey: true };
        case STORE_REPLACE_PINWHEEL_SURVEY:
            return { ...state, fetchingSurvey: false };
        case STORE_FETCH_PINWHEEL_SURVEY_ERROR:
            return { ...state, errors: payload };
        default:
            return state;
    }
};

export default surveyReducer;
