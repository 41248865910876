import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchDataModuleActions = createDataModuleActions(
    'FETCH_PLATFORM_DASHBOARD_NOTIFICATIONS',
);
export const fetchPlatformDashboardNotifications =
    fetchDataModuleActions.dataRequest;
export const fetchPlatformDashboardNotificationsSuccess =
    fetchDataModuleActions.dataSuccess;
export const fetchPlatformDashboardNotificationsError =
    fetchDataModuleActions.dataError;
export const fetchPlatformDashboardNotificationsReset =
    fetchDataModuleActions.dataReset;
