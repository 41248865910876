import {
    BACKEND_CREATE_EXTERNAL_CARD,
    BACKEND_FETCH_EXTERNAL_CARDS,
    STORE_REPLACE_EXTERNAL_CARDS,
    STORE_RESET_EXTERNAL_CARDS,
    STORE_CREATE_EXTERNAL_CARD_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';
import { createAction } from '@reduxjs/toolkit';

export function fetchExternalCards() {
    return {
        type: BACKEND_FETCH_EXTERNAL_CARDS,
    };
}

export function replaceExternalCards(externalCards) {
    return {
        type: STORE_REPLACE_EXTERNAL_CARDS,
        payload: externalCards,
    };
}

export function resetExternalCards() {
    return {
        type: STORE_RESET_EXTERNAL_CARDS,
    };
}

export function createExternalCard(cardNumber, expiryDate, cvc) {
    return {
        type: BACKEND_CREATE_EXTERNAL_CARD,
        payload: {
            cardNumber,
            expiryDate,
            cvc,
        },
    };
}

export function createExternalCardSuccess() {
    return {
        type: STORE_CREATE_EXTERNAL_CARD_SUCCESS,
    };
}
export const createExternalCardErrors = createAction(
    'store/EXTERNAL_CARDS::CREATE::ERROR',
);
