import { exhaustMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsGoBack } from '@perpay-web/hooks/useReduxSteps';
import {
    BACKEND_CREATE_EMPLOYMENT,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    BACKEND_UPDATE_EMPLOYMENT,
    CARD_UPDATE_SALARY_INFO,
    CARD_CREATE_PRIMARY_JOB,
} from '@perpay-web/fintech/constants/actionTypes';
import { CARD_ONBOARDING_MVP_2_STEPS } from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import { JOBS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { primaryJob } from '@perpay-web/fintech/actions/ui/signup';
import { profileUpdatePaycycleSuccess } from '@perpay-web/fintech/actions/ui/employment';
import { getJobUUID } from '@perpay-web/fintech/selectors/ui/signup';
import { getABTestEnabled } from '@perpay-web/fintech/dataModules/fetchABTests';
import { METAL_CARD_STORE_NAME } from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    updateJob,
    updateJobError,
    updateJobSuccess,
} from '@perpay-web/fintech/actions/entities/jobs';

export function updateEmploymentUI(action$, state$) {
    return action$.pipe(
        ofType(BACKEND_CREATE_EMPLOYMENT),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [primaryJob(action.payload)],
                waitFor: [STORE_REPLACE_PRIMARY_JOB],
                waitForDispatch: () => [routeToLocation(paths.profile.path)],
                errors: [STORE_CREATE_PRIMARY_JOB_ERROR],
                errorDispatch: () => [],
            }),
        ),
    );
}

export function updatePaycycleUI(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_UPDATE_EMPLOYMENT),
        withLatestFrom(state$),
        exhaustMap(([action, state]) => {
            const jobUUID = getJobUUID(state);
            return patch(`${JOBS_ENDPOINT}${jobUUID}/`, action.payload);
        }),
        mergeMap(() => [
            routeToLocation(paths.profile.path),
            profileUpdatePaycycleSuccess(),
        ]),
    );
}

export function cardUpdateSalaryInfo(action$, state$) {
    return action$.pipe(
        ofType(CARD_UPDATE_SALARY_INFO),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: [
                    updateJob({
                        companyUuid: action.payload.uuid,
                        companyName: action.payload.company,
                        payCycle: action.payload.payCycle,
                        jobUuid: action.payload.jobUuid,
                    }),
                ],
                waitFor: [updateJobSuccess().type],
                errors: [updateJobError().type],
            }),
        ),
    );
}

export function cardCreatePrimaryJob(action$, state$) {
    return action$.pipe(
        ofType(CARD_CREATE_PRIMARY_JOB),
        mergeMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: [primaryJob(action.payload)],
                waitFor: [STORE_REPLACE_PRIMARY_JOB],
                waitForDispatch: (state) => {
                    const isMetalCardFlow =
                        METAL_CARD_IS_LAUNCHED &&
                        getABTestEnabled(state, METAL_CARD_STORE_NAME);
                    const reduxKey = isMetalCardFlow
                        ? Object.values(metalSteps)
                        : CARD_ONBOARDING_MVP_2_STEPS;
                    return [reduxStepsGoBack(reduxKey)];
                },
                errors: [STORE_CREATE_PRIMARY_JOB_ERROR],
            }),
        ),
    );
}
