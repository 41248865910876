import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPlatformDashboardNotifications,
    fetchPlatformDashboardNotificationsSuccess,
    fetchPlatformDashboardNotificationsError,
    fetchPlatformDashboardNotificationsReset,
} from '@perpay-web/fintech/actions/entities/platformDashboardNotifications';

const FetchPlatformDashboardNotificationsDataModule = createDataModule({
    dataRequest: fetchPlatformDashboardNotifications,
    dataSuccess: fetchPlatformDashboardNotificationsSuccess,
    dataError: fetchPlatformDashboardNotificationsError,
    dataReset: fetchPlatformDashboardNotificationsReset,
});

const getRoot = (state) =>
    state.dataModules.fetchPlatformDashboardNotifications;
const initialValue = {};

export const fetchPlatformDashboardNotificationsDataModule =
    FetchPlatformDashboardNotificationsDataModule({
        getRoot,
        initialValue,
    });

export const getNotification = (state) =>
    fetchPlatformDashboardNotificationsDataModule.getData(state).status || '';
