// The constants defined here should match the values returned by the orders API in `remainingSteps`
export const BILLING_INFORMATION = 'billingInformation';
export const PAYMENT_OPTIONS = 'paymentOptions';
export const PAYSTUB = 'paystub';
export const TERMS_AND_CONDITIONS = 'termsAndConditions';
export const SIGNATURE = 'signature';
export const EMPLOYEE_ID = 'employeeID';
export const PERSONAL_INFORMATION = 'personalInformation';

// The completion of any of these steps counts towards
// the checkout progress bar
export const STEPS_FOR_PROGRESS_PERCENT = [
    BILLING_INFORMATION,
    PAYMENT_OPTIONS,
    PAYSTUB,
    SIGNATURE,
    TERMS_AND_CONDITIONS,
    EMPLOYEE_ID,
    PERSONAL_INFORMATION,
];

// This constant is used for rendering but is not an
// actual required step the user must complete
export const CHECKOUT_COMPLETE = 'checkoutComplete';
