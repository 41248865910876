import { ofType } from 'redux-observable';
import { switchMap, mergeMap } from 'rxjs/operators';
import { handleError } from '@perpay-web/observable/operators/handleError';
import {
    fetchDirectDepositDashboardSummary as fetchDirectDepositDashboardSummaryAction,
    directDepositDashboardSummarySuccess,
    directDepositDashboardSummaryError,
} from '@perpay-web/fintech/actions/entities/directDepositDashboardSummary';
import { DIRECT_DEPOSIT_DASHBOARD_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchDirectDepositDashboardSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchDirectDepositDashboardSummaryAction().type),
        switchMap(() => get(DIRECT_DEPOSIT_DASHBOARD_SUMMARY_ENDPOINT)),
        mergeMap((results) => [
            directDepositDashboardSummarySuccess(results.response),
        ]),
        handleError((error) => [directDepositDashboardSummaryError(error)]),
    );
