// A utility function to merge any backend errors
// with Formik's props provided by the Field component
// Priority is given to backend errors
// Example input and output:
// formikFieldProps
// {
//     form: {
//         errors: {
//             state: 'This is not a US state',
//             phone: 'Must enter a US phone number',
//             lastName: 'Please enter a last name',
//         },
//         ...
//     },
//     field: {
//         ...
//     }
// }
// backendErrors
// {
//     state: 'Please enter a valid US state',
//     phone: 'Please enter a valid US phone number',
// }
// output
// {
//     form: {
//         errors: {
//             state: 'Please enter a valid US state',
//             phone: 'Please enter a valid US phone number',
//             lastName: 'Please enter a last name',
//         },
//         ...
//     },
//     field: {
//         ...
//     }
// }
export function fieldPropsWithBackendErrors(formikFieldProps, backendErrors) {
    const mergedErrors = {
        errors: { ...formikFieldProps.form.errors, ...backendErrors },
    };
    const newFormProps = {
        form: { ...formikFieldProps.form, ...mergedErrors },
    };
    const newFormikFieldProps = { ...formikFieldProps, ...newFormProps };
    return newFormikFieldProps;
}

// Merge backend errors with frontend validation errors, prioritizing frontend errors
// Compatible with, but not tightly coupled to Formik
export function formWithBackendErrors(formErrors, backendErrors) {
    return { ...formErrors, ...backendErrors };
}

// This function takes an errors object like
// {
//     state: ['State is a required field', 'This State selection is invalid'],
//     phone: ['Phone numbers must be US phone numbers'],
// }
// and converts it to an object like
// {
//     state: 'State is a required field',
//     phone: 'Phone numbers must be US phone numbers',
// }
// for compatibility with Formik
export function selectFirstErrors(errors) {
    // The backend returns an array of errrs for each field.
    // Our UI right now only handles one field-level error at a time.
    const cleanedErrors = {};
    if (!errors) {
        return cleanedErrors;
    }

    const keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (typeof errors[key] === 'string') {
            cleanedErrors[key] = [errors[key]];
        } else {
            // Keys only exist in the errors dictionary if there is an error for that key.
            const [error] = errors[key]; // get first item
            cleanedErrors[key] = [error];
        }
    }
    return cleanedErrors;
}

/**
 * Receives a submit event and returns the form data entries
 * @param {SubmitEvent} event
 */
export function formEntriesFromSubmitEvent(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    return Object.fromEntries(formData.entries());
}
