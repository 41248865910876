import { HTTPMethod } from '@perpay-web/constants/httpMethods';

const getJson = (response) =>
    response.text().then((text) => (text ? JSON.parse(text) : null));

function getRequestUrl(url, body, method) {
    if (method !== HTTPMethod.get) {
        return url;
    }

    if (!body) {
        return url;
    }

    const params = `${new URLSearchParams(body)}`;
    if (!params) {
        return url;
    }

    return `${url}?${params}`;
}

function getRequestBody(body, method) {
    if (method === HTTPMethod.get) {
        return undefined;
    }

    return JSON.stringify(body);
}

export function jsonFetch(
    url,
    body,
    method = 'GET',
    headers = {},
    credentials = 'same-origin',
) {
    return window
        .fetch(getRequestUrl(url, body, method), {
            method,
            body: getRequestBody(body, method),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                ...headers,
            },
            credentials,
        })
        .then((response) => {
            if (response.status >= 400) {
                return getJson(response).then((error) => {
                    // Put the error in a format that the epic error handler expects
                    // eslint-disable-next-line no-throw-literal
                    throw { response: error, status: response.status };
                });
            }
            return getJson(response);
        });
}
