import { createDataModule } from '@perpay-web/data-module/createDataModule';
import * as actions from '@perpay-web/fintech/actions/ui/addressSuggestions';

const FetchAddressSuggestionsDataModule = createDataModule({
    dataRequest: actions.fetchAddressSuggestionsForDataModule,
    dataSuccess: actions.replaceAddressSuggestions,
    dataError: actions.addressSuggestionsError,
    dataReset: actions.resetAddressSuggestions,
});

const getRoot = (state) => state.dataModules.fetchAddressSuggestions;
const initialValue = {
    addressSuggestions: [],
};

export const fetchAddressSuggestionsDataModule =
    FetchAddressSuggestionsDataModule({
        getRoot,
        initialValue,
    });

export const getAddressSuggestions = (state) =>
    fetchAddressSuggestionsDataModule.getData(state).addressSuggestions;
