import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    coreSummaryRequest,
    coreSummarySuccess,
    coreSummaryError,
    coreSummaryReset,
} from '@perpay-web/fintech/actions/shared/coreSummary';

const CoreSummaryDataModule = createDataModule({
    dataRequest: coreSummaryRequest,
    dataSuccess: coreSummarySuccess,
    dataError: coreSummaryError,
    dataReset: coreSummaryReset,
});

const getRootCoreSummary = (state) => state.dataModules.coreSummary;
const initialValueCoreSummary = {
    spendingLimit: 0,
    outstandingBalance: 0,
    availableSpendingLimit: 0,
    availableCredit: 0,
    maxPays: 0,
    creditLineAmount: 0,
};

export const coreSummaryDataModule = CoreSummaryDataModule({
    getRoot: getRootCoreSummary,
    initialValue: initialValueCoreSummary,
});

export const getOutstandingBalance = (state) =>
    coreSummaryDataModule.getData(state).outstandingBalance;

export const getPaymentPlanSummary = (state) =>
    coreSummaryDataModule.getData(state).paymentPlanSummary;
