import { useFullBottomNav } from '@perpay-web/hooks/useFullBottomNavContext';
import { useConstCallback } from '@perpay-web/hooks/useConstCallback';

export function withBottomNav(BottomNavComponent) {
    return (Component) => {
        const BottomNavWrapper = (props) => {
            const bottomNavCb = useConstCallback(() => <BottomNavComponent />);
            useFullBottomNav(bottomNavCb);

            return <Component {...props} />;
        };

        BottomNavWrapper.displayName = 'BottomNavWrapper';

        return BottomNavWrapper;
    };
}
