import {
    BACKEND_FETCH_BANKS,
    STORE_REPLACE_BANKS,
    STORE_REPLACE_BANKS_ERROR,
    STORE_RESET_BANKS,
    BACKEND_CREATE_BANK,
    STORE_CREATE_BANK_ERROR,
    STORE_CREATE_BANK_SUCCESS,
    BACKEND_VERIFY_BANK,
    STORE_VERIFY_BANK_ERROR,
    STORE_VERIFY_BANK_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    banksFetching: true,
    submitting: false,
    errors: {},
};

const banksReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_BANKS:
        case STORE_RESET_BANKS:
            return { ...state, banksFetching: true, errors: {} };
        case STORE_REPLACE_BANKS:
            return {
                ...state,
                banksFetching: false,
                submitting: false,
            };
        case STORE_REPLACE_BANKS_ERROR:
            return {
                ...state,
                banksFetching: false,
                submitting: false,
                errors: payload,
            };
        case BACKEND_CREATE_BANK:
        case BACKEND_VERIFY_BANK:
            return { ...state, submitting: true, errors: {} };
        case STORE_VERIFY_BANK_ERROR:
        case STORE_CREATE_BANK_ERROR:
            return { ...state, errors: payload, submitting: false };
        case STORE_CREATE_BANK_SUCCESS:
        case STORE_VERIFY_BANK_SUCCESS:
            return { ...state, errors: {}, submitting: false };
        default:
            return state;
    }
};

export default banksReducer;
