import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_JOBS,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchJobs() {
    return {
        type: BACKEND_FETCH_JOBS,
        payload: null,
    };
}

export function replaceJobs(payload) {
    return {
        type: STORE_REPLACE_JOBS,
        payload,
    };
}

export function fetchJobsError(payload) {
    return {
        type: STORE_REPLACE_JOBS_ERROR,
        payload,
    };
}

const fetchJobsForDataModuleActions = createDataModuleActions('FETCH_JOBS');
export const fetchJobsForDataModule = fetchJobsForDataModuleActions.dataRequest;
export const fetchJobsSuccess = fetchJobsForDataModuleActions.dataSuccess;
export const fetchJobsErrorForDataModule =
    fetchJobsForDataModuleActions.dataError;
export const fetchJobsReset = fetchJobsForDataModuleActions.dataReset;

const createPrimaryJobForDataModuleActions =
    createDataModuleActions('CREATE_PRIMARY_JOB');
export const createPrimaryJob =
    createPrimaryJobForDataModuleActions.dataRequest;
export const createPrimaryJobSuccess =
    createPrimaryJobForDataModuleActions.dataSuccess;
export const createPrimaryJobError =
    createPrimaryJobForDataModuleActions.dataError;
export const createPrimaryJobReset =
    createPrimaryJobForDataModuleActions.dataReset;

const updateJobForDataModuleActions = createDataModuleActions('UPDATE_JOBS');
export const updateJob = updateJobForDataModuleActions.dataRequest;
export const updateJobSuccess = updateJobForDataModuleActions.dataSuccess;
export const updateJobError = updateJobForDataModuleActions.dataError;
export const updateJobReset = updateJobForDataModuleActions.dataReset;
