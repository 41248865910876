import { API_URL_BASE, MAGENTO_HOST } from '@perpay-web/constants/urls';

export const AUTH_ENDPOINT = `${API_URL_BASE}/auth/`;
export const AUTH_MFA_ENDPOINT = `${API_URL_BASE}/auth-mfa/`;
export const AUTH_MFA_RESEND_ENDPOINT = `${API_URL_BASE}/auth-mfa/resend`;
export const REFRESH_ENDPOINT = `${API_URL_BASE}/auth-refresh/`;
export const LOGOUT_ENDPOINT = `${API_URL_BASE}/auth-logout/`;
export const STOREFRONT_LOGOUT_URL = `https://${MAGENTO_HOST}/customer/account/logout`;
export const USERS_ENDPOINT = `${API_URL_BASE}/users/`;
export const PARTNER_ONBOARDING_ENDPOINT = `${API_URL_BASE}/users/partner-onboarding/signup/`;
