import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importExternalCardAdd = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "ExternalCardAdd" */
                '../../components/screens/ExternalCardAdd/ExternalCardAdd'
            ),
    );
