import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchCardDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchCardDashboardSummary';
import {
    fetchPlatformDashboardNotificationsDataModule,
    getNotification as getPlatformNotification,
} from '@perpay-web/fintech/dataModules/fetchPlatformDashboardNotifications';
import {
    fetchBorrowerCreditSummaryDataModule,
    getTotalCreditsAvailable,
} from '@perpay-web/fintech/dataModules/fetchBorrowerCreditSummary';
import { fetchMarketplaceDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchMarketplaceDashboardSummary';
import { fetchDirectDepositDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchDirectDepositDashboardSummary';
import { fetchPerpayPlusDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchPerpayPlusDashboardSummary';
import { getAds } from '@perpay-web/fintech/reducers/entities/ads';
import {
    fetchLearnMoreDataModule,
    getShowLearnMore,
} from '@perpay-web/fintech/dataModules/fetchLearnMore';
import { PLATFORM_GOOD } from '@perpay-web/fintech/utils/platformDashboardNotificationUtils';
import { getAdsLoading } from '@perpay-web/fintech/reducers/ui/ads';

const DashboardDataModule = composeDataModules([
    fetchCardDashboardSummaryDataModule,
    fetchMarketplaceDashboardSummaryDataModule,
    fetchPlatformDashboardNotificationsDataModule,
    fetchPerpayPlusDashboardSummaryDataModule,
    fetchDirectDepositDashboardSummaryDataModule,
    fetchBorrowerCreditSummaryDataModule,
    fetchLearnMoreDataModule,
]);

const getRoot = (state) => state.dataModules.dashboardV2;

export const dashboardDataModule = DashboardDataModule({
    getRoot,
    key: 'DASHBOARD_V2',
});

export { getShowLearnMore };

export const getIsLoading = (state) =>
    dashboardDataModule.getIsLoadingOrUnrequested(state) ||
    getAdsLoading(state);

export const getShowPlatformNotification = (state) =>
    getPlatformNotification(state) !== PLATFORM_GOOD;

export const getShowBoxAd = (state) => Boolean(getAds(state));

export const getShowRewardsSummary = (state) =>
    getTotalCreditsAvailable(state) > 0;
