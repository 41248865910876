import { errorSelfOnboardingCompanies } from '@perpay-web/fintech/actions/ui/companies';
import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_SELF_ONBOARDING,
    ERROR_COMPANIES,
    REPLACE_COMPANIES,
    REPLACE_COMPANIES_SELF_ONBOARDING,
    RESET_COMPANIES,
} from '@perpay-web/fintech/constants/actionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
    error: '',
    selfOnboardingMatches: [],
    results: {},
    isLoadingResults: false,
    hasLoadedResults: false,
};

const companiesReducer = createReducer(initialState, (builder) => {
    builder.addCase(FETCH_COMPANIES, (state) => ({
        ...state,
        error: '',
        isLoadingResults: true,
    }));
    builder.addCase(REPLACE_COMPANIES, (state, action) => ({
        ...state,
        error: '',
        hasLoadedResults: true,
        isLoadingResults: false,
        results: action.payload,
    }));
    builder.addCase(ERROR_COMPANIES, (state, action) => ({
        ...state,
        error: action.payload.message,
        hasLoadedResults: true,
        isLoadingResults: false,
        results: {},
    }));
    builder.addCase(FETCH_COMPANIES_SELF_ONBOARDING, (state) => ({
        ...state,
        error: '',
        isLoadingResults: true,
    }));
    builder.addCase(REPLACE_COMPANIES_SELF_ONBOARDING, (state, action) => ({
        ...state,
        error: '',
        isLoadingResults: false,
        otherCompany: action.payload.otherCompany,
        selfOnboardingMatches: action.payload.matches,
    }));
    builder.addCase(errorSelfOnboardingCompanies, (state, action) => ({
        ...state,
        error: action.payload.message,
        isLoadingResults: false,
        selfOnboardingMatches: [],
    }));
    builder.addCase(RESET_COMPANIES, () => initialState);
});

export default companiesReducer;
