import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { pathIsInternalToApp } from '@perpay-web/utils/routeUtils';
import { isIosAgent } from '@perpay-web/utils/userAgentUtils';
import styles from '@perpay-web/design/styles/constants.scss';
import {
    POST_AUTH_REDIRECT,
    ROUTE_TO_JWT_LOCATION,
    ROUTE_TO_LOCATION,
} from '@perpay-web/fintech/constants/actionTypes';
import { postLogout } from '@perpay-web/fintech/actions/authentication';
import {
    routeToJwtEnabledLocation,
    routeToLocation as routeToLocationAction,
} from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    compareURLHosts,
    getShopLastPageCookie,
    getAndExpireShopLastPageCookie,
    pathIsWhitelistedExternal,
    isUnauthenticatedPath,
} from '@perpay-web/fintech/utils/routeUtils';
import { appUpdatePollStop } from '@perpay-web/fintech/actions/appUpdate';
import { getClientVersion } from '@perpay-web/fintech/selectors/appUpdate';
import {
    JWT_ENABLED_URLS,
    JWT_MAGENTO_URL,
} from '@perpay-web/fintech/constants/urls';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { getCordovaApp } from '@perpay-web/fintech/utils/cordovaUtils';
import { push, replace } from '@perpay-web/services/router';

// Style/color for status bar color change

const DEFAULT_COLOR = styles['perpay-white'];

function getUnauthParams(queryString, nextPath) {
    const search = new URLSearchParams(queryString);
    const neededParams = new URLSearchParams();
    search.forEach((value, key) => {
        const isNeeded = value && !nextPath.includes(`${key}=`);
        if (isNeeded) {
            neededParams.append(key, value);
        }
    });
    return neededParams;
}

function propagateUnauthParams(queryString, nextPath) {
    const paramString = getUnauthParams(queryString, nextPath).toString();
    return paramString ? `${nextPath}?${paramString}` : nextPath;
}

const resetStatusBar = (color, isTransparent) => {
    if (isIosAgent()) {
        getCordovaApp()
            .then((CordovaApp) => CordovaApp.requirePlugin('statusbar'))
            .then(({ setStatusBarColor, overlaysWebView }) => {
                setStatusBarColor(color);
                if (overlaysWebView) {
                    document.documentElement.classList.toggle(
                        'ios--transparent-status-bar',
                        isTransparent,
                    );
                    overlaysWebView(isTransparent);
                }
            });
    }
};

/**
 * Use history replace methods when navigating from these paths
 * to prevent users from being able to return to the logout or login screen
 * after authenticating to prevent user confusion.
 */
const REPLACE_PATHS = [paths.logout.path, paths.login.path];

export function routeToLocation(action$, state$) {
    return action$.pipe(
        ofType(ROUTE_TO_LOCATION),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { path, props, replace: replacePath } = action.payload;
            const clientVersion = getClientVersion(state);
            const isAuthenticated = authentication.getIsAuthenticated();
            const currentPath = window.location.pathname;
            const shouldReplace =
                (isAuthenticated && REPLACE_PATHS.includes(currentPath)) ||
                replacePath;

            // If we pass a path to an external page outside of perpay-web,
            // we want to redirect there immediately.
            if (!pathIsInternalToApp(path)) {
                const locationMethod = shouldReplace ? 'replace' : 'assign';
                window.location[locationMethod](path);
                resetStatusBar(DEFAULT_COLOR, false);
                return [];
            }

            // If we can update to a new version, we should do it on navigation to avoid
            // interrupting the user's experience.
            if (clientVersion !== window.VERSION) {
                const locationMethod = shouldReplace ? 'replace' : 'assign';
                window.location[locationMethod](path);
                return [appUpdatePollStop()];
            }

            if (pathIsInternalToApp(path)) {
                // If the user is unauthenticated and there is a next param,
                // preserve the next param between pages.
                const nextPath = !isAuthenticated
                    ? propagateUnauthParams(window.location.search, path)
                    : path;
                // Use history.replace when navigating away from /logout/ so
                // the back button won't be able to log out the user.
                const historyMethod = shouldReplace ? replace : push;
                historyMethod(nextPath, props);
                return [];
            }

            if (pathIsWhitelistedExternal(path)) {
                const locationMethod = shouldReplace ? 'replace' : 'assign';
                window.location[locationMethod](path);
                return [appUpdatePollStop()];
            }

            return [];
        }),
    );
}

// This is a version of routeToLocation that knows about the auth state so we can
// pass the auth token to the storefront or another route that needs to be able to
// log the user in.
// It is a little complex and could use a refactor one day.
export function routeToJwtLocation(action$) {
    return action$.pipe(
        ofType(ROUTE_TO_JWT_LOCATION),
        mergeMap((action) => {
            if (authentication.getIsRefreshTokenExpired()) {
                return [postLogout()];
            }

            const { url, tokenParamKey, target = '_blank' } = action.payload;

            // If the access token is expired, we must trigger a refresh, wait for it to complete,
            // then re-dispatch the action to navigate.
            if (authentication.getIsAccessTokenExpired()) {
                return authentication
                    .refresh()
                    .then(() => routeToJwtEnabledLocation(url, tokenParamKey));
            }

            // This case is for when we need to pass the JWT to log the user into the storefront.
            // We check for the shop url so that bad actors don't craft malicious redirect links to
            // steal users' tokens
            const params = getUnauthParams(window.location.search, url);
            const lastShop = getAndExpireShopLastPageCookie();
            if (lastShop) {
                params.set('next', lastShop);
            }

            if (
                JWT_ENABLED_URLS.includes(url) &&
                compareURLHosts(params.get('next'), url)
            ) {
                params.set(tokenParamKey, authentication.getAccessToken());
                window.location.replace(`${url}?${params}`);
                return [];
            }

            // Here we are able to navigate to any whitelisted URL with the access token
            if (pathIsWhitelistedExternal(url)) {
                const externalParams = new URLSearchParams();
                externalParams.set(
                    tokenParamKey,
                    authentication.getAccessToken(),
                );

                // create a link that the mobile app will open in a new system browser window
                const a = document.createElement('a');
                a.href = `${url}?${externalParams}`;
                a.target = target;
                document.body.appendChild(a);
                a.click();
                return [];
            }

            return [];
        }),
    );
}

export const postAuthRedirect = (action$) =>
    action$.pipe(
        ofType(POST_AUTH_REDIRECT),
        mergeMap(() => {
            const params = new URLSearchParams(window.location.search);
            const nextPath = params.get('next') || getShopLastPageCookie();
            params.delete('next');

            const redirectToDashboardActions = [
                routeToLocationAction(`${paths.dashboard.path}?${params}`),
            ];

            if (isUnauthenticatedPath(nextPath)) {
                return redirectToDashboardActions;
            }

            if (pathIsInternalToApp(nextPath)) {
                return [routeToLocationAction(`${nextPath}?${params}`)];
            }

            if (compareURLHosts(nextPath, JWT_MAGENTO_URL)) {
                return [routeToJwtEnabledLocation(JWT_MAGENTO_URL, 'access')];
            }

            if (pathIsWhitelistedExternal(nextPath)) {
                const paramString = params.toString();
                const url = nextPath + (paramString ? `?${paramString}` : '');
                window.location.replace(url);
                return [];
            }

            return redirectToDashboardActions;
        }),
    );
