import Modal from '@perpay-web/components/base/Modal/Modal';
import { EndToEndTestIdProvider } from '@perpay-web/utils/endToEndTestIds';
import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import { formatCurrency } from '@perpay-web/utils/stringUtils';
import { getClassName } from '@perpay-web/utils/domUtils';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { ADVOCATE_COMPLETE_AMOUNT_STRING } from '@perpay-web/fintech/constants/referrals';
import {
    PERPAY_TERMS_AND_CONDITIONS_URL,
    PERPAY_PRIVACY_POLICY_URL,
    BLOG_URL,
    HELP_CENTER_HOME_URL,
} from '@perpay-web/fintech/constants/urls';
import { useShopPath } from '@perpay-web/fintech/hooks/useShopPath';

import styles from './HeaderDrawerMenu.scss';

const HeaderDrawerMenu = ({
    onClose,
    fullName = '',
    accountBalance = null,
    onClickPerpayPlus,
    launchSupportToolHandler,
    isPerpayPlusEligible,
    hasClosedCardAccount,
    className = '',
}) => {
    const { shopPath } = useShopPath();
    return (
        <Modal
            onClose={onClose}
            className={getClassName(styles.modal, className)}
            containerClassName={styles.content}
        >
            <div className={styles['content-wrapper']}>
                <div className={styles['name-container']}>
                    <div className={styles.name}>{fullName}</div>
                    <CustomLink href={paths.profile.path} onClick={onClose}>
                        View profile
                    </CustomLink>
                </div>
                <div className={styles['nav-block']}>
                    <div>
                        <ul className={styles['list-container']}>
                            {accountBalance ? (
                                <li className={styles['withdrawal-block']}>
                                    <div>
                                        {formatCurrency(accountBalance, false)}{' '}
                                        in Perpay
                                    </div>
                                    <CustomLink
                                        href={paths.withdrawal.path}
                                        onClick={onClose}
                                    >
                                        Withdraw
                                    </CustomLink>
                                </li>
                            ) : null}
                            <li>
                                <CustomLink
                                    href={paths.referrals.path}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                >
                                    <span className={styles.referrals}>
                                        Invite & Get{' '}
                                        {ADVOCATE_COMPLETE_AMOUNT_STRING}
                                    </span>
                                </CustomLink>
                            </li>
                            <li>
                                <CustomLink
                                    href={shopPath}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                >
                                    Shop
                                </CustomLink>
                            </li>
                            {isPerpayPlusEligible ? (
                                <li>
                                    <CustomLink
                                        href={paths.perpayPlus.path}
                                        onClick={() => {
                                            onClickPerpayPlus();
                                            onClose();
                                        }}
                                        className={styles['nav-item']}
                                    >
                                        perpay
                                        <span className={styles.plus}>+</span>
                                    </CustomLink>
                                </li>
                            ) : null}
                            {hasClosedCardAccount ? (
                                <li>
                                    <CustomLink
                                        href={paths.cardDetails.path}
                                        onClick={onClose}
                                        className={styles['nav-item']}
                                    >
                                        Credit Card
                                    </CustomLink>
                                </li>
                            ) : null}
                            <li>
                                <CustomLink
                                    href={HELP_CENTER_HOME_URL}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                    target='_blank'
                                >
                                    Help Center
                                </CustomLink>
                            </li>
                            <li>
                                <CustomLink
                                    href={BLOG_URL}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                    target='_blank'
                                >
                                    Blog
                                </CustomLink>
                            </li>
                            <li>
                                <EndToEndTestIdProvider testId='log-out-button'>
                                    <CustomLink
                                        href={paths.logout.path}
                                        onClick={onClose}
                                        className={styles['nav-item']}
                                    >
                                        Log Out
                                    </CustomLink>
                                </EndToEndTestIdProvider>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className={styles['list-container']}>
                            <li>
                                <CustomButton
                                    action='inline-link'
                                    onClick={() => {
                                        launchSupportToolHandler();
                                        onClose();
                                    }}
                                    className={styles['nav-item']}
                                >
                                    Contact Support
                                </CustomButton>
                            </li>
                            <li>
                                <CustomLink
                                    href={PERPAY_PRIVACY_POLICY_URL}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                    target='_blank'
                                >
                                    Privacy Policy
                                </CustomLink>
                            </li>
                            <li>
                                <CustomLink
                                    href={PERPAY_TERMS_AND_CONDITIONS_URL}
                                    onClick={onClose}
                                    className={styles['nav-item']}
                                    target='_blank'
                                >
                                    Terms of Service
                                </CustomLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default HeaderDrawerMenu;
