import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const validateAddressForMVP2Actions = createDataModuleActions(
    'VALIDATE_ADDRESS_FOR_MVP_2',
);
export const validateAddressForMVP2 = validateAddressForMVP2Actions.dataRequest;
export const validateAddressSuccessForMVP2 =
    validateAddressForMVP2Actions.dataSuccess;
export const validateAddressErrorForMVP2 =
    validateAddressForMVP2Actions.dataError;
export const validateAddressResetForMVP2 =
    validateAddressForMVP2Actions.dataReset;
