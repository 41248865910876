import { STORE_CARD_ACCOUNT_APPLICATION_POLLING_ERROR } from '@perpay-web/fintech/constants/actionTypes';
import { createReducer } from '@reduxjs/toolkit';

const cardAccountApplicationsReducer = createReducer(
    { pollingError: null },
    (builder) => {
        builder.addCase(
            STORE_CARD_ACCOUNT_APPLICATION_POLLING_ERROR,
            (state, action) => ({ ...state, pollingError: action.payload }),
        );
    },
);

export default cardAccountApplicationsReducer;
