import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchUserStatusDataModule } from '@perpay-web/fintech/dataModules/fetchUserStatus';
import { coreSummaryDataModule } from '@perpay-web/fintech/dataModules/coreSummary';

const OrdersDataModule = composeDataModules([
    coreSummaryDataModule,
    fetchUserStatusDataModule,
]);

const getRoot = (state) => state.dataModules.orders;

export const ordersDataModule = OrdersDataModule({
    getRoot,
    key: 'ORDERS',
});
