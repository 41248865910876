import { PRODUCT_DETAILS } from '@perpay-web/constants/paths';

/**
 * Extract the param from the URL with the name "key".
 *
 * By default, URLSearchParams will convert "+" in URLs to
 * a space character. Since we use + in email addresses extensively,
 * this utility ensures that + characters are preserved.
 */
export function getURLSearchParam(key, search = window.location.search) {
    const searchWithEncodedPlus = search.replace(
        /\+/g,
        encodeURIComponent('+'),
    );
    const urlParams = new URLSearchParams(searchWithEncodedPlus);
    return urlParams.get(key);
}

export const formatProductDetailsURL = (
    objectID,
    productSlug = '',
    swatchValue = null,
) => {
    const pathWithId = PRODUCT_DETAILS.replace(':objectID', objectID);
    const pathWithSlug = pathWithId.replace(':slug?', productSlug);
    const fullPath = swatchValue
        ? `${pathWithSlug}?swatchValue=${encodeURIComponent(swatchValue)}`
        : pathWithSlug;
    return fullPath;
};
