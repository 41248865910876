import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const fetchUserStatusDataModuleActions = createDataModuleActions('USER_STATUS');
export const fetchUserStatusForDataModule =
    fetchUserStatusDataModuleActions.dataRequest;
export const fetchUserStatusSuccess =
    fetchUserStatusDataModuleActions.dataSuccess;
export const fetchUserStatusError = fetchUserStatusDataModuleActions.dataError;
export const fetchUserStatusReset = fetchUserStatusDataModuleActions.dataReset;

export function fetchUserStatus() {
    return {
        type: BACKEND_FETCH_USER_STATUS,
        payload: null,
    };
}

export function replaceUserStatus(userStatus) {
    return {
        type: STORE_REPLACE_USER_STATUS,
        payload: userStatus,
    };
}

export function userStatusError(error) {
    return {
        type: STORE_REPLACE_USER_STATUS_ERROR,
        payload: error,
    };
}
