import {
    ANALYTICS_APPLICATION_DECISION,
    BACKEND_CHECKOUT_LOAN_DECISION_GENERATE,
    STORE_CHECKOUT_LOAN_DECISION_GENERATE_ERROR,
    STORE_CHECKOUT_REPLACE_LOAN_DECISION,
} from '@perpay-web/fintech/constants/actionTypes';

export function checkoutLoanDecisionGenerate(uuid) {
    return {
        type: BACKEND_CHECKOUT_LOAN_DECISION_GENERATE,
        payload: { uuid },
    };
}

export function checkoutLoanDecisionError(error) {
    return {
        type: STORE_CHECKOUT_LOAN_DECISION_GENERATE_ERROR,
        payload: error,
    };
}

export function checkoutReplaceLoanDecision(response) {
    return {
        type: STORE_CHECKOUT_REPLACE_LOAN_DECISION,
        payload: {
            decision: response.decision,
            order: response.order,
        },
    };
}

export function analyticsApplicationDecision(response) {
    return {
        type: ANALYTICS_APPLICATION_DECISION,
        payload: {
            decision: response.decision,
            order_uuid: response.order.uuid,
        },
    };
}
