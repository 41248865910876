import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchReferralStats,
    fetchReferralStatsError,
    replaceReferralStats,
    resetReferralStats,
} from '@perpay-web/fintech/actions/ui/referralStats';

const ReferralStatsDataModule = createDataModule({
    dataRequest: fetchReferralStats,
    dataSuccess: replaceReferralStats,
    dataError: fetchReferralStatsError,
    dataReset: resetReferralStats,
});

const getRoot = (state) => state.dataModules.referralStats;

const initialValue = {
    credits: 0,
    successes: 0,
};

export const referralStatsDataModule = ReferralStatsDataModule({
    getRoot,
    initialValue,
});

export const getCredits = (state) =>
    referralStatsDataModule.getData(state).credits;

export const getSuccesses = (state) =>
    referralStatsDataModule.getData(state).successes;
