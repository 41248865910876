import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchDeductionsForDataModule as fetchDeductionsForDataModuleAction,
    fetchDeductionsSuccess,
    fetchDeductionsError,
    resetFetchDeductions,
} from '@perpay-web/fintech/actions/entities/deductions';
import { CURRENT, PENDING } from '@perpay-web/fintech/constants/deductionTypes';

const FetchDeductionsDataModule = createDataModule({
    dataRequest: fetchDeductionsForDataModuleAction,
    dataSuccess: fetchDeductionsSuccess,
    dataError: fetchDeductionsError,
    dataReset: resetFetchDeductions,
});

const getRoot = (state) => state.dataModules.fetchDeductions;
const initialValue = { all: [] };

export const fetchDeductionsDataModule = FetchDeductionsDataModule({
    getRoot,
    initialValue,
});

export const getRecentDeductionAmount = (state) => {
    const deductions = fetchDeductionsDataModule.getData(state).all;
    const currentDeductions = deductions.filter(
        ({ status }) => status === CURRENT || status === PENDING,
    );

    if (!currentDeductions.length) {
        return 0;
    }

    if (currentDeductions.length === 1) {
        return currentDeductions[0].amount;
    }

    const currentDeduction = currentDeductions.reduce((result, deduction) =>
        deduction.tokenExpiration > result.tokenExpiration ? deduction : result,
    );

    return currentDeduction.amount;
};
