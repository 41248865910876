import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchCardDashboardSummaryActions = createDataModuleActions(
    'FETCH_CARD_DASHBOARD_SUMMARY',
);
export const fetchCardDashboardSummary =
    fetchCardDashboardSummaryActions.dataRequest;
export const cardDashboardSummarySuccess =
    fetchCardDashboardSummaryActions.dataSuccess;
export const cardDashboardSummaryError =
    fetchCardDashboardSummaryActions.dataError;
export const cardDashboardSummaryReset =
    fetchCardDashboardSummaryActions.dataReset;
