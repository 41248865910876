import { iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const isResponse = (value) => value && typeof value.status === 'number';
const hasResponse = (value) =>
    value &&
    typeof value === 'object' &&
    Object.values(value).some((item) => isResponse(item));
const shouldPipeResponse = (value) => isResponse(value) || hasResponse(value);

export function pipeResponse(...pipeForResponses) {
    return (source$) =>
        source$.pipe(
            mergeMap((value) =>
                iif(
                    () => shouldPipeResponse(value),
                    of(value).pipe(...pipeForResponses),
                    of(value),
                ),
            ),
        );
}
