import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_BANKS,
    STORE_REPLACE_BANKS,
    STORE_REPLACE_BANKS_ERROR,
    STORE_RESET_BANKS,
    BACKEND_CREATE_BANK,
    STORE_CREATE_BANK_ERROR,
    STORE_CREATE_BANK_SUCCESS,
    BACKEND_VERIFY_BANK,
    BACKEND_VERIFY_BANK_SELECT,
    BACKEND_VERIFY_BANK_SINGLE,
    STORE_VERIFY_BANK_ERROR,
    STORE_VERIFY_BANK_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchBanks() {
    return {
        type: BACKEND_FETCH_BANKS,
    };
}

export function replaceBanks(banks) {
    return {
        type: STORE_REPLACE_BANKS,
        payload: banks,
    };
}

export function replaceBanksError(error) {
    return {
        type: STORE_REPLACE_BANKS_ERROR,
        payload: error,
    };
}

export function resetBanks() {
    return {
        type: STORE_RESET_BANKS,
    };
}

export function createBank(data) {
    return {
        type: BACKEND_CREATE_BANK,
        payload: data,
    };
}

export function createBankError(error) {
    return {
        type: STORE_CREATE_BANK_ERROR,
        payload: error,
    };
}

export function createBankSuccess() {
    return {
        type: STORE_CREATE_BANK_SUCCESS,
    };
}

export function verifyBank(bankUUID, amount1, amount2) {
    return {
        type: BACKEND_VERIFY_BANK,
        payload: {
            amounts: [amount1, amount2],
            uuid: bankUUID,
        },
    };
}

export function verifyBankSelect(bankUUID, amount1, amount2) {
    return {
        type: BACKEND_VERIFY_BANK_SELECT,
        payload: {
            amounts: [amount1, amount2],
            uuid: bankUUID,
        },
    };
}

export function verifyBankSingle(bankUUID, amount1, amount2) {
    return {
        type: BACKEND_VERIFY_BANK_SINGLE,
        payload: {
            amounts: [amount1, amount2],
            uuid: bankUUID,
        },
    };
}

export function verifyBankError(error) {
    return {
        type: STORE_VERIFY_BANK_ERROR,
        payload: error,
    };
}

export function verifyBankSuccess() {
    return {
        type: STORE_VERIFY_BANK_SUCCESS,
    };
}

const fetchBankAccountsActions = createDataModuleActions('FETCH_BANK_ACCOUNTS');
export const fetchBankAccounts = fetchBankAccountsActions.dataRequest;
export const fetchBankAccountsSuccess = fetchBankAccountsActions.dataSuccess;
export const fetchBankAccountsError = fetchBankAccountsActions.dataError;
export const fetchBankAccountsReset = fetchBankAccountsActions.dataReset;

const createBankAccountActions = createDataModuleActions('CREATE_BANK_ACCOUNT');
export const createBankAccount = createBankAccountActions.dataRequest;
export const createBankAccountSuccess = createBankAccountActions.dataSuccess;
export const createBankAccountError = createBankAccountActions.dataError;
export const createBankAccountReset = createBankAccountActions.dataReset;
