import { useDispatch } from 'react-redux';

import Logout from '@perpay-web/fintech/components/composite/Logout/Logout';
import { postLogout } from '@perpay-web/fintech/actions/authentication';
import { useCallback } from 'react';

const BorrowerLogoutContainer = () => {
    const dispatch = useDispatch();

    const onMount = useCallback(() => dispatch(postLogout()), [dispatch]);

    return <Logout onMount={onMount} />;
};

export default BorrowerLogoutContainer;
