import { formatShortMonthNumericDayYear } from '@perpay-web/utils/dateUtils';
import { validateField } from '@perpay-web/validators/fieldBased';

// Password validators
export const hasLowerCase = (
    value,
    error = 'A lower case character is required',
) => validateField(!/[a-z]+/.test(value), error);

export const hasUpperCase = (
    value,
    error = 'An upper case character is required',
) => validateField(!/[A-Z]+/.test(value), error);

export const hasNumber = (value, error = 'A number is required') =>
    validateField(!/[0-9]+/.test(value), error);

export const hasMinMaxChars = (value, min = 8, max = 100, error = null) => {
    const errorMessage =
        error || `Length should be between ${min} and ${max} characters`;
    const regex = new RegExp(`.{${min},${max}}`);
    return validateField(!regex.test(value), errorMessage);
};

export const requiredCustomMessage = (message) => (value) =>
    value ? undefined : message;

// Validator to detect input with space(s) only
export const requiredNonSpace = (value) => {
    if (value && !value.trim()) {
        return ['This is a required field'];
    }
    return undefined;
};

export const money = (value) => {
    if (!/^(\d+|\d+\.\d{0,2}|\d*\.\d{1,2})?$/.test(value)) {
        return ['Please enter a dollar amount, e.g. $10.00'];
    }
    return undefined;
};

const invalidEmailSuffixRe =
    /\.(cop|con|vom|come|cim|cok|xom|comm|coml|col|coma|comj|coms|comp|clm|comt|ckm|comc|comi|comb|comn|cpm|comd|comr|gom|comh|fom|comg|coom|cao)$/i;

export const email = (value) => {
    if (invalidEmailSuffixRe.test(value)) {
        const suggestion = value.replace(invalidEmailSuffixRe, '.com');
        return [`Invalid email address. Did you mean ${suggestion}?`];
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return ['Invalid email address'];
    }

    return undefined;
};

/**
 * Expects to receive all digits
 */
const phone = (value) => {
    if (!/\d+/.test(value)) {
        return ['Please enter a valid phone number'];
    }

    return undefined;
};

export const emailOrPhone = (value) => {
    const invalidEmail = email(value);
    const invalidPhone = phone(value);

    // If at least one character is not a number, treat it like an email
    const isEmail = /\D/.test(value);
    // If only consecutive digits are present, treat it like a phone number
    const isPhone = /^\d+$/.test(value);

    if (isEmail && !/[@]/.test(value)) {
        return ['Please include an @...'];
    }

    if (isEmail && invalidEmail) {
        return invalidEmail;
    }

    if (isPhone && value.length !== 10) {
        return [
            'Please enter a 10-digit phone number using only numbers (no dashes, parentheses or spaces)',
        ];
    }

    if (isPhone && invalidPhone) {
        return invalidPhone;
    }

    return undefined;
};

export const phoneVerificationCode = (value) => {
    if (value && !/^([0-9]{4})$/i.test(value)) {
        return ['Invalid code, must be 4 digits'];
    }
    return undefined;
};

export const ssn = (value) => {
    if (value && !/^\d{9}$/.test(value)) {
        return ['Invalid SSN, must be 9 digits'];
    }
    return undefined;
};

export const dateFormat = (value) => {
    if (
        value &&
        !/^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value)
    ) {
        return ['Invalid date, must be in the format MM/DD/YYYY'];
    }
    return undefined;
};

export const minDateOfBirth = (value) => {
    if (value && !/^\d{2}\/\d{2}\/(19|20)\d{2}$/.test(value)) {
        return ['Invalid year for given date of birth'];
    }
    return undefined;
};

/**
 * Estimated net pay allows values from 1-9999 inclusive, and
 *  accounts for a comma potentially separating the thousands place.
 */
export const estimatedNetPay = (value) => {
    if (!/^\$?[1-9]([0-9]{0,3}|,?[0-9]{3})$/.test(value)) {
        return ['Invalid currency amount'];
    }
    return undefined;
};

/* Validator to ensure an exact character length of an input

    Example usage:

        <FormikCustomInputServerError
            validate={length(10, 'footballs')}
        />

    On a 7 character input, returns the error:
    "Must be 10 footballs long"
*/
export const exactLength = (length, characterTypeName) => (value) => {
    if (String(value).length !== length) {
        return [`Must be ${length} ${characterTypeName} long`];
    }
    return undefined;
};

/* Validator to ensure a min character length of an input
    Example usage:

        <FormikCustomInputServerError
            validate={length(2, 'characters')}
        />

    On a 1 character input, returns the error:
    "Must be less than or equal to 2 characters long"
*/
const minLength = (length, characterTypeName) => (value) => {
    if (String(value).length < length) {
        return [
            `Must be more than or equal to ${length} ${characterTypeName} long`,
        ];
    }
    return undefined;
};

/* Validator to ensure a max character length of an input
    Example usage:

        <FormikCustomInputServerError
            validate={length(250, 'characters')}
        />

    On a 251 character input, returns the error:
    "Must be less than or equal to 250 characters long"
*/
export const maxLength = (length, characterTypeName) => (value) => {
    if (String(value).length > length) {
        return [
            `Must be less than or equal to ${length} ${characterTypeName} long`,
        ];
    }
    return undefined;
};

export const zipCode = (value) => {
    if (value && !/^([0-9]{5})$/i.test(value)) {
        return ['Invalid zip code, must be 5 digits'];
    }
    return undefined;
};

/*
    The Deserve Field validators are copied from the link below. The final validation is done at backend.
    https://docs.google.com/spreadsheets/d/1zdqyWNBDUY_gzqAeTwqwOiPqs87zs0HXQFXBx4AP41w/edit?usp=sharing
*/

export const deserveFirstName = (value) => {
    const isShort = minLength(2, 'characters')(value);
    const isLong = maxLength(40, 'characters')(value);
    if (isShort || isLong) return isShort || isLong;

    // "English Alphabets with hyphens and apostrophe only and some fixed special characters. Spaces Allowed.
    const firstNameRe = /^[A-Za-zÀ-ž\-'." ]{2,40}$/i;
    if (value && !firstNameRe.test(value.trim())) {
        return ['Invalid name'];
    }

    return undefined;
};

export const deserveLastName = (value) => {
    const isShort = minLength(2, 'characters')(value);
    const isLong = maxLength(40, 'characters')(value);
    if (isShort || isLong) return isShort || isLong;

    // "English Alphabets with hyphens and apostrophe only and some fixed special characters. Spaces Allowed.
    const lastNameRe = /^[A-Za-zÀ-ž\-'." ]{2,40}$/i;
    if (value && !lastNameRe.test(value.trim())) {
        return ['Invalid name'];
    }

    return undefined;
};

export const deserveCity = (value) => {
    const isShort = minLength(1, 'characters')(value);
    const isLong = maxLength(50, 'characters')(value);
    if (isShort || isLong) return isShort || isLong;

    // Alphabets and hyphens and apostrophe. Spaces Allowed.
    const validCityRe = /^[A-Za-zÀ-ž\-"'. ]{1,50}$/i;
    if (value && !validCityRe.test(value)) {
        return ['Invalid city'];
    }

    return undefined;
};

const poBox = (value) => {
    const poBoxRe = /p\.{0,1} {0,2}o\.{0,1} {0,3}box/i;
    if (value && poBoxRe.test(value)) {
        return [
            'P.O. Boxes cannot be used on your Perpay Credit Card application.',
        ];
    }
    return undefined;
};

export const deserveAddressLineOne = (value) => {
    const isShort = minLength(5, 'characters')(value);
    const isLong = maxLength(40, 'characters')(value);
    const isPoBox = poBox(value);
    if (isShort || isLong || isPoBox) {
        return isShort || isLong || isPoBox;
    }
    return undefined;
};

export const deserveAddressLineTwo = (value) => {
    const isLong = maxLength(50, 'characters')(value);
    const isPoBox = poBox(value);
    if (isLong || isPoBox) {
        return isLong || isPoBox;
    }
    return undefined;
};

export const deserveState = (value) => {
    // States which Deserve does not provision cards to
    const unsupportedStates = ['AS', 'FM', 'GU', 'MH', 'MP', 'PW', 'PR', 'VI'];
    if (unsupportedStates.includes(value)) {
        return ['We are unable to send credit cards to this location.'];
    }
    return undefined;
};

export const dateRangeInclusive = (min, max) => (value) => {
    if (value && value < min) {
        return [
            `Please choose a date on or after ${formatShortMonthNumericDayYear(
                min,
            )}.`,
        ];
    }
    if (value && value > max) {
        const overMaxDateObject = new Date(max);
        overMaxDateObject.setDate(overMaxDateObject.getDate() + 1);
        const [overMaxDate] = overMaxDateObject.toISOString().split('T');

        return [
            `Please choose a date before ${formatShortMonthNumericDayYear(
                overMaxDate,
            )}.`,
        ];
    }
    return undefined;
};

// This is a hacky way for unsupported employer field to display error
// after we're trying to modify other fields.
// Caused by Formik launching validators for all fields even if they're untouched.
export const isEmployerSupported = (errors) => () => {
    if (errors.selectedCompany) {
        return [
            "We currently don't support your employer. If you have another employer, please input it",
        ];
    }

    return undefined;
};

export const validateMicroDepositAmount = (value) => {
    if (value > 0.99 || value <= 0) {
        return ['Please enter an amount between $0.00 and $1.00'];
    }
    return undefined;
};
