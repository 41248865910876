import {
    BACKEND_FETCH_ORDERS,
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
    STORE_REPLACE_ORDERS,
    STORE_REPLACE_USER_STATUS,
    STORE_RESET_ORDERS,
    BACKEND_FETCH_ACCOUNT_SUMMARY,
    STORE_ACCOUNT_SUMMARY_ERROR,
    STORE_ACCOUNT_SUMMARY_REPLACE,
    BACKEND_FETCH_DEDUCTIONS,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    BACKEND_FETCH_FEATURES,
    STORE_REPLACE_FEATURES,
    STORE_FETCH_FEATURES_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    ordersFetching: true,
    userStatusFetching: true,
    accountSummaryFetching: true,
    outstandingBalance: 0,
    deductionsFetching: false,
    featureEnrollmentsFetching: true,
    fetchingFeatures: true,
    errors: {},
};

const dashboardReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_USER_STATUS:
            return { ...state, userStatusFetching: true };
        case STORE_REPLACE_USER_STATUS:
        case STORE_REPLACE_USER_STATUS_ERROR:
            return { ...state, userStatusFetching: false };
        case BACKEND_FETCH_ORDERS:
        case STORE_RESET_ORDERS:
            return { ...state, ordersFetching: true };
        case STORE_REPLACE_ORDERS:
            return { ...state, ordersFetching: false };
        case BACKEND_FETCH_ACCOUNT_SUMMARY:
            return { ...state, accountSummaryFetching: true };
        case STORE_ACCOUNT_SUMMARY_ERROR:
            return { ...state, accountSummaryFetching: false };
        case STORE_ACCOUNT_SUMMARY_REPLACE:
            return { ...state, accountSummaryFetching: false };
        case BACKEND_FETCH_DEDUCTIONS:
            return { ...state, deductionsFetching: true };
        case STORE_REPLACE_DEDUCTION:
            return { ...state, deductionsFetching: false };
        case STORE_REPLACE_DEDUCTION_ERROR:
            return { ...state, deductionsFetching: false, errors: payload };
        case BACKEND_FETCH_FEATURE_ENROLLMENTS:
            return { ...state, featureEnrollmentsFetching: true };
        case STORE_REPLACE_FEATURE_ENROLLMENTS:
        case STORE_FETCH_FEATURE_ENROLLMENTS_ERROR:
            return { ...state, featureEnrollmentsFetching: false };
        case BACKEND_FETCH_FEATURES:
            return { ...state, fetchingFeatures: true };
        case STORE_REPLACE_FEATURES:
        case STORE_FETCH_FEATURES_ERROR:
            return { ...state, fetchingFeatures: false };
        default:
            return state;
    }
};

export default dashboardReducer;
