import BottomSheet from '@perpay-web/components/base/BottomSheet/BottomSheet';
import { useMount } from '@perpay-web/hooks/useMount';
import { useModal } from '@perpay-web/components/base/Modal/useModal';
import { formatCurrency } from '@perpay-web/utils/stringUtils';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import highFive from '@perpay-web/fintech/assets/images/highFive.gif';
import { getClassName } from '@perpay-web/utils/domUtils';
import { useAnimatedModal } from '@perpay-web/components/base/Modal/useAnimatedModal';
import { useShopPath } from '@perpay-web/fintech/hooks/useShopPath';

import styles from './CardRewardsNotification.scss';

const CardRewardsNotification = ({
    isLoading,
    onMount,
    firstName,
    cardRewardsAmount,
    analyticsOnClickCta,
    analyticsOnClose,
}) => {
    useMount(() => onMount());
    const { shopPath } = useShopPath();

    const { isModalOpen, setIsModalOpen } = useModal(true);
    const {
        isAnimatedModalOpen,
        setIsAnimatedModalOpen,
        animatedModalClassName,
    } = useAnimatedModal({ isModalOpen, setIsModalOpen });

    return (
        <div>
            {isLoading || !isAnimatedModalOpen ? null : (
                <BottomSheet
                    onClose={() => {
                        analyticsOnClose();
                        setIsAnimatedModalOpen(false);
                    }}
                    className={getClassName(
                        styles.modal,
                        animatedModalClassName,
                    )}
                >
                    <div className={styles['img-container']}>
                        <img
                            className={styles['high-five-img']}
                            src={highFive}
                            alt='Illustration of high five'
                            data-chromatic='ignore'
                        />
                    </div>
                    <div className={styles['copy-container']}>
                        <div className={styles.header}>
                            Keep up the great work, {firstName}!
                        </div>
                        <div className={styles.copy}>
                            We received your recent direct deposit.
                            <strong>
                                {' '}
                                {formatCurrency(cardRewardsAmount, false)}
                            </strong>{' '}
                            has been added to your rewards balance.
                        </div>
                    </div>
                    <div className={styles['shopping-button-container']}>
                        <CustomLink
                            className={styles['shopping-button']}
                            action='primary'
                            href={shopPath}
                            onClick={analyticsOnClickCta}
                            size='small'
                        >
                            Start Shopping
                        </CustomLink>
                    </div>
                </BottomSheet>
            )}
        </div>
    );
};

export default CardRewardsNotification;
