import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateAutoPaySchedule,
    updateAutoPayScheduleSuccess,
    updateAutoPayScheduleError,
    updateAutoPayScheduleReset,
} from '@perpay-web/fintech/actions/entities/autoPaySchedules';

const UpdateAutoPayScheduleDataModule = createDataModule({
    dataRequest: updateAutoPaySchedule,
    dataSuccess: updateAutoPayScheduleSuccess,
    dataError: updateAutoPayScheduleError,
    dataReset: updateAutoPayScheduleReset,
});

const getRoot = (state) => state.dataModules.updateAutoPaySchedule;
const initialValue = {};

export const updateAutoPayScheduleDataModule = UpdateAutoPayScheduleDataModule({
    getRoot,
    initialValue,
});
