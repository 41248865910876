import { centsToDollar } from './numberUtils';

export function formatNumber(string) {
    if (Number.isNaN(string)) {
        return null;
    }
    return Number(string);
}

export function formatCurrency(
    value,
    isCents = true,
    keepCents = true,
    dollarsOperationFunction = undefined,
    formatOptions = {},
) {
    let dollars = isCents ? centsToDollar(value) : value;
    dollars =
        dollarsOperationFunction === undefined
            ? dollars
            : dollarsOperationFunction(dollars);

    const numDecimals = keepCents ? 2 : 0;

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: numDecimals,
        maximumFractionDigits: numDecimals,
        ...formatOptions,
    }).format(dollars);
}

export function formatMaskedPhoneNumber(phone) {
    const areaCode = phone.slice(phone.length - 10, phone.length - 7);
    const officeCode = phone.slice(phone.length - 7, phone.length - 4);
    const stationCode = phone.slice(-4);

    return `(${areaCode})-${officeCode[0]}XX-XXX${stationCode.slice(-1)}`;
}

export function formatCurrencyFloor(value, isCents = true, keepCents = true) {
    return formatCurrency(value, isCents, keepCents, Math.floor);
}

export function formatCurrencyCeil(
    value,
    isCents = true,
    keepCents = true,
    formatOptions = {},
) {
    return formatCurrency(value, isCents, keepCents, Math.ceil, formatOptions);
}

export function formatCurrencyRound(
    value,
    isCents = true,
    keepCents = true,
    formatOptions = {},
) {
    return formatCurrency(value, isCents, keepCents, Math.round, formatOptions);
}

// Disaply cents only if they're not 0: $10.00 -> $10
export function formatCurrencyConditionalCents(
    value,
    isCents = true,
    formatOptions = {},
) {
    const keepCents = value % 100 !== 0;
    return formatCurrency(value, isCents, keepCents, undefined, formatOptions);
}

// Format a +12223334444 phone number into (222) 333-4444
export function formatPhone(phoneNumber) {
    const areaCode = phoneNumber.slice(2, 5);
    const firstThree = phoneNumber.slice(5, 8);
    const lastFour = phoneNumber.slice(8);
    return `(${areaCode}) ${firstThree}-${lastFour}`;
}

export function cleanMoneyInput(value, decimalPlaces = 2) {
    const decimalsAndDigits = value.replace(/[^0-9.]/g, '');
    const digitsAroundFirstDecimal = /(\d*\.?\d*)/g.exec(decimalsAndDigits)[1];
    const decimalRegex = new RegExp(`([0-9]*)\\.([0-9]{${decimalPlaces}}).*`);

    const limitedDecimalPlaces = digitsAroundFirstDecimal.replace(
        decimalRegex,
        `$1.$2`,
    );

    return limitedDecimalPlaces;
}

export function cleanDigitsInput(value) {
    return value.replace(/[^0-9]/g, '');
}

export function cleanWholeDollarMoneyInput(value) {
    const digits = value.replace(/[^0-9]/g, '');
    return digits;
}

export const formatCapitalizeString = (word) => {
    if (!word) {
        return word;
    }

    return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`;
};

/**
 * Converts string to slugified string.  i.e. 'TVs & Entertainment' -> 'tvs-entertainment'
 * Mirrors Django's slugify funciton https://github.com/django/django/blob/main/django/utils/text.py
 */
export const slugify = (string) => {
    let slugifiedString = String(string);
    slugifiedString = slugifiedString.toLowerCase();
    slugifiedString = slugifiedString.replace(/[^\w\s-]/g, ''); // Remove characters that aren't alphanumerics, underscores, or hyphens
    slugifiedString = slugifiedString.replace(/[\s-]+/g, '-'); // Convert spaces or repeated dashes to single dashes
    return slugifiedString.replace(/^[-_]+|[-_]+$/g, ''); // Strip leading and trailing whitespace, dashes, and underscores
};

export const deslugify = (slug) => {
    if (!slug) {
        return slug;
    }
    const deslugifiedString = slug.replace(/[-_]/g, ' ');

    return deslugifiedString
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const normalizePhoneNumber = (phone) =>
    phone.length === 10 ? `1${phone}` : phone;

export const getPricePerPay = (value, numberOfPayments) =>
    `${formatCurrencyRound(value / numberOfPayments, false, false)}/pay`;

export const getPriceRangePerPay = (values, numberOfPayments) =>
    `${getPricePerPay(
        values[0],
        numberOfPayments,
    )} - ${getPricePerPay(values[1], numberOfPayments)}`;

export const extractTimeFromString = (input) => {
    // Example input: Please try again 6 minutes.
    // Use regex to grab time duration.
    const time = input.match(/\d+ (minute|hour|second)[s]?/g);

    if (time) {
        return time[0];
    }

    return null;
};
