import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importOrders = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "OrdersContainer" */
                '@perpay-web/fintech/components/containers/OrdersContainer'
            ),
    );
