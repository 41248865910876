export const BANKS = 'banks';
export const EXTERNAL_CARDS = 'externalCards';
export const USERINFO = 'userInfo';
export const USERSTATUS = 'userStatus';
export const ORDERS = 'orders';
export const JOBS = 'jobs';
export const PAYSTUBS = 'paystubs';
export const INSTRUCTIONS = 'instructions';
export const ADS = 'ads';
export const ORDER_CANCELLATION_REASONS = 'orderCancellationReasons';
export const ACCOUNT_DEACTIVATION_REASONS = 'accountDeactivationReasons';
export const ACCOUNT_DEACTIVATION_ELIGIBILITY =
    'accountDeactivationEligibility';
export const SURVEYS = 'surveys';
export const SURVEY_QUESTIONS = 'surveyQuestions';
export const SURVEY_OPTIONS = 'surveyOptions';
export const DEDUCTIONS = 'deductions';
export const FEATURES = 'features';
export const FEATURE_ENROLLMENTS = 'featureEnrollments';
export const CREDIT_SCORES_SUMMARY = 'creditScoresSummary';
export const NOTIFICATIONS = 'notifications';
