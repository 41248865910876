import {
    STORE_OPEN_CANCEL_ORDER_MODAL,
    STORE_CLOSE_CANCEL_ORDER_MODAL,
    BACKEND_FETCH_ORDER_CANCELLATION_REASONS,
    STORE_REPLACE_ORDER_CANCELLATION_REASONS,
    STORE_ORDER_CANCELLATION_REASONS_ERROR,
    BACKEND_CANCEL_ORDER,
    STORE_REPLACE_ORDER,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    open: false,
    loading: true,
    orderUuid: null,
    submitting: false,
    errors: {},
};

const cancelOrderModalReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_OPEN_CANCEL_ORDER_MODAL:
            return { ...state, open: true, orderUuid: payload };
        case STORE_CLOSE_CANCEL_ORDER_MODAL:
            return initialState;
        case BACKEND_FETCH_ORDER_CANCELLATION_REASONS:
            return { ...state, loading: true };
        case STORE_REPLACE_ORDER_CANCELLATION_REASONS:
            return { ...state, loading: false };
        case STORE_ORDER_CANCELLATION_REASONS_ERROR:
            return {
                ...state,
                loading: false,
                errors: payload,
                submitting: false,
            };
        case BACKEND_CANCEL_ORDER:
            return { ...state, errors: {}, submitting: true };
        case STORE_REPLACE_ORDER:
            return { ...state, errors: {}, submitting: false };
        default:
            return state;
    }
};

export default cancelOrderModalReducer;
