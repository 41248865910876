import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importRecurringPaymentPortalContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "RecurringPaymentPortalContainer" */
                '@perpay-web/fintech/components/containers/RecurringPaymentPortalContainer'
            ),
    );
