import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    resendMFA,
    verifyMFA,
} from '@perpay-web/fintech/actions/authentication';
import { getMFA } from '@perpay-web/fintech/selectors/authentication';
import MFAForm from '@perpay-web/fintech/components/composite/MFAForm/MFAForm';

const MFAContainer = () => {
    const dispatch = useDispatch();
    const mfa = useSelector(getMFA);

    const onMFASubmit = useCallback(
        (values) => dispatch(verifyMFA(mfa.data.otpUuid, values)),
        [dispatch, mfa],
    );

    const onMFASubmitResend = useCallback(
        () => dispatch(resendMFA(mfa.data.otpUuid)),
        [dispatch, mfa],
    );

    return (
        <MFAForm
            data={mfa.data}
            error={mfa.error}
            isLoading={mfa.isLoading}
            onSubmit={onMFASubmit}
            onSubmitResend={onMFASubmitResend}
        />
    );
};

export default MFAContainer;
