export const PINWHEEL_SPLIT_CONFIRM_EMPLOYER_STEP =
    'pinwheelSplitConfirmEmployer';
export const PINWHEEL_SPLIT_INSTRUCTIONS_COMPLETE_STEP =
    'pinwheelSplitPinwheelInstructionsComplete';
export const PINWHEEL_SPLIT_INTRO_STEP = 'pinwheelSplitPinwheelIntro';
export const PINWHEEL_SPLIT_MODAL_STEP = 'pinwheelSplitPinwheelModal';
export const PINWHEEL_SPLIT_SUCCESS_STEP = 'pinwheelSplitPinwheelSuccess';
export const PINWHEEL_SPLIT_FAILURE_STEP = 'pinwheelSplitPinwheelFailure';
export const PINWHEEL_SPLIT_EXIT_SURVEY_STEP = 'pinwheelSplitExitSurvey';
export const PINWHEEL_SPLIT_ABORT_PINWHEEL_STEP = 'pinwheelSplitAbortPinwheel';
export const PINWHEEL_SPLIT_UNAVAILABLE_STEP =
    'pinwheelSplitPinwheelUnavailable';
export const PINWHEEL_SPLIT_ADD_BANK_STEP = 'pinwheelSplitAddBank';
export const PINWHEEL_SPLIT_ADD_BANK_STEP_PINWHEEL_FAILURE =
    'pinwheelSplitAddBankPinwheelFailure';
export const PINWHEEL_SPLIT_PAYMENT_SETUP_DETAILS_STEP =
    'pinwheelSplitPaymentSetupDetailsStep';

export const PINWHEEL_STATUS_SUCCESS = 'success';
export const PINWHEEL_STATUS_FAILURE = 'failure';

export const PINWHEEL_SPLIT_STEPS = [
    PINWHEEL_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
    PINWHEEL_SPLIT_CONFIRM_EMPLOYER_STEP,
    PINWHEEL_SPLIT_INSTRUCTIONS_COMPLETE_STEP,
    PINWHEEL_SPLIT_INTRO_STEP,
    PINWHEEL_SPLIT_MODAL_STEP,
    PINWHEEL_SPLIT_SUCCESS_STEP,
    PINWHEEL_SPLIT_FAILURE_STEP,
    PINWHEEL_SPLIT_EXIT_SURVEY_STEP,
    PINWHEEL_SPLIT_ABORT_PINWHEEL_STEP,
    PINWHEEL_SPLIT_UNAVAILABLE_STEP,
    PINWHEEL_SPLIT_ADD_BANK_STEP,
    PINWHEEL_SPLIT_ADD_BANK_STEP_PINWHEEL_FAILURE,
];
