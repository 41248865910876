import { createDataModule } from '@perpay-web/data-module/createDataModule';
import * as actions from '@perpay-web/fintech/actions/entities/withdrawalBalance';

const FetchWithdrawalBalanceDataModule = createDataModule({
    dataRequest: actions.fetchWithdrawalBalanceV2,
    dataSuccess: actions.fetchWithdrawalBalanceV2Success,
    dataError: actions.fetchWithdrawalBalanceV2Error,
    dataReset: actions.fetchWithdrawalBalanceV2Reset,
});

const getRoot = (state) => state.dataModules.fetchWithdrawalBalanceV2;
const initialValue = {
    availableCredit: null,
};

export const fetchWithdrawalBalanceV2DataModule =
    FetchWithdrawalBalanceDataModule({
        getRoot,
        initialValue,
    });

export const getWithdrawalBalance = (state) =>
    fetchWithdrawalBalanceV2DataModule.getData(state).availableCredit;
