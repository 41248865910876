import { Subject } from 'rxjs';

const actionFromNative$ = new Subject();
window.perpayActionFromNative$ = actionFromNative$;

export function getActionFromNative() {
    return window.perpayActionFromNative$.asObservable();
}

// Generate a string to pass to injectJavaScript that will pass an action to the
// perpayActionFromNative$ Observable to transmit actions from native to fintech.
export function getActionInjectionString(action) {
    return `window.perpayActionFromNative$.next(${JSON.stringify(action)});`;
}
