import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchPerpayPlusDashboardSummaryActions = createDataModuleActions(
    'FETCH_PERPAY_PLUS_DASHBOARD_SUMMARY',
);
export const fetchPerpayPlusDashboardSummary =
    fetchPerpayPlusDashboardSummaryActions.dataRequest;
export const perpayPlusDashboardSummarySuccess =
    fetchPerpayPlusDashboardSummaryActions.dataSuccess;
export const perpayPlusDashboardSummaryError =
    fetchPerpayPlusDashboardSummaryActions.dataError;
export const perpayPlusDashboardSummaryReset =
    fetchPerpayPlusDashboardSummaryActions.dataReset;
