import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';
import { normalize } from 'normalizr';

import { handleError } from '@perpay-web/observable/operators/handleError';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import {
    BACKEND_CREATE_EXTERNAL_CARD,
    BACKEND_FETCH_EXTERNAL_CARDS,
} from '@perpay-web/fintech/constants/actionTypes';
import { externalCard } from '@perpay-web/fintech/normalizers/schemas';
import { EXTERNAL_CARDS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    replaceExternalCards,
    createExternalCardSuccess,
    createExternalCardErrors,
} from '@perpay-web/fintech/actions/entities/externalCards';
import { fetchStripeToken } from '@perpay-web/fintech/utils/stripeUtils';

export function fetchExternalCards(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_EXTERNAL_CARDS),
        exhaustMap(() => get(`${EXTERNAL_CARDS_ENDPOINT}`)),
        mergeMap((results) => {
            const normalized = normalize(results.response, [externalCard]);
            return [replaceExternalCards(normalized.entities.externalCards)];
        }),
        handleError(() => [replaceExternalCards({})]),
    );
}

export function createExternalCard(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_EXTERNAL_CARD),
        exhaustMap((action) => {
            const { cardNumber, expiryDate, cvc } = action.payload;
            return fetchStripeToken(cardNumber, expiryDate, cvc);
        }),
        exhaustMap((stripeResponse) => {
            if (stripeResponse.error) {
                throw new Error(stripeResponse.error.code);
            }
            const token = stripeResponse.id;
            return emitOrTimeout(post(EXTERNAL_CARDS_ENDPOINT, { token }));
        }),
        mergeMap(() => [createExternalCardSuccess()]),
        handleErrorMessageWithFallback((error) => [
            createExternalCardErrors(error),
        ]),
    );
}
