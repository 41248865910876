import * as Sentry from '@sentry/react';

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    attachReduxState: true,
    stateTransformer: (state) => ({
        ...state,
        // clear authentication state
        authentication: null,
        entities: { ...state.entities, userInfo: null },
        dataModules: { ...state.dataModules, fetchUserInfo: null },
        storefront: state.storefront
            ? {
                  ...state.storefront,
                  dataModules: state.storefront.dataModules
                      ? {
                            ...state.storefront.dataModules,
                            // clear storefront tokens
                            fetchAlgoliaToken: {},
                            fetchMagentoToken: {},
                        }
                      : {},
              }
            : null,
    }),
});
