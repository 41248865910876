import { useTitle } from '@perpay-web/hooks/useTitleContext';

export function withPerpayTitle(title) {
    return (Component) => {
        const TitleWrapper = (props) => {
            useTitle(`Perpay | ${title}`);

            return <Component {...props} />;
        };

        TitleWrapper.displayName = 'TitleWrapper';

        return TitleWrapper;
    };
}
