import {
    ANALYTICS_CREATE_UPDATED_PII,
    ANALYTICS_IDENTIFY_USER,
} from '@perpay-web/fintech/constants/actionTypes';

// Send updated user info to Facebook
export function analyticsUpdatedPII(payload) {
    return { type: ANALYTICS_CREATE_UPDATED_PII, payload };
}

export function analyticsIdentifyUser(payload) {
    return {
        type: ANALYTICS_IDENTIFY_USER,
        payload,
    };
}
