import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPaymentSetupSummary,
    fetchPaymentSetupSummarySuccess,
    fetchPaymentSetupSummaryError,
    fetchPaymentSetupSummaryReset,
} from '@perpay-web/fintech/actions/shared/paymentSetupSummary';

const FetchPaymentSetupSummaryDataModule = createDataModule({
    dataRequest: fetchPaymentSetupSummary,
    dataSuccess: fetchPaymentSetupSummarySuccess,
    dataError: fetchPaymentSetupSummaryError,
    dataReset: fetchPaymentSetupSummaryReset,
});

const getRoot = (state) => state.dataModules.fetchPaymentSetupSummary;
const initialValue = {};

export const fetchPaymentSetupSummaryDataModule =
    FetchPaymentSetupSummaryDataModule({
        getRoot,
        initialValue,
    });

export const getIsInstantShipEligible = (state) =>
    fetchPaymentSetupSummaryDataModule.getData(state).isInstantShipEligible ||
    false;

export const getAccountNumber = (state) =>
    fetchPaymentSetupSummaryDataModule.getData(state).accountNumber;

export const getRoutingNumber = (state) =>
    fetchPaymentSetupSummaryDataModule.getData(state).routingNumber;
