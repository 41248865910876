import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs';

import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';

import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';

import { replaceCardOnboardingMaterial } from '@perpay-web/fintech/actions/ui/cardOnboardingMaterial';

export const cardOnboardingMetalReplaceMaterial = (action$, state$) =>
    action$.pipe(
        ofType(replaceCardOnboardingMaterial().type),
        switchMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    reduxStepsSetStep(
                        Object.values(metalSteps),
                        metalSteps.REVIEW_AND_SUBMIT_TERMS_AND_CONDITIONS_STEP,
                    ),
                ],
            }),
        ),
    );
