import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchTransactionDetails,
    replaceTransactionDetails,
    resetTransactionDetails,
    setTransactionDetailsError,
} from '@perpay-web/fintech/actions/entities/transactionDetails';

const FetchTransactionDetailsDataModule = createDataModule({
    dataRequest: fetchTransactionDetails,
    dataSuccess: replaceTransactionDetails,
    dataError: setTransactionDetailsError,
    dataReset: resetTransactionDetails,
});

const getRoot = (state) => state.dataModules.fetchTransactionDetails;

const initialValue = {
    currentPageNumber: 1,
    pageCount: 0,
    results: [],
};

export const fetchTransactionDetailsDataModule =
    FetchTransactionDetailsDataModule({
        getRoot,
        initialValue,
    });

export const getCurrentPageNumber = (state) =>
    fetchTransactionDetailsDataModule.getData(state).currentPageNumber;

export const getPageCount = (state) =>
    fetchTransactionDetailsDataModule.getData(state).pageCount;

export const getResults = (state) =>
    fetchTransactionDetailsDataModule.getData(state).results;
