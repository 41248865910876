import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importEditEmployment = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "EditEmploymentContainer" */
                '@perpay-web/fintech/components/containers/EditEmploymentContainer'
            ),
    );
