import {
    ANALYTICS_CREATE_APPLICATION_SUBMITTED,
    ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO,
} from '@perpay-web/fintech/constants/actionTypes';

export function analyticsApplicationSubmitted(payload) {
    return {
        type: ANALYTICS_CREATE_APPLICATION_SUBMITTED,
        payload,
    };
}

export function analyticsPurchaseSubmittedFromMagento(payload) {
    return {
        type: ANALYTICS_CREATE_PURCHASE_SUBMITTED_FROM_MAGENTO,
        payload,
    };
}
