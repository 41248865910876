import { getClassName } from '@perpay-web/utils/domUtils';
import { noop } from '@perpay-web/utils/noop';

import styles from './Chip.scss';

const Chip = ({
    title,
    className = '',
    onClick = noop,
    onMouseDown = noop,
    isActive = false,
}) => (
    <button
        type='button'
        className={getClassName(
            styles.chip,
            isActive && styles['chip--active'],
            className,
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
    >
        <div
            className={getClassName(
                styles.chip__title,
                isActive && styles['chip__title--active'],
            )}
        >
            {title}
        </div>
    </button>
);

export default Chip;
