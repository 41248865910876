import { getOrderEntity } from '@perpay-web/fintech/selectors/entities/orders';

export const getCancelOrderModalOpen = (state) =>
    state.ui.cancelOrderModal.open;

export const getCancelOrderModalOrderUuid = (state) =>
    state.ui.cancelOrderModal.orderUuid || null;

export const getOrderCancellationReasonsLoading = (state) =>
    state.ui.cancelOrderModal.loading;

export const getOrderCancellationErrors = (state) =>
    state.ui.cancelOrderModal.errors;

export const getOrderCancellationModalSubmitting = (state) =>
    state.ui.cancelOrderModal.submitting;

export const getCancelOrderModalOrderStatus = (state) => {
    const orderUuid = getCancelOrderModalOrderUuid(state);
    if (orderUuid === null) {
        return null;
    }
    const order = getOrderEntity(state, orderUuid);
    return order.status;
};
