import { exhaustMap, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import {
    fetchFeatureEnrollmentsForPerpayPlus as fetchFeatureEnrollmentsForPerpayPlusAction,
    fetchFeatureEnrollmentsForPerpayPlusSuccess,
    fetchFeatureEnrollmentsForPerpayPlusError,
    createFeatureEnrollmentForPerpayPlus as createFeatureEnrollmentForPerpayPlusAction,
    createFeatureEnrollmentForPerpayPlusSuccess,
    createFeatureEnrollmentForPerpayPlusError,
} from '@perpay-web/fintech/actions/ui/featureEnrollments';
import {
    fetchFeatureEnrollments,
    fetchFeatureEnrollmentsSuccess,
    fetchFeatureEnrollmentsError,
    createFeatureEnrollment,
    createFeatureEnrollmentSuccess,
    createFeatureEnrollmentError,
} from '@perpay-web/fintech/actions/entities/featureEnrollments';
import { refreshOrder } from '@perpay-web/fintech/actions/entities/orders';
import {
    PERPAY_PLUS_STEPS,
    SUCCESS_STEP,
} from '@perpay-web/fintech/constants/steps/perpayPlusSteps';
import { PERPAY_PLUS } from '@perpay-web/fintech/constants/featureTypes';
import { getHasEverOptedInToPerpayPlus } from '@perpay-web/fintech/dataModules/fetchFeatureEnrollments';
import { getPathEnd } from '@perpay-web/fintech/utils/routeUtils';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';

export const fetchFeatureEnrollmentsForPerpayPlus = (action$, state$) =>
    action$.pipe(
        ofType(fetchFeatureEnrollmentsForPerpayPlusAction().type),
        withLatestFrom(state$),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchFeatureEnrollments()],
                waitFor: [fetchFeatureEnrollmentsSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const hasEverOptedInToPerpayPlus =
                        getHasEverOptedInToPerpayPlus(state);
                    // only redirect if user is at the enrollment path
                    const shouldRedirect =
                        window.location.pathname === paths.perpayPlus.path;

                    const returnActions = [
                        fetchFeatureEnrollmentsForPerpayPlusSuccess(payload),
                    ];

                    if (hasEverOptedInToPerpayPlus && shouldRedirect) {
                        returnActions.push(
                            routeToLocation({
                                path: paths.dashboard.path,
                                replace: true,
                            }),
                        );
                    }

                    return returnActions;
                },
                errors: [fetchFeatureEnrollmentsError().type],
                errorDispatch: (errorAction) => [
                    fetchFeatureEnrollmentsForPerpayPlusError(
                        errorAction.payload,
                    ),
                ],
            }),
        ),
    );

export const createFeatureEnrollmentForPerpayPlus = (action$, state$) =>
    action$.pipe(
        ofType(createFeatureEnrollmentForPerpayPlusAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createFeatureEnrollment({ feature: PERPAY_PLUS }),
                ],
                waitFor: [createFeatureEnrollmentSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const checkoutUuid = getPathEnd(window.location.pathname);
                    const successActions = [
                        createFeatureEnrollmentForPerpayPlusSuccess(payload),
                        reduxStepsSetStep(PERPAY_PLUS_STEPS, SUCCESS_STEP),
                    ];

                    // if the enrollment is created within the checkout flow
                    // we want to refresh the order so that the PP+ repayment
                    // options display
                    if (checkoutUuid) {
                        successActions.push(refreshOrder({ checkoutUuid }));
                    }

                    return successActions;
                },
                errors: [createFeatureEnrollmentError().type],
                errorDispatch: (errorAction) => [
                    createFeatureEnrollmentForPerpayPlusError(
                        errorAction.payload,
                    ),
                ],
            }),
        ),
    );
