import {
    REPLACE_USER_INFO,
    UPDATE_USER_INFO,
    UPDATE_USER_INFO_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const userInfoReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_USER_INFO:
            return { ...state, errors: {} };
        case REPLACE_USER_INFO:
            return { ...state, ...payload, errors: {} };
        case UPDATE_USER_INFO_ERROR:
            return { ...state, errors: payload };
        default:
            return state;
    }
};

export default userInfoReducer;
