import {
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA,
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_SUCCESS,
    FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_ERROR,
    PERPAY_SPLIT_UPDATE_SALARY_INFO,
    PERPAY_SPLIT_CREATE_PRIMARY_JOB,
    PERPAY_SPLIT_CREATE_BANK,
} from '@perpay-web/fintech/constants/actionTypes';

export const fetchPerpaySplitSetUpPaymentsData = () => ({
    type: FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA,
});

export const fetchPerpaySplitSetUpPaymentsDataSuccess = () => ({
    type: FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_SUCCESS,
});

export const fetchPerpaySplitSetUpPaymentsDataError = (error) => ({
    type: FETCH_PERPAY_SPLIT_SET_UP_PAYMENTS_DATA_ERROR,
    payload: { error },
});

export const perpaySplitUpdateSalaryInfo = (payload) => ({
    type: PERPAY_SPLIT_UPDATE_SALARY_INFO,
    payload,
});

export const perpaySplitCreatePrimaryJob = (payload) => ({
    type: PERPAY_SPLIT_CREATE_PRIMARY_JOB,
    payload,
});

export const perpaySplitCreateBank = (payload) => ({
    type: PERPAY_SPLIT_CREATE_BANK,
    payload,
});
