import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { BACKEND_CREATE_EVENT } from '@perpay-web/fintech/constants/actionTypes';
import { EVENTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export function createEvent(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_EVENT),
        switchMap((action) => post(`${EVENTS_ENDPOINT}`, action.payload)),
        mergeMap(() => EMPTY),
    );
}
