import { loadThirdPartyScript } from '@perpay-web/utils/scriptUtils';
import { isAppBrowserAgent } from '@perpay-web/utils/userAgentUtils';
import { getIsReactNative } from '@perpay-web/native-client/getIsReactNative';
import {
    CORDOVA_INIT_LIB,
    CORDOVA_LIB,
} from '@perpay-web/fintech/constants/urls';
import { noop } from '@perpay-web/utils/noop';

const installCordovaBase = () => loadThirdPartyScript(CORDOVA_LIB);

const installCordovaInit = () => loadThirdPartyScript(CORDOVA_INIT_LIB);

const noopPromise = new Promise(noop);

let cachedAppPromise = null;

export const getCordovaApp = () => {
    if (getIsReactNative()) {
        return noopPromise;
    }

    if (!isAppBrowserAgent()) {
        // This promise will never resolve
        return noopPromise;
    }

    // Return the cached app reference instead of reinitializing
    if (cachedAppPromise) {
        return cachedAppPromise;
    }

    cachedAppPromise = installCordovaBase()
        .then(() => installCordovaInit())
        .then(() => window.CordovaApp());
    return cachedAppPromise;
};
