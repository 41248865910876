import { useState, useCallback } from 'react';

const getInitialStack = (steps, initialStep) => {
    const initialStepIndex = steps.indexOf(initialStep);

    if (initialStepIndex === -1) {
        throw new Error(`Step ${initialStep} not found in ${steps}`);
    }

    return steps.slice(0, initialStepIndex + 1).reverse(); // include the initial step in the slice
};

const CURRENT_STEP_INDEX = 0;
const PREVIOUS_STEP_INDEX = 1;

/**
 * This hook encapsulates the logic needed for step-based React screens with a stack-based history.
 *
 * Sample usage:
 * ```
 * const stepToDisplayText = {
 *   [STEP_FOO]: 'Foo',
 *   [STEP_BAR]: 'Bar',
 * };
 *
 * const MyComponent = ({ initialStep }) => {
 *   const { step, setStep, goBack } = useSteps([STEP_FOO, STEP_BAR], initialStep);
 *   return (
 *     <div>
 *       <p>You are on step {stepToDisplayText[step]}</p>
 *       <Step current={step} name={STEP_FOO} render={() => (
 *          <button onClick={() => setStep(STEP_BAR)}>Next</button>
 *       )}/>
 *       <Step current={step} name={STEP_BAR} render={() => <span>bar</span>}/>
 *       <button onClick={goBack}>back</button>
 *     </div>
 *   );
 * ```
 *
 * Future improvement ideas: Add a transition map as a parameter to allow this to be used as a
 * state machine and allow listeners to be set for certain transitions.
 */
export const useSteps = (steps, firstStep = steps[0]) => {
    /**
     * Stack represents the history state. The current step is the top item on the stack at index 0.
     */
    const [stack, setStack] = useState(getInitialStack(steps, firstStep));

    /**
     * setStep jumps the current step to the step given as a parameter.
     * When setStep is called the previous step is pushed onto the stack to be used
     * for the goBack functionality.
     */
    const setStep = useCallback(
        (value) => {
            if (!steps.includes(value)) {
                throw new Error(`Step ${value} not found in ${steps}`);
            }

            setStack([value, ...stack]);
        },
        [steps, stack, setStack],
    );

    /**
     * Go back uses the stack state to navigate to the previously current step.
     */
    const goBack = useCallback(() => {
        const prevStep = stack[PREVIOUS_STEP_INDEX];
        if (typeof prevStep === 'undefined') {
            return;
        }

        setStack(stack.slice(1));
    }, [stack, setStack]);

    return {
        goBack,
        setStep,
        step: stack[CURRENT_STEP_INDEX],
    };
};
