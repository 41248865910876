import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchBankAccounts,
    fetchBankAccountsSuccess,
    fetchBankAccountsError,
    fetchBankAccountsReset,
} from '@perpay-web/fintech/actions/entities/banks';

const FetchBankAccountsDataModule = createDataModule({
    dataRequest: fetchBankAccounts,
    dataSuccess: fetchBankAccountsSuccess,
    dataError: fetchBankAccountsError,
    dataReset: fetchBankAccountsReset,
});

const getRoot = (state) => state.dataModules.fetchBankAccounts;
const initialValue = {
    all: [],
};

export const fetchBankAccountsDataModule = FetchBankAccountsDataModule({
    getRoot,
    initialValue,
});

export const getBankAccounts = (state) =>
    fetchBankAccountsDataModule.getData(state).all;
