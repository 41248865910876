import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { BACKEND_FETCH_CREDIT_SCORES_SUMMARY } from '@perpay-web/fintech/constants/actionTypes';
import { CREDIT_SCORES_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    replaceCreditScoresSummary,
    fetchCreditScoresSummaryError,
} from '@perpay-web/fintech/actions/entities/creditScoresSummary';

export function fetchCreditScoresSummary(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_CREDIT_SCORES_SUMMARY),
        exhaustMap(() => get(CREDIT_SCORES_SUMMARY_ENDPOINT)),
        mergeMap((results) => [replaceCreditScoresSummary(results.response)]),
        handleErrorMessageWithFallback((error) => [
            fetchCreditScoresSummaryError(error),
        ]),
    );
}
