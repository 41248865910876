export const DENY = 'deny';
export const REDUCE_AMOUNT = 'reduce_amount';
export const PAY_DOWN_BALANCE = 'pay_down_balance';
export const APPROVE = 'approve';
export const CREDIT_LINE_APPROVE = 'credit_line_approve';
export const MANUAL = 'manual';
export const ERROR = 'error';
export const VERIFICATION = 'verification';
export const REQUIRES_PAYSTUB = 'requires_paystub';
export const UNSET = 'unset';
