export const resetState = (state, initialState) => ({
    ...state,
    ...initialState,
});

/**
 * @deprecated if your reducer was created using `@reduxjs/toolkit/createReducer` - you can use `reducer.getInitialState()` instead
 */
export const getInitialState = (reducer) => {
    if (reducer.getInitialState) return reducer.getInitialState();
    return reducer(undefined, {});
};
