import {
    STORE_ACCOUNT_DEACTIVATION_REASONS_ERROR,
    STORE_RESET_DEACTIVATE_ACCOUNT_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function resetAccountDeactivationErrors() {
    return {
        type: STORE_RESET_DEACTIVATE_ACCOUNT_ERROR,
    };
}

export function accountDeactivationReasonsError(error) {
    return {
        type: STORE_ACCOUNT_DEACTIVATION_REASONS_ERROR,
        payload: error,
    };
}
