import {
    ANALYTICS_MARK_AB_TEST_VARIANT_UNAUTHENTICATED,
    ANALYTICS_MARK_AB_TEST_VARIANT_AUTHENTICATED,
} from '@perpay-web/fintech/constants/actionTypes';

export function analyticsMarkAuthenticatedAbTestVariant(experiment, variant) {
    return {
        type: ANALYTICS_MARK_AB_TEST_VARIANT_AUTHENTICATED,
        payload: {
            experiment,
            variant,
        },
    };
}

export function analyticsMarkUnauthenticatedAbTestVariant(experiment, variant) {
    return {
        type: ANALYTICS_MARK_AB_TEST_VARIANT_UNAUTHENTICATED,
        payload: {
            experiment,
            variant,
        },
    };
}
