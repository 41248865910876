import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardActivation,
    fetchCardActivationSuccess,
    fetchCardActivationError,
    fetchCardActivationReset,
} from '@perpay-web/fintech/actions/ui/cardActivation';

const FetchCardActivationDataModule = createDataModule({
    dataRequest: fetchCardActivation,
    dataSuccess: fetchCardActivationSuccess,
    dataError: fetchCardActivationError,
    dataReset: fetchCardActivationReset,
});

const getRoot = (state) => state.dataModules.fetchCardActivation;
const initialValue = {};

export const fetchCardActivationDataModule = FetchCardActivationDataModule({
    getRoot,
    initialValue,
});
