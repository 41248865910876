import { exhaustMap, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { concat } from 'rxjs';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { normalize } from 'normalizr';

import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { verifyABTest } from '@perpay-web/fintech/actions/shared/abTests';
import {
    FETCH_PAYMENT_SETUP_DATA,
    PAYMENT_SETUP_CREATE_PRIMARY_JOB,
    STORE_AB_TEST_ERROR,
    STORE_AB_TEST_REPLACE,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_USER_STATUS_ERROR,
    STORE_REPLACE_JOBS_ERROR,
    STORE_REPLACE_DEDUCTION_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    PERPAY_SPLIT_STEP,
    NON_PINWHEEL_STEP,
    PINWHEEL_STEP,
    PINWHEEL_SPLIT_STEP,
} from '@perpay-web/fintech/components/composite/PaymentSetup/PaymentSetupSteps';

import {
    PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS,
    PAYMENT_SETUP_DETAILS_STEP as PERPAY_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
} from '@perpay-web/fintech/components/composite/PerpaySplitSetUpPayments/PerpaySplitSetUpPaymentsSteps';
import {
    PINWHEEL_SPLIT_STEPS,
    PINWHEEL_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
} from '@perpay-web/fintech/constants/pinwheelSplitSetUpPaymentsConstants';
import {
    PINWHEEL_STEPS,
    PAYMENT_SETUP_DETAILS_STEP,
    PAYMENT_SETUP_DETAILS_RETURNING_BORROWER_STEP,
    PINWHEEL_UNAVAILABLE_STEP,
} from '@perpay-web/fintech/constants/pinwheelConstants';

import { fetchPinwheelEligibility } from '@perpay-web/fintech/actions/shared/pinwheelEligibility';
import { fetchAccountSummary } from '@perpay-web/fintech/actions/shared/accountSummary';
import {
    createPrimaryJob,
    createPrimaryJobError,
    fetchJobs,
    replaceJobs,
} from '@perpay-web/fintech/actions/entities/jobs';
import { getIsPinwheelEnabled } from '@perpay-web/fintech/selectors/shared/pinwheelEligibility';
import { getShowCurrentDeductionScreens } from '@perpay-web/fintech/selectors/ui/pinwheel';
import {
    fetchPaymentSetupDataError,
    fetchPaymentSetupDataSuccess,
} from '@perpay-web/fintech/actions/ui/paymentSetup';
import { getIsPerpaySplitEligible } from '@perpay-web/fintech/selectors/entities/jobs';
import { fetchUserStatus } from '@perpay-web/fintech/actions/entities/userStatus';
import { resetDeductions } from '@perpay-web/fintech/actions/entities/deductions';
import {
    fetchOrdersForDataModule,
    fetchOrdersForDataModuleSuccess,
    fetchOrdersForDataModuleError,
} from '@perpay-web/fintech/actions/entities/orders';
import { createPrimaryJobDataModule } from '@perpay-web/fintech/dataModules/createPrimaryJob';
import { getHasApprovedOrders } from '@perpay-web/fintech/dataModules/fetchOrders';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { OPEN } from '@perpay-web/fintech/constants/orderFilters';
import { job as jobSchema } from '@perpay-web/fintech/normalizers/schemas';
import { paymentSetupStepsSetStep } from '@perpay-web/fintech/components/composite/PaymentSetup/usePaymentSetupStepsContext';

export const waitForAbTestAndSetPaymentSetupStep =
    (originFlow) => (action$, state$) =>
        dispatchObservable({
            action$,
            state$,
            waitFor: STORE_AB_TEST_REPLACE,
            waitForDispatch: (state) => {
                const isPinwheelEligible = getIsPinwheelEnabled(state);
                const isPerpaySplitEligible = getIsPerpaySplitEligible(state);
                const isPinwheelSplitEligible =
                    isPinwheelEligible && isPerpaySplitEligible;

                const returnActions = [];

                if (isPinwheelSplitEligible) {
                    returnActions.push(
                        reduxStepsSetStep(
                            PINWHEEL_SPLIT_STEPS,
                            PINWHEEL_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
                        ),
                    );
                    if (originFlow !== PINWHEEL_SPLIT_STEP) {
                        returnActions.push(
                            paymentSetupStepsSetStep(PINWHEEL_SPLIT_STEP),
                        );
                        routeToLocation({
                            path: window.location.pathname,
                            replace: true,
                        });
                    }
                } else if (isPerpaySplitEligible) {
                    returnActions.push(
                        reduxStepsSetStep(
                            PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS,
                            PERPAY_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
                        ),
                    );
                    if (originFlow !== PERPAY_SPLIT_STEP) {
                        returnActions.push(
                            paymentSetupStepsSetStep(PERPAY_SPLIT_STEP),
                        );
                        routeToLocation({
                            path: window.location.pathname,
                            replace: true,
                        });
                    }
                } else if (isPinwheelEligible) {
                    returnActions.push(
                        reduxStepsSetStep(
                            PINWHEEL_STEPS,
                            PAYMENT_SETUP_DETAILS_STEP,
                        ),
                    );
                    if (originFlow !== PINWHEEL_STEP) {
                        returnActions.push(
                            paymentSetupStepsSetStep(PINWHEEL_STEP),
                        );
                        routeToLocation({
                            path: window.location.pathname,
                            replace: true,
                        });
                    }
                } else {
                    returnActions.push(resetDeductions());
                    if (originFlow === PERPAY_SPLIT_STEP) {
                        returnActions.push(
                            routeToLocation({
                                path: paths.paymentInstructions.path,
                                replace: true,
                            }),
                        );
                    } else {
                        returnActions.push(
                            reduxStepsSetStep(
                                PINWHEEL_STEPS,
                                PINWHEEL_UNAVAILABLE_STEP,
                            ),
                        );
                    }
                }

                return returnActions;
            },
        });

export const fetchPaymentSetupData = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_PAYMENT_SETUP_DATA),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: [
                    fetchUserStatus(),
                    fetchJobs(),
                    fetchOrdersForDataModule({ orderFilter: OPEN }),
                ],
                waitFor: [
                    STORE_REPLACE_USER_STATUS,
                    STORE_REPLACE_JOBS,
                    fetchOrdersForDataModuleSuccess().type,
                ],
                waitForDispatch: (outerState) => {
                    const hasApprovedOrders = getHasApprovedOrders(outerState);
                    if (!hasApprovedOrders) {
                        return [
                            routeToLocation({
                                path: paths.dashboard.path,
                                replace: true,
                            }),
                        ];
                    }
                    return dispatchObservable({
                        action$,
                        state$,
                        initialDispatch: [fetchPinwheelEligibility()],
                        waitFor: [
                            STORE_AB_TEST_REPLACE,
                            STORE_REPLACE_DEDUCTION,
                        ],
                        waitForDispatch: (state) => {
                            const isPinwheelEligible =
                                getIsPinwheelEnabled(state);
                            const isPerpaySplitEligible =
                                getIsPerpaySplitEligible(state);
                            const isPinwheelSplitEligible =
                                isPinwheelEligible && isPerpaySplitEligible;

                            if (isPinwheelSplitEligible) {
                                return [
                                    reduxStepsSetStep(
                                        PINWHEEL_SPLIT_STEPS,
                                        PINWHEEL_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
                                    ),
                                    paymentSetupStepsSetStep(
                                        PINWHEEL_SPLIT_STEP,
                                    ),
                                    fetchPaymentSetupDataSuccess(),
                                ];
                            }
                            if (isPerpaySplitEligible) {
                                return [
                                    reduxStepsSetStep(
                                        PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS,
                                        PERPAY_SPLIT_PAYMENT_SETUP_DETAILS_STEP,
                                    ),
                                    paymentSetupStepsSetStep(PERPAY_SPLIT_STEP),
                                    fetchPaymentSetupDataSuccess(),
                                ];
                            }
                            if (isPinwheelEligible) {
                                const showPinwheelCurrentDeductionScreens =
                                    getShowCurrentDeductionScreens(state);
                                const firstPinwheelStep =
                                    showPinwheelCurrentDeductionScreens
                                        ? PAYMENT_SETUP_DETAILS_RETURNING_BORROWER_STEP
                                        : PAYMENT_SETUP_DETAILS_STEP;

                                return [
                                    reduxStepsSetStep(
                                        PINWHEEL_STEPS,
                                        firstPinwheelStep,
                                    ),
                                    paymentSetupStepsSetStep(PINWHEEL_STEP),
                                    fetchPaymentSetupDataSuccess(),
                                ];
                            }

                            return [
                                paymentSetupStepsSetStep(NON_PINWHEEL_STEP),
                                fetchPaymentSetupDataSuccess(),
                            ];
                        },
                        errors: [
                            STORE_AB_TEST_ERROR,
                            STORE_REPLACE_DEDUCTION_ERROR,
                        ],
                        errorDispatch: (errorAction) => [
                            fetchPaymentSetupDataError(errorAction.payload),
                        ],
                        timeoutDispatch: () => [
                            fetchPaymentSetupDataError(new Error('timeout')),
                        ],
                    });
                },
                errors: [
                    STORE_REPLACE_JOBS_ERROR,
                    STORE_REPLACE_USER_STATUS_ERROR,
                    fetchOrdersForDataModuleError().type,
                ],
                errorDispatch: (errorAction) => [
                    fetchPaymentSetupDataError(errorAction.payload),
                ],
                timeoutDispatch: () => [
                    fetchPaymentSetupDataError(new Error('timeout')),
                ],
            }),
        ),
    );

export const paymentSetupCreatePrimaryJob = (action$, state$) =>
    action$.pipe(
        ofType(PAYMENT_SETUP_CREATE_PRIMARY_JOB),
        switchMap((action) => {
            const { employmentData, originFlow } = action.payload;
            return concat(
                dispatchObservable({
                    action$,
                    state$,
                    initialDispatch: [createPrimaryJob(employmentData)],
                    waitFor: [createPrimaryJobDataModule.dataSuccess().type],
                    waitForDispatch: (state, results) => {
                        const job = results[0].payload;
                        const normalized = normalize([job], [jobSchema]);

                        return [
                            replaceJobs(normalized.entities.jobs),
                            verifyABTest('pinwheel'),
                            fetchAccountSummary(),
                        ];
                    },
                    errors: [createPrimaryJobError().type],
                }),
                waitForAbTestAndSetPaymentSetupStep(originFlow)(
                    action$,
                    state$,
                ),
            );
        }),
    );
