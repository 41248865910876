import { ORDERS } from '@perpay-web/fintech/constants/tableNames';

export const getOrderEntity = (state, orderUUID) => {
    if (!state.entities[ORDERS]) {
        return null;
    }
    return state.entities[ORDERS][orderUUID];
};

export const getRemainingSteps = (state, orderUUID) => {
    const order = getOrderEntity(state, orderUUID);
    return order ? order.remainingSteps : [];
};

export const getPaymentOptions = (state, orderUUID) => {
    const order = getOrderEntity(state, orderUUID);
    return order ? order.repaymentOptions : [];
};

export const getOrderTotal = (state, orderUUID) => {
    const order = getOrderEntity(state, orderUUID);
    return order ? order.checkout.summary.total : null;
};

export const getShippingInfo = (state, orderUUID) => {
    const order = getOrderEntity(state, orderUUID);
    return order ? order.checkout.shippingInfo : null;
};

export const getOrderCredit = (state, orderUUID) => {
    const order = getOrderEntity(state, orderUUID);
    return order ? order.credit : null;
};

export const getOrderAdjustedTotal = (state, orderUUID) => {
    const orderTotal = getOrderTotal(state, orderUUID) || 0;
    const orderCredit = getOrderCredit(state, orderUUID) || 0;
    return Math.max(orderTotal - orderCredit, 0);
};

export const getOrderFromCheckoutUuid = (state, checkoutUUID) => {
    const orders = Object.values(state.entities[ORDERS]);
    if (!orders) {
        return null;
    }

    const checkoutOrder = orders.filter(
        (order) => order.checkout && order.checkout.uuid === checkoutUUID,
    )[0];

    if (!checkoutOrder) {
        return null;
    }

    return checkoutOrder;
};

export const getOrderUuidFromCheckoutUuid = (state, checkoutUUID) => {
    const orders = Object.values(state.entities[ORDERS]);
    if (!orders) {
        return null;
    }

    const checkoutOrder = orders.filter(
        (order) => order.checkout && order.checkout.uuid === checkoutUUID,
    )[0];

    if (!checkoutOrder) {
        return null;
    }

    return checkoutOrder.uuid;
};
