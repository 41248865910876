import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateCreditLimit,
    updateCreditLimitSuccess,
    updateCreditLimitError,
    updateCreditLimitReset,
} from '@perpay-web/fintech/actions/entities/creditLimits';

const UpdateCreditLimitDataModule = createDataModule({
    dataRequest: updateCreditLimit,
    dataSuccess: updateCreditLimitSuccess,
    dataError: updateCreditLimitError,
    dataReset: updateCreditLimitReset,
});

const getRoot = (state) => state.dataModules.updateCreditLimit;
const initialValue = {};

export const updateCreditLimitDataModule = UpdateCreditLimitDataModule({
    getRoot,
    initialValue,
});
