import Chip from '@perpay-web/storefront/components/base/Chip/Chip';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './TrendingSearchesList.scss';

const MOBILE_LIMIT = 15;
const DESKTOP_LIMIT = 10;

const TrendingSearchesList = ({
    className = '',
    isMobile,
    items,
    getItemProps,
    source,
    listProps,
}) => {
    const maxItems = isMobile
        ? Math.min(items.length, MOBILE_LIMIT)
        : DESKTOP_LIMIT;
    // max 2 rows limited by design
    const rows = isMobile ? 2 : 1;
    const row1 = items.slice(0, Math.ceil(maxItems / rows));
    const row2 = items.slice(Math.ceil(maxItems / rows), maxItems);

    return (
        <div className={styles.container}>
            <div
                className={styles.list}
                id={listProps.id}
                aria-labelledby={listProps['aria-labelledby']}
                role={listProps.role}
            >
                {[row1, row2].map((row) => (
                    <div
                        key={row}
                        className={getClassName(styles.row, className)}
                    >
                        {row.map((item) => {
                            const {
                                id,
                                onClick,
                                onMouseDown,
                                onMouseMove,
                                'aria-selected': ariaSelected,
                            } = getItemProps({
                                source,
                                item,
                            });

                            return (
                                <div
                                    className={styles.item}
                                    key={id}
                                    aria-selected={ariaSelected}
                                >
                                    <Chip
                                        title={item.name}
                                        onClick={onClick}
                                        onMouseDown={onMouseDown}
                                        onMouseMove={onMouseMove}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrendingSearchesList;
