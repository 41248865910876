export const TURN_OFF_DIRECT_DEPOSIT_STEP = 'turnOff';
export const REGAIN_CARD_ACCESS_STEP = 'suspendedCard';
export const DELINQUENT_LATE_STEP = 'delinquentOrLate';
export const ACCEPT_CREDIT_LIMIT_STEP = 'acceptCreditLimit';
export const UPDATE_EMPLOYER_AND_AMOUNT_STEP = 'updateEmployerAndAmount';
export const EDIT_EMPLOYER_STEP = 'editEmployer';
export const EDIT_AMOUNT_STEP = 'editAmount';
export const SELF_ONBOARDING_STEP = 'selfOnboarding';
export const MANUAL_SETUP_STEP = 'manualSetup';
export const PINWHEEL_STEP = 'pinwheel';
export const SUCCESS_STEP = 'success';
export const CLI_SUCCESS_STEP = 'creditLimitIncreaseSuccess';

export const UPDATE_DIRECT_DEPOSIT_STEPS = [
    TURN_OFF_DIRECT_DEPOSIT_STEP,
    REGAIN_CARD_ACCESS_STEP,
    DELINQUENT_LATE_STEP,
    ACCEPT_CREDIT_LIMIT_STEP,
    UPDATE_EMPLOYER_AND_AMOUNT_STEP,
    EDIT_EMPLOYER_STEP,
    EDIT_AMOUNT_STEP,
    SELF_ONBOARDING_STEP,
    MANUAL_SETUP_STEP,
    SUCCESS_STEP,
    PINWHEEL_STEP,
    CLI_SUCCESS_STEP,
];
