import { timer, race } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const DEFAULT_TIMEOUT_MS = 15000;

/**
 * Create an observable which waits for the input observable to
 * emit an event (usually an action), or times out.
 * On timing out, emits an error.
 */
export function emitOrTimeout(observable, timeout = DEFAULT_TIMEOUT_MS) {
    return race(
        observable,
        timer(timeout).pipe(
            mergeMap(() => {
                throw new Error('timeout!');
            }),
        ),
    );
}
