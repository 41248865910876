import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createAutoPayScheduleForCardAutoPay,
    createAutoPayScheduleForCardAutoPaySuccess,
    createAutoPayScheduleForCardAutoPayError,
    createAutoPayScheduleForCardAutoPayReset,
} from '@perpay-web/fintech/actions/ui/autoPaySchedules';

const CreateAutoPayScheduleForCardAutoPayDataModule = createDataModule({
    dataRequest: createAutoPayScheduleForCardAutoPay,
    dataSuccess: createAutoPayScheduleForCardAutoPaySuccess,
    dataError: createAutoPayScheduleForCardAutoPayError,
    dataReset: createAutoPayScheduleForCardAutoPayReset,
});

const getRoot = (state) =>
    state.dataModules.createAutoPayScheduleForCardAutoPay;
const initialValue = {};

export const createAutoPayScheduleForCardAutoPayDataModule =
    CreateAutoPayScheduleForCardAutoPayDataModule({
        getRoot,
        initialValue,
    });

export const getFixedAmount = (state) =>
    createAutoPayScheduleForCardAutoPayDataModule.getData(state).fixedAmount;

export const getAmountType = (state) =>
    createAutoPayScheduleForCardAutoPayDataModule.getData(state).amountType;

export const getFrequency = (state) =>
    createAutoPayScheduleForCardAutoPayDataModule.getData(state).frequency;

export const getSchedule = (state) =>
    createAutoPayScheduleForCardAutoPayDataModule.getData(state).schedule;

export const getNextPaymentDate = (state) =>
    createAutoPayScheduleForCardAutoPayDataModule.getData(state)
        .nextPaymentDate;
