import {
    createDeposit,
    createDepositErrors,
    createDepositSuccess,
} from '@perpay-web/fintech/actions/entities/deposits';
import { createExternalCardErrors } from '@perpay-web/fintech/actions/entities/externalCards';
import { closePaymentPortal } from '@perpay-web/fintech/actions/ui/paymentPortal';
import {
    BACKEND_FETCH_ACCOUNT_SUMMARY,
    STORE_ACCOUNT_SUMMARY_REPLACE,
    BACKEND_FETCH_EXTERNAL_CARDS,
    STORE_REPLACE_EXTERNAL_CARDS,
    BACKEND_CREATE_EXTERNAL_CARD,
    STORE_CREATE_EXTERNAL_CARD_SUCCESS,
    STORE_PAYMENT_PORTAL_OPEN_ADD_EXTERNAL_CARD_MODAL,
    STORE_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL,
    STORE_PAYMENT_PORTAL_RESET,
    STORE_PAYMENT_PORTAL_CLEAR_ERRORS,
    BACKEND_CREATE_PAYMENT_PLAN,
    STORE_CREATE_PAYMENT_PLAN_SUCCESS,
    STORE_CREATE_PAYMENT_PLAN_ERROR,
    BACKEND_FETCH_JOBS,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: true,
    loadingExternalCards: true,
    loadingJobs: true,
    submittingAddExternalCard: false,
    addExternalCardModalOpen: false,
    submittingCharge: false,
    submittingPaymentPlanCreate: false,
    paymentPlanCreated: false,
    chargeCompleted: false,
    errors: {},
    createExternalCardErrors: {},
    mostRecentExternalCardUUID: null,
    deposit: null,
};

const paymentPortalReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case closePaymentPortal().type:
        case STORE_PAYMENT_PORTAL_RESET:
            return initialState;
        case BACKEND_FETCH_ACCOUNT_SUMMARY:
            return { ...state, loading: true };
        case STORE_ACCOUNT_SUMMARY_REPLACE:
            return { ...state, loading: false };
        case BACKEND_FETCH_EXTERNAL_CARDS:
            return { ...state, loadingExternalCards: true };
        case STORE_REPLACE_EXTERNAL_CARDS: {
            const externalCards = payload ? Object.values(payload) : [];
            const sortedExternalCards = externalCards.sort(
                (card1, card2) =>
                    Date.parse(card2.created) - Date.parse(card1.created),
            );
            const mostRecentExternalCard =
                sortedExternalCards.length > 0
                    ? sortedExternalCards[0]
                    : state.mostRecentExternalCardUUID;
            const mostRecentExternalCardUUID = mostRecentExternalCard
                ? mostRecentExternalCard.uuid
                : '';
            return {
                ...state,
                loadingExternalCards: false,
                mostRecentExternalCardUUID,
            };
        }
        case BACKEND_CREATE_EXTERNAL_CARD:
            return { ...state, submittingAddExternalCard: true };
        case STORE_PAYMENT_PORTAL_OPEN_ADD_EXTERNAL_CARD_MODAL:
            return { ...state, addExternalCardModalOpen: true };
        case STORE_PAYMENT_PORTAL_CLOSE_ADD_EXTERNAL_CARD_MODAL:
            return { ...state, addExternalCardModalOpen: false };
        case STORE_CREATE_EXTERNAL_CARD_SUCCESS:
            return {
                ...state,
                submittingAddExternalCard: false,
                addExternalCardModalOpen: false,
            };
        case createExternalCardErrors().type:
            return {
                ...state,
                submittingAddExternalCard: false,
                createExternalCardErrors: payload,
            };
        case createDeposit().type:
            return { ...state, submittingCharge: true };
        case createDepositErrors().type:
            return {
                ...state,
                submittingCharge: false,
                errors: payload,
            };
        case createDepositSuccess().type:
            return {
                ...state,
                submittingCharge: false,
                chargeCompleted: true,
                deposit: payload,
            };
        case BACKEND_CREATE_PAYMENT_PLAN:
            return { ...state, submittingPaymentPlanCreate: true };
        case STORE_CREATE_PAYMENT_PLAN_ERROR:
            return {
                ...state,
                submittingPaymentPlanCreate: false,
                errors: payload,
            };
        case STORE_CREATE_PAYMENT_PLAN_SUCCESS:
            return {
                ...state,
                submittingPaymentPlanCreate: false,
                paymentPlanCreated: true,
            };
        case BACKEND_FETCH_JOBS:
            return { ...state, loadingJobs: true };
        case STORE_REPLACE_JOBS_ERROR:
        case STORE_REPLACE_JOBS:
            return { ...state, loadingJobs: false };
        case STORE_PAYMENT_PORTAL_CLEAR_ERRORS:
            return { ...state, errors: {} };
        default:
            return state;
    }
};

export default paymentPortalReducer;
