import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchBankAccountsForCardAutoPay,
    fetchBankAccountsForCardAutoPaySuccess,
    fetchBankAccountsForCardAutoPayError,
    fetchBankAccountsForCardAutoPayReset,
} from '@perpay-web/fintech/actions/ui/banks';

const FetchBankAccountsForCardAutoPayDataModule = createDataModule({
    dataRequest: fetchBankAccountsForCardAutoPay,
    dataSuccess: fetchBankAccountsForCardAutoPaySuccess,
    dataError: fetchBankAccountsForCardAutoPayError,
    dataReset: fetchBankAccountsForCardAutoPayReset,
});

const getRoot = (state) => state.dataModules.fetchBankAccountsForCardAutoPay;
const initialValue = {
    all: [],
};

export const fetchBankAccountsForCardAutoPayDataModule =
    FetchBankAccountsForCardAutoPayDataModule({
        getRoot,
        initialValue,
    });
