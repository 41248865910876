import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createAutoPaySchedule,
    createAutoPayScheduleSuccess,
    createAutoPayScheduleError,
    createAutoPayScheduleReset,
} from '@perpay-web/fintech/actions/entities/autoPaySchedules';

const CreateAutoPayScheduleDataModule = createDataModule({
    dataRequest: createAutoPaySchedule,
    dataSuccess: createAutoPayScheduleSuccess,
    dataError: createAutoPayScheduleError,
    dataReset: createAutoPayScheduleReset,
});

const getRoot = (state) => state.dataModules.createAutoPaySchedule;
const initialValue = {};

export const createAutoPayScheduleDataModule = CreateAutoPayScheduleDataModule({
    getRoot,
    initialValue,
});
