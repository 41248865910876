import {
    BACKEND_CREATE_PAYMENT_PLAN,
    STORE_CREATE_PAYMENT_PLAN_SUCCESS,
    STORE_CREATE_PAYMENT_PLAN_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function createPaymentPlan(
    maxChargeAmount,
    chargeSource,
    cadence,
    startDate,
) {
    return {
        type: BACKEND_CREATE_PAYMENT_PLAN,
        payload: {
            maxChargeAmount,
            chargeSource,
            cadence,
            startDate,
        },
    };
}

export function createPaymentPlanSuccess() {
    return {
        type: STORE_CREATE_PAYMENT_PLAN_SUCCESS,
    };
}

export function createPaymentPlanErrors(errors) {
    return {
        type: STORE_CREATE_PAYMENT_PLAN_ERROR,
        payload: errors,
    };
}
