import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { BACKEND_FETCH_ORDER_CANCELLATION_REASONS } from '@perpay-web/fintech/constants/actionTypes';
import { ORDER_CANCELLATION_REASONS } from '@perpay-web/fintech/constants/tableNames';
import { ORDER_CANCELLATION_REASONS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { orderCancellationReason } from '@perpay-web/fintech/normalizers/schemas';
import { replaceOrderCancellationReasons } from '@perpay-web/fintech/actions/entities/orderCancellationReasons';
import { orderCancellationReasonsError } from '@perpay-web/fintech/actions/ui/cancelOrderModal';

export function getOrderCancellationReasons(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_ORDER_CANCELLATION_REASONS),
        exhaustMap(() => get(ORDER_CANCELLATION_REASONS_ENDPOINT)),

        mergeMap((results) => {
            const normalized = normalize(results.response, [
                orderCancellationReason,
            ]);
            return [
                replaceOrderCancellationReasons(
                    normalized.entities[ORDER_CANCELLATION_REASONS],
                ),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            orderCancellationReasonsError(error),
        ]),
    );
}
