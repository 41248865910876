import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importUpdateDirectDepositContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "UpdateDirectDepositContainer" */
                '@perpay-web/fintech/components/containers/UpdateDirectDepositContainer'
            ),
    );
