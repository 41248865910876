import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    partnerOnboardingSignup,
    partnerOnboardingSignupSuccess,
    partnerOnboardingSignupError,
    partnerOnboardingSignupReset,
} from '@perpay-web/fintech/actions/entities/partnerOnboarding';

const PartnerOnboardingSignupDataModule = createDataModule({
    dataRequest: partnerOnboardingSignup,
    dataSuccess: partnerOnboardingSignupSuccess,
    dataError: partnerOnboardingSignupError,
    dataReset: partnerOnboardingSignupReset,
});

const getRoot = (state) => state.dataModules.partnerOnboardingSignup;
const initialValue = {
    isExistingUser: false,
    errors: {},
};
export const partnerOnboardingSignupDataModule =
    PartnerOnboardingSignupDataModule({
        getRoot,
        initialValue,
    });
