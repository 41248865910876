import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPinwheelABTest,
    replacePinwheelABTest,
    fetchPinwheelABTestError,
    resetPinwheelABTest,
} from '@perpay-web/fintech/actions/shared/pinwheelABTest';

const FetchPinwheelABTestDataModule = createDataModule({
    dataRequest: fetchPinwheelABTest,
    dataSuccess: replacePinwheelABTest,
    dataError: fetchPinwheelABTestError,
    dataReset: resetPinwheelABTest,
});

const getRoot = (state) => state.dataModules.fetchPinwheelABTest;
const initialValue = {};

export const fetchPinwheelABTestDataModule = FetchPinwheelABTestDataModule({
    getRoot,
    initialValue,
});

export const getPinwheelABTestEnabled = (state) =>
    fetchPinwheelABTestDataModule.getData(state).pinwheel;
