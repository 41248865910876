import { useCallback, useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { validateForm } from '@perpay-web/validators/formBased';
import { mfaFormSchema } from '@perpay-web/fintech/validators/formBased';
import BackButton from '@perpay-web/components/base/BackButton/BackButton';
import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import Modal from '@perpay-web/components/base/Modal/Modal';
import NonFieldErrors from '@perpay-web/components/base/NonFieldErrors/NonFieldErrors';
import FormikTextInput from '@perpay-web/components/base/TextInput/FormikTextInput';
import FocusContent from '@perpay-web/components/composite/FocusLayoutV2/FocusContent';
import FocusFooter from '@perpay-web/components/composite/FocusLayoutV2/FocusFooter';
import FocusHeader from '@perpay-web/components/composite/FocusLayoutV2/FocusHeader';
import FocusLayoutV2 from '@perpay-web/components/composite/FocusLayoutV2/FocusLayoutV2';
import { Stack } from '@perpay-web/components/design-system/Stack/Stack';
import DefaultHeader from '@perpay-web/fintech/components/base/Header/DefaultHeader';
import { useFullHeader } from '@perpay-web/fintech/hooks/useFullHeaderContext';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { useIsMobile } from '@perpay-web/hooks/useIsMobile';
import { EndToEndTestIdProvider } from '@perpay-web/utils/endToEndTestIds';
import { extractTimeFromString } from '@perpay-web/utils/stringUtils';

import styles from './MFAForm.scss';

const MFAForm = ({
    error = {
        attempt: null,
        resend: null,
        throttle: null,
    },
    isLoading = false,
    onSubmit,
    onSubmitResend,
    data = {
        otpUuid: '',
        validUntil: '',
        contactInfo: {
            email: '',
            phone: '',
        },
    },
    initialValues = {
        code: '',
    },
}) => {
    const navigate = useNavigate();

    const { isMobile } = useIsMobile();
    const headerElement = useMemo(
        () => (
            <FocusHeader
                leftButtonElement={<BackButton href={paths.login.path} />}
            />
        ),
        [],
    );

    const renderHeaderCallback = useCallback(
        (props) => (isMobile ? headerElement : <DefaultHeader {...props} />),
        [isMobile, headerElement],
    );
    useFullHeader(renderHeaderCallback);

    const goBack = () => {
        navigate(paths.login.path);
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values, formikHelpers) => {
            // Reset touched to disable form submission if user entered invalid code
            // previously and hasn't updated their input.
            formikHelpers.setTouched({});
            onSubmit(values.code);
        },
        validate: (values) => validateForm(values, mfaFormSchema),
    });

    return (
        <FormikProvider value={formik}>
            {error && error.throttle && (
                <Modal
                    showClose={false}
                    containerClassName={styles.modal__container}
                >
                    <div className={styles.modal__content}>
                        <div className={styles.modal__header}>
                            You have reached maximum attempts.
                        </div>
                        <div className={styles.modal__body}>
                            Please contact customer service for support or try
                            log in again in{' '}
                            {extractTimeFromString(error.throttle.message[0])}.
                        </div>
                        <CustomButton action='primary' onClick={goBack}>
                            OK
                        </CustomButton>
                    </div>
                </Modal>
            )}
            <form onSubmit={formik.handleSubmit}>
                <FocusLayoutV2 className={styles['focus-layout']}>
                    <FocusContent>
                        <Stack className={styles['inner-stack']}>
                            <p className={styles['responsive-small-header']}>
                                Enter authentication code
                            </p>
                            <p className={styles.contact}>
                                Please enter the 6 digit authentication code we
                                sent to {data.contactInfo.phone} and{' '}
                                {data.contactInfo.email}.
                            </p>
                        </Stack>
                        <EndToEndTestIdProvider testId='confirmation-code'>
                            <FormikTextInput
                                autocomplete='one-time-code'
                                label='Authentication Code'
                                placeholder='XXXXXX'
                                name='code'
                                type='tel'
                                maxLength='6'
                            />
                        </EndToEndTestIdProvider>
                        {!error.resend && (
                            <div className={styles.resend}>
                                {data.resendsLeft >= 0 ? (
                                    <>
                                        You have{' '}
                                        {data.resendsLeft > 0
                                            ? data.resendsLeft
                                            : 'no'}{' '}
                                        resend{data.resendsLeft !== 1 && 's'}{' '}
                                        left.
                                    </>
                                ) : (
                                    <>Didn&apos;t receive a code?</>
                                )}
                                &nbsp;
                                {data.resendsLeft !== 0 && (
                                    <CustomButton
                                        action='inline-link'
                                        onClick={() => onSubmitResend()}
                                    >
                                        Resend
                                    </CustomButton>
                                )}
                            </div>
                        )}

                        {error.attempt ? (
                            <NonFieldErrors errors={error.attempt} />
                        ) : (
                            error.resend && (
                                <NonFieldErrors errors={error.resend} />
                            )
                        )}
                    </FocusContent>
                    <FocusFooter>
                        <EndToEndTestIdProvider testId='mfa-form'>
                            <CustomButton
                                action='primary'
                                className={styles.verify__button}
                                disabled={!formik.isValid}
                                loading={isLoading}
                                type='submit'
                            >
                                Verify
                            </CustomButton>
                        </EndToEndTestIdProvider>
                    </FocusFooter>
                </FocusLayoutV2>
            </form>
        </FormikProvider>
    );
};

export default MFAForm;
