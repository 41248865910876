const urlToPromiseMap = {};

export function loadThirdPartyScript(url) {
    if (urlToPromiseMap[url]) {
        return urlToPromiseMap[url];
    }

    urlToPromiseMap[url] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.addEventListener('load', resolve);
        script.onerror = (event) => {
            urlToPromiseMap[url] = null;
            reject(new Error(`Failed to load ${event.target.src}`));
        };
        document.body.appendChild(script);
    });

    return urlToPromiseMap[url];
}
