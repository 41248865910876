import { Fragment } from 'react';
import { parseAlgoliaHitReverseHighlight } from '@algolia/autocomplete-preset-algolia';
import { getClassName } from '@perpay-web/utils/domUtils';
import { Skeleton } from '@perpay-web/components/design-system/Skeleton/Skeleton';

import styles from './AutocompleteListItem.scss';

export const AutocompleteListItem = ({
    attribute = 'name',
    hit,
    isLoading = false,
    onClick,
    onMouseDown,
    onClear,
    className,
}) => (
    <div className={getClassName(styles['autocomplete-list-item'], className)}>
        {isLoading ? (
            <div className={styles['autocomplete-list-item__skeleton']}>
                <Skeleton
                    className={getClassName(
                        styles['autocomplete-list-item__skeleton__item'],
                        styles[
                            'autocomplete-list-item__skeleton__item--circular'
                        ],
                    )}
                />
                <Skeleton
                    className={getClassName(
                        styles['autocomplete-list-item__skeleton__item'],
                        styles['autocomplete-list-item__skeleton__item--text'],
                    )}
                />
            </div>
        ) : (
            <div
                className={getClassName(
                    styles['autocomplete-list-item__buttons'],
                )}
            >
                <button
                    className={
                        styles['autocomplete-list-item__buttons__main-button']
                    }
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    type='button'
                >
                    <div className={styles['autocomplete-list-item__content']}>
                        {parseAlgoliaHitReverseHighlight({
                            attribute,
                            hit,
                        }).map(({ isHighlighted, value }, index) => {
                            const key = index;
                            const Element = isHighlighted ? 'strong' : Fragment;

                            return <Element key={key}>{value}</Element>;
                        })}
                    </div>
                </button>
                {onClear && (
                    <button
                        type='button'
                        onClick={onClear}
                        className={styles['autocomplete-list-item__clear-icon']}
                        aria-label='Remove item'
                    />
                )}
            </div>
        )}
    </div>
);
