import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchPerpayPlusDashboardSummary,
    perpayPlusDashboardSummarySuccess,
    perpayPlusDashboardSummaryError,
    perpayPlusDashboardSummaryReset,
} from '@perpay-web/fintech/actions/entities/perpayPlusDashboardSummary';

const FetchPerpayPlusDashboardSummaryDataModule = createDataModule({
    dataRequest: fetchPerpayPlusDashboardSummary,
    dataSuccess: perpayPlusDashboardSummarySuccess,
    dataError: perpayPlusDashboardSummaryError,
    dataReset: perpayPlusDashboardSummaryReset,
});

// TODO: Remove use of underscore_case once backend change has been made
const getRoot = (state) => state.dataModules.fetchPerpayPlusDashboardSummary;
const initialValue = {
    perpay_plus_status: '',
    perpayPlusStatus: '',
    baseline: {
        vantage_score_v4: 0,
        vantageScoreV4: 0,
        report_date: '',
        reportDate: '',
    },
    current: {
        vantage_score_v4: 0,
        vantageScoreV4: 0,
    },
};

export const fetchPerpayPlusDashboardSummaryDataModule =
    FetchPerpayPlusDashboardSummaryDataModule({
        getRoot,
        initialValue,
    });

export const getPerpayPlusStatus = (state) =>
    fetchPerpayPlusDashboardSummaryDataModule.getData(state)
        .perpay_plus_status ||
    fetchPerpayPlusDashboardSummaryDataModule.getData(state).perpayPlusStatus ||
    '';

const getBaselineScoreObject = (state) =>
    fetchPerpayPlusDashboardSummaryDataModule.getData(state).baseline ||
    initialValue.baseline;

export const getBaselineScore = (state) =>
    getBaselineScoreObject(state).vantage_score_v4 ||
    getBaselineScoreObject(state).vantageScoreV4 ||
    0;

const getCurrentScoreObject = (state) =>
    fetchPerpayPlusDashboardSummaryDataModule.getData(state).current ||
    initialValue.current;

export const getCurrentScore = (state) =>
    getCurrentScoreObject(state).vantage_score_v4 ||
    getCurrentScoreObject(state).vantageScoreV4 ||
    0;

export const getCurrentReportDate = (state) =>
    getCurrentScoreObject(state).report_date ||
    getCurrentScoreObject(state).reportDate ||
    '';

export const getErrors = (state) =>
    fetchPerpayPlusDashboardSummaryDataModule.getData(state).errors;
