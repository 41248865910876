import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importBorrowerLoginContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "BorrowerLoginContainer" */
                '@perpay-web/fintech/components/containers/BorrowerLoginContainer'
            ),
    );
