export const getRoot = (state) => state.ui.cardOnboardingMVP2;

export const getIsSubmittingJob = (state) => getRoot(state).isSubmittingJob;

export const getErrors = (state) => getRoot(state).errors;

export const getFetchingPinwheelToken = (state) =>
    getRoot(state).fetchingPinwheelToken;

export const getPinwheelErrors = (state) => getRoot(state).pinwheelErrors;

export const getIsSubmittingSelfOnboardingForm = (state) =>
    getRoot(state).isSubmittingSelfOnboardingForm;

export const getSelfOnboardingErrors = (state) =>
    getRoot(state).selfOnboardingErrors;

export const getAmountToCharge = (state) => getRoot(state).amountToCharge;
