import {
    BACKEND_FETCH_ACCOUNT_SUMMARY,
    STORE_ACCOUNT_SUMMARY_ERROR,
    STORE_ACCOUNT_SUMMARY_REPLACE,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    errors: {},
    loading: false,
};

const accountSummaryReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_ACCOUNT_SUMMARY:
            return { ...state, loading: true, errors: {} };
        case STORE_ACCOUNT_SUMMARY_ERROR:
            return { ...state, loading: false, errors: payload };
        case STORE_ACCOUNT_SUMMARY_REPLACE:
            return { ...state, loading: false, errors: {} };
        default:
            return state;
    }
};

export default accountSummaryReducer;
