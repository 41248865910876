import { Suspense } from 'react';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import FullscreenContainer from '@perpay-web/components/base/FullscreenContainer/FullscreenContainer';

const DEFAULT_DELAY = 200;

export const withLoader = (Component) => (props) => (
    <Suspense fallback={<WarningLoaderCircle delay={DEFAULT_DELAY} />}>
        <Component {...props} />
    </Suspense>
);

export const withFullscreenLoader = (Component) => (props) => (
    <Suspense
        fallback={
            <FullscreenContainer>
                <WarningLoaderCircle fullscreen delay={DEFAULT_DELAY} />
            </FullscreenContainer>
        }
    >
        <Component {...props} />
    </Suspense>
);
