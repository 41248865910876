import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';
import { normalize } from 'normalizr';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import {
    BACKEND_FETCH_BANKS,
    BACKEND_CREATE_BANK,
    BACKEND_VERIFY_BANK,
} from '@perpay-web/fintech/constants/actionTypes';
import { bank } from '@perpay-web/fintech/normalizers/schemas';
import {
    BANKS_ENDPOINT,
    BANK_VERIFICATION,
} from '@perpay-web/fintech/constants/urls';
import {
    replaceBanks,
    replaceBanksError,
    createBankError,
    verifyBankError,
    createBankSuccess,
    verifyBankSuccess,
    fetchBankAccounts as fetchBankAccountsAction,
    fetchBankAccountsSuccess,
    fetchBankAccountsError,
    createBankAccount as createBankAccountAction,
    createBankAccountSuccess,
    createBankAccountError,
} from '@perpay-web/fintech/actions/entities/banks';

export function fetchBanks(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_BANKS),
        exhaustMap(() => emitOrTimeout(get(BANKS_ENDPOINT))),
        mergeMap((results) => {
            const normalized = normalize(results.response, [bank]);
            return [replaceBanks(normalized.entities.banks)];
        }),
        handleErrorMessageWithFallback((error) => [replaceBanksError(error)]),
    );
}

// used with fetchBankAccountsDataModule
export function fetchBankAccounts(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchBankAccountsAction().type),
        exhaustMap(() => emitOrTimeout(get(BANKS_ENDPOINT))),
        mergeMap((results) => [
            fetchBankAccountsSuccess({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchBankAccountsError(error),
        ]),
    );
}

export function createBank(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_BANK),
        exhaustMap((action) =>
            emitOrTimeout(post(BANKS_ENDPOINT, action.payload)),
        ),
        mergeMap(() => [createBankSuccess()]),
        handleErrorMessageWithFallback((error) => [createBankError(error)]),
    );
}

// used with createBankAccountDataModule
export function createBankAccount(action$, state$, { post }) {
    return action$.pipe(
        ofType(createBankAccountAction().type),
        exhaustMap((action) =>
            emitOrTimeout(post(BANKS_ENDPOINT, action.payload)),
        ),
        mergeMap((results) => [createBankAccountSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            createBankAccountError(error),
        ]),
    );
}

export function verifyBank(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_VERIFY_BANK),
        exhaustMap((action) => {
            const data = {
                amounts: action.payload.amounts,
            };
            return emitOrTimeout(
                post(`${BANK_VERIFICATION}${action.payload.uuid}`, data),
            );
        }),
        mergeMap(() => [verifyBankSuccess()]),
        handleErrorMessageWithFallback((error) => [verifyBankError(error)]),
    );
}
