import {
    ANALYTICS_PERPAY_PLUS_CLICK_HAMBURGER,
    ANALYTICS_PERPAY_PLUS_VIEW_PAYS,
    ANALYTICS_PERPAY_PLUS_CLICK_PAYS,
    ANALYTICS_PERPAY_PLUS_INTRO_STEP_VIEWED,
    ANALYTICS_PERPAY_PLUS_INTRO_CLICK_CLOSE,
    ANALYTICS_PERPAY_PLUS_INTRO_CLICK_LETS_DO_IT,
    ANALYTICS_PERPAY_PLUS_TERMS_MODAL_VIEW,
    ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_ACTIVATE,
    ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_CLOSE,
    ANALYTICS_PERPAY_PLUS_INTRO_CLICK_MAYBE_LATER,
    ANALYTICS_PERPAY_PLUS_SUCCESS_STEP_VIEWED,
    ANALYTICS_PERPAY_PLUS_SUCCESS_CLICK_DONE,
} from '@perpay-web/fintech/constants/actionTypes';

export function analyticsPerpayPlusViewPays() {
    return {
        type: ANALYTICS_PERPAY_PLUS_VIEW_PAYS,
    };
}
export function analyticsPerpayPlusClickPays() {
    return {
        type: ANALYTICS_PERPAY_PLUS_CLICK_PAYS,
    };
}

export function analyticsPerpayPlusClickHamburger() {
    return {
        type: ANALYTICS_PERPAY_PLUS_CLICK_HAMBURGER,
    };
}

export const analyticsOnPerpayPlusIntroStepView = () => ({
    type: ANALYTICS_PERPAY_PLUS_INTRO_STEP_VIEWED,
});

export const analyticsOnPerpayPlusIntroClickClose = () => ({
    type: ANALYTICS_PERPAY_PLUS_INTRO_CLICK_CLOSE,
});

export const analyticsOnPerpayPlusIntroClickMaybeLater = () => ({
    type: ANALYTICS_PERPAY_PLUS_INTRO_CLICK_MAYBE_LATER,
});

export const analyticsOnPerpayPlusIntroClickLetsDoIt = ({
    buttonLocation,
}) => ({
    type: ANALYTICS_PERPAY_PLUS_INTRO_CLICK_LETS_DO_IT,
    payload: { buttonLocation },
});

export const analyticsOnPerpayPlusTermsModalView = () => ({
    type: ANALYTICS_PERPAY_PLUS_TERMS_MODAL_VIEW,
});

export const analyticsOnPerpayPlusTermsModalClickActivate = () => ({
    type: ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_ACTIVATE,
});

export const analyticsOnPerpayPlusTermsModalClickClose = () => ({
    type: ANALYTICS_PERPAY_PLUS_TERMS_MODAL_CLICK_CLOSE,
});

export const analyticsOnPerpayPlusSuccessStepView = () => ({
    type: ANALYTICS_PERPAY_PLUS_SUCCESS_STEP_VIEWED,
});

export const analyticsOnPerpayPlusSuccessStepClickDone = () => ({
    type: ANALYTICS_PERPAY_PLUS_SUCCESS_CLICK_DONE,
});
