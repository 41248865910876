import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchDirectDepositDashboardSummaryActions = createDataModuleActions(
    'FETCH_DIRECT_DEPOSIT_DASHBOARD_SUMMARY',
);
export const fetchDirectDepositDashboardSummary =
    fetchDirectDepositDashboardSummaryActions.dataRequest;
export const directDepositDashboardSummarySuccess =
    fetchDirectDepositDashboardSummaryActions.dataSuccess;
export const directDepositDashboardSummaryError =
    fetchDirectDepositDashboardSummaryActions.dataError;
export const directDepositDashboardSummaryReset =
    fetchDirectDepositDashboardSummaryActions.dataReset;
