import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './Stack.scss';

/**
 * The Stack component is a "layout primitive."
 * It is responsible for the following behaviors:
 * - Wrapping elements into a flex display context with a column flow
 * - Giving the same margin of 0 for all first-level children.
 * - Specifying sane defaults for rendering as a child of a flex context
 *
 * When would I use a Stack over a raw div?
 * - When I want the above behaviors
 * - When I want to composably style children in a column with simple layout
 *
 * When would I not use a Stack over a raw div?
 * - When I want to do more complex styling that does not fit into the above buckets
 *
 * Example usage:
 * ```
 * // MyComponent.js
 * <Stack className={styles['my-component']}>
 *   <CoolComponent />
 *   <CoolComponent />
 * </Stack>
 *
 * // MyComponent.scss
 * .my-component {
 *   composes: stack--gap__public from '~@perpay-web/components/design-system/Stack/Stack.scss';
 *   --stack-gap: #{$xs-space};
 * }
 * ```
 */
export const Stack = ({ className = '', children }) => (
    <div className={getClassName(styles.stack, className)}>{children}</div>
);
