import { createCrudDataModule } from '@perpay-web/data-module/createCrudDataModule';
import { fetchUserInfoDataModule } from '@perpay-web/fintech/dataModules/fetchUserInfo';
import { updateUserInfoForCardOnboardingMVP2DataModule } from '@perpay-web/fintech/dataModules/updateUserInfoForCardOnboardingMVP2';

const UserInfoForCardOnboardingMVP2CrudDataModule = createCrudDataModule({
    read: fetchUserInfoDataModule,
    update: updateUserInfoForCardOnboardingMVP2DataModule,
});

const getRoot = (state) => state.dataModules.userInfoForCardOnboardingMVP2Crud;

export const userInfoForCardOnboardingMVP2CrudDataModule =
    UserInfoForCardOnboardingMVP2CrudDataModule({ getRoot });

const getUserInfo = (state) => {
    const userInfoEntities =
        userInfoForCardOnboardingMVP2CrudDataModule.getData(state);
    const userInfo = Object.values(userInfoEntities)[0];

    return userInfo || {};
};

export const getFirstName = (state) => getUserInfo(state).firstName || '';

export const getLastName = (state) => getUserInfo(state).lastName || '';

export const getVisibleSsn = (state) => getUserInfo(state).visibleSsn || '';

export const getDateOfBirth = (state) => getUserInfo(state).dateOfBirth || '';

export const getSsnIsVerified = (state) => getUserInfo(state).ssnIsVerified;

export const getSsnIsNull = (state) => getUserInfo(state).ssn === null;

export const getAddress = (state) => ({
    street1: getUserInfo(state).streetAddr,
    street2: '',
    city: getUserInfo(state).city,
    state: getUserInfo(state).state,
    zipcode: getUserInfo(state).zipcode,
});

export const getIsAddressComplete = (state) => {
    const address = getAddress(state);

    if (address.street1 && address.city && address.state && address.zipcode) {
        return true;
    }

    return false;
};

export const formatAddress = (address) => ({
    line1: address.street1 || '',
    line2: address.street2 || '',
    city: address.city || '',
    state: address.state || '',
    zip: address.zipcode || '',
    type: '',
});
