import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importDirectDepositDetailsContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "DirectDepositDetailsContainer" */
                '@perpay-web/fintech/components/containers/DirectDepositDetailsContainer'
            ),
    );
