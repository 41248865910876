import {
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    STORE_RESET_FEATURE_ENROLLMENTS,
    STORE_FEATURES_ENROLL_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const featureEnrollmentsReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_FEATURES_ENROLL_SUCCESS:
        case STORE_REPLACE_FEATURE_ENROLLMENTS:
            return { ...state, ...payload };
        case STORE_FETCH_FEATURE_ENROLLMENTS_ERROR:
            return { ...state, error: payload };
        case STORE_RESET_FEATURE_ENROLLMENTS:
            return initialState;
        default:
            return state;
    }
};

export default featureEnrollmentsReducer;
