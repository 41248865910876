import { createQuerySuggestionsPlugin as createAlgoliaQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import {
    createLocalStorage,
    createRecentSearchesPlugin as createAlgoliaRecentSearchesPlugin,
    search,
} from '@algolia/autocomplete-plugin-recent-searches';
import { AutocompleteQuerySuggestionItem } from '@perpay-web/storefront/components/composite/AutocompleteQuerySuggestionItem/AutocompleteQuerySuggestionItem';
import { AutocompleteRecentSearchItem } from '@perpay-web/storefront/components/composite/AutocompleteRecentSearchItem/AutocompleteRecentSearchItem';
import {
    AutocompleteCategoryList,
    styles,
} from '@perpay-web/storefront/components/composite/AutocompleteCategoryList/AutocompleteCategoryList';
import TrendingSearchesList from '@perpay-web/storefront/components/composite/TrendingSearchesList/TrendingSearchesList';
import { QUERY_SUGGESTIONS_INDEX_NAME } from '@perpay-web/storefront/constants/algoliaSearchConfig';
import {
    categoryMenuDefaultAdapter,
    categoryMenuNewArrivalsAdapter,
} from './categoryMenuAdapters';
import { getSearchPath } from './algoliaUtils';

export const AutocompleteSource = {
    RecentSearches: 'Recent Searches',
    QuerySuggestions: 'Query Suggestions',
    TrendingSearches: 'Trending Searches',
    Categories: 'Categories',
};

const RECENT_SEARCHES_LIMIT = 5;

export const getIsRecentSearch = (source) =>
    source.sourceId === AutocompleteSource.RecentSearches;

const recentSearchesStorage = createLocalStorage({
    key: 'autocomplete',
    limit: RECENT_SEARCHES_LIMIT,
    search,
});

/**
 * @param {import('@algolia/autocomplete-plugin-recent-searches').CreateRecentSearchesLocalStorageOptions<AutocompleteQuerySuggestionsHit>} options
 */
export const createRecentSearchesPlugin = ({ onSelect, ...options }) =>
    createAlgoliaRecentSearchesPlugin({
        storage: recentSearchesStorage,
        transformSource: ({ source }) => ({
            ...source,
            sourceId: AutocompleteSource.RecentSearches,
            onSelect,
            getItemInputValue: ({ item }) => item.label,
            getItemUrl: ({ item }) => getSearchPath(item.label),
            templates: {
                item: AutocompleteRecentSearchItem,
            },
            ...options,
        }),
    });

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource & import('@algolia/autocomplete-plugin-query-suggestions').CreateQuerySuggestionsPluginParams<AutocompleteQuerySuggestionsHit>} options
 */
export const createQuerySuggestionsPlugin = ({
    onSelect,
    searchClient,
    ...options
}) =>
    createAlgoliaQuerySuggestionsPlugin({
        ...options,
        searchClient,
        indexName: QUERY_SUGGESTIONS_INDEX_NAME,
        categoryAttribute: ['instant_search', 'facets', 'exact_matches'],
        transformSource: ({ source }) => ({
            ...source,
            sourceId: AutocompleteSource.QuerySuggestions,
            onSelect,
            getItems: (params) => {
                // When no query exists, do not show suggestions
                if (!params.query) {
                    return [];
                }
                return source.getItems(params);
            },
            getItemInputValue: ({ item }) => item.query,
            getItemUrl: ({ item }) => getSearchPath(item.query),
            templates: {
                item: AutocompleteQuerySuggestionItem,
            },
        }),
    });

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource} options
 * @returns {import('@algolia/autocomplete-core').AutocompleteSource}
 */
export const createCategoriesSource = (options = {}) => ({
    ...options,
    sourceId: AutocompleteSource.Categories,
    onSelect: options.onSelect,
    getItems: ({ query }) => {
        if (query) return [];

        return [
            {
                id: 1,
                name: 'New',
                href: categoryMenuNewArrivalsAdapter(),
                className: styles['star-icon'],
            },
            {
                id: 2,
                name: 'Electronics',
                href: categoryMenuDefaultAdapter('electronics'),
                className: styles['game-pad-icon'],
            },
            {
                id: 3,
                name: 'Home',
                href: categoryMenuDefaultAdapter('home'),
                className: styles['table-lamp-hanging-icon'],
            },
            {
                id: 4,
                name: 'Fashion',
                href: categoryMenuDefaultAdapter('fashion-beauty'),
                className: styles['tank-top-icon'],
            },
            {
                id: 5,
                name: 'Pet',
                href: categoryMenuDefaultAdapter('pet'),
                className: styles['dog-bone-icon'],
            },
            {
                id: 6,
                name: 'Lifestyle',
                href: categoryMenuDefaultAdapter('lifestyle'),
                className: styles['bicycle-icon'],
            },
            {
                id: 7,
                name: 'Auto',
                href: categoryMenuDefaultAdapter('auto'),
                className: styles['car-icon'],
            },
            {
                id: 8,
                name: 'Baby & Kids',
                href: categoryMenuDefaultAdapter('baby-kids'),
                className: styles['baby-trolley-icon'],
            },
        ];
    },
    getItemInputValue: ({ item }) => item.name,
    getItemUrl: ({ item }) => item.href,
    templates: {
        list: AutocompleteCategoryList,
    },
});

/**
 * @param {import('@algolia/autocomplete-core').AutocompleteSource} options
 * @returns {import('@algolia/autocomplete-core').AutocompleteSource}
 */
export const createTrendingSearchesSource = (options = {}) => ({
    ...options,
    sourceId: AutocompleteSource.TrendingSearches,
    onSelect: options.onSelect,
    getItems: ({ query }) => {
        if (query) return [];

        return [
            {
                id: 1,
                name: 'PS5',
            },
            {
                id: 2,
                name: 'TV',
            },
            {
                id: 3,
                name: 'iPhone',
            },
            {
                id: 4,
                name: 'iPad',
            },
            {
                id: 5,
                name: 'Laptop',
            },
            {
                id: 6,
                name: 'Shoes',
            },
            {
                id: 7,
                name: 'Xbox',
            },
            {
                id: 8,
                name: 'Apple Watch',
            },
            {
                id: 9,
                name: 'AirPods',
            },
            {
                id: 10,
                name: 'Tools',
            },
            {
                id: 11,
                name: 'Perfume',
            },
            {
                id: 12,
                name: 'Toys',
            },
            {
                id: 13,
                name: 'Ugg',
            },
            {
                id: 14,
                name: 'Jewelry',
            },
            {
                id: 15,
                name: 'Mattress',
            },
        ];
    },
    getItemInputValue: ({ item }) => item.name,
    getItemUrl: ({ item }) => getSearchPath(item.name),
    templates: {
        list: TrendingSearchesList,
    },
});

export const clearAlgoliaRecentSearches = () => {
    let recentSearchList = recentSearchesStorage.getAll();
    while (recentSearchList.length > 0) {
        recentSearchList.forEach((item) => {
            recentSearchesStorage.onRemove(item.id);
        });

        recentSearchList = recentSearchesStorage.getAll();
    }
};
