import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importProfile = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "Profile" */
                '../../components/screens/Profile/Profile'
            ),
    );
