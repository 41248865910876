import { switchMap, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchCardAccount as fetchCardAccountAction,
    replaceCardAccount,
    resetCardAccount,
    setCardAccountError,
} from '@perpay-web/fintech/actions/entities/cardAccounts';
import { CARD_ACCOUNTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchCardAccount = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchCardAccountAction().type),
        switchMap(() => get(CARD_ACCOUNTS_ENDPOINT)),
        mergeMap((results) => {
            const cardAccount = results.response;
            if (!cardAccount) return [resetCardAccount()];
            return [replaceCardAccount({ all: cardAccount })];
        }),
        handleErrorMessageWithFallback((error) => [setCardAccountError(error)]),
    );
