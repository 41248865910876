import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_CARD_ACCOUNT_APPLICATION_START_POLLING,
    BACKEND_CARD_ACCOUNT_APPLICATION_CANCEL_POLLING,
    BACKEND_CARD_ACCOUNT_APPLICATION_STOP_POLLING,
    STORE_CARD_ACCOUNT_APPLICATION_POLLING_ERROR,
    CARD_PROCESS_APPLICATION,
    CARD_PROCESS_APPLICATION_ADDRESS,
} from '@perpay-web/fintech/constants/actionTypes';

export const cardAccountApplicationStartPolling = (
    cardAccountApplicationUUID,
) => ({
    type: BACKEND_CARD_ACCOUNT_APPLICATION_START_POLLING,
    payload: { cardAccountApplicationUUID },
});

export const cardAccountApplicationPollingComplete = () => ({
    type: BACKEND_CARD_ACCOUNT_APPLICATION_STOP_POLLING,
});

export const cardAccountApplicationCancelPolling = () => ({
    type: BACKEND_CARD_ACCOUNT_APPLICATION_CANCEL_POLLING,
});

export const cardAccountApplicationPollingError = (payload) => ({
    type: STORE_CARD_ACCOUNT_APPLICATION_POLLING_ERROR,
    payload,
});

const fetchCardAccountApplicationActions = createDataModuleActions(
    'FETCH_CARD_ACCOUNT_APPLICATION',
);
export const fetchCardAccountApplication =
    fetchCardAccountApplicationActions.dataRequest;
export const fetchCardAccountApplicationSuccess =
    fetchCardAccountApplicationActions.dataSuccess;
export const fetchCardAccountApplicationError =
    fetchCardAccountApplicationActions.dataError;
export const fetchCardAccountApplicationReset =
    fetchCardAccountApplicationActions.dataReset;

const fetchCardAccountApplicationForOnboardingActions = createDataModuleActions(
    'FETCH_CARD_ACCOUNT_APPLICATION_FOR_ONBOARDING',
);
export const fetchCardAccountApplicationForOnboarding =
    fetchCardAccountApplicationForOnboardingActions.dataRequest;
export const fetchCardAccountApplicationForOnboardingSuccess =
    fetchCardAccountApplicationForOnboardingActions.dataSuccess;
export const fetchCardAccountApplicationForOnboardingError =
    fetchCardAccountApplicationForOnboardingActions.dataError;
export const fetchCardAccountApplicationForOnboardingReset =
    fetchCardAccountApplicationForOnboardingActions.dataReset;

const creditUnfreezeCardAccountApplicationActions = createDataModuleActions(
    'CREDIT_UNFREEZE_CARD_ACCOUNT_APPLICATION',
);
export const creditUnfreezeCardAccountApplication =
    creditUnfreezeCardAccountApplicationActions.dataRequest;
export const creditUnfreezeCardAccountApplicationSuccess =
    creditUnfreezeCardAccountApplicationActions.dataSuccess;
export const creditUnfreezeCardAccountApplicationError =
    creditUnfreezeCardAccountApplicationActions.dataError;
export const creditUnfreezeCardAccountApplicationReset =
    creditUnfreezeCardAccountApplicationActions.dataReset;

const createCardAccountApplicationActions = createDataModuleActions(
    'CREATE_CARD_ACCOUNT_APPLICATION',
    {
        dataRequest: (requestBody) => ({
            requestBody,
        }),
    },
);
export const createCardAccountApplication =
    createCardAccountApplicationActions.dataRequest;
export const createCardAccountApplicationSuccess =
    createCardAccountApplicationActions.dataSuccess;
export const createCardAccountApplicationError =
    createCardAccountApplicationActions.dataError;
export const createCardAccountApplicationReset =
    createCardAccountApplicationActions.dataReset;

const updateCardAccountApplicationActions = createDataModuleActions(
    'UPDATE_CARD_ACCOUNT_APPLICATION',
    {
        dataRequest: (requestBody, cardAccountApplicationUUID) => ({
            requestBody,
            cardAccountApplicationUUID,
        }),
    },
);
export const updateCardAccountApplication =
    updateCardAccountApplicationActions.dataRequest;
export const updateCardAccountApplicationSuccess =
    updateCardAccountApplicationActions.dataSuccess;
export const updateCardAccountApplicationError =
    updateCardAccountApplicationActions.dataError;
export const updateCardAccountApplicationReset =
    updateCardAccountApplicationActions.dataReset;

const updateCardAccountApplicationForOnboardingActions =
    createDataModuleActions('UPDATE_CARD_ACCOUNT_APPLICATION_FOR_ONBOARDING', {
        dataRequest: (requestBody, cardAccountApplicationUUID) => ({
            requestBody,
            cardAccountApplicationUUID,
        }),
    });
export const updateCardAccountApplicationForOnboarding =
    updateCardAccountApplicationForOnboardingActions.dataRequest;
export const updateCardAccountApplicationForOnboardingSuccess =
    updateCardAccountApplicationForOnboardingActions.dataSuccess;
export const updateCardAccountApplicationForOnboardingError =
    updateCardAccountApplicationForOnboardingActions.dataError;
export const updateCardAccountApplicationForOnboardingReset =
    updateCardAccountApplicationForOnboardingActions.dataReset;

export const processCardApplication = (requestBody, state) => ({
    type: CARD_PROCESS_APPLICATION,
    payload: {
        requestBody,
        state,
    },
});

export const processCardApplicationAddress = (address) => ({
    type: CARD_PROCESS_APPLICATION_ADDRESS,
    payload: {
        streetAddr: `${address.streetAddr} ${address.addressLine2}`,
        city: address.city,
        state: address.state,
        zipcode: address.zipcode,
    },
});
