import {
    BRANDS_ROOT,
    CATEGORY_ROOT,
    COLLECTIONS_ROOT,
    NEW_ARRIVALS_ROOT,
    GIFTS_COLLECTIONS_ROOT,
} from '@perpay-web/storefront/constants/paths';

export const categoryMenuDefaultAdapter = (value) =>
    `${CATEGORY_ROOT}/${value}`;

export const categoryMenuBrandAdapter = (value) => `${BRANDS_ROOT}/${value}`;

export const categoryMenuCollectionAdapter = (value) =>
    `${COLLECTIONS_ROOT}/${value}`;

export const categoryMenuNewArrivalsAdapter = (value) =>
    `${NEW_ARRIVALS_ROOT}/${value || ''}`;

export const categoryMenuGiftsCollectionAdapter = (value) => {
    const encodeParams = encodeURIComponent(`0:${value * 10}`);
    return `${GIFTS_COLLECTIONS_ROOT}?price=${encodeParams}`;
};
