// Visible for testing only
export function getCookieHelper(documentCookie, cookieName) {
    const name = `${cookieName}=`;
    const decodedCookies = decodeURIComponent(documentCookie);
    const cookies = decodedCookies.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.slice(name.length).replace(/^"|"$/g, '');
        }
    }
    return '';
}

export function getCookie(cookieName) {
    return getCookieHelper(document.cookie, cookieName);
}

function defaultSetter(value) {
    document.cookie = value;
}

// Visible for testing only
export function setCookieHelper(
    cookieName,
    value,
    host,
    expiry,
    suffix = '',
    setter = defaultSetter,
) {
    const assignment = `${cookieName}=${encodeURIComponent(value)}`;
    const expires = `expires=${expiry}`;
    const domain = `domain=.${host}`;
    const path = 'path=/';

    const attributes = [
        assignment,
        expires,
        domain,
        path,
        ...(suffix ? [suffix] : []),
    ];

    setter(attributes.join(';'));
}

const EXPIRES_NEVER = 'Fri, 31 Dec 9999 23:59:59 UTC';

export function setCookie(cookieName, value, host) {
    const suffix = 'SameSite=None; Secure';
    setCookieHelper(cookieName, value, host, EXPIRES_NEVER, suffix);
}

const DELETE_VALUE = '';

const EXPIRES_IMMEDIATELY = 'Thu, 01 Jan 1970 00:00:00 UTC';

export function expireCookie(host, cookieName) {
    setCookieHelper(cookieName, DELETE_VALUE, host, EXPIRES_IMMEDIATELY);
}
