import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importOneTimePaymentPortalContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "OneTimePaymentPortalContainer" */
                '@perpay-web/fintech/components/containers/OneTimePaymentPortalContainer'
            ),
    );
