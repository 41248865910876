import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './ProgressBar.scss';

const ProgressBar = ({ steps, currentStep = 0, cardRelated = false }) => (
    <div
        className={getClassName(
            styles['progress-container'],
            cardRelated && styles['card-related'],
        )}
        style={{
            '--steps-count': steps,
        }}
    >
        {Array.from({ length: steps }, (_, index) => (
            <div
                key={index}
                className={getClassName(
                    styles.step,
                    index < currentStep - 1 && styles.passed,
                    index === currentStep - 1 && styles.active,
                )}
            >
                <div className={styles['step-bar']} />
            </div>
        ))}
    </div>
);

export default ProgressBar;
