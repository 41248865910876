export const SELECT_PAYMENT_METHOD_STEP = 'select-payment-method';
export const SELECT_BANK_STEP = 'select-bank';
export const CREATE_BANK_STEP = 'create-bank';
export const SELECT_FREQUENCY_STEP = 'select-frequency';
export const SELECT_DATE_STEP = 'select-date';
export const SELECT_AMOUNT_STEP = 'select-amount-step';
export const SUCCESS_STEP = 'success';

export const CARD_CREATE_AUTO_PAY_STEPS = [
    SELECT_PAYMENT_METHOD_STEP,
    SELECT_BANK_STEP,
    CREATE_BANK_STEP,
    SELECT_FREQUENCY_STEP,
    SELECT_DATE_STEP,
    SELECT_AMOUNT_STEP,
    SUCCESS_STEP,
];
