import { centsToDollar } from '@perpay-web/utils/numberUtils';
import {
    getRemainingSteps,
    getOrderTotal,
    getOrderCredit,
} from '@perpay-web/fintech/selectors/entities/orders';
import {
    getCheckoutOrderUUID,
    getSkipIdentityVerification,
} from '@perpay-web/fintech/selectors/ui/checkout';
import {
    BILLING_INFORMATION,
    PAYMENT_OPTIONS,
    PAYSTUB,
    SIGNATURE,
    TERMS_AND_CONDITIONS,
    EMPLOYEE_ID,
    PERSONAL_INFORMATION,
    STEPS_FOR_PROGRESS_PERCENT,
} from '@perpay-web/fintech/constants/steps/checkoutSteps';

// Get the list of page-like checkout steps that the user
// must complete to finish the checkout flow
function getOrderedRemainingCheckoutSteps(state) {
    // Combine multiple order requirements into a single step where needed
    // and order the resulting checkout steps
    function orderStepsToOrderedRemainingCheckoutSteps(orderSteps) {
        const checkoutStepsRemaining = {};
        for (let i = 0; i < orderSteps.length; i += 1) {
            const orderStep = orderSteps[i];
            if (orderStep === 'dateOfBirth' || orderStep === 'socialSecurity') {
                checkoutStepsRemaining[PERSONAL_INFORMATION] = true;
            } else {
                checkoutStepsRemaining[orderStep] = true;
            }
        }
        const checkoutStepOrder = [
            BILLING_INFORMATION,
            PAYMENT_OPTIONS,
            SIGNATURE,
            EMPLOYEE_ID,
            PERSONAL_INFORMATION,
            PAYSTUB,
            TERMS_AND_CONDITIONS,
        ];
        const orderedRemainingCheckoutSteps = [];
        for (let i = 0; i < checkoutStepOrder.length; i += 1) {
            const checkoutStep = checkoutStepOrder[i];
            if (checkoutStepsRemaining[checkoutStep]) {
                orderedRemainingCheckoutSteps.push(checkoutStep);
            }
        }
        return orderedRemainingCheckoutSteps;
    }

    const orderUUID = getCheckoutOrderUUID(state);
    const orderSteps = getRemainingSteps(state, orderUUID);
    return orderStepsToOrderedRemainingCheckoutSteps(orderSteps);
}

export function getProgress(state) {
    // Returns a number from 0 to 1 representing
    // percent progress through the checkout flow
    // this continues progress from the storefront which
    // ends at 25% complete.
    //
    // (1/3) / ( (1/3) + 1 ) = .25
    // So, to make the progress pick up from 25%, we pretend that
    // an additional 1/3 * perpayCheckoutSteps exist and that they were completed
    const perpayCheckoutSteps = STEPS_FOR_PROGRESS_PERCENT.length;
    const storefrontEffectiveSteps = perpayCheckoutSteps * (1 / 3);
    const totalSteps = perpayCheckoutSteps + storefrontEffectiveSteps;

    const numberRemaining = getOrderedRemainingCheckoutSteps(state).length;
    const progress = (totalSteps - numberRemaining) / totalSteps;
    return progress;
}

// Return the order amount of the current checkout formatted like 1.23
export function getOrderAmountWithoutCredit(state) {
    const orderUUID = getCheckoutOrderUUID(state);
    const orderTotal = getOrderTotal(state, orderUUID);
    const orderCredit = getOrderCredit(state, orderUUID);
    const amountWithoutCredits = orderTotal - orderCredit;
    return centsToDollar(amountWithoutCredits);
}

export function getStepsRemainingMaybeSkippingIDV(state) {
    const skipIDV = getSkipIdentityVerification(state);
    const remainingSteps = getOrderedRemainingCheckoutSteps(state);
    if (skipIDV && remainingSteps.indexOf(PERSONAL_INFORMATION) >= 0) {
        remainingSteps.splice(remainingSteps.indexOf(PERSONAL_INFORMATION), 1);
    }
    return remainingSteps;
}
