export const MAX_VALUES_PER_FACET = 300;
/**
 * This is a sorted list of brands in descending priority i.e. Apple is top priority.
 * This is the order we should see brands in the brand preview in the filter drawer.
 *
 * Sourced from:
 * https://docs.google.com/spreadsheets/d/14bDt_wjn3HBJRgjrJFq34l4rXaLURn3I5_5Tl4sC8QM/edit#gid=1589018209
 */
export const PRIORITY_BRANDS = [
    'Apple',
    'Sony',
    'HP',
    'Samsung',
    'Signature Design by Ashley',
    'Nintendo',
    'Microsoft',
    'MARC JACOBS',
    'Beats by Dr. Dre',
    'Meta',
    'Insignia',
    'Nike',
    'Bissell',
    'MICHAEL KORS',
    'GUCCI',
    'DeWalt',
    'Ninja',
    'Google',
    'Dyson',
    'Contixo',
    'Amazon',
    'TCL',
    'JBL',
    'Crosley',
    'LG',
    'Coach',
    'Ugg',
    'Cricut',
    'Valve',
    'Sierra Sleep',
    'Tory Burch',
    'Canon',
    'Shark',
    'Dooney & Bourke',
    'Ring',
    'MCM',
    'Bellavita',
    'Hamilton Beach',
    'JHS',
    'YVES SAINT LAURENT',
    'GlareWheel',
    'Royal Gourmet',
    'Rachael Ray',
    'Cuisinart',
    'Toshiba',
    'Ray-Ban',
    'GoPro',
    'CHRISTIAN DIOR',
    'Oakley',
    'Roku',
    'KitchenAid',
    'Fraser Hill Farm',
    'Hanover',
    'Black & Decker',
    'Melissa & Doug',
    'MSI',
    'Techni Mobili',
    'Bose',
    'Lugz',
    'Befree Sound',
    'Logitech',
    'Keurig',
    'Garmin',
    'Crislu',
    'Breville',
    'Megachef',
    'Surya',
    'Pandora',
    'Arctic Wind',
    'Toucan Vupoint',
    'Golden Nugget Jewelers',
    'Techni Sport',
    'Goodyear',
    'BARBIE',
    'DOLCE & GABBANA',
    'JIMMY CHOO',
    'Ai Power',
    'Best Ride on Cars',
    'Yeti',
    'Casio',
    'Hisense',
    'Cambridge',
    'Adidas',
    'Carolina Herrera',
    'Ayesha Curry',
    'Versace Eros',
    'LEGO',
    'Seagate',
    'Crosswind',
    'Stamina',
    'Magic Chef',
    'Motorola',
    'BURBERRY',
    'Astro Gaming',
    'Bella',
    'Supersonic',
    'iRobot',
    'Lasko',
    'InUSA',
    'Baxton Studio',
    'Pioneer',
];
