import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    uploadCardDocuments,
    uploadCardDocumentsSuccess,
    uploadCardDocumentsError,
    uploadCardDocumentsReset,
} from '@perpay-web/fintech/actions/entities/cardDocuments';

const UploadCardDocumentsDataModule = createDataModule({
    dataRequest: uploadCardDocuments,
    dataSuccess: uploadCardDocumentsSuccess,
    dataError: uploadCardDocumentsError,
    dataReset: uploadCardDocumentsReset,
});

const getRoot = (state) => state.dataModules.uploadCardDocuments;

const initialValue = {};

export const uploadCardDocumentsDataModule = UploadCardDocumentsDataModule({
    getRoot,
    initialValue,
});
