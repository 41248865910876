import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [FullBottomNavComponentStackProvider, useFullBottomNavContext] =
    createContextProvider(componentStackProviderFactory);

export { FullBottomNavComponentStackProvider };

export const useFullBottomNav = (renderCb) =>
    useComponentStack(useFullBottomNavContext(), renderCb);

export const useFullBottomNavRender = () =>
    useComponentStackRender(useFullBottomNavContext());
