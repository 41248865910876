import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchFeaturesForOnboardingActions = createDataModuleActions(
    'FETCH_FEATURES_FOR_ONBOARDING',
);
export const fetchFeaturesForOnboarding =
    fetchFeaturesForOnboardingActions.dataRequest;
export const fetchFeaturesForOnboardingSuccess =
    fetchFeaturesForOnboardingActions.dataSuccess;
export const fetchFeaturesForOnboardingError =
    fetchFeaturesForOnboardingActions.dataError;
export const fetchFeaturesForOnboardingReset =
    fetchFeaturesForOnboardingActions.dataReset;
