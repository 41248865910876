import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TO_CHARGE,
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TYPE,
    STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_COMPANY_NAME,
    UPDATE_DIRECT_DEPOSIT_CREATE_PRIMARY_JOB,
} from '@perpay-web/fintech/constants/actionTypes';

export const replaceAmountToCharge = (payload) => ({
    type: STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TO_CHARGE,
    payload,
});

export const replaceAmountType = (payload) => ({
    type: STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_AMOUNT_TYPE,
    payload,
});

export const replaceSelfOnboardingCompanyName = (payload) => ({
    type: STORE_UPDATE_DIRECT_DEPOSIT_REPLACE_COMPANY_NAME,
    payload,
});

export const updateDirectDepositCreatePrimaryJob = (payload) => ({
    type: UPDATE_DIRECT_DEPOSIT_CREATE_PRIMARY_JOB,
    payload,
});

const dataModuleActions = createDataModuleActions(
    'SET_STEPS_FOR_UPDATE_DIRECT_DEPOSIT',
);
export const setStepsForUpdateDirectDeposit = dataModuleActions.dataRequest;
export const setStepsForUpdateDirectDepositSuccess =
    dataModuleActions.dataSuccess;
export const setStepsForUpdateDirectDepositError = dataModuleActions.dataError;
export const setStepsForUpdateDirectDepositReset = dataModuleActions.dataReset;
