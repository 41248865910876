import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import { BANK_SCREEN_CREATE_BANK } from '@perpay-web/fintech/constants/actionTypes';

export const bankScreenCreateBank = (payload) => ({
    type: BANK_SCREEN_CREATE_BANK,
    payload,
});

const createBankAccountForCardAutoPayActions = createDataModuleActions(
    'CREATE_BANK_ACCOUNT_FOR_CARD_AUTO_PAY',
);
export const createBankAccountForCardAutoPay =
    createBankAccountForCardAutoPayActions.dataRequest;
export const createBankAccountForCardAutoPaySuccess =
    createBankAccountForCardAutoPayActions.dataSuccess;
export const createBankAccountForCardAutoPayError =
    createBankAccountForCardAutoPayActions.dataError;
export const createBankAccountForCardAutoPayReset =
    createBankAccountForCardAutoPayActions.dataReset;

const fetchBankAccountsForCardAutoPayActions = createDataModuleActions(
    'FETCH_BANK_ACCOUNTS_FOR_CARD_AUTO_PAY',
);
export const fetchBankAccountsForCardAutoPay =
    fetchBankAccountsForCardAutoPayActions.dataRequest;
export const fetchBankAccountsForCardAutoPaySuccess =
    fetchBankAccountsForCardAutoPayActions.dataSuccess;
export const fetchBankAccountsForCardAutoPayError =
    fetchBankAccountsForCardAutoPayActions.dataError;
export const fetchBankAccountsForCardAutoPayReset =
    fetchBankAccountsForCardAutoPayActions.dataReset;
