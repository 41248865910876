import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateJob,
    updateJobSuccess,
    updateJobError,
    updateJobReset,
} from '@perpay-web/fintech/actions/entities/jobs';

const UpdateJobDataModule = createDataModule({
    dataRequest: updateJob,
    dataSuccess: updateJobSuccess,
    dataError: updateJobError,
    dataReset: updateJobReset,
});

const getRoot = (state) => state.dataModules.updateJob;
const initialValue = {};

export const updateJobDataModule = UpdateJobDataModule({
    getRoot,
    initialValue,
});
