import {
    ERROR_STATE,
    LOADING_STATE,
    SUCCESS_STATE,
} from '@perpay-web/data-module/constants';
import { getDataModuleInitialState } from '@perpay-web/data-module/getDataModuleInitialState';
import { createReducer } from '@reduxjs/toolkit';

export const createDataModuleReducer = (
    REQUEST_ACTION,
    ERROR_ACTION,
    SUCCESS_ACTION,
    RESET_ACTION,
    optInitialValue,
) =>
    createReducer(getDataModuleInitialState(optInitialValue), (builder) => {
        builder.addCase(REQUEST_ACTION, (state) => ({
            ...state,
            requestState: LOADING_STATE,
            errors: {},
        }));
        builder.addCase(ERROR_ACTION, (state, action) => ({
            ...state,
            requestState: ERROR_STATE,
            errors: action.payload,
        }));
        builder.addCase(SUCCESS_ACTION, (state, action) => ({
            ...state,
            requestState: SUCCESS_STATE,
            value: action.payload,
        }));
        builder.addCase(RESET_ACTION, () =>
            getDataModuleInitialState(optInitialValue),
        );
    });
