import CustomSharedLink from '@perpay-web/components/base/CustomSharedLink/CustomSharedLink';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './BackButton.scss';

const BackButton = ({ className = '', href, ...rest }) => (
    <CustomSharedLink
        action='link'
        className={getClassName(styles['link-back'], className)}
        href={href}
        {...rest}
    >
        <div className={styles['arrow-back']} />
    </CustomSharedLink>
);

export default BackButton;
