import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getOrCreateRouter } from '@perpay-web/services/router';
import { createAction } from '@reduxjs/toolkit';

export const analyticsLocationChange = createAction(
    'ANALYTICS::LOCATION_CHANGE',
);

export const useTrackLocationChange = (onChange) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = getOrCreateRouter().subscribe((state) => {
            dispatch(
                analyticsLocationChange({
                    location: state.location,
                    action: state.historyAction,
                }),
            );
            if (onChange) onChange(state);
        });

        return unsubscribe;
    }, [dispatch, onChange]);
};
