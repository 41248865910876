import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchMarketplaceDashboardSummaryActions = createDataModuleActions(
    'FETCH_MARKETPLACE_DASHBOARD_SUMMARY',
);
export const fetchMarketplaceDashboardSummary =
    fetchMarketplaceDashboardSummaryActions.dataRequest;
export const fetchMarketplaceDashboardSummarySuccess =
    fetchMarketplaceDashboardSummaryActions.dataSuccess;
export const fetchMarketplaceDashboardSummaryError =
    fetchMarketplaceDashboardSummaryActions.dataError;
export const fetchMarketplaceDashboardSummaryReset =
    fetchMarketplaceDashboardSummaryActions.dataReset;
