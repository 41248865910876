import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { onShow, onHide } from '@intercom/messenger-js-sdk';
import { useIsSemiLarge } from '@perpay-web/hooks/useIsSemiLarge';
import {
    launchSupportTool,
    closeSupportTool,
    updateSupportTool,
    analyticsSelfHelpOpened,
} from '@perpay-web/services/supportTool';
import { authentication } from '@perpay-web/authentication/singleton';

const ICON_PADDING = 20;
const FOOTER_PADDING = 60;
export const useSupportTool = (repositionSupportTool = false) => {
    const dispatch = useDispatch();
    const isSubscribedRef = useRef(false);

    const updateSupportToolHandler = useCallback(
        (payload) => dispatch(updateSupportTool(payload)),
        [dispatch],
    );
    const supportToolLaunched = useCallback(() => {
        dispatch(launchSupportTool());
        dispatch(analyticsSelfHelpOpened());
    }, [dispatch]);
    const supportToolClosed = useCallback(
        () => dispatch(closeSupportTool()),
        [dispatch],
    );

    // make support tool shift up if it intersects with storefront footer
    useEffect(() => {
        updateSupportToolHandler({
            vertical_padding:
                ICON_PADDING + (repositionSupportTool ? FOOTER_PADDING : 0),
        });
    }, [repositionSupportTool, updateSupportToolHandler]);

    // show/hide support tool based on screen size
    const { isSemiLarge } = useIsSemiLarge();
    useEffect(() => {
        updateSupportToolHandler({ hide_default_launcher: !isSemiLarge });
    }, [isSemiLarge, updateSupportToolHandler]);

    // Subscribe to support tool events
    const isAuthenticated = authentication.getIsAuthenticated();
    useEffect(() => {
        // subscribe to listeners only after user is authenticated
        if (!isSubscribedRef.current && isAuthenticated) {
            onShow(() => {
                supportToolLaunched();
            });
            onHide(() => {
                supportToolClosed();
            });
            isSubscribedRef.current = true;
        }
    }, [supportToolLaunched, supportToolClosed, isAuthenticated]);
};
