import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importFacebook = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "facebook" */
                '@perpay-web/fintech/middleware/facebook'
            ),
    );
