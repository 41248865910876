import {
    BACKEND_CREATE_PAYSTUB,
    STORE_REPLACE_PAYSTUB,
    BACKEND_PAYSTUB_START_POLLING,
    BACKEND_PAYSTUB_POLLING_COMPLETE,
    BACKEND_PAYSTUB_CANCEL_POLLING,
    STORE_PAYSTUB_POLLING_ERROR,
    ANALYTICS_APPLICATION_UPLOAD_PAYSTUB,
} from '@perpay-web/fintech/constants/actionTypes';

export function createPaystub(file, checkoutUuid) {
    return {
        type: BACKEND_CREATE_PAYSTUB,
        payload: { file, checkoutUuid },
    };
}

export function replacePaystub(payload) {
    return {
        type: STORE_REPLACE_PAYSTUB,
        payload,
    };
}

export function paystubStartPolling(paystubUUID) {
    return {
        type: BACKEND_PAYSTUB_START_POLLING,
        payload: { paystubUUID },
    };
}

export function paystubPollingComplete() {
    return {
        type: BACKEND_PAYSTUB_POLLING_COMPLETE,
    };
}

export function paystubCancelPolling() {
    return {
        type: BACKEND_PAYSTUB_CANCEL_POLLING,
    };
}

export function paystubPollingError(errorDegree, userMessage) {
    return {
        type: STORE_PAYSTUB_POLLING_ERROR,
        payload: {
            errorDegree,
            userMessage,
        },
    };
}

export function analyticsApplicationUploadPaystub(orderUUID) {
    return {
        type: ANALYTICS_APPLICATION_UPLOAD_PAYSTUB,
        payload: { orderUUID },
    };
}
