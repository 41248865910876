import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './LoginForm.scss';

const LoginBanner = (className = '') => (
    <div className={getClassName(styles['login-banner'], className)}>
        Your information already exists in our system. Please log in to continue
        applying for the Perpay Credit Card.
    </div>
);

export default LoginBanner;
