export function centsToDollar(cents) {
    return cents / 100.0;
}

export function dollarToCents(dollar) {
    return Math.round(dollar * 100);
}

/**
 * Receives a number and formats it into a compact short suffix string:
 * ```js
 * format2DigitCompactShortSuffix(100) // "100"
 * format2DigitCompactShortSuffix(1000) // "1K"
 * format2DigitCompactShortSuffix(11500) // "11.5K"
 * format2DigitCompactShortSuffix(11530) // "11.5K"
 * format2DigitCompactShortSuffix(1000000) // "1M"
 * ```
 */
export function format1DigitCompactShortSuffix(number) {
    // Use the toLocaleString method to add suffixes to the number
    return number.toLocaleString('en-US', {
        // add suffixes for thousands, millions, and billions
        // the maximum number of decimal places to use
        maximumFractionDigits: 1,
        // specify the abbreviations to use for the suffixes
        notation: 'compact',
        compactDisplay: 'short',
    });
}

export function extractNumberFromString(input) {
    // Use regex to match all digits in the input string
    const digits = input.match(/\d+\.?\d*/g);

    // If digits were found, join them into a single string and convert to a number
    if (digits) {
        return Number(digits[0]);
    }

    // If no digits were found, return NaN
    return NaN;
}
