import { centsToDollar } from '@perpay-web/utils/numberUtils';
import {
    formatCurrencyFloor,
    formatCurrencyCeil,
} from '@perpay-web/utils/stringUtils';
import {
    AUTHENTICATED,
    REFRESHING,
} from '@perpay-web/fintech/reducers/authentication';
import { getOrFallback } from '@perpay-web/fintech/utils/objectUtils';

export const getIsAuthenticated = (state) =>
    state.authentication.tokenState === AUTHENTICATED ||
    state.authentication.tokenState === REFRESHING;

export const getJWTData = (state) => {
    const payload = (state.authentication.access || {}).payload || {};
    const repaymentOptions = payload.payment_options || [];
    const maxPayOption = repaymentOptions[repaymentOptions.length - 1] || {};
    const spendingLimit = centsToDollar(payload.total_spending_limit);
    const availableLimit = centsToDollar(
        Math.max(payload.available_spending_limit, 0),
    );
    const outstandingBalance = centsToDollar(payload.outstanding_balance);
    return {
        uuid: payload.user_uuid,
        email: payload.email || '',
        firstName: payload.first_name || '',
        lastName: payload.last_name || '',
        fullName: payload.first_name
            ? `${payload.first_name} ${payload.last_name}`
            : '',
        spendingLimit,
        spendingLimitRounded: formatCurrencyCeil(spendingLimit, false, false),
        spendingLimitType: payload.spending_limit_type,
        availableLimit,
        availableLimitRounded: formatCurrencyFloor(
            availableLimit,
            false,
            false,
        ),
        outstandingBalance,
        outstandingBalanceRounded: formatCurrencyCeil(
            outstandingBalance,
            false,
            false,
        ),
        accountBalance: centsToDollar(
            (payload.account_balance || {}).total || 0,
        ),
        minimumPayment: centsToDollar(payload.minimum_payment || 0),
        maxPays: maxPayOption.number_of_payments || 8,
        perpayCredits: centsToDollar((payload.perpay_credits || {}).total || 0),
        signupRequirements: payload.signup_requirements || [],
        intercomHash: payload.intercom_hash,
    };
};

export const getLoginLoading = (state) => state.authentication.loginLoading;

export const getErrors = (state) => state.authentication.errors || {};

export const getPrimaryJobUUID = (state) =>
    getOrFallback(
        ['authentication', 'access', 'payload', 'job_uuid'],
        state,
        '',
    );

export const getResetPasswordPath = (state) => {
    const authState = state.authentication || {};
    const passwordState = authState.password || {};
    return passwordState.resetRedirectPath;
};

export const getResetPasswordError = (state) => {
    const authState = state.authentication || {};
    const passwordState = authState.password || {};
    return passwordState.resetError;
};

export const getResetPasswordLoading = (state) =>
    state.authentication.resetLoading;

export const getIsRecaptchaActive = (state) =>
    state.authentication.recaptchaActive || false;

export const getIsExistingUser = (state) =>
    state.authentication.isExistingUser || false;

export const getMFA = (state) => state.authentication.mfa || {};
