import {
    BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS,
    STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS,
    STORE_RESET_ACCOUNT_DEACTIVATION_REASONS,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchAccountDeactivationReasons() {
    return {
        type: BACKEND_FETCH_ACCOUNT_DEACTIVATION_REASONS,
    };
}

export function replaceAccountDeactivationReasons(reasons) {
    return {
        type: STORE_REPLACE_ACCOUNT_DEACTIVATION_REASONS,
        payload: reasons,
    };
}

export function resetAccountDeactivationReasons() {
    return {
        type: STORE_RESET_ACCOUNT_DEACTIVATION_REASONS,
    };
}
