import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCheckoutContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "CheckoutContainer" */
                '@perpay-web/fintech/components/containers/CheckoutContainer'
            ),
    );
