import { connect } from 'react-redux';

import {
    resetPassword,
    clearResetPassword,
} from '@perpay-web/fintech/actions/authentication';
import ResetPasswordForm from '@perpay-web/fintech/components/composite/ResetPasswordForm/ResetPasswordForm';
import {
    getResetPasswordError,
    getResetPasswordPath,
    getResetPasswordLoading,
} from '@perpay-web/fintech/selectors/authentication';

const mapStateToProps = (state) => ({
    loading: getResetPasswordLoading(state),
    redirectPath: getResetPasswordPath(state),
    errorMessage: getResetPasswordError(state),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (payload) => dispatch(resetPassword(payload)),
    clearHandler: () => dispatch(clearResetPassword()),
});

// Legacy usage of Redux connect API. New code should not use this pattern
// eslint-disable-next-line @perpay-web/no-redux-connect
const BorrowerResetPassword = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPasswordForm);

export default BorrowerResetPassword;
