import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateCardAccountApplication,
    updateCardAccountApplicationSuccess,
    updateCardAccountApplicationError,
    updateCardAccountApplicationReset,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';

const UpdateCardAccountApplicationDataModule = createDataModule({
    dataRequest: updateCardAccountApplication,
    dataSuccess: updateCardAccountApplicationSuccess,
    dataError: updateCardAccountApplicationError,
    dataReset: updateCardAccountApplicationReset,
});

const getRootUpdateCardAccountApplication = (state) =>
    state.dataModules.updateCardAccountApplication;

const initialValueUpdateCardAccountApplication = {};

export const updateCardAccountApplicationDataModule =
    UpdateCardAccountApplicationDataModule({
        getRoot: getRootUpdateCardAccountApplication,
        initialValue: initialValueUpdateCardAccountApplication,
    });
