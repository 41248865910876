import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    cancelAutoPaySchedule,
    cancelAutoPayScheduleSuccess,
    cancelAutoPayScheduleError,
    cancelAutoPayScheduleReset,
} from '@perpay-web/fintech/actions/ui/autoPaySchedules';

const CancelAutoPayScheduleDataModule = createDataModule({
    dataRequest: cancelAutoPaySchedule,
    dataSuccess: cancelAutoPayScheduleSuccess,
    dataError: cancelAutoPayScheduleError,
    dataReset: cancelAutoPayScheduleReset,
});

const getRoot = (state) => state.dataModules.cancelAutoPaySchedule;
const initialValue = {};

export const cancelAutoPayScheduleDataModule = CancelAutoPayScheduleDataModule({
    getRoot,
    initialValue,
});
