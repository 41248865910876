import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { useSignupRedirectCheck } from '@perpay-web/fintech/hooks/useSignupRedirectCheck';

export function withSignupRedirectCheck(Component) {
    const SignupRedirectCheckWrapper = (props) => {
        const { isLoadingSignupRedirectCheck } = useSignupRedirectCheck();

        if (isLoadingSignupRedirectCheck) {
            return <WarningLoaderCircle />;
        }

        return <Component {...props} />;
    };

    SignupRedirectCheckWrapper.displayName = 'SignupRedirectCheckWrapper';

    return SignupRedirectCheckWrapper;
}
