import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importIdentityVerificationContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "IdentityVerificationContainer" */
                '@perpay-web/fintech/components/containers/IdentityVerificationContainer'
            ),
    );
