import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { fetchCardStatementsDataModule } from '@perpay-web/fintech/dataModules/fetchCardStatements';
import { gateCardAccountDataModule } from '@perpay-web/fintech/dataModules/gateCardAccount';

const CardStatementsDataModule = composeDataModules([
    fetchCardStatementsDataModule,
    gateCardAccountDataModule,
]);

const getRoot = (state) => state.dataModules.cardStatements;

export const cardStatementsDataModule = CardStatementsDataModule({
    getRoot,
    key: 'CARD_STATEMENTS',
});

export { getCardStatements } from '@perpay-web/fintech/dataModules/fetchCardStatements';
