import { Observable, of, concat } from 'rxjs';
import { catchError, mergeAll } from 'rxjs/operators';

export const handleError = (cb) =>
    catchError((error, source$) => {
        const result = cb(error);

        let errorAction$;
        if (result instanceof Observable) {
            errorAction$ = result;
        } else {
            errorAction$ = of(result).pipe(mergeAll());
        }

        return concat(errorAction$, source$);
    });
