import {
    ERROR,
    UNSET,
} from '@perpay-web/fintech/components/composite/OrderReview/loanDecision';
import {
    BACKEND_CHECKOUT_LOAN_DECISION_GENERATE,
    STORE_CHECKOUT_REPLACE_LOAN_DECISION,
    STORE_CHECKOUT_LOAN_DECISION_GENERATE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: false,
    decision: UNSET,
    errors: {},
};

const orderReviewReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_CHECKOUT_LOAN_DECISION_GENERATE:
            return {
                ...state,
                loading: true,
                decision: UNSET,
                errors: {},
            };
        case STORE_CHECKOUT_REPLACE_LOAN_DECISION:
            return {
                ...state,
                loading: false,
                decision: payload.decision,
                errors: {},
            };
        case STORE_CHECKOUT_LOAN_DECISION_GENERATE_ERROR:
            return {
                ...state,
                loading: false,
                decision: ERROR,
                errors: payload.error,
            };
        default:
            return state;
    }
};

export default orderReviewReducer;
