import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    gateCardAccount,
    gateCardAccountSuccess,
    gateCardAccountError,
    resetGateCardAccount,
} from '@perpay-web/fintech/actions/entities/gateCardAccount';

const GateCardAccountDataModule = createDataModule({
    dataRequest: gateCardAccount,
    dataSuccess: gateCardAccountSuccess,
    dataError: gateCardAccountError,
    dataReset: resetGateCardAccount,
});

const getRoot = (state) => state.dataModules.gateCardAccount;
const initialValue = {};

export const gateCardAccountDataModule = GateCardAccountDataModule({
    getRoot,
    initialValue,
});
