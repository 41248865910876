import {
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    isLoading: false,
    cardArrivalDateISO: '',
};

const userStatusReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case BACKEND_FETCH_USER_STATUS:
            return { ...state, isLoading: true };
        case STORE_REPLACE_USER_STATUS_ERROR:
            return { ...state, isLoading: false };
        case STORE_REPLACE_USER_STATUS:
            return {
                ...state,
                ...payload,
                isLoading: false,
                cardArrivalDateISO: payload.metadata.cardArrivalDateIso,
            };
        default:
            return state;
    }
};

export default userStatusReducer;
