import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { BACKEND_CREATE_PAYMENT_PLAN } from '@perpay-web/fintech/constants/actionTypes';
import { PAYMENT_PLANS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    createPaymentPlanSuccess,
    createPaymentPlanErrors,
} from '@perpay-web/fintech/actions/entities/paymentPlans';

export function createPaymentPlan(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_PAYMENT_PLAN),
        exhaustMap((action) => post(PAYMENT_PLANS_ENDPOINT, action.payload)),
        mergeMap(() => [createPaymentPlanSuccess()]),
        handleErrorMessageWithFallback((error) => [
            createPaymentPlanErrors(error),
        ]),
    );
}
