import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchPayrollInstructionsDataModule,
    getDirectDepositFormUrl,
} from '@perpay-web/fintech/dataModules/fetchPayrollInstructions';
import {
    fetchPinwheelABTestDataModule,
    getPinwheelABTestEnabled,
} from '@perpay-web/fintech/dataModules/fetchPinwheelABTest';
import {
    fetchJobsDataModule,
    getIsCompanyUnverified,
} from '@perpay-web/fintech/dataModules/fetchJobs';

const DirectDepositUpdateManualPaymentSetupDataModule = composeDataModules([
    fetchJobsDataModule,
    fetchPayrollInstructionsDataModule,
    fetchPinwheelABTestDataModule,
]);

const getRoot = (state) =>
    state.dataModules.directDepositUpdateManualPaymentSetup;

export const directDepositUpdateManualPaymentSetupDataModule =
    DirectDepositUpdateManualPaymentSetupDataModule({
        getRoot,
        key: 'DIRECT_DEPOSIT_UPDATE_MANUAL_PAYMENT_SETUP',
    });

export const getIsPinwheelEligible = (state) => {
    const isCompanyUnverified = getIsCompanyUnverified(state);
    if (isCompanyUnverified) return true;
    return getPinwheelABTestEnabled(state);
};

export { getDirectDepositFormUrl };
