export function fetchStripeToken(number, exp, cvc) {
    return window.stripePromise.then(
        (Stripe) =>
            new Promise((resolve) => {
                Stripe.card.createToken(
                    { number, exp, cvc },
                    (status, response) => {
                        resolve(response);
                    },
                );
            }),
    );
}
