import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createCardAccountApplication,
    createCardAccountApplicationSuccess,
    createCardAccountApplicationError,
    createCardAccountApplicationReset,
} from '@perpay-web/fintech/actions/entities/cardAccountApplications';

const CreateCardAccountApplicationsDataModule = createDataModule({
    dataRequest: createCardAccountApplication,
    dataSuccess: createCardAccountApplicationSuccess,
    dataError: createCardAccountApplicationError,
    dataReset: createCardAccountApplicationReset,
});

const getRootCreateCardAccountApplication = (state) =>
    state.dataModules.createCardAccountApplications;
const initialValueCreateCardAccountApplication = {};

export const createCardAccountApplicationsDataModule =
    CreateCardAccountApplicationsDataModule({
        getRoot: getRootCreateCardAccountApplication,
        initialValue: initialValueCreateCardAccountApplication,
    });
