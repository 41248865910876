import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchLearnMore,
    fetchLearnMoreSuccess,
    fetchLearnMoreError,
    fetchLearnMoreReset,
} from '@perpay-web/fintech/actions/entities/learnMore';

const FetchLearnMoreDataModule = createDataModule({
    dataRequest: fetchLearnMore,
    dataSuccess: fetchLearnMoreSuccess,
    dataError: fetchLearnMoreError,
    dataReset: fetchLearnMoreReset,
});

const getRoot = (state) => state.dataModules.fetchLearnMore;
const initialValue = {
    showLearnMore: false,
};
export const fetchLearnMoreDataModule = FetchLearnMoreDataModule({
    getRoot,
    initialValue,
});

export const getShowLearnMore = (state) =>
    fetchLearnMoreDataModule.getData(state).showLearnMore ||
    initialValue.showLearnMore;
