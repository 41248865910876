import {
    BACKEND_CREATE_EMPLOYMENT,
    BACKEND_UPDATE_EMPLOYMENT,
    STORE_REPLACE_PRIMARY_JOB,
    STORE_CREATE_PRIMARY_JOB_ERROR,
    STORE_UPDATE_EMPLOYMENT_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    errors: {},
    submitting: false,
};

const employmentReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case BACKEND_CREATE_EMPLOYMENT:
        case BACKEND_UPDATE_EMPLOYMENT:
            return { ...state, submitting: true, errors: {} };
        case STORE_REPLACE_PRIMARY_JOB:
        case STORE_UPDATE_EMPLOYMENT_SUCCESS:
            return { ...state, submitting: false, errors: {} };
        case STORE_CREATE_PRIMARY_JOB_ERROR:
            return { ...state, submitting: false, errors: payload };
        default:
            return state;
    }
};

export default employmentReducer;
