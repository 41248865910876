import { useContext } from 'react';

import {
    getTestIdProps,
    EndToEndTestIDContext,
} from '@perpay-web/utils/endToEndTestIds';

export const useEndToEndTestId = () => {
    const testId = useContext(EndToEndTestIDContext);
    return getTestIdProps(testId);
};
