export const MINIMUM_PAYMENT = 'minimum_payment';
export const STATEMENT_BALANCE = 'statement_balance';
export const TOTAL_BALANCE = 'total_balance';
export const FIXED_AMOUNT = 'fixed_amount';

export const AMOUNT_TYPE_MAP = {
    [MINIMUM_PAYMENT]: 'minimum payment due',
    [STATEMENT_BALANCE]: 'statement balance',
    [TOTAL_BALANCE]: 'total balance',
    [FIXED_AMOUNT]: 'other amount',
};
