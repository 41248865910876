import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import { setInitialSelectedFrequency } from '@perpay-web/fintech/actions/ui/cardCreateAutoPay';
import { AUTO_PAY_SCHEDULE_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    fetchAutoPaySchedules as fetchAutoPaySchedulesAction,
    fetchAutoPaySchedulesSuccess,
    fetchAutoPaySchedulesError,
    createAutoPaySchedule as createAutoPayScheduleAction,
    createAutoPayScheduleSuccess,
    createAutoPayScheduleError,
    updateAutoPaySchedule as updateAutoPayScheduleAction,
    updateAutoPayScheduleSuccess,
    updateAutoPayScheduleError,
} from '@perpay-web/fintech/actions/entities/autoPaySchedules';
import {
    WEEKLY,
    ONE_TIME,
} from '@perpay-web/fintech/constants/autoPayScheduleFrequencyTypes';

const getOneTimeAutoPayments = (scheduledPayments) =>
    scheduledPayments.filter(
        (autoPaySchedule) => autoPaySchedule.schedule === ONE_TIME,
    );
const getInitialFrequency = (scheduledPayments) => {
    // Need to ensure that the number of scheduled one-time payments is a most 2
    // If there are 2 or more shceduled one-time payments we want to set the scheduled frequency to WEEKLY for the form
    if (getOneTimeAutoPayments(scheduledPayments).length >= 2) {
        return WEEKLY;
    }
    return ONE_TIME;
};

export const fetchAutoPaySchedules = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchAutoPaySchedulesAction().type),
        exhaustMap(() => emitOrTimeout(get(AUTO_PAY_SCHEDULE_ENDPOINT))),
        mergeMap((results) => [
            setInitialSelectedFrequency(getInitialFrequency(results.response)),
            fetchAutoPaySchedulesSuccess({ all: results.response }),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchAutoPaySchedulesError(error),
        ]),
    );

export const createAutoPaySchedule = (action$, state$, { post }) =>
    action$.pipe(
        ofType(createAutoPayScheduleAction().type),
        exhaustMap((action) =>
            emitOrTimeout(post(AUTO_PAY_SCHEDULE_ENDPOINT, action.payload)),
        ),
        mergeMap((results) => [createAutoPayScheduleSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            createAutoPayScheduleError(error),
        ]),
    );

export const updateAutoPaySchedule = (action$, state$, { patch }) =>
    action$.pipe(
        ofType(updateAutoPayScheduleAction().type),
        exhaustMap((action) => {
            const { uuid, requestBody } = action.payload;
            return emitOrTimeout(
                patch(`${AUTO_PAY_SCHEDULE_ENDPOINT}${uuid}/`, requestBody),
            );
        }),
        mergeMap((results) => [updateAutoPayScheduleSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            updateAutoPayScheduleError(error),
        ]),
    );
