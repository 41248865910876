import { useCallback } from 'react';
import { noop } from '@perpay-web/utils/noop';

import styles from './InputError.scss';

export const InputError = ({ error, name }) => {
    const onErrorClick = useCallback(
        (e) => {
            const container = e.currentTarget.parentNode;
            const input = container.querySelector(`[name="${name}"]`);
            input.focus();
        },
        [name],
    );
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={styles['error-message']}
            onKeyDown={noop}
            onClick={onErrorClick}
        >
            {error}
        </div>
    );
};
