import { useState } from 'react';
import { isSemiLargeScreen } from '@perpay-web/utils/responsiveUtils';
import { windowSubjects } from '@perpay-web/services/windowSubjects';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';

export const useIsSemiLarge = () => {
    const [isSemiLarge, setIsSemiLarge] = useState(isSemiLargeScreen());

    useMountAndUnmount(() => {
        const resizeSubscription = windowSubjects.resize.subscribe(() => {
            const newIsSemiLarge = isSemiLargeScreen(window.innerWidth);
            setIsSemiLarge(newIsSemiLarge);
        });

        return () => resizeSubscription.unsubscribe();
    });

    return { isSemiLarge };
};
