import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import {
    fetchAddressDetailsForDataModule as fetchAddressDetailsForDataModuleAction,
    replaceAddressDetails,
    addressDetailsError,
    resetAddressDetails,
} from '@perpay-web/fintech/actions/ui/addressDetails';
import { GOOGLE_PLACE_DETAILS_URL } from '@perpay-web/fintech/constants/urls';
import { GOOGLE_PLACES_KEY } from '@perpay-web/fintech/constants/serviceCredentials';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';
import { handleError } from '@perpay-web/observable/operators/handleError';

export const fetchAddressDetailsForDataModule = (
    action$,
    state$,
    { jsonFetch },
) =>
    action$.pipe(
        ofType(fetchAddressDetailsForDataModuleAction().type),
        switchMap((action) => {
            const { placeId, firstName, lastName, phoneNumber, email } =
                action.payload;
            if (!placeId) {
                return [resetAddressDetails({})];
            }
            const url = new URL(GOOGLE_PLACE_DETAILS_URL + placeId);
            const params = url.searchParams;
            params.append('fields', 'address_components');

            const config = { headers: { 'X-Goog-Api-Key': GOOGLE_PLACES_KEY } };

            return jsonFetch(url, {}, HTTPMethod.get, config).then(
                (results) => [results, firstName, lastName, phoneNumber, email],
            );
        }),
        switchMap(([data, firstName, lastName, phoneNumber, email]) => {
            // This request returns a hideous beast, so we need to format the response
            const simplifiedObject = data.addressComponents.reduce(
                (acc, component) => {
                    component.types.forEach((type) => {
                        acc[type] = {
                            longText: component.longText,
                            shortText: component.shortText,
                        };
                    });
                    return acc;
                },
                {},
            );

            const streetNumberLong =
                (simplifiedObject.street_number &&
                    simplifiedObject.street_number.longText) ||
                '';
            const routeLong =
                (simplifiedObject.route && simplifiedObject.route.longText) ||
                '';
            const premiseLong =
                (simplifiedObject.premise &&
                    simplifiedObject.premise.longText) ||
                '';
            const street1 =
                `${streetNumberLong} ${routeLong}`.trim() || premiseLong;

            const street2 =
                (simplifiedObject.subpremise &&
                    simplifiedObject.subpremise.longText) ||
                '';
            const city =
                (simplifiedObject.locality &&
                    simplifiedObject.locality.longText) ||
                '';
            const state =
                (simplifiedObject.administrative_area_level_1 &&
                    simplifiedObject.administrative_area_level_1.shortText) ||
                '';
            const zipcode =
                (simplifiedObject.postal_code &&
                    simplifiedObject.postal_code.longText) ||
                '';

            const address = {
                street1,
                street2,
                city,
                state,
                zipcode,
                firstName,
                lastName,
                phoneNumber,
                email,
            };

            return [
                replaceAddressDetails({
                    addressDetails: address,
                }),
            ];
        }),
        handleError((e) => [addressDetailsError(e)]),
    );
