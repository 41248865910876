import { useMemo } from 'react';
import { FormikProvider, useFormik } from 'formik';

import Alert, { AlertSeverity } from '@perpay-web/components/base/Alert/Alert';
import CustomButton from '@perpay-web/components/base/CustomButton/CustomButton';
import FocusContent from '@perpay-web/components/composite/FocusLayoutV2/FocusContent';
import FocusFooter from '@perpay-web/components/composite/FocusLayoutV2/FocusFooter';
import FocusLayoutV2 from '@perpay-web/components/composite/FocusLayoutV2/FocusLayoutV2';
import NonFieldErrors from '@perpay-web/components/base/NonFieldErrors/NonFieldErrors';
import { Stack } from '@perpay-web/components/design-system/Stack/Stack';
import { EndToEndTestIdProvider } from '@perpay-web/utils/endToEndTestIds';
import CustomLink from '@perpay-web/fintech/components/base/CustomLink/CustomLink';
import RecaptchaContainer from '@perpay-web/fintech/components/containers/RecaptchaContainer';
import { paths } from '@perpay-web/fintech/props/appPaths';
import ResponsiveHeader from '@perpay-web/fintech/components/base/ResponsiveHeader/ResponsiveHeader';
import { useIsMobile } from '@perpay-web/hooks/useIsMobile';
import FormikPasswordInput from '@perpay-web/fintech/components/base/PasswordInput/FormikPasswordInput';
import FormikTextInput from '@perpay-web/components/base/TextInput/FormikTextInput';

import { loginFormSchema } from '@perpay-web/fintech/validators/formBased';
import { validateForm } from '@perpay-web/validators/formBased';
import styles from './LoginForm.scss';
import LoginBanner from './LoginBanner';

const LoginForm = ({
    onSubmit,
    errors = {
        email: [],
        password: [],
        message: [],
    },
    isLoading = false,
    mfa = {},
    initialValues = {
        email_or_phone: '',
        password: '',
    },
    isExistingUser = false,
}) => {
    const { isMobile } = useIsMobile();

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            // TODO: Find a better way to pass the value from the recaptcha componenet
            // through to the Formik component. This works for now.
            const [recaptcha] = document.getElementsByName(
                'g-recaptcha-response',
            );
            const value = (recaptcha && recaptcha.value) || '';

            const isEmail = values.email_or_phone.includes('@');

            const emailOrPhone =
                isEmail || values.email_or_phone.startsWith('+1')
                    ? values.email_or_phone
                    : `+1${values.email_or_phone}`;

            const newValues = {
                ...values,
                recaptchaResponse: value,
                email_or_phone: emailOrPhone,
            };
            onSubmit(newValues);
        },
        validate: (values) => validateForm(values, loginFormSchema),
        validateOnChange: false,
    });

    const mfaAlert = useMemo(
        () =>
            mfa && mfa.error && mfa.error.throttle ? (
                <Alert
                    content={mfa.error.throttle.message[0]}
                    severity={AlertSeverity.DANGER}
                    className={styles['throttle-alert']}
                />
            ) : null,
        [mfa],
    );

    return (
        <EndToEndTestIdProvider testId='login-form'>
            {!isMobile && mfaAlert}
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <FocusLayoutV2 className={styles['focus-layout']}>
                        <FocusContent>
                            {isMobile && mfaAlert}
                            {isMobile && isExistingUser && <LoginBanner />}
                            <ResponsiveHeader className={styles.title}>
                                Login to Perpay
                            </ResponsiveHeader>
                            <Stack className={styles['inner-stack']}>
                                <EndToEndTestIdProvider testId='login-email-input'>
                                    <FormikTextInput
                                        label='Email address or phone number'
                                        placeholder='me@example.com or 5551234567'
                                        name='email_or_phone'
                                        type='text'
                                        inputMode='email'
                                        errors={errors}
                                    />
                                </EndToEndTestIdProvider>
                                <EndToEndTestIdProvider testId='login-password-input'>
                                    <FormikPasswordInput
                                        name='password'
                                        errors={errors}
                                    />
                                </EndToEndTestIdProvider>
                                <CustomLink href={paths.resetPassword.path}>
                                    Forgot password?
                                </CustomLink>
                                <NonFieldErrors errors={errors} />
                                <RecaptchaContainer
                                    className={styles.recaptcha}
                                />
                            </Stack>
                        </FocusContent>
                        <FocusFooter>
                            <EndToEndTestIdProvider testId='login-button'>
                                <CustomButton
                                    action='primary'
                                    loading={isLoading}
                                    type='submit'
                                >
                                    Login
                                </CustomButton>
                            </EndToEndTestIdProvider>
                            <p className={styles.signup}>
                                No account yet?{' '}
                                <EndToEndTestIdProvider testId='sign-up-button'>
                                    <CustomLink href={paths.signUp.path}>
                                        Sign up
                                    </CustomLink>
                                </EndToEndTestIdProvider>
                            </p>
                        </FocusFooter>
                    </FocusLayoutV2>
                </form>
            </FormikProvider>
        </EndToEndTestIdProvider>
    );
};

export default LoginForm;
