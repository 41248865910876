import {
    TURN_OFF_DIRECT_DEPOSIT_STEP,
    REGAIN_CARD_ACCESS_STEP,
    UPDATE_EMPLOYER_AND_AMOUNT_STEP,
    DELINQUENT_LATE_STEP,
} from '@perpay-web/fintech/constants/steps/updateDirectDepositSteps';

import {
    CARD_SUSPENDED,
    CORE_LATE,
    CORE_DELINQUENT,
    TURN_OFF,
} from '@perpay-web/fintech/constants/directDepositStatuses';

export const getStepFromStatus = (status) => {
    switch (status) {
        case CARD_SUSPENDED:
            return REGAIN_CARD_ACCESS_STEP;
        case CORE_LATE:
            return DELINQUENT_LATE_STEP;
        case CORE_DELINQUENT:
            return DELINQUENT_LATE_STEP;
        case TURN_OFF:
            return TURN_OFF_DIRECT_DEPOSIT_STEP;
        default:
            return UPDATE_EMPLOYER_AND_AMOUNT_STEP;
    }
};
