import { switchMap, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchGeneralAccountSummary as fetchGeneralAccountSummaryAction,
    replaceGeneralAccountSummary,
    fetchGeneralAccountSummaryError,
} from '@perpay-web/fintech/actions/shared/generalAccountSummary';
import { GENERAL_ACCOUNT_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchGeneralAccountSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchGeneralAccountSummaryAction().type),
        switchMap(() => get(GENERAL_ACCOUNT_SUMMARY_ENDPOINT)),
        mergeMap((results) => [replaceGeneralAccountSummary(results.response)]),
        handleErrorMessageWithFallback((error) => [
            fetchGeneralAccountSummaryError(error),
        ]),
    );
