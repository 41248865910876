import { CardAddAddressModalProvider } from '@perpay-web/fintech/hooks/useCardAddAddressModalContext';
import { CardAutoPayMakePaymentModalProvider } from '@perpay-web/fintech/hooks/useCardAutoPayMakePaymentModalContext';
import { CardCancelAutoPayScheduleModalProvider } from '@perpay-web/fintech/hooks/useCardCancelAutoPayScheduleModalContext';
import { PartnerOnboardingSignupIneligibleStateModalProvider } from '@perpay-web/fintech/hooks/usePartnerOnboardingSignupIneligibleStateModalContext';
import { PartnerOnboardingExistingUserModalProvider } from '@perpay-web/fintech/hooks/usePartnerOnboardingExistingUserModalContext';
import { createCombinedProvider } from '@perpay-web/utils/componentUtils';

const ModalProviders = createCombinedProvider(
    CardAutoPayMakePaymentModalProvider,
    CardAddAddressModalProvider,
    CardCancelAutoPayScheduleModalProvider,
    PartnerOnboardingSignupIneligibleStateModalProvider,
    PartnerOnboardingExistingUserModalProvider,
);

export default ModalProviders;
