import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap } from 'rxjs/operators';

import { BACKEND_FETCH_ACCOUNT_DEACTIVATION_ELIGIBILITY } from '@perpay-web/fintech/constants/actionTypes';
import { USER_DEACTIVATION_ELIGIBILITY_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { replaceAccountDeactivationEligibility } from '@perpay-web/fintech/actions/entities/accountDeactivationEligibility';

export function getAccountDeactivationEligibility(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_ACCOUNT_DEACTIVATION_ELIGIBILITY),
        exhaustMap(() => get(USER_DEACTIVATION_ELIGIBILITY_ENDPOINT)),
        mergeMap((results) => [
            replaceAccountDeactivationEligibility(
                results.response.deactivationEligible,
            ),
        ]),
    );
}
