import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createFeatureEnrollment,
    createFeatureEnrollmentSuccess,
    createFeatureEnrollmentError,
    createFeatureEnrollmentReset,
} from '@perpay-web/fintech/actions/entities/featureEnrollments';

const CreateFeatureEnrollmentDataModule = createDataModule({
    dataRequest: createFeatureEnrollment,
    dataSuccess: createFeatureEnrollmentSuccess,
    dataError: createFeatureEnrollmentError,
    dataReset: createFeatureEnrollmentReset,
});

const getRoot = (state) => state.dataModules.createFeatureEnrollment;
const initialValue = {};

export const createFeatureEnrollmentDataModule =
    CreateFeatureEnrollmentDataModule({
        getRoot,
        initialValue,
    });
