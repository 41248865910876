import {
    SEGMENT_MIDDLEWARE_READY,
    FACEBOOK_MIDDLEWARE_READY,
} from '@perpay-web/fintech/constants/actionTypes';

export const segmentMiddlewareReady = () => ({
    type: SEGMENT_MIDDLEWARE_READY,
});

export const facebookMiddlewareReady = () => ({
    type: FACEBOOK_MIDDLEWARE_READY,
});
