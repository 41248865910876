import { forwardRef } from 'react';
import { SHOP_LAST_PAGE_COOKIE } from '@perpay-web/constants/cookies';
import { getCookie, expireCookie } from '@perpay-web/utils/cookieUtils';
import { pathIsInternalToApp } from '@perpay-web/utils/routeUtils';
import CustomSharedLink from '@perpay-web/components/base/CustomSharedLink/CustomSharedLink';
import { useEndToEndTestId } from '@perpay-web/hooks/useEndToEndTestId';
import { PROD_HOST, MAGENTO_URL } from '@perpay-web/constants/urls';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { useNavigate } from 'react-router-dom';

const ANALYTICS_REQUEST_DELAY_MS = 300;

const CustomLink = forwardRef(
    (
        {
            onClick: ownPropsOnClick,
            children = null,
            href,
            state: unusedState = null,
            target = null,
            className = '',
            action = 'inline-link',
            size = '',
            ...rest
        },
        ref,
    ) => {
        const navigate = useNavigate();
        let linkHref = href;

        const onClick = (navigateTo) => {
            if (ownPropsOnClick) {
                ownPropsOnClick();
            }
            if (target !== '_blank') {
                navigate(navigateTo);
            }
        };

        if (linkHref === MAGENTO_URL) {
            const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);

            if (shopLastPageCookie) {
                linkHref = shopLastPageCookie;
            }
        }
        const endToEndTestIDParams = useEndToEndTestId();

        const handleClick = (event) => {
            const shopLastPageCookie = getCookie(SHOP_LAST_PAGE_COOKIE);
            if (linkHref.startsWith(MAGENTO_URL) && shopLastPageCookie) {
                expireCookie(PROD_HOST, SHOP_LAST_PAGE_COOKIE);
            }
            if (
                (window.PRODUCTION || window.STAGING) &&
                linkHref.startsWith(MAGENTO_URL) &&
                !authentication.getIsAccessTokenExpired()
            ) {
                const accessToken = authentication.getAccessToken();
                // Synchronously navigate if we do not need to wait for an analytics onClick
                if (!onClick) {
                    const { target: linkElement } = event;
                    // TODO: Remove once Magento does not need jwt in url
                    linkElement.href = `${MAGENTO_URL}jwt?access=${accessToken}&next=${linkHref}`;
                    return;
                }

                // If an onClick is present, then we delay the redirect to give any analytics in
                // the onClick a little extra time to successfully complete.
                // Based on the Segment analytics.js approach to link tracking
                // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
                event.preventDefault();
                onClick(linkHref);
                setTimeout(() => {
                    // TODO: Remove jwt once Magento does not need jwt in url
                    window.location.href = `${MAGENTO_URL}jwt?access=${accessToken}&next=${linkHref}`;
                }, ANALYTICS_REQUEST_DELAY_MS);
                return;
            }

            if (event.metaKey || event.ctrlKey) {
                // Allow normal link behavior to continue
                onClick(linkHref);
                return;
            }

            // Let external links be handled by the browser
            // Internal links will be handled in the onClick handler
            if (!pathIsInternalToApp(linkHref)) {
                onClick(linkHref);
                return;
            }

            event.preventDefault();
            onClick(linkHref);
        };

        return (
            <CustomSharedLink
                ref={ref}
                href={linkHref}
                onClick={handleClick}
                target={target}
                className={className}
                action={action}
                size={size}
                {...endToEndTestIDParams}
                {...rest}
            >
                {children}
            </CustomSharedLink>
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
