import { ofType } from 'redux-observable';
import { mergeMap, withLatestFrom } from 'rxjs/operators';

import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { STORE_CARD_CONFIRM_EMPLOYER_NEXT_STEP } from '@perpay-web/fintech/constants/actionTypes';
import {
    getIsAddressComplete,
    getSsnIsNull,
    getAddress,
} from '@perpay-web/fintech/dataModules/crud/userInfoForCardOnboardingMVP2';
import { getABTestEnabled } from '@perpay-web/fintech/dataModules/fetchABTests';
import { METAL_CARD_STORE_NAME } from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import {
    CARD_ONBOARDING_MVP_2_STEPS,
    ADDRESS_REJECTED,
    ADD_ADDRESS_STEP,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';

import statesIneligibleForCard from '@perpay-web/fintech/constants/statesIneligibleForCardCardSignupMVP';

export const cardConfirmEmployerSetNextStep = (action$, state$) =>
    action$.pipe(
        ofType(STORE_CARD_CONFIRM_EMPLOYER_NEXT_STEP),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const isAddressComplete = getIsAddressComplete(state);
            const isSsnIsNull = getSsnIsNull(state);
            const address = getAddress(state);

            const isMetalCardFlow =
                METAL_CARD_IS_LAUNCHED &&
                getABTestEnabled(state, METAL_CARD_STORE_NAME);
            const reduxKey = isMetalCardFlow
                ? Object.values(metalSteps)
                : CARD_ONBOARDING_MVP_2_STEPS;

            let reduxStep = isMetalCardFlow
                ? metalSteps.ADD_ADDRESS_STEP
                : ADD_ADDRESS_STEP;
            if (
                isAddressComplete &&
                !isSsnIsNull &&
                statesIneligibleForCard.includes(address.state)
            ) {
                reduxStep = isMetalCardFlow
                    ? metalSteps.ADDRESS_REJECTED_STEP
                    : ADDRESS_REJECTED;
            }

            return [reduxStepsSetStep(reduxKey, reduxStep)];
        }),
    );
