import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchAddressSuggestionsActions = createDataModuleActions(
    'FETCH_ADDRESS_SUGGESTIONS',
);
export const fetchAddressSuggestionsForDataModule =
    fetchAddressSuggestionsActions.dataRequest;
export const replaceAddressSuggestions =
    fetchAddressSuggestionsActions.dataSuccess;
export const addressSuggestionsError = fetchAddressSuggestionsActions.dataError;
export const resetAddressSuggestions = fetchAddressSuggestionsActions.dataReset;
