import { getClassName } from '@perpay-web/utils/domUtils';
import styles from './PasswordPill.scss';

export const PasswordPill = ({ label, checked }) => (
    <div
        className={getClassName(
            styles['password-pill'],
            checked && styles['password-pill--checked'],
        )}
    >
        <span
            className={getClassName(
                styles['pill-icon'],
                checked && styles['pill-icon--checked'],
            )}
        />
        <span className={styles['pill-label']}>{label}</span>
    </div>
);
