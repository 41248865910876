import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchTransactionDetails as fetchTransactionDetailsAction,
    replaceTransactionDetails,
    setTransactionDetailsError,
} from '@perpay-web/fintech/actions/entities/transactionDetails';
import { getStandardResultsSetPaginatedEndpoint } from '@perpay-web/fintech/epics/utils/pagination';
import { TRANSACTION_DETAILS_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchTransactionDetails = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchTransactionDetailsAction().type),
        mergeMap((action) => {
            const { payload } = action;

            return get(
                getStandardResultsSetPaginatedEndpoint({
                    endpoint: TRANSACTION_DETAILS_ENDPOINT,
                    pageSize: payload.pageSize,
                    pageNumber: payload.pageNumber,
                }),
            );
        }),
        mergeMap((results) => [replaceTransactionDetails(results.response)]),
        handleErrorMessageWithFallback((error) => [
            setTransactionDetailsError(error),
        ]),
    );
