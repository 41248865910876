import { createDataModuleActions as createDataModuleActionsUtil } from '@perpay-web/data-module/createDataModuleActions';

const createDataModuleActions = createDataModuleActionsUtil(
    'CREATE_FEATURE_ENROLLMENT_FOR_PPP',
);
export const createFeatureEnrollmentForPerpayPlus =
    createDataModuleActions.dataRequest;
export const createFeatureEnrollmentForPerpayPlusSuccess =
    createDataModuleActions.dataSuccess;
export const createFeatureEnrollmentForPerpayPlusError =
    createDataModuleActions.dataError;
export const createFeatureEnrollmentForPerpayPlusReset =
    createDataModuleActions.dataReset;

const fetchDataModuleActions = createDataModuleActionsUtil(
    'FETCH_FEATURE_ENROLLMENT_FOR_PPP',
);
export const fetchFeatureEnrollmentsForPerpayPlus =
    fetchDataModuleActions.dataRequest;
export const fetchFeatureEnrollmentsForPerpayPlusSuccess =
    fetchDataModuleActions.dataSuccess;
export const fetchFeatureEnrollmentsForPerpayPlusError =
    fetchDataModuleActions.dataError;
export const fetchFeatureEnrollmentsForPerpayPlusReset =
    fetchDataModuleActions.dataReset;
