import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const dataModuleActions = createDataModuleActions(
    'FETCH_INITIATED_AUTO_PAY_PAYMENTS',
);
export const fetchInitiatedAutoPayPayments = dataModuleActions.dataRequest;
export const fetchInitiatedAutoPayPaymentsSuccess =
    dataModuleActions.dataSuccess;
export const fetchInitiatedAutoPayPaymentsError = dataModuleActions.dataError;
export const fetchInitiatedAutoPayPaymentsReset = dataModuleActions.dataReset;
