import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateUserInfoForCardOnboardingMVP2,
    updateUserInfoForCardOnboardingMVP2Success,
    updateUserInfoForCardOnboardingMVP2Error,
    updateUserInfoForCardOnboardingMVP2Reset,
} from '@perpay-web/fintech/actions/entities/userInfo';

const UpdateUserInfoForCardOnboardingMVP2DataModule = createDataModule({
    dataRequest: updateUserInfoForCardOnboardingMVP2,
    dataSuccess: updateUserInfoForCardOnboardingMVP2Success,
    dataError: updateUserInfoForCardOnboardingMVP2Error,
    dataReset: updateUserInfoForCardOnboardingMVP2Reset,
});

const getRoot = (state) =>
    state.dataModules.updateUserInfoForCardOnboardingMVP2;

const initialValue = {};

export const updateUserInfoForCardOnboardingMVP2DataModule =
    UpdateUserInfoForCardOnboardingMVP2DataModule({
        getRoot,
        initialValue,
    });
