import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchPaymentSetupSummaryDataModule,
    getIsInstantShipEligible,
} from '@perpay-web/fintech/dataModules/fetchPaymentSetupSummary';
import {
    fetchABTestsDataModule,
    getABTestEnabled,
} from '@perpay-web/fintech/dataModules/fetchABTests';
import {
    fetchCardAccountsDataModule,
    getIsCardAccountActive,
} from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import { getHasRepaymentOrders } from '@perpay-web/fintech/dataModules/fetchOrders';
import { EARLY_INSTANT_SHIP_MOTIVATOR_STORE_NAME } from '@perpay-web/constants/experiments';

const PinwheelDataModule = composeDataModules([
    fetchABTestsDataModule,
    fetchPaymentSetupSummaryDataModule,
    fetchCardAccountsDataModule,
]);

const getRoot = (state) => state.dataModules.pinwheel;

export const pinwheelDataModule = PinwheelDataModule({
    getRoot,
    key: 'PINWHEEL',
});

export const getIsEarlyInstantShip = (state) => {
    const isInstantShipEligible = getIsInstantShipEligible(state);
    const isCardAccountActive = getIsCardAccountActive(state);
    // fetchOrdersDataModule not included in composed data module fetch as it's
    // fetched higher up the component tree
    const isInRepayment = getHasRepaymentOrders(state);
    const isInEarlyInstantShipTest = getABTestEnabled(
        state,
        EARLY_INSTANT_SHIP_MOTIVATOR_STORE_NAME,
    );
    const isUserPaying = isCardAccountActive || isInRepayment;
    return isInstantShipEligible && isUserPaying && isInEarlyInstantShipTest;
};

export const getIsDefaultInstantShip = (state) => {
    const isEarlyInstantShip = getIsEarlyInstantShip(state);
    const isInstantShipEligible = getIsInstantShipEligible(state);
    return isInstantShipEligible && !isEarlyInstantShip;
};
