import { ofType } from 'redux-observable';
import { map, mergeMap, exhaustMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    replacePayrollInstructions,
    fetchPayrollInstructionsError,
    fetchPayrollInstructionsForDataModule as fetchPayrollInstructionsForDataModuleAction,
    fetchPayrollInstructionsForDataModuleSuccess,
    fetchPayrollInstructionsForDataModuleError,
} from '@perpay-web/fintech/actions/entities/instructions';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { BACKEND_FETCH_PAYROLL_INSTRUCTIONS } from '@perpay-web/fintech/constants/actionTypes';
import { PAYROLL_INSTRUCTIONS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { fetchUserStatus } from '@perpay-web/fintech/actions/entities/userStatus';

const getInstructionsUrl = (action) => {
    const { amount } = action.payload;
    const search = amount ? `?amount=${amount}` : '';
    return `${PAYROLL_INSTRUCTIONS_ENDPOINT}${search}`;
};

export function fetchPayrollInstructions(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_PAYROLL_INSTRUCTIONS),
        exhaustMap((action) =>
            get(getInstructionsUrl(action)).pipe(
                map((results) => [results, action]),
            ),
        ),
        mergeMap(([results, action]) => {
            const { payload } = action;
            const { instructionsType } = results.response;

            const isPayloadStartOrChange =
                payload === 'start' || payload === 'change';
            const isPayloadStop = payload === 'stop';
            const isInstructionsTypeStartOrChange =
                instructionsType === 'start' || instructionsType === 'change';
            const isInstructionsTypeStop = instructionsType === 'stop';

            if (isPayloadStop && isInstructionsTypeStartOrChange) {
                return [fetchUserStatus()];
            }

            if (isPayloadStartOrChange && isInstructionsTypeStop) {
                return [routeToLocation(paths.dashboard.path)];
            }

            return [replacePayrollInstructions(results.response)];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchPayrollInstructionsError(error),
        ]),
    );
}

export function fetchPayrollInstructionsForDataModule(
    action$,
    state$,
    { get },
) {
    return action$.pipe(
        ofType(fetchPayrollInstructionsForDataModuleAction().type),
        exhaustMap((action) =>
            get(getInstructionsUrl(action)).pipe(
                map((results) => [results, action]),
            ),
        ),
        mergeMap(([results]) => [
            fetchPayrollInstructionsForDataModuleSuccess(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchPayrollInstructionsForDataModuleError(error),
        ]),
    );
}
