import { combineReducers } from '@reduxjs/toolkit';

import accountSummaryReducer from './accountSummary';
import abTestReducer from './abTests';

// A reducer for model-like data that isn't keyed on some id
const sharedSummary = combineReducers({
    accountSummary: accountSummaryReducer,
    abTests: abTestReducer,
});

export default sharedSummary;
