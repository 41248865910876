import { combineReducers } from '@reduxjs/toolkit';

/**
 * The ReducerManager class allows new reducers to be added async after load-time.
 * This code is adapted from the Redux documentation
 * https://redux.js.org/recipes/code-splitting
 */
export class ReducerManager {
    constructor(initialReducers) {
        this.reducers = { ...initialReducers };

        this.combinedReducer = combineReducers(this.reducers);

        this.keysToRemove = [];
    }

    createRootReducer(rootReducer) {
        return (state, action) => {
            const newState = rootReducer(state, action);
            return this.reduce(newState, action);
        };
    }

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce(state, action) {
        // If any reducers have been removed, clean up their state first
        if (this.keysToRemove.length > 0) {
            const newState = { ...state };
            this.keysToRemove.forEach((key) => delete newState[key]);
            this.keysToRemove = [];
            return this.combinedReducer(newState, action);
        }

        // Delegate to the combined reducer
        return this.combinedReducer(state, action);
    }

    // Adds a new reducer with the specified key
    add(key, reducer) {
        if (!key || this.reducers[key]) {
            return;
        }

        // Add the reducer to the reducer mapping
        this.reducers[key] = reducer;

        // Generate a new combined reducer
        this.combinedReducer = combineReducers(this.reducers);
    }

    // Removes a reducer with the specified key
    remove(key) {
        if (!key || !this.reducers[key]) {
            return;
        }

        // Remove it from the reducer mapping
        delete this.reducers[key];

        // Add the key to the list of keys to clean up
        this.keysToRemove.push(key);

        // Generate a new combined reducer
        this.combinedReducer = combineReducers(this.reducers);
    }
}
