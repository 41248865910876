import {
    BACKEND_CREATE_SURVEY_RESPONSE,
    STORE_CREATE_SURVEY_RESPONSE_SUCCESS,
    STORE_CREATE_SURVEY_RESPONSE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    submittingSurveyResponse: false,
    errors: {},
};

const surveyResponseReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_CREATE_SURVEY_RESPONSE:
            return { ...state, submittingSurveyResponse: true };
        case STORE_CREATE_SURVEY_RESPONSE_SUCCESS:
            return { ...state, submittingSurveyResponse: false };
        case STORE_CREATE_SURVEY_RESPONSE_ERROR:
            return {
                ...state,
                errors: payload,
                submittingSurveyResponse: false,
            };
        default:
            return state;
    }
};

export default surveyResponseReducer;
