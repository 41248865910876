const DEFAULT_LIMIT = 10;
/**
 * Function implements pagination, prepares endpoint by given arguments, returns endpoint.
 * [Wikipedia] Pagination, also known as paging, is the process of dividing a document into discrete pages, either electronic pages or printed pages.
 *
 * @param {string} endpoint Original endpoint.
 * @param {number} limit The entries number in the response.
 * @param {number} offset The entries number to skip.
 *
 * @return {string} generated endpoint.
 */
export const getLimitOffsetPaginatedEndpoint = ({
    endpoint,
    limit = DEFAULT_LIMIT,
    offset = 0,
    ...rest
}) => {
    const paginatedEndpoint = new URL(endpoint);
    paginatedEndpoint.searchParams.append('limit', limit);
    paginatedEndpoint.searchParams.append('offset', offset);
    Object.keys(rest).forEach((key) => {
        paginatedEndpoint.searchParams.append(key, rest[key]);
    });
    return paginatedEndpoint.toString();
};

const DEFAULT_PAGE_SIZE = 9;
const DEFAULT_PAGE_NUMBER = 1;
/**
 * Function implements pagination, prepares endpoint by given arguments, returns endpoint.
 * [Wikipedia] Pagination, also known as paging, is the process of dividing a document into discrete pages, either electronic pages or printed pages.
 *
 * @param {string} endpoint Original endpoint.
 * @param {number} pageSize The entries number in the response.
 * @param {number} pageNumber The entries page number.
 *
 * @return {string} generated endpoint.
 */
export const getStandardResultsSetPaginatedEndpoint = ({
    endpoint,
    pageSize = DEFAULT_PAGE_SIZE,
    pageNumber = DEFAULT_PAGE_NUMBER,
    ...rest
}) => {
    const paginatedEndpoint = new URL(endpoint);
    paginatedEndpoint.searchParams.append('page_size', pageSize);
    paginatedEndpoint.searchParams.append('page', pageNumber);
    Object.keys(rest).forEach((key) => {
        paginatedEndpoint.searchParams.append(key, rest[key]);
    });
    return paginatedEndpoint.toString();
};
