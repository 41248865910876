import { createSlice } from '@reduxjs/toolkit';
import { noop } from '@perpay-web/utils/noop';

const supportToolSlice = createSlice({
    name: 'supportTool',
    initialState: {
        isOpen: false,
        isLoaded: false,
    },
    reducers: (create) => ({
        load: create.reducer((state) => ({
            ...state,
            isLoaded: true,
        })),
        launch: create.reducer((state) => ({
            ...state,
            isOpen: true,
        })),
        update: create.reducer(noop),
        close: create.reducer((state) => ({
            ...state,
            isOpen: false,
        })),
        shutDown: create.reducer((state) => ({
            ...state,
            isOpen: false,
            isLoaded: false,
        })),
        analyticsSelfHelpOpened: create.reducer(noop),
    }),
});

// Actions
export const {
    load: loadSupportTool,
    launch: launchSupportTool,
    update: updateSupportTool,
    close: closeSupportTool,
    shutDown: shutDownSupportTool,
    analyticsSelfHelpOpened,
} = supportToolSlice.actions;

export default supportToolSlice.reducer;
