import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const dataModuleActions = createDataModuleActions('FETCH_REFERRAL_STATS', {
    dataSuccess: (credits, successes) => ({
        credits,
        successes,
    }),
});
export const fetchReferralStats = dataModuleActions.dataRequest;
export const replaceReferralStats = dataModuleActions.dataSuccess;
export const fetchReferralStatsError = dataModuleActions.dataError;
export const resetReferralStats = dataModuleActions.dataReset;
