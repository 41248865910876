import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [TitleComponentStackProvider, useTitleContext] = createContextProvider(
    componentStackProviderFactory,
);
export { TitleComponentStackProvider };

export const useTitle = (renderCb) =>
    useComponentStack(useTitleContext(), renderCb);

export const useTitleRender = () => useComponentStackRender(useTitleContext());
