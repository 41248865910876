import {
    ERROR_COMPANIES,
    FETCH_COMPANIES,
    FETCH_COMPANIES_SELF_ONBOARDING,
    REPLACE_COMPANIES,
    REPLACE_COMPANIES_SELF_ONBOARDING,
    RESET_COMPANIES,
} from '@perpay-web/fintech/constants/actionTypes';
import { createAction } from '@reduxjs/toolkit';

export function fetchCompanies(typeahead) {
    return {
        type: FETCH_COMPANIES,
        payload: { typeahead },
    };
}

export function fetchCompaniesSelfOnboarding(typeahead) {
    return {
        type: FETCH_COMPANIES_SELF_ONBOARDING,
        payload: { typeahead },
    };
}

export function replaceCompanies(payload) {
    return {
        type: REPLACE_COMPANIES,
        payload,
    };
}

export function replaceCompaniesSelfOnboarding(payload) {
    return {
        type: REPLACE_COMPANIES_SELF_ONBOARDING,
        payload,
    };
}

export function resetCompanies() {
    return {
        type: RESET_COMPANIES,
    };
}

export function errorCompanies(error) {
    return {
        type: ERROR_COMPANIES,
        payload: { message: error.message },
    };
}

export const errorSelfOnboardingCompanies = createAction(
    'store/SELF_ONBOARDING_COMPANIES::ERROR',
);
