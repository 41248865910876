import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchGeneralAccountSummary,
    replaceGeneralAccountSummary,
    resetGeneralAccountSummary,
    fetchGeneralAccountSummaryError,
} from '@perpay-web/fintech/actions/shared/generalAccountSummary';

const GeneralAccountSummaryDataModule = createDataModule({
    dataRequest: fetchGeneralAccountSummary,
    dataSuccess: replaceGeneralAccountSummary,
    dataError: fetchGeneralAccountSummaryError,
    dataReset: resetGeneralAccountSummary,
});

const getRoot = (state) => state.dataModules.generalAccountSummary;
const initialValue = {
    minimumPayment: {
        total: 0,
        card: 0,
        core: 0,
    },
    nextDirectDepositDate: '10/12/2022',
    mostRecentDirectDeposit: null,
    accountBalance: 0,
    userStatus: 'good',
    payCycleType: 'bi_weekly',
};

export const generalAccountSummaryDataModule = GeneralAccountSummaryDataModule({
    getRoot,
    initialValue,
});

export const getMinimumPayment = (state) =>
    generalAccountSummaryDataModule.getData(state).minimumPayment.total;

export const getCoreMinimumPayment = (state) =>
    generalAccountSummaryDataModule.getData(state).minimumPayment.core;

export const getCardMinimumPayment = (state) =>
    generalAccountSummaryDataModule.getData(state).minimumPayment.card;

export const getPayCycleType = (state) =>
    generalAccountSummaryDataModule.getData(state).payCycleType;

export const getMostRecentDirectDeposit = (state) =>
    generalAccountSummaryDataModule.getData(state).mostRecentDirectDeposit;

export const getNextDirectDepositDate = (state) =>
    generalAccountSummaryDataModule.getData(state).nextDirectDepositDate;
