import { schema } from 'normalizr';
import {
    BANKS,
    EXTERNAL_CARDS,
    JOBS,
    ORDERS,
    USERINFO,
    PAYSTUBS,
    ORDER_CANCELLATION_REASONS,
    ACCOUNT_DEACTIVATION_REASONS,
    SURVEYS,
    SURVEY_QUESTIONS,
    SURVEY_OPTIONS,
    DEDUCTIONS,
    FEATURES,
    FEATURE_ENROLLMENTS,
} from '@perpay-web/fintech/constants/tableNames';

// TODO: all these normalizers can be loaded async since they're the result of ajax requests
// and not responsible for any initial-render UI

export const bank = new schema.Entity(BANKS, {}, { idAttribute: 'uuid' });
export const externalCard = new schema.Entity(
    EXTERNAL_CARDS,
    {},
    { idAttribute: 'uuid' },
);
export const order = new schema.Entity(ORDERS, {}, { idAttribute: 'uuid' });
export const job = new schema.Entity(JOBS, {}, { idAttribute: 'uuid' });
export const userInfo = new schema.Entity(
    USERINFO,
    {},
    { idAttribute: 'uuid' },
);
export const paystub = new schema.Entity(PAYSTUBS, {}, { idAttribute: 'uuid' });
export const orderCancellationReason = new schema.Entity(
    ORDER_CANCELLATION_REASONS,
    {},
    { idAttribute: 'uuid' },
);
export const accountDeactivationReason = new schema.Entity(
    ACCOUNT_DEACTIVATION_REASONS,
    {},
    { idAttribute: 'uuid' },
);
export const deduction = new schema.Entity(
    DEDUCTIONS,
    {},
    { idAttribute: 'uuid' },
);
const surveyOptions = new schema.Entity(
    SURVEY_OPTIONS,
    {},
    { idAttribute: 'uuid' },
);
const surveyQuestions = new schema.Entity(
    SURVEY_QUESTIONS,
    {
        options: [surveyOptions],
    },
    { idAttribute: 'uuid' },
);
export const survey = new schema.Entity(
    SURVEYS,
    {
        questions: [surveyQuestions],
    },
    { idAttribute: 'uuid' },
);
export const feature = new schema.Entity(FEATURES, {}, { idAttribute: 'uuid' });
export const featureEnrollment = new schema.Entity(
    FEATURE_ENROLLMENTS,
    {},
    { idAttribute: 'uuid' },
);
