import { useScrollReset } from '@perpay-web/hooks/useScrollReset';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './FocusLayoutV2.scss';

/**
 * @param children - could be any JSX elements, but designed for use with accompanying subcomponents, example usage:
 *  <FocusLayoutV2>
        <FocusHeader
            leftButtonElement={<BackButton />}
            rightButtonElement={<InfoButton />}
        />
        <FocusContent>{overflowText}</FocusContent>
        <FocusFooter>{button}</FocusFooter>
    </FocusLayoutV2>
 * @param className - Styling overrides for wrapper, see __public classes in FocusLayoutV2.scss for common cases
 * @param shouldResetScroll - resets scroll to top of page on reload, desired behavior for most of our flows using this layout
 */
const FocusLayoutV2 = ({
    children,
    className = '',
    shouldResetScroll = true,
}) => {
    useScrollReset(shouldResetScroll);

    return (
        <div className={getClassName(styles['focus-layout'], className)}>
            {children}
        </div>
    );
};

export default FocusLayoutV2;
