import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchWithdrawalBalanceV2Actions = createDataModuleActions(
    'FETCH_WITHDRAWAL_BALANCE',
);

export const fetchWithdrawalBalanceV2 =
    fetchWithdrawalBalanceV2Actions.dataRequest;
export const fetchWithdrawalBalanceV2Success =
    fetchWithdrawalBalanceV2Actions.dataSuccess;
export const fetchWithdrawalBalanceV2Error =
    fetchWithdrawalBalanceV2Actions.dataError;
export const fetchWithdrawalBalanceV2Reset =
    fetchWithdrawalBalanceV2Actions.dataReset;
