import { createDataModule } from '@perpay-web/data-module/createDataModule';
import * as actions from '@perpay-web/fintech/actions/entities/payments';

const FetchActivePaymentsDataModule = createDataModule({
    dataRequest: actions.fetchActivePayments,
    dataSuccess: actions.fetchActivePaymentsSuccess,
    dataError: actions.fetchActivePaymentsError,
    dataReset: actions.resetFetchActivePayments,
});

const getRoot = (state) => state.dataModules.fetchActivePayments;
const initialValue = {
    currentPageNumber: 1,
    pageCount: 0,
    results: [],
};

export const fetchActivePaymentsDataModule = FetchActivePaymentsDataModule({
    getRoot,
    initialValue,
});

export const getActivePayments = (state) =>
    fetchActivePaymentsDataModule.getData(state).results;

export const getActivePaymentsPageCount = (state) =>
    fetchActivePaymentsDataModule.getData(state).pageCount;

export const getActivePaymentsPageNumber = (state) =>
    fetchActivePaymentsDataModule.getData(state).currentPageNumber;
