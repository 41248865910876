import { createDataModuleActions as createDataModuleActionsUtil } from '@perpay-web/data-module/createDataModuleActions';

const fetchDataModuleActions = createDataModuleActionsUtil(
    'FETCH_AUTO_PAY_SCHEDULE',
);
export const fetchAutoPaySchedules = fetchDataModuleActions.dataRequest;
export const fetchAutoPaySchedulesSuccess = fetchDataModuleActions.dataSuccess;
export const fetchAutoPaySchedulesError = fetchDataModuleActions.dataError;
export const fetchAutoPaySchedulesReset = fetchDataModuleActions.dataReset;

const createDataModuleActions = createDataModuleActionsUtil(
    'CREATE_AUTO_PAY_SCHEDULE',
);
export const createAutoPaySchedule = createDataModuleActions.dataRequest;
export const createAutoPayScheduleSuccess = createDataModuleActions.dataSuccess;
export const createAutoPayScheduleError = createDataModuleActions.dataError;
export const createAutoPayScheduleReset = createDataModuleActions.dataReset;

const updateDataModuleActions = createDataModuleActionsUtil(
    'UPDATE_AUTO_PAY_SCHEDULE',
);
export const updateAutoPaySchedule = updateDataModuleActions.dataRequest;
export const updateAutoPayScheduleSuccess = updateDataModuleActions.dataSuccess;
export const updateAutoPayScheduleError = updateDataModuleActions.dataError;
export const updateAutoPayScheduleReset = updateDataModuleActions.dataReset;
