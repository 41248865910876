import { Subject, EMPTY } from 'rxjs';

export const createHookActionObservable = () => {
    // The hookAction$ observable exposes the actions from the epic middleware
    // in an Observable that is outside the context of the epic middleware.
    // This allows us to safely access the stream of actions without needing to
    // reference anything internal to the redux-observable middleware.
    const hookActionSubject$ = new Subject();
    const hookAction$ = hookActionSubject$.asObservable();

    // This epic needs to be registered with the root epic to feed
    // the hookActionSubject$ actions from the Redux store.
    const hookActionEpic = (action$) => {
        action$.subscribe(hookActionSubject$);
        return EMPTY;
    };

    return {
        hookAction$,
        hookActionEpic,
    };
};
