import { ANALYTICS_ITERABLE_PUSH_NOTIFICATION_OPEN } from '@perpay-web/fintech/constants/actionTypes';

export const analyticsIterablePushNotificationOpen = (
    email,
    campaignId,
    messageId,
    templateId,
) => ({
    type: ANALYTICS_ITERABLE_PUSH_NOTIFICATION_OPEN,
    payload: {
        email,
        campaignId,
        messageId,
        templateId,
    },
});
