import {
    STORE_CHECKOUT_CREATE_ORDER_ERROR,
    STORE_CHECKOUT_REPLACE_ORDER_UUID,
    STORE_CHECKOUT_NEXT_STEP,
    STORE_CHECKOUT_BACK_STEP,
    STORE_BACK_STEP_FROM_CONFIRM_ORDER,
    STORE_CHECKOUT_RESET_STEP_INDEX,
    BACKEND_CHECKOUT_UPDATE_USER_INFO,
    STORE_CHECKOUT_UPDATE_ORDER_ERROR,
    BACKEND_CHECKOUT_UPDATE_USER_INFO_ERROR,
    BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID,
    BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID_ERROR,
    STORE_RESET_CHECKOUT,
    STORE_CHECKOUT_REPLACE_LOADING_SIGNATURE,
    ANALYTICS_APPLICATION_SUBMIT_PERSONAL_INFO,
    ANALYTICS_APPLICATION_ATTEMPT_IDENTITY_VERIFICATION,
    BACKEND_CHECKOUT_VERIFY_IDENTITY,
    BACKEND_CHECKOUT_VERIFY_IDENTITY_ERROR,
    STORE_CHECKOUT_VERIFY_IDENTITY_SERVICE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function checkoutReplaceOrderUUID(orderUUID) {
    return {
        type: STORE_CHECKOUT_REPLACE_ORDER_UUID,
        payload: orderUUID,
    };
}

export function checkoutNextStep() {
    return {
        type: STORE_CHECKOUT_NEXT_STEP,
    };
}

export function checkoutBackStep() {
    return {
        type: STORE_CHECKOUT_BACK_STEP,
    };
}

export function confirmOrderBackStep() {
    return {
        type: STORE_BACK_STEP_FROM_CONFIRM_ORDER,
    };
}

export function checkoutResetStepIndex() {
    return {
        type: STORE_CHECKOUT_RESET_STEP_INDEX,
    };
}

export function checkoutUpdateUserInfo(payload) {
    return {
        type: BACKEND_CHECKOUT_UPDATE_USER_INFO,
        payload,
    };
}

export function checkoutCreateOrderError(error) {
    return {
        type: STORE_CHECKOUT_CREATE_ORDER_ERROR,
        payload: error,
    };
}

export function checkoutUpdateOrderError(error) {
    return {
        type: STORE_CHECKOUT_UPDATE_ORDER_ERROR,
        payload: error,
    };
}

export function checkoutUpdateUserInfoError(error) {
    return {
        type: BACKEND_CHECKOUT_UPDATE_USER_INFO_ERROR,
        payload: error,
    };
}

export function checkoutUpdateEID(payload) {
    return {
        type: BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID,
        payload,
    };
}

export function checkoutUpdateEIDError(error) {
    return {
        type: BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID_ERROR,
        payload: error,
    };
}

export function resetCheckout() {
    return { type: STORE_RESET_CHECKOUT };
}

export function replaceIsLoadingSignature(payload) {
    return {
        type: STORE_CHECKOUT_REPLACE_LOADING_SIGNATURE,
        payload,
    };
}

export function analyticsApplicationSubmitPersonalInfo(orderUUID) {
    return {
        type: ANALYTICS_APPLICATION_SUBMIT_PERSONAL_INFO,
        payload: { orderUUID },
    };
}

export function analyticsApplicationAttemptIdentityVerification(orderUUID) {
    return {
        type: ANALYTICS_APPLICATION_ATTEMPT_IDENTITY_VERIFICATION,
        payload: { orderUUID },
    };
}

export function checkoutVerifyIdentity(payload) {
    return {
        type: BACKEND_CHECKOUT_VERIFY_IDENTITY,
        payload: {
            ...payload,
            forceIdentityVerification: true,
        },
    };
}

export function checkoutVerifyIdentityError(error) {
    return {
        type: BACKEND_CHECKOUT_VERIFY_IDENTITY_ERROR,
        payload: error,
    };
}

export function checkoutVerifyIdentityServiceError(error) {
    return {
        type: STORE_CHECKOUT_VERIFY_IDENTITY_SERVICE_ERROR,
        payload: error,
    };
}
