import { createDataModule } from '@perpay-web/data-module/createDataModule';
import * as actions from '@perpay-web/fintech/actions/entities/cardActivity';

const FetchCardActivityDataModule = createDataModule({
    dataRequest: actions.fetchCardActivity,
    dataSuccess: actions.fetchCardActivitySuccess,
    dataError: actions.fetchCardActivityError,
    dataReset: actions.resetFetchCardActivity,
});

const getRoot = (state) => state.dataModules.fetchCardActivity;
const initialValue = { all: [], pageCount: 0 };

export const fetchCardActivityDataModule = FetchCardActivityDataModule({
    getRoot,
    initialValue,
});

export const getCardActivity = (state) =>
    fetchCardActivityDataModule.getData(state).all;

export const getPageCount = (state) =>
    fetchCardActivityDataModule.getData(state).pageCount;
