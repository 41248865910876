import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    updateUserInfoForDataModule,
    updateUserInfoSuccess,
    updateUserInfoError,
    updateUserInfoReset,
} from '@perpay-web/fintech/actions/entities/userInfo';

const UpdateUserInfoDataModule = createDataModule({
    dataRequest: updateUserInfoForDataModule,
    dataSuccess: updateUserInfoSuccess,
    dataError: updateUserInfoError,
    dataReset: updateUserInfoReset,
});

const getRoot = (state) => state.dataModules.updateUserInfo;
const initialValue = {};

export const updateUserInfoDataModule = UpdateUserInfoDataModule({
    getRoot,
    initialValue,
});
