import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importReferralLandingContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "ReferralLandingContainer" */
                '@perpay-web/fintech/components/containers/ReferralLandingContainer'
            ),
    );
