import {
    fetchAds,
    replaceAds,
    fetchAdsError,
} from '@perpay-web/fintech/reducers/entities/ads';
import { createSlice } from '@reduxjs/toolkit';

const adsSlice = createSlice({
    name: 'ads',
    initialState: { adsFetching: true },
    extraReducers: (builder) => {
        builder.addCase(fetchAds, () => ({ adsFetching: true }));
        builder.addCase(replaceAds, () => ({ adsFetching: false }));
        builder.addCase(fetchAdsError, () => ({ adsFetching: false }));
    },
});

export const getAdsLoading = (state) => state.ui.ads.adsFetching;

export default adsSlice.reducer;
