import {
    BACKEND_FETCH_ORDER_CANCELLATION_REASONS,
    STORE_REPLACE_ORDER_CANCELLATION_REASONS,
    STORE_RESET_ORDER_CANCELLATION_REASONS,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchOrderCancellationReasons() {
    return {
        type: BACKEND_FETCH_ORDER_CANCELLATION_REASONS,
    };
}

export function replaceOrderCancellationReasons(reasons) {
    return {
        type: STORE_REPLACE_ORDER_CANCELLATION_REASONS,
        payload: reasons,
    };
}

export function resetOrderCancellationReasons() {
    return {
        type: STORE_RESET_ORDER_CANCELLATION_REASONS,
    };
}
