import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_DEDUCTIONS,
    BACKEND_UPDATE_SUCCESS_DEDUCTION,
    BACKEND_UPDATE_SUCCESS_DEDUCTION_ACCOUNT_ID,
    BACKEND_UPDATE_FAILURE_DEDUCTION,
    STORE_REPLACE_DEDUCTION,
    STORE_REPLACE_DEDUCTION_ERROR,
    BACKEND_CREATE_DEDUCTION,
    STORE_DEDUCTION_CLEAR_ERRORS,
    STORE_RESET_DEDUCTIONS,
    BACKEND_UPDATE_RETRYING_DEDUCTION,
} from '@perpay-web/fintech/constants/actionTypes';

const fetchDataModuleActions = createDataModuleActions('FETCH_DEDUCTIONS');
export const fetchDeductionsForDataModule = fetchDataModuleActions.dataRequest;
export const fetchDeductionsSuccess = fetchDataModuleActions.dataSuccess;
export const fetchDeductionsError = fetchDataModuleActions.dataError;
export const resetFetchDeductions = fetchDataModuleActions.dataReset;

export function fetchDeductions() {
    return {
        type: BACKEND_FETCH_DEDUCTIONS,
    };
}

export function updateSuccessDeduction(payload) {
    return {
        type: BACKEND_UPDATE_SUCCESS_DEDUCTION,
        payload,
    };
}

export function updateDeductionAccountId(payload) {
    return {
        type: BACKEND_UPDATE_SUCCESS_DEDUCTION_ACCOUNT_ID,
        payload,
    };
}

export function updateFailureDeduction(error) {
    return {
        type: BACKEND_UPDATE_FAILURE_DEDUCTION,
        payload: error,
    };
}

export function updateRetryingDeduction(error) {
    return {
        type: BACKEND_UPDATE_RETRYING_DEDUCTION,
        payload: error,
    };
}

export function replaceDeduction(payload) {
    return {
        type: STORE_REPLACE_DEDUCTION,
        payload,
    };
}

export function replaceDeductionError(error) {
    return {
        type: STORE_REPLACE_DEDUCTION_ERROR,
        payload: error,
    };
}

export function clearDeductionError() {
    return {
        type: STORE_DEDUCTION_CLEAR_ERRORS,
    };
}

export function createDeduction(payload) {
    return {
        type: BACKEND_CREATE_DEDUCTION,
        payload,
    };
}

export function resetDeductions() {
    return {
        type: STORE_RESET_DEDUCTIONS,
    };
}
