import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    setStepsForUpdateDirectDeposit,
    setStepsForUpdateDirectDepositSuccess,
    setStepsForUpdateDirectDepositError,
    setStepsForUpdateDirectDepositReset,
} from '@perpay-web/fintech/actions/ui/updateDirectDeposit';

const SetStepsForUpdateDirectDepositDataModule = createDataModule({
    dataRequest: setStepsForUpdateDirectDeposit,
    dataSuccess: setStepsForUpdateDirectDepositSuccess,
    dataError: setStepsForUpdateDirectDepositError,
    dataReset: setStepsForUpdateDirectDepositReset,
});

const getRoot = (state) => state.dataModules.setStepsForUpdateDirectDeposit;
const initialValue = {};

export const setStepsForUpdateDirectDepositDataModule =
    SetStepsForUpdateDirectDepositDataModule({
        getRoot,
        initialValue,
    });
