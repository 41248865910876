import { useEffect } from 'react';
import { ofType } from 'redux-observable';

export const useActionObservableCallback = (hookAction$, type, onAction) => {
    useEffect(() => {
        // When the component mounts (or when the type or callback values change)
        // we should subscribe to the hookAction$ Observable and listen for actions
        // with the specified type. When the action with the correct type is received,
        // we should call the callback associated with that action type.
        const subscriber = hookAction$
            .pipe(ofType(type))
            .subscribe((action) => onAction(action.payload));

        // When we need to clean up the subscription, we unsubscribe.
        return () => subscriber.unsubscribe();
    }, [hookAction$, onAction, type]);
};
