import { createExternalCardErrors } from '@perpay-web/fintech/actions/entities/externalCards';
import {
    BACKEND_FETCH_EXTERNAL_CARDS,
    STORE_REPLACE_EXTERNAL_CARDS,
    STORE_RESET_EXTERNAL_CARDS,
    BACKEND_CREATE_EXTERNAL_CARD,
    STORE_CREATE_EXTERNAL_CARD_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    externalCardsFetching: true,
    submitting: false,
    errors: {},
};

const externalCardsReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_EXTERNAL_CARDS:
        case STORE_RESET_EXTERNAL_CARDS:
            return { ...state, externalCardsFetching: true };
        case STORE_REPLACE_EXTERNAL_CARDS:
            return { ...state, externalCardsFetching: false };
        case BACKEND_CREATE_EXTERNAL_CARD:
            return { ...state, submitting: true, errors: {} };
        case STORE_CREATE_EXTERNAL_CARD_SUCCESS:
            return { ...state, submitting: false, errors: {} };
        case createExternalCardErrors().type:
            return { ...state, submitting: false, errors: payload };
        default:
            return state;
    }
};

export default externalCardsReducer;
