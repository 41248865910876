import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchFeaturesForOnboarding,
    fetchFeaturesForOnboardingSuccess,
    fetchFeaturesForOnboardingError,
    fetchFeaturesForOnboardingReset,
} from '@perpay-web/fintech/actions/ui/cardOnboarding';

const FetchFeaturesForOnboardingDataModule = createDataModule({
    dataRequest: fetchFeaturesForOnboarding,
    dataSuccess: fetchFeaturesForOnboardingSuccess,
    dataError: fetchFeaturesForOnboardingError,
    dataReset: fetchFeaturesForOnboardingReset,
});

const getRoot = (state) => state.dataModules.fetchFeaturesForOnboarding;
const initialValue = {};

export const fetchFeaturesForOnboardingDataModule =
    FetchFeaturesForOnboardingDataModule({
        getRoot,
        initialValue,
    });
