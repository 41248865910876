export const PAGE_VIEW = 'pageView';

export function pageView(createEvent) {
    return (action, prevState, nextState) => {
        const eventData = createEvent(action, prevState, nextState);
        return {
            type: PAGE_VIEW,
            payload: {
                path: eventData.path,
                queryString: eventData.queryString,
                method: eventData.method,
            },
        };
    };
}

export const ITERABLE_TRACK_PUSH_OPEN = 'iterableTrackPushOpen';

export function iterableTrackPushOpen(createEvent) {
    return (action, prevState, nextState) => {
        const eventData = createEvent(action, prevState, nextState);
        return {
            type: ITERABLE_TRACK_PUSH_OPEN,
            payload: {
                email: eventData.email,
                campaignId: eventData.campaignId,
                messageId: eventData.messageId,
                templateId: eventData.templateId,
            },
        };
    };
}
