import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { AB_TESTS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    fetchPinwheelABTest as fetchPinwheelABTestAction,
    replacePinwheelABTest,
    fetchPinwheelABTestError,
} from '@perpay-web/fintech/actions/shared/pinwheelABTest';

export function fetchPinwheelABTest(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchPinwheelABTestAction().type),
        mergeMap(() => get(`${AB_TESTS_ENDPOINT}pinwheel/`)),
        mergeMap((results) => [replacePinwheelABTest(results.response)]),
        handleErrorMessageWithFallback((error) => [
            fetchPinwheelABTestError(error),
        ]),
    );
}
