import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCardOnboardingMVP2Container = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "CardOnboardingMVP2Container" */
                '@perpay-web/fintech/components/containers/CardOnboardingMVP2Container'
            ),
    );
