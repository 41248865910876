import {
    STORE_REPLACE_CREDIT_SCORES_SUMMARY,
    STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR,
    STORE_RESET_CREDIT_SCORES_SUMMARY,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const creditScoresSummaryReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_CREDIT_SCORES_SUMMARY:
            return { ...state, ...payload };
        case STORE_FETCH_CREDIT_SCORES_SUMMARY_ERROR:
            return { ...state, error: payload };
        case STORE_RESET_CREDIT_SCORES_SUMMARY:
            return initialState;
        default:
            return state;
    }
};

export default creditScoresSummaryReducer;
