import { wrapRetry } from '@perpay-web/utils/importUtils';
import { REWARDS_AND_CREDITS_V2_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';

export const importRewardsAndCreditsContainer = () =>
    wrapRetry(() =>
        REWARDS_AND_CREDITS_V2_IS_LAUNCHED
            ? import(
                  /* webpackChunkName: "RewardsAndCreditsV2Container" */
                  '@perpay-web/fintech/components/containers/RewardsAndCreditsV2Container'
              )
            : import(
                  /* webpackChunkName: "RewardsAndCreditsContainer" */
                  '@perpay-web/fintech/components/containers/RewardsAndCreditsContainer'
              ),
    );
