export const required = (value) =>
    value ? undefined : ['This is a required field'];

export const phoneNumber = (value) => {
    if (!/^(0|[1-9][0-9]{9})$/i.test(value)) {
        return ['Invalid phone number, must be 10 digits'];
    }
    return undefined;
};

export const zipCodeWithExt = (value) => {
    if (value && !/^([0-9]{5})(-[0-9]{4})?$/i.test(value)) {
        return ['Invalid zipcode format, must be 5 digits'];
    }
    return undefined;
};

// Apply multiple validators, returning an error from the first failed validator
export const combined = (validators) => {
    const valueThroughValidators = (value) => {
        for (let i = 0; i < validators.length; i += 1) {
            const validator = validators[i];
            const error = validator(value);
            if (typeof error !== 'undefined') {
                return error;
            }
        }
        return undefined;
    };
    return valueThroughValidators;
};

export const validateField = (conditional, error) =>
    conditional ? [error] : undefined;
