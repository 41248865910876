import {
    BACKEND_FETCH_PINWHEEL_SURVEY,
    STORE_REPLACE_PINWHEEL_SURVEY,
    STORE_FETCH_PINWHEEL_SURVEY_ERROR,
    STORE_RESET_PINWHEEL_SURVEY,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchPinwheelSurvey() {
    return {
        type: BACKEND_FETCH_PINWHEEL_SURVEY,
    };
}

export function replacePinwheelSurvey(survey) {
    return {
        type: STORE_REPLACE_PINWHEEL_SURVEY,
        payload: survey,
    };
}

export function fetchPinwheelSurveyError(error) {
    return {
        type: STORE_FETCH_PINWHEEL_SURVEY_ERROR,
        payload: error,
    };
}

export function resetPinwheelSurvey() {
    return {
        type: STORE_RESET_PINWHEEL_SURVEY,
    };
}
