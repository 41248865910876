import { createCrudDataModule } from '@perpay-web/data-module/createCrudDataModule';
import { createCardAccountApplicationsDataModule } from '@perpay-web/fintech/dataModules/createCardAccountApplications';
import { fetchCardAccountApplicationDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccountApplications';
import { updateCardAccountApplicationDataModule } from '@perpay-web/fintech/dataModules/updateCardAccountApplications';
import {
    ID_PROOF,
    ADDRESS_PROOF,
} from '@perpay-web/fintech/constants/cardDocumentTypes';

const CardAccountApplicationCrudDataModule = createCrudDataModule({
    create: createCardAccountApplicationsDataModule,
    read: fetchCardAccountApplicationDataModule,
    update: updateCardAccountApplicationDataModule,
});

const getRoot = (state) => state.dataModules.cardAccountApplicationCrud;
export const cardAccountApplicationCrudDataModule =
    CardAccountApplicationCrudDataModule({ getRoot });

const getCardAccountApplication = (state) => {
    const cardAccountApplicationEntities =
        cardAccountApplicationCrudDataModule.getData(state);
    const cardAccountApplication = Object.values(
        cardAccountApplicationEntities,
    )[0];

    return cardAccountApplication;
};

export const getEstimatedCardMinimumPayment = (state) => {
    const cardAccountApplication = getCardAccountApplication(state);
    if (!cardAccountApplication) {
        return 0;
    }
    return cardAccountApplication.estimatedCardMinimumPayment;
};

export const getCardAccountApplicationUuid = (state) => {
    const cardAccountApplication = getCardAccountApplication(state);
    if (!cardAccountApplication) {
        return null;
    }
    return cardAccountApplication.uuid;
};

export const getAPR = (state) => {
    const cardAccountApplication = getCardAccountApplication(state);
    if (!cardAccountApplication) {
        return 0;
    }
    return cardAccountApplication.apr;
};

export const getApplicationCreditLimit = (state) => {
    const cardAccountApplication = getCardAccountApplication(state);
    if (!cardAccountApplication) {
        return 0;
    }
    return cardAccountApplication.creditLimit;
};

const getDocumentRequests = (state) => {
    const cardAccountApplication = getCardAccountApplication(state);
    if (!cardAccountApplication) return [];

    // TODO remove once we find a more permanent solution
    // https://perpay.atlassian.net/browse/CC-483
    // documentRequests should be populating but due to possible delay in
    // Deserve returning these, sometimes they're empty so in this case, we will
    // request the user upload both documents
    if (!cardAccountApplication.documentRequests.length) {
        return [ID_PROOF, ADDRESS_PROOF];
    }

    return cardAccountApplication.documentRequests;
};

export const getIsProofOfIdRequested = (state) => {
    const documentRequests = getDocumentRequests(state);
    return documentRequests.includes(ID_PROOF);
};

export const getIsProofOfAddressRequested = (state) => {
    const documentRequests = getDocumentRequests(state);
    return documentRequests.includes(ADDRESS_PROOF);
};
