import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCreditLimits,
    fetchCreditLimitsSuccess,
    fetchCreditLimitsError,
    fetchCreditLimitsReset,
} from '@perpay-web/fintech/actions/entities/creditLimits';
import {
    NEW,
    ACCEPTED,
} from '@perpay-web/fintech/constants/creditLimitStatuses';

const FetchCreditLimitsDataModule = createDataModule({
    dataRequest: fetchCreditLimits,
    dataSuccess: fetchCreditLimitsSuccess,
    dataError: fetchCreditLimitsError,
    dataReset: fetchCreditLimitsReset,
});

const getRoot = (state) => state.dataModules.fetchCreditLimits;
const initialValue = {
    creditLimits: [],
};

export const fetchCreditLimitsDataModule = FetchCreditLimitsDataModule({
    getRoot,
    initialValue,
});

const getNewCreditLimits = (state) =>
    fetchCreditLimitsDataModule
        .getData(state)
        .creditLimits.filter(({ status }) => status === NEW);

export const getNewCreditLimit = (state) => {
    const newCreditLimits = getNewCreditLimits(state);

    if (!newCreditLimits || newCreditLimits.length === 0) {
        return null;
    }

    // get most recent credit limit if there are multiple new limit offers
    const sortedNewCreditLimits = newCreditLimits.sort(
        (creditLimit1, creditLimit2) =>
            creditLimit2.created.localeCompare(creditLimit1.created),
    );

    return sortedNewCreditLimits[0];
};

export const getNewCreditLimitAmount = (state) => {
    const creditLimit = getNewCreditLimit(state);
    return creditLimit ? creditLimit.limit : 0;
};

export const getNewCreditLimitUuid = (state) => {
    const creditLimit = getNewCreditLimit(state);
    return creditLimit ? creditLimit.uuid : null;
};

export const getNewCardMinimumPayment = (state) => {
    const creditLimit = getNewCreditLimit(state);
    return creditLimit ? creditLimit.cardMinimumPayment : 0;
};

const getAcceptedCreditLimits = (state) =>
    fetchCreditLimitsDataModule
        .getData(state)
        .creditLimits.filter(({ status }) => status === ACCEPTED);

export const getAcceptedCreditLimitExists = (state) => {
    const acceptedCreditLimits = getAcceptedCreditLimits(state);
    return Boolean(acceptedCreditLimits.length);
};

const getAcceptedCreditLimit = (state) => {
    const acceptedCreditLimits = getAcceptedCreditLimits(state);

    if (!acceptedCreditLimits || acceptedCreditLimits.length === 0) {
        return null;
    }

    // get most recent credit limit if there are multiple accepted offers
    const sortedAcceptedCreditLimits = acceptedCreditLimits.sort(
        (creditLimit1, creditLimit2) =>
            creditLimit2.created.localeCompare(creditLimit1.created),
    );

    return sortedAcceptedCreditLimits[0];
};

export const getAcceptedCreditLimitAmount = (state) => {
    const creditLimit = getAcceptedCreditLimit(state);
    return creditLimit ? creditLimit.limit : 0;
};
