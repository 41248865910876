import { useEffect } from 'react';

/*
 * This hook is similar to useMount but it allows onMountCb to return
 * a cleanup callback onUnmountCb.
 *
 * onMountCb is called when the component using the useMountAndUnmount hook mounts.
 * If onMountCb requires any cleanup, it may return a function that executes cleanup code,
 * and the cleanup function will be called when the component unmounts.
 *
 * The raw useEffect hook is still needed if the behavior needs to be triggered more than once.
 *
 * Example Usage:
 * ```
 * const MyComponent({
 *   onMount,
 *   onUnmount,
 * }) => {
 *   useMountAndUnmount(() => {
 *     onMount();
 *     return () => onUnmount();
 *   });
 *   return <p>Hello, world!</p>
 * };
 * ```
 *
 * https://reactjs.org/docs/hooks-effect.html
 */
export const useMountAndUnmount = (onMountCb) =>
    useEffect(() => {
        const onUnmountCb = onMountCb();

        // Return the cleanup function that gets called when the component unmounts.
        return onUnmountCb;

        // We disable the eslint rule for this line because we want `onMountCb` to be called
        // once and only once, even if it changes from props passed down from above.
        // If we added `onMountCb` to this deps array, it would be called 1. on mount and 2. each time
        // the value of this variable changes from props, which is unexpected and different than the
        // behavior of componentDidMount. Basically, "I'm 99% sure I know what I'm doing here"TM
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
