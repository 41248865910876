import { Subject } from 'rxjs';

const WINDOW_RESIZE_EVENT = 'resize';

function configureListeners() {
    const resizeSubject = new Subject();
    const resizeCallback = (e) => resizeSubject.next(e);

    window.addEventListener(WINDOW_RESIZE_EVENT, resizeCallback);

    return { resize: resizeSubject };
}

export const windowSubjects = configureListeners();
