import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const dataModuleActions = createDataModuleActions('FETCH_BORROWER_CREDITS', {
    dataRequest: (creditType, pageNumber) => ({
        creditType,
        pageNumber,
    }),
});
export const fetchBorrowerCredits = dataModuleActions.dataRequest;
export const replaceBorrowerCredits = dataModuleActions.dataSuccess;
export const setBorrowerCreditsError = dataModuleActions.dataError;
export const resetBorrowerCredits = dataModuleActions.dataReset;
