import styles from '@perpay-web/design/styles/constants.scss';

const SMALL_SCREEN_WIDTH = parseInt(styles['small-screen'], 10);
const MEDIUM_SCREEN_WIDTH = parseInt(styles['medium-screen'], 10);
const SEMI_LARGE_SCREEN_WIDTH = parseInt(styles['semi-large-screen'], 10);
const JUMBO_SCREEN_WIDTH = parseInt(styles['jumbo-screen'], 10);

export function isMobileScreen(width) {
    return (width || window.innerWidth) <= SMALL_SCREEN_WIDTH;
}

export function isSemiLargeScreen(width) {
    return (width || window.innerWidth) >= SEMI_LARGE_SCREEN_WIDTH;
}

export function isDesktopScreen(width) {
    return (width || window.innerWidth) >= MEDIUM_SCREEN_WIDTH;
}

export function isJumboScreen(width) {
    return (width || window.innerWidth) >= JUMBO_SCREEN_WIDTH;
}
