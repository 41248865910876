import { switchMap, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchPaymentSetupSummary as fetchPaymentSetupSummaryAction,
    fetchPaymentSetupSummarySuccess,
    fetchPaymentSetupSummaryError,
} from '@perpay-web/fintech/actions/shared/paymentSetupSummary';
import { PAYMENT_SETUP_SUMMARY_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchPaymentSetupSummary = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchPaymentSetupSummaryAction().type),
        switchMap((action) => {
            const shouldFetchInstantShipEligibility = action.payload
                ? action.payload.getIsInstantShipEligible || false
                : false;

            return get(
                `${PAYMENT_SETUP_SUMMARY_ENDPOINT}?get_is_instant_ship_eligible=${shouldFetchInstantShipEligibility}`,
            );
        }),

        mergeMap((results) => [
            fetchPaymentSetupSummarySuccess(results.response),
        ]),
        handleErrorMessageWithFallback((error) => [
            fetchPaymentSetupSummaryError(error),
        ]),
    );
