export const FACEBOOK_PIXEL_ID = window.PRODUCTION
    ? '1510966082448459'
    : '436006366968749';

// They're the same for now
export const SEGMENT_FACEBOOK_PIXEL_ID = window.PRODUCTION
    ? '535379520422681'
    : '535379520422681';

const GOOGLE_RECAPTCHA_SITEKEY_PROD =
    '6LcyQ0gUAAAAAB_6B8kIlvVslzgQz5O4tPLlpPhJ';
const GOOGLE_RECAPTCHA_SITEKEY_TEST =
    '6LchQUgUAAAAAGQ8LxbWt9bSFSV_JKknGA25clqp';
export const GOOGLE_RECAPTCHA_SITEKEY = window.PRODUCTION
    ? GOOGLE_RECAPTCHA_SITEKEY_PROD
    : GOOGLE_RECAPTCHA_SITEKEY_TEST;

export const GOOGLE_PLACES_KEY = 'AIzaSyDX7WbIqiOMqqKrEiR4bpnYCx4wRrm76Sc';

const SEGMENT_WRITE_KEY_PROD = 'ux5NZmgjpEKohgChNmF0coryiLDdrY0s';
const SEGMENT_WRITE_KEY_TEST = 'twN816D63ad5WNfbh6datz4layXinj2c';

export const SEGMENT_WRITE_KEY = window.PRODUCTION
    ? SEGMENT_WRITE_KEY_PROD
    : SEGMENT_WRITE_KEY_TEST;

export const INTERCOM_APP_ID = window.PRODUCTION ? 'pffa6bga' : 'e2nmlkv4';
