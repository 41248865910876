import { useEffect } from 'react';

export const useScrollReset = (enable = true) => {
    useEffect(() => {
        if (!enable) {
            return;
        }

        if (window.STORYBOOK || process.env.JEST_WORKER_ID !== undefined) {
            return;
        }
        window.scrollTo(0, 0);
    }, [enable]);
};
