import {
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_SELECTED_BANK_UUID,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY,
    STORE_CARD_CREATE_AUTO_PAY_SET_INITIAL_SELECTED_FREQUENCY,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE_TYPE,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT,
    STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT_TYPE,
    STORE_CARD_CREATE_AUTO_PAY_RESET_DATE_AND_AMOUNT,
    STORE_CARD_CREATE_AUTO_PAY_RESET,
} from '@perpay-web/fintech/constants/actionTypes';

export const replaceSelectedBankUuid = (uuid) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_SELECTED_BANK_UUID,
    payload: uuid,
});

export const replaceSelectedFrequency = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_FREQUENCY,
    payload,
});

export const setInitialSelectedFrequency = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_SET_INITIAL_SELECTED_FREQUENCY,
    payload,
});

export const replaceSelectedDate = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE,
    payload,
});

export const replaceSelectedDateType = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_DATE_TYPE,
    payload,
});

export const replaceAmount = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT,
    payload,
});

export const replaceSelectedAmountType = (payload) => ({
    type: STORE_CARD_CREATE_AUTO_PAY_REPLACE_AMOUNT_TYPE,
    payload,
});

export const resetCardCreateAutoPayDateAndAmountData = () => ({
    type: STORE_CARD_CREATE_AUTO_PAY_RESET_DATE_AND_AMOUNT,
});

export const resetCardCreateAutoPay = () => ({
    type: STORE_CARD_CREATE_AUTO_PAY_RESET,
});
