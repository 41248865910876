import { ofType } from 'redux-observable';
import { exhaustMap, mergeMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { BACKEND_CREATE_SURVEY_RESPONSE } from '@perpay-web/fintech/constants/actionTypes';
import {
    createSurveyResponseError,
    createSurveyResponseSuccess,
} from '@perpay-web/fintech/actions/entities/surveyResponses';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { resetPinwheelSurvey } from '@perpay-web/fintech/actions/entities/surveys';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { SURVEY_RESPONSES_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export function createSurveyResponse(action$, state$, { post }) {
    return action$.pipe(
        ofType(BACKEND_CREATE_SURVEY_RESPONSE),
        exhaustMap((action) => {
            const requestBody = {
                answers: [
                    {
                        surveyOption: action.payload.option_uuid,
                    },
                ],
            };

            return post(SURVEY_RESPONSES_ENDPOINT, requestBody);
        }),
        mergeMap((results) => [
            createSurveyResponseSuccess(results.response),
            resetPinwheelSurvey(),
            routeToLocation(paths.dashboard.path),
        ]),
        handleErrorMessageWithFallback((error) => [
            createSurveyResponseError(error),
        ]),
    );
}
