import { exhaustMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import {
    PROFILE_CREATE_EXTERNAL_CARD,
    STORE_CREATE_EXTERNAL_CARD_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    createExternalCard,
    createExternalCardErrors,
} from '@perpay-web/fintech/actions/entities/externalCards';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { PROFILE_EXTERNAL_CARDS_ID } from '@perpay-web/fintech/constants/paths';

export const profileCreateExternalCard = (action$, state$) =>
    action$.pipe(
        ofType(PROFILE_CREATE_EXTERNAL_CARD),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createExternalCard(
                        action.payload.cardNumber,
                        action.payload.expiryDate,
                        action.payload.cvc,
                    ),
                ],
                waitFor: [STORE_CREATE_EXTERNAL_CARD_SUCCESS],
                waitForDispatch: () => [
                    routeToLocation(
                        `${paths.profile.path}#${PROFILE_EXTERNAL_CARDS_ID}`,
                    ),
                ],
                // The UI consumes the errors that are stored by the createExternalCard epic.
                errors: [createExternalCardErrors().type],
                errorDispatch: () => [],
                // Timeouts are handled within the card entity epic
            }),
        ),
    );
