import { jwtDecode } from 'jwt-decode';

import { expireCookie, getCookie } from '@perpay-web/utils/cookieUtils';

const TOKEN_KEY = 'perpay.web.token';
const JWT_REFRESH_COOKIE = 'jwt_refresh';

export function expireRefreshCookie(host) {
    const jwtRefreshCookie = getCookie(JWT_REFRESH_COOKIE);
    if (jwtRefreshCookie) {
        expireCookie(host, JWT_REFRESH_COOKIE);
    }
}

export function storeToken(tokens) {
    if (window.localStorage) {
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
    }
}

export function retrieveToken() {
    let token;
    const jwtRefreshCookie = getCookie(JWT_REFRESH_COOKIE);

    if (window.localStorage && window.localStorage.getItem(TOKEN_KEY)) {
        const storageToken =
            JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || undefined;
        token = storageToken ? { ...storageToken } : undefined;
    }

    if (jwtRefreshCookie) {
        const tokenDict = {
            refresh: {
                token: jwtRefreshCookie,
                payload: jwtDecode(jwtRefreshCookie),
            },
        };

        // Use as new refresh default if none exist or take the latest one
        if (
            !token ||
            token.refresh.payload.exp < tokenDict.refresh.payload.exp
        ) {
            token = { ...tokenDict };
            storeToken(token);
        }
    }

    return token;
}

export function removeToken() {
    if (window.localStorage) {
        window.localStorage.removeItem(TOKEN_KEY);
    }
}

export function decodeTokens(tokens) {
    let tokenDict = {};
    if (tokens.access) {
        tokenDict = {
            ...tokenDict,
            access: {
                token: tokens.access,
                payload: jwtDecode(tokens.access),
            },
        };
    }
    if (tokens.refresh) {
        tokenDict = {
            ...tokenDict,
            refresh: {
                token: tokens.refresh,
                payload: jwtDecode(tokens.refresh),
            },
        };
    }
    if (tokens.mfaRequired) {
        tokenDict = {
            mfa: {
                ...tokens,
            },
        };
    }

    return tokenDict;
}

const normalizePhone = (phone) => (phone ? phone.replace(/\+/, '') : phone);

// Extract from the decoded tokens the relevant data for analytics to identify a user
export function getAnalyticsIdentity(decodedTokens, userInfo) {
    if (!decodedTokens || !decodedTokens.access) {
        return {};
    }

    return {
        created: decodedTokens.access.payload.created,
        user_uuid: decodedTokens.access.payload.user_uuid,
        email: decodedTokens.access.payload.email,
        company: decodedTokens.access.payload.company_name,
        first_name: decodedTokens.access.payload.first_name,
        last_name: decodedTokens.access.payload.last_name,
        phone_number: normalizePhone(decodedTokens.access.payload.phone_number),
        city: userInfo.city,
        country: userInfo.country,
        postal_code: userInfo.zipcode,
        state: userInfo.state,
    };
}
