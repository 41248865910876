import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    acceptCreditLimit,
    acceptCreditLimitSuccess,
    acceptCreditLimitError,
    acceptCreditLimitReset,
} from '@perpay-web/fintech/actions/ui/creditLimits';

const AcceptCreditLimitDataModule = createDataModule({
    dataRequest: acceptCreditLimit,
    dataSuccess: acceptCreditLimitSuccess,
    dataError: acceptCreditLimitError,
    dataReset: acceptCreditLimitReset,
});

const getRoot = (state) => state.dataModules.acceptCreditLimit;
const initialValue = {};

export const acceptCreditLimitDataModule = AcceptCreditLimitDataModule({
    getRoot,
    initialValue,
});
