import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchBorrowerCreditSummary,
    replaceBorrowerCreditSummary,
    resetBorrowerCreditSummary,
    setBorrowerCreditSummaryError,
} from '@perpay-web/fintech/actions/shared/borrowerCreditSummary';

const FetchBorrowerCreditSummaryDataModule = createDataModule({
    dataRequest: fetchBorrowerCreditSummary,
    dataSuccess: replaceBorrowerCreditSummary,
    dataError: setBorrowerCreditSummaryError,
    dataReset: resetBorrowerCreditSummary,
});

const getRoot = (state) => state.dataModules.fetchBorrowerCreditSummary;

const initialValue = {
    totalCreditsAvailable: 0,
    cardRewardsAvailable: 0,
    coreCreditsAvailable: 0,
    allTimeCardRewardsBalance: 0,
};

export const fetchBorrowerCreditSummaryDataModule =
    FetchBorrowerCreditSummaryDataModule({
        getRoot,
        initialValue,
    });

export const getTotalCreditsAvailable = (state) =>
    fetchBorrowerCreditSummaryDataModule.getData(state).totalCreditsAvailable;

export const getCardRewardsAvailable = (state) =>
    fetchBorrowerCreditSummaryDataModule.getData(state).cardRewardsAvailable;

export const getCoreCreditsAvailable = (state) =>
    fetchBorrowerCreditSummaryDataModule.getData(state).coreCreditsAvailable;

export const getAllTimeCardRewardsBalance = (state) =>
    fetchBorrowerCreditSummaryDataModule.getData(state)
        .allTimeCardRewardsBalance;
