export const getIsSubmitting = (state) => state.ui.checkout.submitting || false;

export const getCheckoutOrderUUID = (state) => state.ui.checkout.orderUUID;

export const getCheckoutStepIndex = (state) => state.ui.checkout.stepIndex;

export const getCheckoutUploadingPaystub = (state) =>
    state.ui.checkout.uploadingPaystub || false;

export const getCheckoutLoadingBillingInformation = (state) =>
    state.ui.checkout.loadingBillingInformation || false;

export const getCheckoutErrors = (state) => state.ui.checkout.errors || {};

export const getPaystubErrors = (state) =>
    getCheckoutErrors(state).paystub || {};

export const getIsLoadingSignature = (state) =>
    typeof state.ui.checkout.isLoadingSignature !== 'undefined'
        ? state.ui.checkout.isLoadingSignature
        : true;

export const getSkipIdentityVerification = (state) =>
    state.ui.checkout.skipIdentityVerification;

export const getReturnToPaymentOptions = (state) =>
    state.ui.checkout.returnToPaymentOptions;

const getClaimingCheckout = (state) =>
    state.ui.checkout.claimingCheckout || false;

const getFeaturesEnrollmentsIsLoading = (state) =>
    state.ui.checkout.fetchingFeatureEnrollments || false;

const getUserStatusIsLoading = (state) => state.ui.fetchingUserStatus || false;

export const getCheckoutLoading = (state) =>
    getClaimingCheckout(state) ||
    getUserStatusIsLoading(state) ||
    getFeaturesEnrollmentsIsLoading(state);
