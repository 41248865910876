import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const getEnvironment = () => {
    if (window.PRODUCTION) return 'production';
    if (window.STAGING) return 'staging';

    return 'development';
};

const transport = Sentry.makeMultiplexedTransport(
    Sentry.makeFetchTransport,
    (args) => {
        const event = args.getEvent();

        let feature;
        if (event && event.tags) {
            feature = event.tags.feature;
        }

        switch (feature) {
            case 'fintech':
                return [
                    {
                        dsn: window.SENTRY_DSN_FINTECH,
                        release: window.SENTRY_RELEASE,
                    },
                ];
            case 'storefront':
                return [
                    {
                        dsn: window.SENTRY_DSN_STOREFRONT,
                        release: window.SENTRY_RELEASE,
                    },
                ];
            default:
                return [];
        }
    },
);

const createSentryClient = () =>
    Sentry.init({
        transport,
        dsn: window.SENTRY_DSN_FINTECH,
        release: window.SENTRY_RELEASE,
        integrations: [
            Sentry.replayIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        // Record only 5% of session replays
        replaysSessionSampleRate: 0.05,
        // Record only 10% of error session replays
        replaysOnErrorSampleRate: 0.1,
        tracesSampleRate: 0.2,
        environment: getEnvironment(),
        beforeSend: (event, hint) => {
            if (hint.originalException === '') {
                return null;
            }

            // This is a known exception that stems from our imported StripeJS package.
            // Skip sending to sentry.
            if (
                hint.originalException ===
                'Unable to retrieve the origin of the event'
            ) {
                return null;
            }

            // Intentionally pointing on the event to explicitly
            // state we would want it modified
            const modifiedEvent = event;
            if (
                modifiedEvent &&
                modifiedEvent.request &&
                modifiedEvent.request.url &&
                modifiedEvent.request.url.includes('/shop')
            ) {
                modifiedEvent.tags = {
                    ...modifiedEvent.tags,
                    feature: 'storefront',
                };
            } else if (modifiedEvent && modifiedEvent.tags) {
                modifiedEvent.tags = {
                    ...modifiedEvent.tags,
                    feature: 'fintech',
                };
            }
            return modifiedEvent;
        },
    });

export default createSentryClient();
