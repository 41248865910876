import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import { generalAccountSummaryDataModule } from '@perpay-web/fintech/dataModules/generalAccountSummary';
import { fetchCardAccountsDataModule } from '@perpay-web/fintech/dataModules/fetchCardAccounts';
import { setStepsForUpdateDirectDepositDataModule } from '@perpay-web/fintech/dataModules/setStepsForUpdateDirectDeposit';
import { fetchJobsDataModule } from '@perpay-web/fintech/dataModules/fetchJobs';

const dataModules = [
    fetchCardAccountsDataModule,
    generalAccountSummaryDataModule,
    setStepsForUpdateDirectDepositDataModule,
    fetchJobsDataModule,
];

const UpdateDirectDepositDataModule = composeDataModules(dataModules);

const getRoot = (state) => state.dataModules.updateDirectDeposit;

export const updateDirectDepositDataModule = UpdateDirectDepositDataModule({
    getRoot,
    key: 'UPDATE_DIRECT_DEPOSIT',
});
