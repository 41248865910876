import { Stack } from '@perpay-web/components/design-system/Stack/Stack';
import { getClassName } from '@perpay-web/utils/domUtils';

import styles from './FocusContent.scss';

const FocusContent = ({ children, className = '' }) => (
    <Stack className={getClassName(styles['outer-stack'], className)}>
        {children}
    </Stack>
);

export default FocusContent;
