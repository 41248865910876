import * as Sentry from '@sentry/react';

// test-only export
export const FALLBACK_ERROR = {
    message: [
        'Oops, looks like there was an issue with your request. Please try again in a few moments.',
    ],
};

export const SENTRY_LEVELS = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    LOG: 'log',
    INFO: 'info',
    DEBUG: 'debug',
};

/**
 *
 * @param {Error | string} error
 * @param {object} extra - extra details
 * @param {object: {tag: { name: string, value: string }}} config
 */
export const reportError = (error, extra = {}, config = {}) => {
    Sentry.withScope((scope) => {
        Object.entries(extra).forEach(([key, value]) => {
            scope.setExtra(key, value);
        });

        const { tag, level } = config;
        if (tag && tag.name && tag.value) {
            scope.setTag(tag.name, tag.value);
        }

        if (level && level in SENTRY_LEVELS) {
            scope.setLevel(level);
        }

        Sentry.captureException(error);
    });
};

/**
 * Accepts an ajax error and returns an object with a standard format.
 * 5xx errors may not have a formatted JSON body, so we need to provide a fallback message
 */
export const standardizeError = (error) =>
    error.response ? error.response : FALLBACK_ERROR;

export const hasErrors = (errors) =>
    Object.values(errors).filter((errorList) => errorList.length > 0).length >
    0;
