export const UPDATE_DIRECT_DEPOSIT = 'View update direct deposit flow';
export const REFERRAL = 'Refer friends and get';
export const UPLOAD_PAYSTUB = 'Upload paystub';
export const INCOMPLETE_APPLICATION = 'Complete application';
export const PAYMENT_SETUP = 'Set up my direct deposit';
export const SUPPORT_TOOL = 'Fix this';
export const IDENTITY_VERIFICATION = 'Verify identity';
export const ACTIVATE_CARD = 'Activate my card';
export const ADD_BANK_ACCOUNT = 'Add my bank';
export const CLOSED_CARD_HELP_CENTER = 'Closed card help center link';
