import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchInitiatedAutoPayPayments,
    fetchInitiatedAutoPayPaymentsSuccess,
    fetchInitiatedAutoPayPaymentsError,
    fetchInitiatedAutoPayPaymentsReset,
} from '@perpay-web/fintech/actions/entities/autoPayPayments';

const FetchInitiatedAutoPayPaymentsDataModule = createDataModule({
    dataRequest: fetchInitiatedAutoPayPayments,
    dataSuccess: fetchInitiatedAutoPayPaymentsSuccess,
    dataError: fetchInitiatedAutoPayPaymentsError,
    dataReset: fetchInitiatedAutoPayPaymentsReset,
});

const getRoot = (state) => state.dataModules.fetchInitiatedAutoPayPayments;
const initialValue = {
    all: [],
};

export const fetchInitiatedAutoPayPaymentsDataModule =
    FetchInitiatedAutoPayPaymentsDataModule({
        getRoot,
        initialValue,
    });

export const getInitiatedAutoPayPayments = (state) =>
    fetchInitiatedAutoPayPaymentsDataModule.getData(state).all;
