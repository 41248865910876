import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchBorrowerCredits as fetchBorrowerCreditsAction,
    replaceBorrowerCredits,
    setBorrowerCreditsError,
} from '@perpay-web/fintech/actions/entities/borrowerCredits';
import { BORROWER_CREDITS_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchBorrowerCredits = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchBorrowerCreditsAction().type),
        mergeMap((action) => {
            let endpoint = `${BORROWER_CREDITS_ENDPOINT}?credit_type=${action.payload.creditType}`;

            if (action.payload.pageNumber) {
                endpoint = `${endpoint}&page=${action.payload.pageNumber}`;
            }

            return get(endpoint);
        }),
        mergeMap((results) => [replaceBorrowerCredits(results.response)]),
        handleErrorMessageWithFallback((error) => [
            setBorrowerCreditsError(error),
        ]),
    );
