import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';
import {
    BACKEND_FETCH_PAYROLL_INSTRUCTION_ERROR,
    STORE_REPLACE_PAYROLL_INSTRUCTIONS,
    ANALYTICS_PAYROLL_INSTRUCTIONS_VIEWED,
    ANALYTICS_PAYROLL_INSTRUCTIONS_FINISH_CLICKED,
} from '@perpay-web/fintech/constants/actionTypes';

const dataModuleActions = createDataModuleActions('FETCH_PAYROLL_INSTRUCTIONS');
export const fetchPayrollInstructionsForDataModule =
    dataModuleActions.dataRequest;
export const fetchPayrollInstructionsForDataModuleSuccess =
    dataModuleActions.dataSuccess;
export const fetchPayrollInstructionsForDataModuleError =
    dataModuleActions.dataError;
export const fetchPayrollInstructionsForDataModuleReset =
    dataModuleActions.dataReset;

export function fetchPayrollInstructionsError(error) {
    return {
        type: BACKEND_FETCH_PAYROLL_INSTRUCTION_ERROR,
        payload: error,
    };
}

export function replacePayrollInstructions(payload) {
    return {
        type: STORE_REPLACE_PAYROLL_INSTRUCTIONS,
        payload,
    };
}

export function analyticsPayrollInstructionsViewed() {
    return {
        type: ANALYTICS_PAYROLL_INSTRUCTIONS_VIEWED,
    };
}

export function analyticsPayrollInstructionsFinishClicked(instructionsType) {
    return {
        type: ANALYTICS_PAYROLL_INSTRUCTIONS_FINISH_CLICKED,
        payload: {
            instructionsType,
        },
    };
}
