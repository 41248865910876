import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importCardCreateAutoPayContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "CardCreateAutoPayContainer" */
                '@perpay-web/fintech/components/containers/CardCreateAutoPayContainer'
            ),
    );
