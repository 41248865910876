import { Subject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { handleError } from '@perpay-web/observable/operators/handleError';
import { getCordovaApp } from '@perpay-web/fintech/utils/cordovaUtils';
import { analyticsIterablePushNotificationOpen as analyticsIterablePushNotificationOpenAction } from '@perpay-web/fintech/actions/analytics/tracking';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { APP_HOST } from '@perpay-web/fintech/constants/urls';
import { HOST, MAGENTO_HOST } from '@perpay-web/constants/urls';

function getPushObservable() {
    const push$ = new Subject();

    getCordovaApp()
        .then((CordovaApp) => CordovaApp.requirePlugin('notification'))
        .then((pushPlugin) => {
            pushPlugin.onNotification(({ setting, pushData }) => {
                // We only care about notifications that a user tapped.
                if (setting === 'background') {
                    push$.next(pushData);
                }
            });
        });

    return push$.asObservable();
}

export const analyticsIterablePushNotificationOpen = () => {
    const push$ = getPushObservable();
    return push$.pipe(
        // We only care about Iterable-initiated pushes here
        filter((pushData) => Boolean(pushData.itbl)),
        // Combine the push data with the user's email address from the latest access token
        mergeMap((pushData) => {
            const { campaignId, messageId, templateId } = JSON.parse(
                pushData.itbl,
            );

            return authentication
                .getRefreshedEmail()
                .then((email) =>
                    analyticsIterablePushNotificationOpenAction(
                        email,
                        campaignId,
                        messageId,
                        templateId,
                    ),
                );
        }),
        handleError(() => []),
    );
};

const WHITELISTED_APP_HOSTS = [HOST, MAGENTO_HOST, APP_HOST];

export const navigateOnUrlPush = () => {
    const push$ = getPushObservable();
    return push$.pipe(
        // We only care about Iterable-initiated pushes here
        filter((pushData) => Boolean(pushData.uri)),
        // Combine the push data with the user's email address from the latest access token
        mergeMap((pushData) => {
            const { uri } = pushData;
            const { host } = new URL(uri);

            if (WHITELISTED_APP_HOSTS.includes(host)) {
                getCordovaApp()
                    .then((CordovaApp) => CordovaApp.requirePlugin('statusbar'))
                    .then(({ setStatusBarColor, overlaysWebView }) => {
                        setStatusBarColor('#ffffff');
                        if (overlaysWebView) {
                            document.documentElement.classList.remove(
                                'ios--transparent-status-bar',
                            );
                            overlaysWebView(false);
                        }
                        window.location.href = uri;
                    });

                return [];
            }

            // inapp browser plugin
            const a = document.createElement('a');
            a.href = uri;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            a.remove();

            return [];
        }),
        handleError(() => []),
    );
};
