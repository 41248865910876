import {
    BACKEND_FETCH_FEATURES,
    STORE_REPLACE_FEATURES,
    STORE_FETCH_FEATURES_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export const fetchFeatures = () => ({
    type: BACKEND_FETCH_FEATURES,
});

export const replaceFeatures = (results) => ({
    type: STORE_REPLACE_FEATURES,
    payload: results,
});

export const fetchFeaturesError = (error) => ({
    type: STORE_FETCH_FEATURES_ERROR,
    payload: error,
});
