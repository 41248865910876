import { resetState } from '@perpay-web/utils/reducerUtils';
import {
    STORE_REPLACE_PAYROLL_INSTRUCTIONS,
    STORE_RESET_PAYROLL_INSTRUCTIONS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    payroll: {},
    paystub: {},
};

const instructionsReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_PAYROLL_INSTRUCTIONS:
            return { ...state, payroll: payload };
        case STORE_RESET_PAYROLL_INSTRUCTIONS:
            return resetState(state, initialState);
        default:
            return state;
    }
};

export default instructionsReducer;
