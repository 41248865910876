import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createPrimaryJob,
    createPrimaryJobSuccess,
    createPrimaryJobError,
    createPrimaryJobReset,
} from '@perpay-web/fintech/actions/entities/jobs';

const CreatePrimaryJobDataModule = createDataModule({
    dataRequest: createPrimaryJob,
    dataSuccess: createPrimaryJobSuccess,
    dataError: createPrimaryJobError,
    dataReset: createPrimaryJobReset,
});

const getRoot = (state) => state.dataModules.createPrimaryJob;
const initialValue = {};

export const createPrimaryJobDataModule = CreatePrimaryJobDataModule({
    getRoot,
    initialValue,
});
