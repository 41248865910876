import { exhaustMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import {
    BACKEND_VERIFY_BANK_SELECT,
    BACKEND_VERIFY_BANK_SINGLE,
    BANK_SCREEN_CREATE_BANK,
    STORE_CREATE_BANK_ERROR,
    STORE_CREATE_BANK_SUCCESS,
    STORE_VERIFY_BANK_ERROR,
    STORE_VERIFY_BANK_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';
import {
    createBank,
    verifyBank,
    createBankAccount as createBankAccountAction,
    createBankAccountSuccess,
    createBankAccountError,
    fetchBankAccounts as fetchBankAccountsAction,
    fetchBankAccountsSuccess,
    fetchBankAccountsError,
} from '@perpay-web/fintech/actions/entities/banks';
import {
    createBankAccountForCardAutoPay as createBankAccountForCardAutoPayAction,
    createBankAccountForCardAutoPaySuccess,
    createBankAccountForCardAutoPayError,
    fetchBankAccountsForCardAutoPay as fetchBankAccountsForCardAutoPayAction,
    fetchBankAccountsForCardAutoPaySuccess,
    fetchBankAccountsForCardAutoPayError,
} from '@perpay-web/fintech/actions/ui/banks';
import { replaceSelectedBankUuid } from '@perpay-web/fintech/actions/ui/cardCreateAutoPay';
import { routeToLocation } from '@perpay-web/fintech/actions/router';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { PROFILE_BANKS_ID } from '@perpay-web/fintech/constants/paths';
import {
    STEPS,
    VERIFY_SELECT_SUCCESS,
} from '@perpay-web/fintech/constants/steps/verifyBankFormSelectSteps';
import {
    CARD_CREATE_AUTO_PAY_STEPS,
    SELECT_BANK_STEP,
} from '@perpay-web/fintech/constants/steps/cardCreateAutoPaySteps';

export const bankScreenCreateBank = (action$, state$) =>
    action$.pipe(
        ofType(BANK_SCREEN_CREATE_BANK),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [createBank(action.payload)],
                waitFor: [STORE_CREATE_BANK_SUCCESS],
                waitForDispatch: () => [
                    routeToLocation(
                        `${paths.profile.path}#${PROFILE_BANKS_ID}`,
                    ),
                ],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [STORE_CREATE_BANK_ERROR],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );

export const verifySelectBank = (action$, state$) =>
    action$.pipe(
        ofType(BACKEND_VERIFY_BANK_SELECT),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    verifyBank(
                        action.payload.uuid,
                        action.payload.amounts[0],
                        action.payload.amounts[1],
                    ),
                ],
                waitFor: [STORE_VERIFY_BANK_SUCCESS],
                waitForDispatch: () => [
                    reduxStepsSetStep(STEPS, VERIFY_SELECT_SUCCESS),
                ],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [STORE_VERIFY_BANK_ERROR],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );

export const verifySingleBank = (action$, state$) =>
    action$.pipe(
        ofType(BACKEND_VERIFY_BANK_SINGLE),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    verifyBank(
                        action.payload.uuid,
                        action.payload.amounts[0],
                        action.payload.amounts[1],
                    ),
                ],
                waitFor: [STORE_VERIFY_BANK_SUCCESS],
                waitForDispatch: () => [
                    routeToLocation(
                        `${paths.profile.path}#${PROFILE_BANKS_ID}`,
                    ),
                ],
                // The UI consumes the errors that are stored by the createBank epic.
                errors: [STORE_VERIFY_BANK_ERROR],
                errorDispatch: () => [],
                // Timeouts are handled within the bank entity epic
            }),
        ),
    );

export const createBankAccountForCardAutoPay = (action$, state$) =>
    action$.pipe(
        ofType(createBankAccountForCardAutoPayAction().type),
        exhaustMap((action) =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [
                    createBankAccountAction(action.payload),
                ],
                waitFor: [createBankAccountSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const { uuid } = payload;
                    return [
                        createBankAccountForCardAutoPaySuccess(payload),
                        replaceSelectedBankUuid(uuid),
                        reduxStepsSetStep(
                            CARD_CREATE_AUTO_PAY_STEPS,
                            SELECT_BANK_STEP,
                        ),
                    ];
                },
                errors: [createBankAccountError().type],
                errorDispatch: (errorAction) => [
                    createBankAccountForCardAutoPayError(errorAction.payload),
                ],
            }),
        ),
    );

export const fetchBankAccountsForCardAutoPay = (action$, state$) =>
    action$.pipe(
        ofType(fetchBankAccountsForCardAutoPayAction().type),
        exhaustMap(() =>
            dispatchObservable({
                action$,
                state$,
                initialDispatch: () => [fetchBankAccountsAction()],
                waitFor: [fetchBankAccountsSuccess().type],
                waitForDispatch: (state, results) => {
                    const { payload } = results[0];
                    const returnActions = [
                        fetchBankAccountsForCardAutoPaySuccess(payload),
                    ];
                    if (payload.all.length) {
                        returnActions.push(
                            replaceSelectedBankUuid(payload.all[0].uuid),
                        );
                    }
                    return returnActions;
                },
                errors: [fetchBankAccountsError().type],
                errorDispatch: (errorAction) => [
                    fetchBankAccountsForCardAutoPayError(errorAction.payload),
                ],
            }),
        ),
    );
