import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [HeaderRightNavComponentStackProvider, useHeaderRightNavContext] =
    createContextProvider(componentStackProviderFactory);
export { HeaderRightNavComponentStackProvider };

export const useHeaderRightNav = (renderCb) =>
    useComponentStack(useHeaderRightNavContext(), renderCb);

export const useHeaderRightNavRender = () =>
    useComponentStackRender(useHeaderRightNavContext());
