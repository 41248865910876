import {
    BACKEND_CREATE_SURVEY_RESPONSE,
    STORE_CREATE_SURVEY_RESPONSE_SUCCESS,
    STORE_CREATE_SURVEY_RESPONSE_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function createSurveyResponse(payload) {
    return {
        type: BACKEND_CREATE_SURVEY_RESPONSE,
        payload,
    };
}

export function createSurveyResponseSuccess(payload) {
    return {
        type: STORE_CREATE_SURVEY_RESPONSE_SUCCESS,
        payload,
    };
}

export function createSurveyResponseError(error) {
    return {
        type: STORE_CREATE_SURVEY_RESPONSE_ERROR,
        payload: error,
    };
}
