const APPROVED_STEP = 'approved';
export const CONFIRM_EMPLOYER_STEP = 'confirmEmployer';
export const PERPAY_SPLIT_EDUCATION_STEP = 'education';
export const INSTRUCTIONS_STEP = 'instructions';
export const ADD_BANK_STEP = 'addBank';
export const PAYMENT_SETUP_DETAILS_STEP = 'paymentSetupDetailsStep';

export const PERPAY_SPLIT_SET_UP_PAYMENTS_STEPS = [
    PAYMENT_SETUP_DETAILS_STEP,
    APPROVED_STEP,
    CONFIRM_EMPLOYER_STEP,
    PERPAY_SPLIT_EDUCATION_STEP,
    INSTRUCTIONS_STEP,
    ADD_BANK_STEP,
];
