import {
    STORE_CHECKOUT_REPLACE_ORDER_UUID,
    STORE_CHECKOUT_NEXT_STEP,
    STORE_CHECKOUT_RESET_STEP_INDEX,
    BACKEND_CHECKOUT_CREATE_ORDER,
    STORE_REPLACE_ORDER,
    BACKEND_CREATE_PAYSTUB,
    BACKEND_PAYSTUB_POLLING_COMPLETE,
    BACKEND_FETCH_JOBS,
    FETCH_USER_INFO,
    REPLACE_USER_INFO,
    STORE_REPLACE_JOBS,
    STORE_REPLACE_JOBS_ERROR,
    BACKEND_CHECKOUT_UPDATE_ORDER,
    BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID,
    BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID_ERROR,
    BACKEND_CHECKOUT_UPDATE_USER_INFO,
    BACKEND_CHECKOUT_UPDATE_USER_INFO_ERROR,
    STORE_RESET_CHECKOUT,
    STORE_PAYSTUB_POLLING_ERROR,
    STORE_CHECKOUT_REPLACE_LOADING_SIGNATURE,
    STORE_CHECKOUT_BACK_STEP,
    STORE_BACK_STEP_FROM_CONFIRM_ORDER,
    BACKEND_CHECKOUT_VERIFY_IDENTITY,
    BACKEND_CHECKOUT_VERIFY_IDENTITY_ERROR,
    STORE_CHECKOUT_VERIFY_IDENTITY_SERVICE_ERROR,
    STORE_VERIFY_IDENTITY_SERVICE_ERROR,
    BACKEND_FETCH_FEATURE_ENROLLMENTS,
    STORE_REPLACE_FEATURE_ENROLLMENTS,
    STORE_FETCH_FEATURE_ENROLLMENTS_ERROR,
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS_ERROR,
    STORE_REPLACE_USER_STATUS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    submitting: false,
    stepIndex: 0,
    claimingCheckout: true,
    uploadingPaystub: true,
    loadingBillingInformation: true,
    errors: {},
    isLoadingSignature: true,
    skipIdentityVerification: false,
    orderUUID: null,
    returnToPaymentOptions: false,
    fetchingFeatureEnrollments: false,
    fetchingUserStatus: false,
    loadingJobs: false,
};

const checkoutReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_CHECKOUT_REPLACE_LOADING_SIGNATURE:
            return { ...state, isLoadingSignature: payload };
        case STORE_CHECKOUT_REPLACE_ORDER_UUID:
            return { ...state, orderUUID: payload };
        case STORE_CHECKOUT_NEXT_STEP:
            return {
                ...state,
                stepIndex: state.stepIndex + 1,
                submitting: false,
                errors: {},
            };
        case STORE_CHECKOUT_BACK_STEP:
            return {
                ...state,
                stepIndex: Math.max(state.stepIndex - 1, 0),
                submitting: false,
                errors: {},
            };
        case STORE_BACK_STEP_FROM_CONFIRM_ORDER:
            return {
                ...state,
                stepIndex: 0,
                returnToPaymentOptions: true,
                submitting: false,
                errors: {},
            };
        case STORE_CHECKOUT_RESET_STEP_INDEX:
            return {
                ...state,
                stepIndex: 0,
                submitting: false,
                errors: {},
            };
        case BACKEND_CHECKOUT_CREATE_ORDER:
            return { ...state, claimingCheckout: true };
        case STORE_REPLACE_ORDER:
            return { ...state, claimingCheckout: false };
        case BACKEND_CREATE_PAYSTUB:
            return { ...state, uploadingPaystub: true, errors: {} };
        case BACKEND_PAYSTUB_POLLING_COMPLETE:
            return { ...state, uploadingPaystub: false };
        case STORE_PAYSTUB_POLLING_ERROR:
            return {
                ...state,
                uploadingPaystub: false,
                errors: { paystub: payload },
            };
        case FETCH_USER_INFO:
            return {
                ...state,
                loadingBillingInformation: true,
            };
        case BACKEND_FETCH_JOBS:
            return { ...state, loadingJobs: true };
        case STORE_REPLACE_JOBS_ERROR:
        case STORE_REPLACE_JOBS:
            return { ...state, loadingJobs: false };
        case REPLACE_USER_INFO:
            return {
                ...state,
                loadingBillingInformation: false,
            };
        case BACKEND_CHECKOUT_UPDATE_ORDER:
            return {
                ...state,
                returnToPaymentOptions: false,
                submitting: true,
            };
        case BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID:
            return { ...state, submitting: true };
        case BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID_ERROR:
            return { ...state, submitting: false, errors: payload };
        case BACKEND_CHECKOUT_VERIFY_IDENTITY:
        case BACKEND_CHECKOUT_UPDATE_USER_INFO:
            return { ...state, submitting: true };
        case BACKEND_CHECKOUT_VERIFY_IDENTITY_ERROR:
        case BACKEND_CHECKOUT_UPDATE_USER_INFO_ERROR:
            return { ...state, submitting: false, errors: payload };
        case STORE_CHECKOUT_VERIFY_IDENTITY_SERVICE_ERROR:
            return {
                ...state,
                submitting: false,
                errors: payload,
                skipIdentityVerification: true,
            };
        case STORE_RESET_CHECKOUT:
            return initialState;
        case STORE_VERIFY_IDENTITY_SERVICE_ERROR:
            return { ...state, skipIdentityVerification: true };
        case BACKEND_FETCH_FEATURE_ENROLLMENTS:
            return { ...state, fetchingFeatureEnrollments: true };
        case STORE_REPLACE_FEATURE_ENROLLMENTS:
        case STORE_FETCH_FEATURE_ENROLLMENTS_ERROR:
            return { ...state, fetchingFeatureEnrollments: false };
        case BACKEND_FETCH_USER_STATUS:
            return { ...state, fetchingUserStatus: true };
        case STORE_REPLACE_USER_STATUS:
        case STORE_REPLACE_USER_STATUS_ERROR:
            return { ...state, fetchingUserStatus: false };
        default:
            return state;
    }
};

export default checkoutReducer;
