import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchCardStatements,
    replaceCardStatements,
    resetCardStatements,
    setCardStatementsError,
} from '@perpay-web/fintech/actions/entities/cardStatements';

const FetchCardStatementsDataModule = createDataModule({
    dataRequest: fetchCardStatements,
    dataSuccess: replaceCardStatements,
    dataError: setCardStatementsError,
    dataReset: resetCardStatements,
});

const getRoot = (state) => state.dataModules.fetchCardStatements;

const initialValue = { all: [] };

export const fetchCardStatementsDataModule = FetchCardStatementsDataModule({
    getRoot,
    initialValue,
});

export const getCardStatements = (state) =>
    fetchCardStatementsDataModule.getData(state).all;

export const getHasCardStatement = (state) => {
    const cardStatements = getCardStatements(state);
    return cardStatements.length !== 0;
};
