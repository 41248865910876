// Size facet options for algolia products, sorted in ascending order
export const APPAREL_SIZES = [
    '2XS',
    'XS',
    'X-Small',
    'S',
    'Small',
    'Small/Medium',
    'M',
    'Medium',
    'M/L',
    'Medium/Large',
    'L',
    'Large',
    'Large/X-Large',
    'Large/Extra Large',
    'XL',
    'X-Large',
    'XLarge',
    'Extra Large',
    'XXL',
    'XX-Large',
    '3XL',
    '4XL',
];
export const MEMORY_SIZE_UNITS = ['KB', 'MB', 'GB', 'TB', 'PB'];
