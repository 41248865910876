import {
    FETCH_PAYMENT_SETUP_DATA,
    FETCH_PAYMENT_SETUP_DATA_SUCCESS,
    FETCH_PAYMENT_SETUP_DATA_ERROR,
    PAYMENT_SETUP_CREATE_PRIMARY_JOB,
} from '@perpay-web/fintech/constants/actionTypes';

export const fetchPaymentSetupData = () => ({
    type: FETCH_PAYMENT_SETUP_DATA,
});

export const fetchPaymentSetupDataSuccess = () => ({
    type: FETCH_PAYMENT_SETUP_DATA_SUCCESS,
});

export const fetchPaymentSetupDataError = (error) => ({
    type: FETCH_PAYMENT_SETUP_DATA_ERROR,
    payload: { error },
});

export const paymentSetupCreatePrimaryJob = (payload) => ({
    type: PAYMENT_SETUP_CREATE_PRIMARY_JOB,
    payload,
});
