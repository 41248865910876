import { STORE_REPLACE_FEATURES } from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const featuresReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_FEATURES:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default featuresReducer;
