import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    validateAddressForMVP2,
    validateAddressSuccessForMVP2,
    validateAddressErrorForMVP2,
    validateAddressResetForMVP2,
} from '@perpay-web/fintech/actions/ui/addressValidation';

const ValidateAddressForMVP2DataModule = createDataModule({
    dataRequest: validateAddressForMVP2,
    dataSuccess: validateAddressSuccessForMVP2,
    dataError: validateAddressErrorForMVP2,
    dataReset: validateAddressResetForMVP2,
});

const getRoot = (state) => state.dataModules.validateAddressForMVP2;
const initialValue = {};

export const validateAddressForMVP2DataModule =
    ValidateAddressForMVP2DataModule({
        getRoot,
        initialValue,
    });

export const getAddressEntered = (state) =>
    validateAddressForMVP2DataModule.getData(state).originalAddress;

export const getAddressSuggested = (state) =>
    validateAddressForMVP2DataModule.getData(state).validatedAddress;

export const getUSPSAdressFound = (state) =>
    !(
        validateAddressForMVP2DataModule.getData(state).uspsAddressNotFound ||
        false
    );
