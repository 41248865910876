import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    partnerOnboardingSubmitCardApplication,
    partnerOnboardingSubmitCardApplicationSuccess,
    partnerOnboardingSubmitCardApplicationError,
    partnerOnboardingSubmitCardApplicationReset,
} from '@perpay-web/fintech/actions/entities/partnerOnboarding';

const PartnerOnboardingSubmitCardApplicationDataModule = createDataModule({
    dataRequest: partnerOnboardingSubmitCardApplication,
    dataSuccess: partnerOnboardingSubmitCardApplicationSuccess,
    dataError: partnerOnboardingSubmitCardApplicationError,
    dataReset: partnerOnboardingSubmitCardApplicationReset,
});

const getRoot = (state) =>
    state.dataModules.partnerOnboardingSubmitCardApplication;
const initialValue = {
    errors: {},
};
export const partnerOnboardingSubmitCardApplicationDataModule =
    PartnerOnboardingSubmitCardApplicationDataModule({
        getRoot,
        initialValue,
    });
