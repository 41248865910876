import {
    componentStackProviderFactory,
    useComponentStack,
    useComponentStackRender,
} from '@perpay-web/hooks/useComponentStack';
import { createContextProvider } from '@perpay-web/utils/componentUtils';

const [HeaderLeftNavComponentStackProvider, useHeaderLeftNavContext] =
    createContextProvider(componentStackProviderFactory);
export { HeaderLeftNavComponentStackProvider };

export const useHeaderLeftNav = (renderCb) =>
    useComponentStack(useHeaderLeftNavContext(), renderCb);

export const useHeaderLeftNavRender = () =>
    useComponentStackRender(useHeaderLeftNavContext());
