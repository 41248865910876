/**
 * Alternative to WithFieldServerErrors, compatible with, but not tightly coupled to Formik
 */
import { forwardRef } from 'react';

import {
    formWithBackendErrors,
    selectFirstErrors,
} from '@perpay-web/utils/formUtils';

export function withFormServerErrors(Component) {
    const FormServerErrorsWrapper = forwardRef(
        (
            {
                className = null,
                errors = null,
                formErrors = null,
                label = '',
                name,
                ...rest
            },
            ref,
        ) => {
            const firstFormErrors = selectFirstErrors(formErrors);
            const firstBackendErrors = selectFirstErrors(errors);
            const joinedErrors = formWithBackendErrors(
                firstFormErrors,
                firstBackendErrors,
            );

            return (
                <Component
                    ref={ref}
                    {...rest}
                    name={name}
                    errors={joinedErrors[name]}
                    className={className}
                    label={label}
                />
            );
        },
    );

    FormServerErrorsWrapper.displayName = 'withFormServerErrors';

    return FormServerErrorsWrapper;
}
