import { combined } from '@perpay-web/validators/fieldBased';

/**
 * Validate form values with a given schema
 *
 * @param values - object mapping field name to value e.g.
 *  {
 *      first_name: "Rocky",
 *      last_name: "Balboa",
 *      phone: "555-555-5555",
 *      dateOfBirth: "07/06/1945",
 *  }
 * @param schema - object mapping field name to array of validator functions e.g.
 *  {
 *      first_name: [deserveFirstName],
 *      last_name: [deserveLastName],
 *      phone: [phone],
 *      dateOfBirth: [required, dateFormat, minDateOfBirth],
 *  }
 */
export const validateForm = (values, schema) => {
    const fieldNames = Object.getOwnPropertyNames(values);

    const errors = fieldNames.reduce((result, name) => {
        const fieldValidators = schema[name];
        const fieldValue = values[name];
        if (!fieldValidators) {
            return result;
        }

        const validators = combined(fieldValidators);
        const validationErrors = validators(fieldValue);
        if (!validationErrors) {
            return result;
        }

        return { ...result, [name]: validationErrors };
    }, {});

    return errors;
};
