import {
    BACKEND_FETCH_ORDERS,
    STORE_REPLACE_ORDERS,
    STORE_RESET_ORDERS,
    STORE_UPDATE_ORDERS_FILTER,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    filterType: 'all',
    ordersFetching: true,
};

const ordersReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case BACKEND_FETCH_ORDERS:
        case STORE_RESET_ORDERS:
            return { ...state, ordersFetching: true };
        case STORE_REPLACE_ORDERS:
            return { ...state, ordersFetching: false };
        case STORE_UPDATE_ORDERS_FILTER:
            return { ...state, filterType: payload };
        default:
            return state;
    }
};

export default ordersReducer;
