import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchDirectDepositDashboardSummary,
    directDepositDashboardSummarySuccess,
    directDepositDashboardSummaryError,
    directDepositDashboardSummaryReset,
} from '@perpay-web/fintech/actions/entities/directDepositDashboardSummary';

const FetchDirectDepositDashboardSummaryDataModule = createDataModule({
    dataRequest: fetchDirectDepositDashboardSummary,
    dataSuccess: directDepositDashboardSummarySuccess,
    dataError: directDepositDashboardSummaryError,
    dataReset: directDepositDashboardSummaryReset,
});

// TODO: Remove use of underscore_case once backend change has been made
const getRoot = (state) => state.dataModules.fetchDirectDepositDashboardSummary;
const initialValue = {
    minimumPayment: 0,
    nextDepositEstimate: '',
};

export const fetchDirectDepositDashboardSummaryDataModule =
    FetchDirectDepositDashboardSummaryDataModule({
        getRoot,
        initialValue,
    });

const getDirectDepositObject = (state) =>
    fetchDirectDepositDashboardSummaryDataModule.getData(state).directDeposit ||
    initialValue;

export const getMinimumPayment = (state) =>
    getDirectDepositObject(state).minimumPayment || 0;

export const getNextDepositEstimate = (state) =>
    getDirectDepositObject(state).nextDepositEstimate || '';
