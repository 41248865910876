import { createAction } from '@reduxjs/toolkit';

export const createDeposit = createAction('backend/DEPOSITS::CREATE');

export const createDepositSuccess = createAction(
    'backend/DEPOSITS::CREATE::SUCCESS',
);

export const createDepositErrors = createAction(
    'backend/DEPOSITS::CREATE::ERROR',
);
