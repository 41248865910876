import {
    FETCH_USER_INFO,
    REPLACE_USER_INFO,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    loading: true,
};

const referralsReducer = (state = initialState, action = {}) => {
    const { type } = action;

    switch (type) {
        case FETCH_USER_INFO:
            return { ...state, loading: true };
        case REPLACE_USER_INFO:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default referralsReducer;
