import { ReducerManager } from '@perpay-web/services/ReducerManager';
import supportToolReducer from '@perpay-web/services/supportTool';
import dataModulesReducer from './dataModules';
import uiReducer from './ui/ui';
import entitiesReducer from './entities/entities';
import authenticationReducer from './authentication';
import sharedReducer from './shared/shared';
import appUpdateReducer from './appUpdate';

const staticReducers = {
    ui: uiReducer,
    entities: entitiesReducer,
    authentication: authenticationReducer,
    shared: sharedReducer,
    appUpdate: appUpdateReducer,
    dataModules: dataModulesReducer,
    supportTool: supportToolReducer,
};

let reducerManager = null;

export function getReducerManager() {
    if (!reducerManager) {
        reducerManager = new ReducerManager(staticReducers);
    }
    return reducerManager;
}
