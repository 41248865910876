import {
    ACKNOWLEDGE_NOTIFICATION,
    ACKNOWLEDGE_NOTIFICATION_ERROR,
    ACKNOWLEDGE_NOTIFICATION_SUCCESS,
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_ERROR,
    FETCH_NOTIFICATIONS_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

export const fetchNotifications = () => ({
    type: FETCH_NOTIFICATIONS,
});

export const fetchNotificationsSuccess = (result) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: result,
});

export const fetchNotificationsError = (error) => ({
    type: FETCH_NOTIFICATIONS_ERROR,
    payload: error,
});

export const acknowledgeNotification = (uuid) => ({
    type: ACKNOWLEDGE_NOTIFICATION,
    payload: { uuid },
});

export const acknowledgeNotificationSuccess = (result) => ({
    type: ACKNOWLEDGE_NOTIFICATION_SUCCESS,
    payload: { result },
});

export const acknowledgeNotificationError = (error) => ({
    type: ACKNOWLEDGE_NOTIFICATION_ERROR,
    payload: error,
});
