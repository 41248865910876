import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { reportError as doReportError } from '@perpay-web/utils/errorHandlers';
import { REPORT_ERROR } from '@perpay-web/fintech/constants/actionTypes';

export function reportError(action$) {
    return action$.pipe(
        ofType(REPORT_ERROR),
        mergeMap((action) => {
            doReportError(action.payload);
            return [];
        }),
    );
}
