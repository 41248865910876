import { merge, ReplaySubject } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

function combineEpics(epics) {
    return (...args) => merge(...epics.map((epic) => epic(...args)));
}

// https://redux-observable.js.org/docs/recipes/AddingNewEpicsAsynchronously.html
export class EpicManager {
    constructor(epics) {
        this.epic$ = new ReplaySubject();
        this.epic$.next(combineEpics(epics));

        this.rootEpic = (action$, state$, deps) =>
            this.epic$.pipe(
                mergeMap((epic) => {
                    const epic$ = epic(action$, state$, deps);
                    return epic$.pipe(
                        catchError((error, source$) => {
                            if (!window.PRODUCTION) {
                                // eslint-disable-next-line no-console
                                console.error('[ROOT EPIC ERROR] ', error);
                            }
                            return source$;
                        }),
                    );
                }),
            );
    }

    getRootEpic() {
        return this.rootEpic;
    }

    add(epic) {
        this.epic$.next(epic);
    }
}
