export function isAppBrowserAgent() {
    const userAgent = (window.navigator && window.navigator.userAgent) || '';
    return userAgent.includes('PerpayAppBrowser');
}

export function isIosAgent() {
    const userAgent = (window.navigator && window.navigator.userAgent) || '';
    return userAgent.includes('PerpayAppBrowserIos');
}

export function isAndroidAgent() {
    const userAgent = (window.navigator && window.navigator.userAgent) || '';
    return userAgent.includes('PerpayAppBrowserAndroid');
}

export function isAndroidDevice() {
    const userAgent = (window.navigator && window.navigator.userAgent) || '';
    return userAgent.includes('Android');
}
