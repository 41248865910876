import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { BACKEND_FETCH_PINWHEEL_SURVEY } from '@perpay-web/fintech/constants/actionTypes';
import { SURVEYS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { survey } from '@perpay-web/fintech/normalizers/schemas';
import {
    SURVEYS,
    SURVEY_QUESTIONS,
    SURVEY_OPTIONS,
} from '@perpay-web/fintech/constants/tableNames';
import {
    replacePinwheelSurvey,
    fetchPinwheelSurveyError,
} from '@perpay-web/fintech/actions/entities/surveys';
import { replacePinwheelSurveyQuestions } from '@perpay-web/fintech/actions/entities/surveyQuestions';
import { replacePinwheelSurveyOptions } from '@perpay-web/fintech/actions/entities/surveyOptions';

export function fetchPinwheelSurvey(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_PINWHEEL_SURVEY),
        exhaustMap(() => get(`${SURVEYS_ENDPOINT}pinwheel-exit-survey/`)),
        mergeMap((results) => {
            const normalized = normalize(results.response, survey);
            return [
                replacePinwheelSurvey(normalized.entities[SURVEYS]),
                replacePinwheelSurveyQuestions(
                    normalized.entities[SURVEY_QUESTIONS],
                ),
                replacePinwheelSurveyOptions(
                    normalized.entities[SURVEY_OPTIONS],
                ),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchPinwheelSurveyError(error),
        ]),
    );
}
