import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { paths } from '@perpay-web/fintech/props/appPaths';
import { getIsAuthenticated } from '@perpay-web/fintech/selectors/authentication';

export const RedirectFallbackContainer = () => {
    const isAuthenticated = useSelector(getIsAuthenticated);

    return (
        <Navigate
            to={{
                pathname: isAuthenticated
                    ? paths.dashboard.path
                    : paths.login.path,
                search: window.location.search,
            }}
        />
    );
};
