import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    downloadCardStatement,
    cardStatementsDownloadSuccess,
    cardStatementsDownloadError,
    resetCardStatementsDownload,
} from '@perpay-web/fintech/actions/entities/cardStatements';

const DownloadCardStatementDataModule = createDataModule({
    dataRequest: downloadCardStatement,
    dataSuccess: cardStatementsDownloadSuccess,
    dataError: cardStatementsDownloadError,
    dataReset: resetCardStatementsDownload,
});

const getRoot = (state) => state.dataModules.downloadCardStatement;

const initialValue = {};

export const downloadCardStatementDataModule = DownloadCardStatementDataModule({
    getRoot,
    initialValue,
});
