import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importPaymentSetupContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "PaymentSetupContainer" */
                '@perpay-web/fintech/components/containers/PaymentSetupContainer'
            ),
    );
