import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchPinwheelABTestDataModule,
    getPinwheelABTestEnabled,
} from '@perpay-web/fintech/dataModules/fetchPinwheelABTest';
import {
    fetchJobsDataModule,
    getIsCompanyUnverified,
    getIsPerpaySplitEligible,
    getCompanyName,
} from '@perpay-web/fintech/dataModules/fetchJobs';

const DirectDepositUpdateEmployerAndAmountDataModule = composeDataModules([
    fetchPinwheelABTestDataModule,
    fetchJobsDataModule,
]);

const getRoot = (state) =>
    state.dataModules.directDepositUpdateEmployerAndAmount;

export const directDepositUpdateEmployerAndAmountDataModule =
    DirectDepositUpdateEmployerAndAmountDataModule({
        getRoot,
        key: 'DIRECT_DEPOSIT_UPDATE_EMPLOYER_AND_AMOUNT',
    });

export const getIsPinwheelEligible = (state) => {
    const isCompanyUnverified = getIsCompanyUnverified(state);
    if (isCompanyUnverified) return true;
    return getPinwheelABTestEnabled(state);
};

export { getIsPerpaySplitEligible, getCompanyName };
