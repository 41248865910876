import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importDirectDepositActivityContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "DirectDepositActivityContainer" */
                '@perpay-web/fintech/components/containers/DirectDepositActivityContainer'
            ),
    );
