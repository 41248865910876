import { ofType } from 'redux-observable';
import { mergeMap, exhaustMap } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { ADS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    replaceAds,
    fetchAds as fetchAdsAction,
    fetchAdsError,
} from '@perpay-web/fintech/reducers/entities/ads';

export function fetchAds(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchAdsAction.type),
        exhaustMap(() => get(ADS_ENDPOINT)),
        mergeMap((results) => [replaceAds(results.response)]),
        handleErrorMessageWithFallback((error) => [fetchAdsError(error)]),
    );
}
