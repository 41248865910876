import { ofType } from 'redux-observable';
import { switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import {
    validateAddressForMVP2,
    validateAddressSuccessForMVP2,
    validateAddressErrorForMVP2,
    validateAddressResetForMVP2,
} from '@perpay-web/fintech/actions/ui/addressValidation';
import { getABTestEnabled } from '@perpay-web/fintech/dataModules/fetchABTests';
import { METAL_CARD_STORE_NAME } from '@perpay-web/constants/experiments';
import { METAL_CARD_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';
import { ADDRESS_VALIDATION_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import { updateUserInfoForCardOnboardingMVP2DataModule } from '@perpay-web/fintech/dataModules/updateUserInfoForCardOnboardingMVP2';
import {
    ADDRESS_REJECTED,
    CARD_ONBOARDING_MVP_2_STEPS,
} from '@perpay-web/fintech/constants/steps/cardOnboardingMVP2Steps';
import * as metalSteps from '@perpay-web/fintech/constants/steps/cardOnboardingMetalSteps';
import { recommendedAddressSameAsOriginal } from '@perpay-web/utils/addressUtils';
import statesIneligibleForCard from '@perpay-web/fintech/constants/statesIneligibleForCardCardSignupMVP';
import { cardAddAddressModalSetIsModalOpen } from '@perpay-web/fintech/hooks/useCardAddAddressModalContext';

export const validateAddressesForMVP2 = (action$, state$, { post }) =>
    action$.pipe(
        ofType(validateAddressForMVP2().type),
        switchMap((action) => {
            const requestBody = action.payload;

            return emitOrTimeout(
                post(ADDRESS_VALIDATION_ENDPOINT, requestBody),
            );
        }),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const addressValidation = results.response;

            const {
                addressChangeRecommended,
                originalAddress,
                uspsAddressNotFound,
                validatedAddress,
            } = addressValidation;

            const streetAddr = originalAddress.street2
                ? `${originalAddress.street1} ${originalAddress.street2}`
                : `${originalAddress.street1}`;

            const addressToSubmit = {
                streetAddr,
                city: originalAddress.city,
                state: originalAddress.state,
                zipcode: originalAddress.zipcode,
            };

            // drop out of the flow if the state is not supported for card
            if (statesIneligibleForCard.includes(originalAddress.state)) {
                const isMetalCardFlow =
                    METAL_CARD_IS_LAUNCHED &&
                    getABTestEnabled(state, METAL_CARD_STORE_NAME);
                const reduxKey = isMetalCardFlow
                    ? Object.values(metalSteps)
                    : CARD_ONBOARDING_MVP_2_STEPS;
                const reduxStep = isMetalCardFlow
                    ? metalSteps.ADDRESS_REJECTED_STEP
                    : ADDRESS_REJECTED;
                return [
                    updateUserInfoForCardOnboardingMVP2DataModule.dataRequest(
                        addressToSubmit,
                    ),
                    // Reset the requestState to get rid of the infinite loader when we move to back to the previous screen
                    validateAddressResetForMVP2(),
                    reduxStepsSetStep(reduxKey, reduxStep),
                ];
            }
            if (
                (addressChangeRecommended &&
                    !recommendedAddressSameAsOriginal(
                        validatedAddress,
                        originalAddress,
                    )) ||
                uspsAddressNotFound
            ) {
                return [
                    validateAddressSuccessForMVP2(addressValidation),
                    cardAddAddressModalSetIsModalOpen(true),
                ];
            }

            // Update the address if there is no recommended address change
            return [
                validateAddressSuccessForMVP2(addressValidation),
                updateUserInfoForCardOnboardingMVP2DataModule.dataRequest(
                    addressToSubmit,
                ),
            ];
        }),
        handleErrorMessageWithFallback((error) => [
            validateAddressErrorForMVP2(error),
        ]),
    );
