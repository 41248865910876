import { ofType } from 'redux-observable';
import { mergeMap, switchMap } from 'rxjs/operators';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import {
    fetchReferralStats as fetchReferralStatsAction,
    replaceReferralStats,
    fetchReferralStatsError,
} from '@perpay-web/fintech/actions/ui/referralStats';
import { REFERRAL_STATS_ENDPOINT } from '@perpay-web/fintech/constants/urls';

export const fetchReferralStats = (action$, state$, { get }) =>
    action$.pipe(
        ofType(fetchReferralStatsAction().type),
        switchMap(() => get(REFERRAL_STATS_ENDPOINT)),
        mergeMap((results) => {
            const { amount, count } = results.response;
            return [replaceReferralStats(amount, count)];
        }),
        handleErrorMessageWithFallback((errors) => [
            fetchReferralStatsError(errors),
        ]),
    );
