import { STORE_ACCOUNT_SUMMARY_REPLACE } from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    outstandingBalance: 0,
    lateBalance: 0,
    minimumPayment: 0,
    nextPayment: {
        amount: 0,
        source: null,
        date: null,
    },
    previousPayment: {
        amount: 0,
        source: null,
        date: null,
        isMissed: false,
    },
    paymentPlan: {
        dayOfWeek: null,
        frequency: null,
        amount: 0,
    },
    status: 'current',
    userStatus: 'good',
    availableSpendingLimit: 0,
};

const accountSummaryReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_ACCOUNT_SUMMARY_REPLACE:
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default accountSummaryReducer;
