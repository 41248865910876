export const TAKEOVER_STEP = 'takeover';
export const REVIEW_COMPANY_STEP = 'review-company';
export const EDIT_COMPANY_STEP = 'edit-company';
export const COMPANY_REJECTED_STEP = 'company-rejected';
export const ADD_ADDRESS_STEP = 'add-address';
export const ADDRESS_REJECTED_STEP = 'address-rejected';
export const IDENTITY_VERIFICATION_STEP = 'verify-identity';
export const SELECT_MATERIAL_STEP = 'select-material';
export const REVIEW_AND_SUBMIT_TERMS_AND_CONDITIONS_STEP = 'review-terms';
export const SUBMITTING_STEP = 'submitting-acceptance';
export const APPROVED_STEP = 'approved';
export const REVIEW_DIRECT_DEPOSIT_STEP = 'review-direct-deposit';
export const SELECT_DIRECT_DEPOSIT_AMOUNT_STEP = 'select-direct-deposit-amount';
export const PINWHEEL_STEP = 'pinwheel';
export const PINWHEEL_ERROR_STEP = 'pinwheel-error';
export const APPLICATION_ERROR_STEP = 'application-error';
export const CLARITY_UNFREEZE_STEP = 'clarity-unfreeze';
export const DENIED_STEP = 'denied';
export const SUCCESS_STEP = 'success';
export const UPLOAD_DOCUMENTS_STEP = 'upload-documents';
export const IN_REVIEW_STEP = 'in-review';
export const MANUAL_PAYMENT_STEP = 'manual-payment';
