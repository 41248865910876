import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createBankAccount,
    createBankAccountSuccess,
    createBankAccountError,
    createBankAccountReset,
} from '@perpay-web/fintech/actions/entities/banks';

const CreateBankAccountDataModule = createDataModule({
    dataRequest: createBankAccount,
    dataSuccess: createBankAccountSuccess,
    dataError: createBankAccountError,
    dataReset: createBankAccountReset,
});

const getRoot = (state) => state.dataModules.createBankAccount;
const initialValue = {};

export const createBankAccountDataModule = CreateBankAccountDataModule({
    getRoot,
    initialValue,
});
