import {
    BACKEND_VERIFY_IDENTITY,
    STORE_VERIFY_IDENTITY_SUCCESS,
    STORE_VERIFY_IDENTITY_SERVICE_ERROR,
    STORE_VERIFY_IDENTITY_ERROR,
    STORE_VERIFY_IDENTITY_MOUNTED,
    ANALYTICS_ATTEMPT_IDENTITY_VERIFICATION,
    ANALYTICS_IDENTITY_VERIFICATION_FAILURE,
    ANALYTICS_IDENTITY_VERIFICATION_CLICK_CONTACT_US,
} from '@perpay-web/fintech/constants/actionTypes';

export function verifyIdentity(firstName, lastName, ssn) {
    return {
        type: BACKEND_VERIFY_IDENTITY,
        payload: {
            firstName,
            lastName,
            ssn,
        },
    };
}

export function verifyIdentitySuccess(payload) {
    return {
        type: STORE_VERIFY_IDENTITY_SUCCESS,
        payload,
    };
}

export function verifyIdentityError(errors) {
    return {
        type: STORE_VERIFY_IDENTITY_ERROR,
        payload: errors,
    };
}

export function verifyIdentityServiceError(errors) {
    return {
        type: STORE_VERIFY_IDENTITY_SERVICE_ERROR,
        payload: errors,
    };
}

export function verifyIdentityMounted() {
    return {
        type: STORE_VERIFY_IDENTITY_MOUNTED,
    };
}

export function analyticsAttemptIdentityVerification(checkoutUUID) {
    return {
        type: ANALYTICS_ATTEMPT_IDENTITY_VERIFICATION,
        payload: checkoutUUID,
    };
}

export function analyticsIdentityVerificationFailure(checkoutUUID) {
    return {
        type: ANALYTICS_IDENTITY_VERIFICATION_FAILURE,
        payload: checkoutUUID,
    };
}

export function analyticsIdentityVerificationClickContactUs() {
    return {
        type: ANALYTICS_IDENTITY_VERIFICATION_CLICK_CONTACT_US,
    };
}
