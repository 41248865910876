import {
    BACKEND_CREATE_EMPLOYMENT,
    BACKEND_UPDATE_EMPLOYMENT,
    CARD_UPDATE_SALARY_INFO,
    CARD_CREATE_PRIMARY_JOB,
    STORE_UPDATE_EMPLOYMENT_SUCCESS,
} from '@perpay-web/fintech/constants/actionTypes';

export function profileUpdateJob(uuid, name, payCycle) {
    return {
        type: BACKEND_CREATE_EMPLOYMENT,
        payload: {
            name,
            uuid,
            payCycle,
        },
    };
}

export function profileUpdatePaycycle(uuid, payCycle, name) {
    return {
        type: BACKEND_UPDATE_EMPLOYMENT,
        payload: {
            name,
            uuid,
            payCycle,
        },
    };
}

export function profileUpdatePaycycleSuccess() {
    return { type: STORE_UPDATE_EMPLOYMENT_SUCCESS };
}

export function cardUpdateSalaryInfo(uuid, company, payCycle, jobUuid) {
    return {
        type: CARD_UPDATE_SALARY_INFO,
        payload: {
            company,
            uuid,
            payCycle,
            jobUuid,
        },
    };
}

export function cardCreatePrimaryJob(payload) {
    return {
        type: CARD_CREATE_PRIMARY_JOB,
        payload,
    };
}
