import {
    BACKEND_FETCH_PAYROLL_INSTRUCTIONS,
    STORE_REPLACE_PAYROLL_INSTRUCTIONS,
    BACKEND_FETCH_USER_STATUS,
    STORE_REPLACE_USER_STATUS,
    BACKEND_FETCH_JOBS,
    STORE_REPLACE_JOBS_ERROR,
    STORE_REPLACE_JOBS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {
    payrollInstructionsFetching: false,
    userStatusFetching: false,
    jobsFetching: false,
};

const instructionsReducer = (state = initialState, action = {}) => {
    const { type } = action;

    switch (type) {
        case BACKEND_FETCH_PAYROLL_INSTRUCTIONS:
            return { ...state, payrollInstructionsFetching: true };
        case STORE_REPLACE_PAYROLL_INSTRUCTIONS:
            return { ...state, payrollInstructionsFetching: false };
        case BACKEND_FETCH_USER_STATUS:
            return { ...state, userStatusFetching: true };
        case STORE_REPLACE_USER_STATUS:
            return { ...state, userStatusFetching: false };
        case BACKEND_FETCH_JOBS:
            return { ...state, jobsFetching: true };
        case STORE_REPLACE_JOBS_ERROR:
        case STORE_REPLACE_JOBS:
            return { ...state, jobsFetching: false };
        default:
            return state;
    }
};

export default instructionsReducer;
