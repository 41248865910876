import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createBankAccountForCardAutoPay,
    createBankAccountForCardAutoPaySuccess,
    createBankAccountForCardAutoPayError,
    createBankAccountForCardAutoPayReset,
} from '@perpay-web/fintech/actions/ui/banks';

const CreateBankAccountForCardAutoPayDataModule = createDataModule({
    dataRequest: createBankAccountForCardAutoPay,
    dataSuccess: createBankAccountForCardAutoPaySuccess,
    dataError: createBankAccountForCardAutoPayError,
    dataReset: createBankAccountForCardAutoPayReset,
});

const getRoot = (state) => state.dataModules.createBankAccountForCardAutoPay;
const initialValue = {};

export const createBankAccountForCardAutoPayDataModule =
    CreateBankAccountForCardAutoPayDataModule({
        getRoot,
        initialValue,
    });
