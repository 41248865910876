import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    coreSummaryDataModule,
    getPaymentPlanSummary,
} from '@perpay-web/fintech/dataModules/coreSummary';
import { fetchMarketplaceDashboardSummaryDataModule } from '@perpay-web/fintech/dataModules/fetchMarketplaceDashboardSummary';
import { fetchActivePaymentsDataModule } from '@perpay-web/fintech/dataModules/fetchActivePayments';

const MarketplaceDetailsDataModule = composeDataModules([
    coreSummaryDataModule,
    fetchActivePaymentsDataModule,
    fetchMarketplaceDashboardSummaryDataModule,
]);

const getRoot = (state) => state.dataModules.marketplaceDetails;

export const marketplaceDetailsDataModule = MarketplaceDetailsDataModule({
    getRoot,
    key: 'MARKETPLACE_DETAILS',
});

export { getPaymentPlanSummary };
