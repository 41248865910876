import {
    APP_UPDATE_POLL_STOP,
    APP_UPDATE_POLL_START,
    APP_UPDATE_QUEUE_REFRESH,
} from '@perpay-web/fintech/constants/actionTypes';

export const appUpdatePollStart = () => ({
    type: APP_UPDATE_POLL_START,
});

export const appUpdatePollStop = () => ({
    type: APP_UPDATE_POLL_STOP,
});

export function appUpdateQueueRefresh(version) {
    return {
        type: APP_UPDATE_QUEUE_REFRESH,
        payload: { version },
    };
}
