import { getIsFetchingABTest } from '@perpay-web/fintech/selectors/shared/abTests';
import { currentDeductionsExist } from '@perpay-web/fintech/selectors/entities/deductions';

export const getIsSubmittingJob = (state) =>
    state.ui.pinwheel.isSubmittingJob || false;

export const getFetchingPinwheelToken = (state) =>
    state.ui.pinwheel.fetchingPinwheelToken;

export const getErrors = (state) => state.ui.pinwheel.errors;

export const getShowCurrentDeductionScreens = (state) =>
    currentDeductionsExist(state);

const getIsLoadingPinwheelEligibility = (state) =>
    getIsFetchingABTest(state) ||
    state.ui.pinwheel.fetchingUserStatus ||
    state.ui.pinwheel.fetchingDeductions;

export const getIsLoading = (state) =>
    getIsLoadingPinwheelEligibility(state) ||
    getIsSubmittingJob(state) ||
    state.ui.pinwheel.fetchingAccountSummary ||
    state.ui.pinwheel.fetchingJobs ||
    state.ui.pinwheel.patchingDeductions;

export const getAmountToCharge = (state) => state.ui.pinwheel.amountToCharge;

export const getRoot = (state) => state.ui.pinwheel;
