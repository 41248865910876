import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchCreditScoreHistoryAction = createDataModuleActions(
    'FETCH_CREDIT_SCORE_HISTORY',
);
export const fetchCreditScoreHistory =
    fetchCreditScoreHistoryAction.dataRequest;
export const fetchCreditScoreHistorySuccess =
    fetchCreditScoreHistoryAction.dataSuccess;
export const fetchCreditScoreHistoryError =
    fetchCreditScoreHistoryAction.dataError;
export const fetchCreditScoreHistoryReset =
    fetchCreditScoreHistoryAction.dataReset;
