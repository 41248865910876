import { exhaustMap } from 'rxjs/operators';
import { dispatchObservable } from '@perpay-web/observable/dispatchObservable';
import { ofType } from '@perpay-web/observable/operators/ofType';
import { createDataModule } from '@perpay-web/data-module/createDataModule';
import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const getProvidedAction = (requestAction, dataModule) => {
    if (!requestAction.payload) {
        return null;
    }

    if (!requestAction.payload.actions) {
        return null;
    }

    const providedAction = requestAction.payload.actions.find(
        (action) => action.type === dataModule.dataRequest().type,
    );
    if (!providedAction) {
        return null;
    }

    return providedAction;
};

export const composeDataModules =
    (queryDataModules) =>
    ({ getRoot, key }) => {
        const { dataRequest, dataError, dataSuccess, dataReset } =
            createDataModuleActions(`COMPOSED::${key}`);

        const composedEpic = (action$, state$) =>
            action$.pipe(
                ofType(dataRequest().type),
                exhaustMap((composedAction) =>
                    dispatchObservable({
                        action$,
                        state$,
                        initialDispatch: queryDataModules.map((dataModule) => {
                            const providedAction = getProvidedAction(
                                composedAction,
                                dataModule,
                            );
                            if (providedAction) {
                                return providedAction;
                            }
                            return dataModule.dataRequest();
                        }),
                        waitFor: queryDataModules.map(
                            (dataModule) => dataModule.SUCCESS_ACTION,
                        ),
                        // Composed data modules do not have data of their own to store, but we do
                        // dispatch the replace action so the reducer can update the internal loading state.
                        waitForDispatch: () => [dataSuccess(null)],
                        errors: queryDataModules.map(
                            (dataModule) => dataModule.ERROR_ACTION,
                        ),
                        errorDispatch: (errorAction) => [
                            dataError(errorAction.payload),
                        ],
                    }),
                ),
            );

        return createDataModule({
            dataRequest,
            dataError,
            dataSuccess,
            dataReset,
            composedEpic,
        })({ getRoot, initialValue: null });
    };
