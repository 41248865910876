import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    cardStatementsDataModule,
    getCardStatements,
} from '@perpay-web/fintech/dataModules/composed/cardStatements';
import {
    fetchCardActivityDataModule,
    getCardActivity,
    getPageCount,
} from '@perpay-web/fintech/dataModules/fetchCardActivity';

const CardActivityDataModule = composeDataModules([
    cardStatementsDataModule,
    fetchCardActivityDataModule,
]);

const getRoot = (state) => state.dataModules.cardActivity;

export const cardActivityDataModule = CardActivityDataModule({
    getRoot,
    key: 'CARD_ACTIVITY',
});

export { getCardActivity, getCardStatements, getPageCount };
