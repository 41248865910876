import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchDataModuleActions = createDataModuleActions('FETCH_CREDIT_LIMITS');
export const fetchCreditLimits = fetchDataModuleActions.dataRequest;
export const fetchCreditLimitsSuccess = fetchDataModuleActions.dataSuccess;
export const fetchCreditLimitsError = fetchDataModuleActions.dataError;
export const fetchCreditLimitsReset = fetchDataModuleActions.dataReset;

const updateDataModuleActions = createDataModuleActions('UPDATE_CREDIT_LIMIT');
export const updateCreditLimit = updateDataModuleActions.dataRequest;
export const updateCreditLimitSuccess = updateDataModuleActions.dataSuccess;
export const updateCreditLimitError = updateDataModuleActions.dataError;
export const updateCreditLimitReset = updateDataModuleActions.dataReset;
