import { composeDataModules } from '@perpay-web/data-module/composeDataModules';
import {
    fetchJobsDataModule,
    getIsPerpaySplitEligible,
    getIsCompanyUnverified,
} from '@perpay-web/fintech/dataModules/fetchJobs';
import {
    fetchUserStatusDataModule,
    getUserIsFirstTimeBorrowerInAwaitingPayment,
} from '@perpay-web/fintech/dataModules/fetchUserStatus';

const PayrollInstructionsFlow = composeDataModules([
    fetchJobsDataModule,
    fetchUserStatusDataModule,
]);

const getRoot = (state) => state.dataModules.payrollInstructionsFlow;

export const payrollInstructionsFlowDataModule = PayrollInstructionsFlow({
    getRoot,
    key: 'PAYROLL_INSTRUCTIONS_FLOW',
});

export const getUserIsInboundPhoneTestEligible = (state) =>
    getUserIsFirstTimeBorrowerInAwaitingPayment(state) &&
    !getIsCompanyUnverified(state) &&
    !getIsPerpaySplitEligible(state);

export { getIsCompanyUnverified } from '@perpay-web/fintech/dataModules/fetchJobs';
export { getUserStatusToInstructionsType } from '@perpay-web/fintech/dataModules/fetchUserStatus';
