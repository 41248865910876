import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchOrdersForDataModule,
    fetchOrdersForDataModuleSuccess,
    fetchOrdersForDataModuleError,
    fetchOrdersForDataModuleReset,
} from '@perpay-web/fintech/actions/entities/orders';
import { APPROVED, CURRENT } from '@perpay-web/fintech/constants/orderStatuses';

const FetchOrdersDataModule = createDataModule({
    dataRequest: fetchOrdersForDataModule,
    dataSuccess: fetchOrdersForDataModuleSuccess,
    dataError: fetchOrdersForDataModuleError,
    dataReset: fetchOrdersForDataModuleReset,
});

const getRoot = (state) => state.dataModules.fetchOrders;
const initialValue = {
    all: [],
};

export const fetchOrdersDataModule = FetchOrdersDataModule({
    getRoot,
    initialValue,
});

export const getHasApprovedOrders = (state) => {
    const { all } = fetchOrdersDataModule.getData(state);
    const approvedOrders = all.filter(({ status }) => status === APPROVED);
    return approvedOrders.length > 0;
};

export const getHasRepaymentOrders = (state) => {
    const { all } = fetchOrdersDataModule.getData(state);
    const repaymentOrders = all.filter(({ status }) => status === CURRENT);
    return repaymentOrders.length > 0;
};
