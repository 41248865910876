import { useNavigate } from 'react-router-dom';
import { useMount } from '@perpay-web/hooks/useMount';
import { CARD_PARTNER_ONBOARDING_APPLY } from '@perpay-web/constants/paths';

const PhoExistingUserNotification = () => {
    const navigate = useNavigate();

    useMount(() => {
        navigate(`${CARD_PARTNER_ONBOARDING_APPLY}?existing_user=true`, {
            replace: true,
        });
    });
};

export default PhoExistingUserNotification;
