import {
    STORE_REPLACE_DEDUCTION,
    STORE_RESET_DEDUCTIONS,
} from '@perpay-web/fintech/constants/actionTypes';

const initialState = {};

const deductionReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_REPLACE_DEDUCTION:
            return { ...state, ...payload };
        case STORE_RESET_DEDUCTIONS:
            return initialState;
        default:
            return state;
    }
};

export default deductionReducer;
