import {
    TRACKING_PAGEVIEW_ENDPOINT,
    TRACKING_ITERABLE_TRACK_PUSH_OPEN_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import {
    ITERABLE_TRACK_PUSH_OPEN,
    PAGE_VIEW,
} from '@perpay-web/fintech/beacon/eventDefinitions/DjangoTracking';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { authenticatedJsonFetch } from '@perpay-web/authentication/authenticatedJsonFetch';
import { noop } from '@perpay-web/utils/noop';
import { HTTPMethod } from '@perpay-web/constants/httpMethods';

const DjangoTrackingTargetFactory = () => {
    // Don't try to make tracking requests in the testing environment
    if (typeof window === 'undefined') {
        return noop;
    }

    return (events) => {
        events.forEach((event) => {
            switch (event.type) {
                case PAGE_VIEW: {
                    // TODO: convert this to use a non-XHR request
                    const httpRequest = new window.XMLHttpRequest();
                    httpRequest.open(
                        HTTPMethod.post,
                        TRACKING_PAGEVIEW_ENDPOINT,
                    );
                    httpRequest.setRequestHeader(
                        'Content-Type',
                        'application/json',
                    );
                    if (window.WITH_CREDENTIALS) {
                        httpRequest.setRequestHeader(
                            'X-Requested-With',
                            'XMLHttpRequest',
                        );
                        // `withCredentials` is needed for cookies to be received and set
                        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
                        httpRequest.withCredentials = true;
                    }

                    if (!authentication.getIsAccessTokenExpired()) {
                        httpRequest.setRequestHeader(
                            'AUTHORIZATION',
                            `Bearer ${authentication.getAccessToken()}`,
                        );
                    }
                    httpRequest.send(JSON.stringify(event.payload));
                    break;
                }
                case ITERABLE_TRACK_PUSH_OPEN: {
                    authenticatedJsonFetch(
                        TRACKING_ITERABLE_TRACK_PUSH_OPEN_ENDPOINT,
                        event.payload,
                        HTTPMethod.post,
                    );
                    break;
                }
                default:
                    break;
            }
        });
    };
};

export default DjangoTrackingTargetFactory;
