import { createDataModuleActions } from '@perpay-web/data-module/createDataModuleActions';

const fetchAddressDetailsActions = createDataModuleActions(
    'FETCH_ADDRESS_DETAILS',
);
export const fetchAddressDetailsForDataModule =
    fetchAddressDetailsActions.dataRequest;
export const replaceAddressDetails = fetchAddressDetailsActions.dataSuccess;
export const addressDetailsError = fetchAddressDetailsActions.dataError;
export const resetAddressDetails = fetchAddressDetailsActions.dataReset;
