import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import { map, mergeMap, exhaustMap, withLatestFrom } from 'rxjs/operators';

import { handleError } from '@perpay-web/observable/operators/handleError';
import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { standardizeError } from '@perpay-web/utils/errorHandlers';
import { userInfo } from '@perpay-web/fintech/normalizers/schemas';
import { BACKEND_VERIFY_IDENTITY } from '@perpay-web/fintech/constants/actionTypes';
import { USER_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    verifyIdentitySuccess,
    verifyIdentityError,
    verifyIdentityServiceError,
    analyticsAttemptIdentityVerification,
    analyticsIdentityVerificationFailure,
} from '@perpay-web/fintech/actions/entities/identityVerification';
import { getCheckoutUUID } from '@perpay-web/fintech/selectors/ui/identityVerification';
import { replaceUserInfo } from '@perpay-web/fintech/actions/entities/userInfo';
import {
    IDENTITY_VERIFICATION_STEPS,
    SUCCESS_STEP,
    FAILURE_STEP,
} from '@perpay-web/fintech/constants/steps/identityVerificationSteps';
import { MAX_SSN_VERIFICATION_ATTEMPTS } from '@perpay-web/fintech/constants/identityVerificationConstants';

export function verifyIdentity(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_VERIFY_IDENTITY),
        withLatestFrom(state$),
        exhaustMap(([action, state]) =>
            patch(USER_ENDPOINT, action.payload).pipe(
                map((results) => [results, state]),
            ),
        ),
        mergeMap(([results, state]) => {
            const normalized = normalize(results.response, userInfo);
            const { ssnIsVerified, ssnVerificationAttempts } = results.response;
            const failedIdv =
                ssnVerificationAttempts >= MAX_SSN_VERIFICATION_ATTEMPTS;

            return [
                verifyIdentitySuccess(results.response),
                replaceUserInfo(normalized.entities.userInfo),
                analyticsAttemptIdentityVerification(getCheckoutUUID(state)),
                // failedIdv and ssnIsVerified will never be true at the same time
                ...(failedIdv
                    ? [
                          analyticsIdentityVerificationFailure(
                              getCheckoutUUID(state),
                          ),
                          reduxStepsSetStep(
                              IDENTITY_VERIFICATION_STEPS,
                              FAILURE_STEP,
                          ),
                      ]
                    : []),
                ...(ssnIsVerified
                    ? [
                          reduxStepsSetStep(
                              IDENTITY_VERIFICATION_STEPS,
                              SUCCESS_STEP,
                          ),
                      ]
                    : []),
            ];
        }),
        handleError((error) => {
            const standardizedError = standardizeError(error);
            if (error.status >= 500) {
                return [verifyIdentityServiceError(standardizedError)];
            }
            return [verifyIdentityError(standardizedError)];
        }),
    );
}
