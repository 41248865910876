import { DEDUCTIONS } from '@perpay-web/fintech/constants/tableNames';
import { CURRENT } from '@perpay-web/fintech/constants/deductionTypes';

export const getDeductionUUID = (state) => {
    const deductions = state.entities[DEDUCTIONS];
    const uuids = Object.keys(deductions);
    let latestUUID;

    if (uuids.length > 1) {
        latestUUID = uuids.reduce((result, uuid) =>
            deductions[uuid].tokenExpiration >
            deductions[result].tokenExpiration
                ? uuid
                : result,
        );
    } else {
        [latestUUID] = uuids;
    }

    return latestUUID;
};

export const currentDeductionsExist = (state) =>
    Object.keys(state.entities[DEDUCTIONS]).filter(
        (uuid) => state.entities[DEDUCTIONS][uuid].status === CURRENT,
    ).length > 0;

export const getDeductionToken = (state, deductionUUID) =>
    state.entities[DEDUCTIONS][deductionUUID].token;

export const getWasInstantShipped = (state, deductionUUID) =>
    state.entities[DEDUCTIONS][deductionUUID].instantShipped;
