/**
 * Update a nested object at some path inside a larger object
 *
 * Example Usage:
 *
 * ```
 * const myBigObject = {
 *     dogs: {
 *         goodGirls: {
 *             names: ['river'],
 *         },
 *     },
 * }
 * const newObject = deepMerge(myBigObject, ['dogs', 'goodBoyes', 'names'], ['kingsley', 'bart']);
 * console.log(newObject):
 * >const myBigObject = {
 * >    dogs: {
 * >        goodGirls: {
 * >            names: ['river'],
 * >        },
 * >        goodBoyes: {
 * >            names: ['kingsley', 'bart'],
 * >        },
 * >    },
 * >}
 * ```
 */
export const deepMerge = (initialObj, path, newValues) => {
    if (path.length === 1) {
        const newObject = { ...initialObj, [path[0]]: newValues };
        return newObject;
    }
    const currentPathLevel = path[0];
    const remainingPathParts = path.slice(1);
    const updatedSubObject = deepMerge(
        initialObj[currentPathLevel],
        remainingPathParts,
        newValues,
    );
    const updatedObject = {
        ...initialObj,
        [currentPathLevel]: updatedSubObject,
    };
    return updatedObject;
};

export const arrayBufferToJson = (arrayBuffer) => {
    const inputString = String.fromCharCode(...new Uint8Array(arrayBuffer));
    return JSON.parse(inputString);
};

export const deepCompare = (previous, current) => {
    if (!previous && !current) {
        return true;
    }

    if (typeof previous !== typeof current) {
        return false;
    }

    if (Array.isArray(previous) !== Array.isArray(current)) {
        return false;
    }

    if (Array.isArray(previous)) {
        if (previous.length !== current.length) {
            return false;
        }

        return previous.every((entry, index) =>
            deepCompare(entry, current[index]),
        );
    }

    if (typeof previous === 'object') {
        const sourceKeys = [
            ...Object.keys(previous),
            ...Object.getOwnPropertySymbols(previous),
        ];
        const targetKeys = [
            ...Object.keys(current),
            ...Object.getOwnPropertySymbols(current),
        ];

        if (sourceKeys.length !== targetKeys.length) {
            return false;
        }

        return sourceKeys.every((key) =>
            deepCompare(previous[key], current[key]),
        );
    }

    return previous === current;
};
