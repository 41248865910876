import { HOST, MAGENTO_HOST, API_URL_BASE } from '@perpay-web/constants/urls';

export const PRODUCT_IMAGE_BASE =
    'https://dy9fjybvjj7s7.cloudfront.net/product_images';

const CATEGORY_IMAGE_S3_BUCKET = window.PRODUCTION
    ? 'app.perpay.com'
    : 'app.grapejuiceclub.com';
export const CATEGORY_IMAGE_S3_BUCKET_URL = `https://${CATEGORY_IMAGE_S3_BUCKET}/storefront-category-images/`;

/* CONFIGURATIONS */
const API_SUBDOMAIN = 'ui';

/* BASE */
const MAGENTO_BASE_URL = `https://${MAGENTO_HOST}/rest/V1`;

/* ALGOLIA */
export const ALGOLIA_TOKEN_URL = `https://${API_SUBDOMAIN}.${HOST}/v2/auth/algolia/`;

/* MAGENTO */
export const MAGENTO_TOKEN_URL = `https://${API_SUBDOMAIN}.${HOST}/v2/auth/magento2/`;
export const MAGENTO_CART_ENDPOINT = `${MAGENTO_BASE_URL}/carts/mine`;
export const MAGENTO_CART_ITEMS_ENDPOINT = `${MAGENTO_BASE_URL}/carts/mine/items`;
export const MAGENTO_CART_TOTALS_ENDPOINT = `${MAGENTO_BASE_URL}/carts/mine/totals`;
export const MAGENTO_CART_CHECKOUT_ENDPOINT = `${MAGENTO_BASE_URL}/carts/mine/checkout`;
export const MAGENTO_SET_SHIPPING_ADDRESS_ENDPOINT = `${MAGENTO_BASE_URL}/carts/mine/shipping-information`;
export const MAGENTO_CALCULATE_TAX_ENDPOINT = `${MAGENTO_CART_ENDPOINT}/estimate-shipping-methods`;
export const MAGENTO_CART_PAYMENT_SECURE_ENDPOINT = `${MAGENTO_CART_ENDPOINT}/payment-secure/`;
export const MAGENTO_CART_COUPONS_ENDPOINT = `${MAGENTO_CART_ENDPOINT}/coupons/`;
const MAGENTO_TIRE_ENDPOINT = `${MAGENTO_BASE_URL}/tires`;
export const MAGENTO_TIRE_MAKES_ENDPOINT = `${MAGENTO_TIRE_ENDPOINT}/makes`;
export const MAGENTO_TIRE_MODELS_ENDPOINT = `${MAGENTO_TIRE_ENDPOINT}/models`;
export const MAGENTO_TIRE_YEARS_ENDPOINT = `${MAGENTO_TIRE_ENDPOINT}/years`;
export const MAGENTO_TIRE_RESULTS_ENDPOINT = `${MAGENTO_TIRE_ENDPOINT}/results`;

/* FINTECH */
export const USER_STOREFRONT_SUMMARY_URL = `${API_URL_BASE}/storefront/user-summary/`;
export const USERS_ENDPOINT = `${API_URL_BASE}/users/`;
export const ADDRESS_VALIDATION_ENDPOINT = `${API_URL_BASE}/address-validation/`;
export const CHECKOUT_BORROWER_CREDITS = `${API_URL_BASE}/checkout/borrower-credits/`;
export const BORROWER_CREDIT_SUMMARY_ENDPOINT = `${API_URL_BASE}/borrower-credit-summary/`;

/* EXTERNAL LINKS */
export const SUBMIT_PRODUCT_FORM =
    'https://docs.google.com/forms/d/e/1FAIpQLSf28f1m1y8igGtDU3R9aTZpGOdtnzU0WNBTyp3nT_5drLDiNw/viewform';
export const TIRE_BUYING_GUIDE =
    'https://help.perpay.com/en/articles/2511118-tire-buying-guide';
export const TIRE_REQUEST_FORM =
    'https://docs.google.com/forms/d/e/1FAIpQLSeMxAwhDgVwF3cp2Q8pEp0VGTSqsGlhM6QDro_n27l9LML32w/viewform';
