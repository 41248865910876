export const getOrFallback = (path, object, fallback) =>
    path.reduce(
        (currentObject, key) =>
            currentObject && currentObject[key] ? currentObject[key] : fallback,
        object,
    );

/**
 * Update a nested object at some path inside a larger object
 *
 * Example Usage:
 *
 * ```
 * const myBigObject = {
 *     dogs: {
 *         goodGirls: {
 *             names: ['river'],
 *         },
 *     },
 * }
 * const newObject = deepMerge(myBigObject, ['dogs', 'goodBoyes', 'names'], ['kingsley', 'bart']);
 * console.log(newObject):
 * >const myBigObject = {
 * >    dogs: {
 * >        goodGirls: {
 * >            names: ['river'],
 * >        },
 * >        goodBoyes: {
 * >            names: ['kingsley', 'bart'],
 * >        },
 * >    },
 * >}
 * ```
 */
export const deepMerge = (initialObj, path, newValues) => {
    if (path.length === 1) {
        const newObject = { ...initialObj, [path[0]]: newValues };
        return newObject;
    }
    const currentPathLevel = path[0];
    const remainingPathParts = path.slice(1);
    const updatedSubObject = deepMerge(
        initialObj[currentPathLevel],
        remainingPathParts,
        newValues,
    );
    const updatedObject = {
        ...initialObj,
        [currentPathLevel]: updatedSubObject,
    };
    return updatedObject;
};
