import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    createFeatureEnrollmentForPerpayPlus,
    createFeatureEnrollmentForPerpayPlusSuccess,
    createFeatureEnrollmentForPerpayPlusError,
    createFeatureEnrollmentForPerpayPlusReset,
} from '@perpay-web/fintech/actions/ui/featureEnrollments';

const CreateFeatureEnrollmentForPerpayPlusDataModule = createDataModule({
    dataRequest: createFeatureEnrollmentForPerpayPlus,
    dataSuccess: createFeatureEnrollmentForPerpayPlusSuccess,
    dataError: createFeatureEnrollmentForPerpayPlusError,
    dataReset: createFeatureEnrollmentForPerpayPlusReset,
});

const getRoot = (state) =>
    state.dataModules.createFeatureEnrollmentForPerpayPlus;
const initialValue = {};

export const createFeatureEnrollmentForPerpayPlusDataModule =
    CreateFeatureEnrollmentForPerpayPlusDataModule({
        getRoot,
        initialValue,
    });
