import { wrapRetry } from '@perpay-web/utils/importUtils';

export const importMarketplaceDetailsContainer = () =>
    wrapRetry(
        () =>
            import(
                /* webpackChunkName: "MarketplaceDetailsContainer" */
                '@perpay-web/fintech/components/containers/MarketplaceDetailsContainer'
            ),
    );
