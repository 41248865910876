import {
    BACKEND_FETCH_ACCOUNT_SUMMARY,
    STORE_ACCOUNT_SUMMARY_REPLACE,
    STORE_ACCOUNT_SUMMARY_ERROR,
} from '@perpay-web/fintech/constants/actionTypes';

export function fetchAccountSummary() {
    return {
        type: BACKEND_FETCH_ACCOUNT_SUMMARY,
    };
}

export function replaceAccountSummary(payload) {
    const {
        lateBalance,
        minimumPayment,
        nextPayment,
        outstandingBalance,
        paymentPlan,
        pendingPayment,
        previousPayment,
        userStatus,
        availableSpendingLimit,
    } = payload;

    return {
        type: STORE_ACCOUNT_SUMMARY_REPLACE,
        payload: {
            lateBalance,
            minimumPayment,
            nextPayment,
            outstandingBalance,
            paymentPlan,
            pendingPayment,
            previousPayment,
            userStatus,
            availableSpendingLimit,
        },
    };
}

export function fetchAccountSummaryError(error) {
    return {
        type: STORE_ACCOUNT_SUMMARY_ERROR,
        payload: error,
    };
}
