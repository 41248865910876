import { useState } from 'react';

export const useModal = (initialValue = false) => {
    const [isModalOpen, setIsModalOpen] = useState(initialValue);

    return {
        isModalOpen,
        setIsModalOpen,
    };
};
